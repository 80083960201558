import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Dos and Don'ts for Supporting Someone During Seizures</h3>
   
    <p>There could be a sudden, unforeseen instance when someone you care about experiences a seizure, leaving you feeling as though time has halted. In that split second, you may undergo uncertainty, desperately seeking guidance on providing the best support. While they may seem frightening, most seizures generally last between 30 seconds to two minutes, and stop on their own, causing no permanent harm. Yet, understanding the dos and don'ts in this situation can help you remain composed and offer the appropriate aid to the affected individual.</p>
<p>This blog will examine the dos and don'ts of supporting someone during a seizure episode. But first, let us understand seizures.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
