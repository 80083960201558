import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Frequently Asked Questions (FAQs) About General Physicians</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a general physician?</Accordion.Header>
                    <Accordion.Body>
                      <p>A general physician, also known as a family doctor or primary care physician, is a medical professional who provides comprehensive healthcare for individuals and families. They are trained to diagnose and treat a wide range of medical conditions and play a crucial role in preventive care.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What services do general physicians offer?</Accordion.Header>
                    <Accordion.Body>

                      <p>General physicians offer a broad spectrum of services, including routine check-ups, preventive care, vaccinations, management of chronic conditions, early disease detection, and coordination with specialists when necessary.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Why is it important to have a general physician?</Accordion.Header>
                    <Accordion.Body>

                      <p>Having a general physician is important for several reasons. They provide continuity of care, offer preventive services to maintain health, manage chronic conditions, and serve as a central point of contact for coordinating specialized care when needed.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How often should I see my general physician?</Accordion.Header>
                    <Accordion.Body>

                      <p>The frequency of visits may vary based on age, health status, and individual needs. However, a general guideline is to have an annual check-up for adults and more frequent visits for those with chronic conditions or specific health concerns.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What is the difference between a general physician and a specialist?</Accordion.Header>
                    <Accordion.Body>
                      <p>General physicians are trained to provide comprehensive care for a wide range of health issues, while specialists focus on specific medical fields or conditions. General physicians often act as coordinators, referring patients to specialists when necessary.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Can a general physician treat chronic conditions?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, general physicians are trained to manage and treat a variety of chronic conditions such as diabetes, hypertension, and asthma. They work in collaboration with specialists to ensure comprehensive and coordinated care.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How do I choose the right general physician for me?</Accordion.Header>
                    <Accordion.Body>
                      <p>Consider factors such as location, office hours, communication style, and any specific medical needs you may have. Ask for recommendations from friends, family, or colleagues, and schedule a meet-and-greet appointment to assess compatibility.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>What should I bring to my first appointment with a general physician?</Accordion.Header>
                    <Accordion.Body>
                      <p>Bring a list of current medications, past medical records, information about your family's medical history, and any specific concerns or symptoms you may be experiencing. This helps your general physician understand your health better.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Do general physicians provide vaccinations?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, general physicians offer vaccinations as part of preventive care. They can provide routine vaccinations for children and adults, as well as recommend specific vaccinations based on individual health needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How do general physicians contribute to preventive care?</Accordion.Header>
                    <Accordion.Body>
                      <p>General physicians play a key role in preventive care by conducting regular check-ups, screenings, and providing guidance on healthy lifestyle choices. They focus on identifying and addressing health issues before they become more serious.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index