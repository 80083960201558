import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Third_Section from './Third_Section/index'
import Seventh_Section from './Seventh_Section/index'
import Faqs from './Faqs/index'


import { Helmet } from 'react-helmet'

export default function Home_New(){

    const faqscheme = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "Are home visit doctor services available 24/7 in Gurgaon?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Kyno Health's healthcare professionals are available 24/7 in Gurgaon to provide you with all types of medical attention."
        }
      },{
        "@type": "Question",
        "name": "Do home visit doctors in Gurgaon handle emergency medical situations?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, where ambulances are not accessible or are not on time, we come as the perfect resort to execute emergency medical attention with the highest quality equipment."
        }
      },{
        "@type": "Question",
        "name": "Can I receive ongoing care from a home visit doctor in Gurgaon?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sure you can! Kyno Health is not just for one-time consultations. In fact, we are always here to give you regular consultations and provide deeper insights into your progress."
        }
      },{
        "@type": "Question",
        "name": "What preparations are needed for a doctor's home visit in Gurgaon?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Your presence is all that matters. If you are trying to get checked for a chronic or ongoing problem, you can compile your previous medical reports. You may even create a comfortable spot for you to get the consultation."
        }
      },{
        "@type": "Question",
        "name": "What are the advantages of choosing a home visit doctor in Gurgaon?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A home visit saves you a lot of time during emergency situations where time is crucial. Even if it is not an emergency situation, sometimes time and circumstances keep you from visiting physical clinics. This is why a home visit doctor is great for helping you receive medical care right from home."
        }
      }]
    }
    
      

    return(
        <div>
                <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Emergency Home Visit Doctor Service in Gurgaon | Kyno Health</title>
                    <meta name="description" content="Get immediate medical attention at home with Kyno Health emergency home visit doctor service in Gurgaon. Contact us for emergency medical assistance at your doorstep."/>
                    <link rel="canonical" href="https://www.kynohealth.com/emergency-home-visit-doctor-gurgaon"/>

            </Helmet>
            <Nav/>
            <Hero/> 
            <Third_Section/>

            <Seventh_Section/>
            <Faqs/>
     
    <Footer/>

        </div>
    )
}