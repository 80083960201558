import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>The Importance of 24/7 Medical Care</h2>
<p>Health emergencies and unexpected medical issues can occur at any time. When faced with discomfort, pain, or uncertainty, having access to a doctor around the clock can provide a sense of relief and security. Our 24/7 doctor services ensure that you're never alone when navigating health concerns.</p>
<div className='ml-70'>
<div className='row'>

<h2>Advantages of 24/7 Doctor Services</h2>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Immediate Response</h4>
<p>Medical issues can't always wait for an appointment. Our 24/7 doctors are available to respond promptly to your medical needs, offering reassurance and addressing concerns in a timely manner.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience Redefined</h4>
<p>Forget about rushing to the clinic or waiting in long queues. Our doctors come to you, providing medical care in the comfort of your chosen location, whether it's your home, office, or even a hotel room.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Comprehensive Care</h4>
<p>Our 24/7 doctors offer a wide range of medical services, from diagnosing minor illnesses to addressing urgent medical situations. This comprehensive approach ensures you receive the care you need, when you need it.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Attention</h4>
<p>Each individual's health journey is unique. Our 24/7 doctors take the time to listen to your concerns, understand your medical history, and provide personalized treatment and guidance.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Peace of Mind</h4>
<p>Knowing that a qualified doctor is available 24/7 can provide peace of mind for you and your loved ones, especially during times of health-related uncertainty.</p>
</div>
</div>


</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>The 24/7 Doctor Experience</h2>
<p>When you choose our 24/7 doctor service, you're choosing convenience, care, and professionalism. Here's what you can expect:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem3'>
<h4>Easy Access</h4>
<p>Getting in touch with our 24/7 doctors is easy. Whether you prefer a phone call or an online consultation, we're available to address your medical concerns promptly.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Comprehensive Consultation</h4>
<p>Our doctors conduct thorough consultations to understand your symptoms, medical history, and concerns. This information guides their diagnosis and treatment recommendations.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>In-Person Visits</h4>
<p>If your situation requires an in-person examination, our doctors will come to your location. They'll perform necessary physical assessments, provide diagnoses, and offer treatment options.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Prescriptions and Referrals</h4>
<p>Depending on your diagnosis, our 24/7 doctors can provide prescriptions for medications and offer referrals for further medical care if necessary.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Follow-Up Care</h4>
<p>Our commitment doesn't end with the consultation. Our doctors ensure that you receive the necessary follow-up care and support to monitor your progress.

</p>
</div>
</div>

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Quality Care, Around the Clock</h2>
<p>We understand that medical needs can arise unexpectedly and at any hour. Our 24/7 doctor services are delivered with a focus on professionalism, expertise, and a patient-centered approach:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Qualified Professionals</h4>
<p>Our team of 24/7 doctors comprises licensed and experienced medical professionals who adhere to the highest standards of care.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Emergency Awareness</h4>
<p>While our service is designed for non-emergencies, our doctors are trained to recognize situations requiring immediate attention and guide patients to appropriate resources.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Continuity of Care</h4>
<p>If follow-up or ongoing treatment is required, our doctors ensure that you receive the necessary information and guidance for a seamless transition.</p>
</div>
</div>



{/* <div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will schedule follow-up visits as needed to monitor your progress and make adjustments to your treatment plan if required.</p>
</div>
</div> */}


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Access Quality Healthcare, Anytime
</h5>
<p>With "24/7 Doctor" services, you're never alone in your health journey. Our dedicated team of healthcare professionals is available around the clock to provide expert medical care when and where you need it. Prioritize your health and well-being – contact us today to experience the convenience and assurance of quality healthcare available 24/7.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index