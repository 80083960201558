import React, { useState } from 'react'
import logo from "../utilities/images/logo.png"
import { useNavigate } from 'react-router-dom'

import "../styles/refund_policy.scss"
export default function Refund_policy() {

    const navigate = useNavigate()
    return (
        <>
            <div className='refund-policy'>

                <div className='logo'>
                    <img src={logo} alt='Kyno Health' onClick={(e) => {
                        navigate("/")
                    }} />
                </div>
                <div className='title'>
                    Refund Policy
                </div>

                <div className='content'>
                    <div className='subtitle'>
                        Thank you for choosing Kyno Health, a division of Huami Technologies Pvt. Ltd. We are dedicated to providing convenient and reliable doctor consultation services at home. This Refund Policy outlines the terms and conditions for requesting and obtaining a refund from Kyno Health. Please read this policy carefully before making a purchase.
                    </div>

                    <div className='point'>
                        <div className='point-title'>1. Eligibility for Refunds:</div>
                        <div className='point-content'>1.1 Doctor Consultation Services:

                            <br />
                            &emsp;1.1.1 Refunds for doctor consultation services provided by Kyno Health may be available if the service has not been rendered or completed, subject to the following conditions:
                            <br />&emsp;&emsp; a) The refund request must be made before the doctor booking is confirmed.
                            <br />&emsp;&emsp; b) Once the doctor booking is confirmed, no refund will be provided.</div>
                    </div>

                    <div className='point'>
                        <div className='point-title'>2. Refund Process:</div>
                        <div className='point-content'>
                            2.1 Initiation of Refund Request:
                            <br />
                            &emsp;2.1.1 To initiate a refund request for doctor consultation services, please contact our customer support team at the following contact details:
                            <br />
                            &emsp;- Phone: 01206056623
                            <br />
                            &emsp;- Email: support@kynohealth.com
                            <br />
                            2.2 Evaluation of Refund Requests:
                            <br />
                            &emsp;2.2.1 Kyno Health will evaluate each refund request on a case-by-case basis. We reserve the right to refuse a refund if the request does not meet the eligibility criteria mentioned in this policy or if the doctor booking has already been confirmed.

                            <br />
                            2.3 Refund Approval and Process:
                            <br />
                            &emsp;2.3.1 If your refund request is approved and meets the eligibility criteria, Kyno Health will initiate the refund process within 72 hours of the confirmation.
                            <br />
                            &emsp;2.3.2 The refund will be processed using the original payment method used for the appointment unless otherwise specified by you. Please note that the processing time for refunds may vary depending on your payment provider.
                        </div>
                    </div>

                    <div className='point'>
                        <div className='point-title'>3. Non-Refundable Services:</div>
                        <div className='point-content'>
                            3.1 Doctor Consultation Services:
                            <br />
                            3.1.1 No refund will be provided if the doctor booking is already confirmed.
                        </div>
                    </div>
                    <div className='point'>
                        <div className='point-title'>4. Modifications and Termination:</div>
                        <div className='point-content'>
                            4.1 Kyno Health reserves the right to modify or update this Refund Policy at any time. Any changes will be effective upon posting the revised policy on our website or notifying you directly.
                            <br />
                            4.2 Kyno Health also reserves the right to terminate or discontinue refund options for specific services or promotions at its discretion.
                        </div>
                    </div>

                    <div className='point'>
                        <div className='point-title'>5. Contact Us:</div>
                        <div className='point-content'>

                            If you have any questions or concerns about our Refund Policy, please contact our customer support team at the following contact details:
                            <br/>

                            Phone: 01206056623
                            <br/>
                            Email: support@kynohealth.com
                            <br/>
                            Our customer support team will be happy to assist you.
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}
