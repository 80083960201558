import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet';

function index() {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Home Visit Doctors Near Me : Bringing Healthcare to Your Doorstep</title>
      <meta name="description"
      content="Access quality healthcare with home visit doctors near you. Convenient and personalized medical care in the comfort of your home. Schedule an appointment today." />
      <link rel="canonical" href="https://www.kynohealth.com/doctors-near-me/" />
    </Helmet>
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}

export default index