import React, { useEffect } from "react";
import Navbar from "./homePage/Navbar";
import "../styles/bookingConfirm.css"
import pic from "../utilities/images/bookingConfirmed.png"

export default function BookingConfirm(){


    useEffect(() => {
        document.title = "KYNO HEALTH | Doctor at your Home in 60 Minutes"
    }, [])
    return (
        <>
            <div>
                <Navbar />
                <div className="bookingConfirm">
                    <div className="title">
                    Thank you for reaching out to Kyno Health . Our medical coordinator will call you asap.
                    </div>
                    <div className="content">For any  queries,  call us on 7996643888</div>
                    <img src={pic} />
                </div>
            </div>
        </>
    )
}