import React, { useEffect } from 'react'
import ConsultUsFor from './homePage/ConsultUsFor'
import DoctorAtDoorstep from './homePage/doctorAtDoorstep'
import Footer from './homePage/footer'
import Meetoutdoctors from './homePage/meetOurdoctors'
import Navbar from './homePage/Navbar'
import OurServices from './homePage/OurServices'
import OverviewCarousel from './homePage/Overview'
import WhyChooseKynoHealth from './homePage/wckh'
import FAQ from './homePage/faq'
import Testimonials from './homePage/testimonials'
import CheckBox from './homePage/checkBox'
import "../styles/home.css"
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import logo from "../../src/utilities/images/logo.png"

export default function Home() {


    const navigate = useNavigate()
    const aboutUsClicked = () => {
        const elem = document.getElementById("aboutUs");
        if(elem){
            elem.scrollIntoView({'behavior': "smooth"})
        }
    }

    const ourServicesClicked = () => {
        const elem = document.getElementById("ourServices");
        if(elem){
            elem.scrollIntoView({'behavior': 'smooth'})
        }
    }

    const openInNewTab = (url) => {
        window.open(url, '_black', 'noreferrrer')
    }


    const gtag_report_conversion = (url) => {
        var callback = function(){
            if(typeof(url) != 'undefined'){
                window.location = url;
            }
        }

        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/KBFFCOiLhpEYEKGEvaUp',
            'event_callback': callback
        });

        return false;
        
    }

    const gtag_report_conversion_wa = (url) => {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/WTK8CKiy0ZMYEKGEvaUp',
            'event_callback': callback
        });
        return false;
      }

    function gtag() {
        window.dataLayer.push(arguments);
    }

    useEffect(() => {
        document.title = "KYNO HEALTH | Doctor at your Home in 60 Minutes"
        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', 'AW-11084448289')
        gtag('config', 'G-96HFKDRG6C');

    }, [])

    return (
        <>
            <div >
                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Kyno Health</title>
                    <meta name='description' content='Kyno Health' /> 
                    <meta property='og:image'  content={logo} />
                </Helmet>

                <Navbar aboutUsClicked={aboutUsClicked} ourServicesClicked={ourServicesClicked} Source="Website Book Appointment" />
                <OverviewCarousel phone_number={"01206056623"} />
                <OurServices id="ourServices" />
                <WhyChooseKynoHealth />
                {/* <CheckBox /> */}
                {/* <ConsultUsFor /> */}
                <Meetoutdoctors />
                <Testimonials />
                {/* <DoctorAtDoorstep /> */}
                <FAQ />
                <Footer phone_number={"01206056623"} />

                <div className='mobile-bottom'>
                    <div onClick={() => {
                        gtag_report_conversion_wa(window.location)
                        openInNewTab("https://tinyurl.com/2ek8y33y")
                    }}>Connect on WhatsApp</div>
                    <div className='phone' onClick={() => {
                        gtag_report_conversion(window.location);
                        openInNewTab("tel:01206056623")
                    }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg> Call Us</div>
                </div>
            </div>
        </>
    )
}