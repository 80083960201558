import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do I really find a good doctor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The internet can help you find a good doctor. Open your browser and type ' best home visit doctors near me', and you will be presented with top-notch recommendations from where you can browse their profiles and choose the one you find the best to fit your medical requirements."
      }
    },{
      "@type": "Question",
      "name": "How do I make a good doctor's appointment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Once you find a good doctor, simply reach out to their website and book an appointment. You can also call out the helpline numbers if provided for making an appointment with the home visit doctors based on their availability."
      }
    },{
      "@type": "Question",
      "name": "What type of doctor is best for primary care?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A family medical specialist or internal medicine specialist is the best kind of home visit doctors to look up to for primary care at the earliest."
      }
    },{
      "@type": "Question",
      "name": "How do I find the best surgeon in my area?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can easily search for the best surgeon in your area by using the web. Once you find a suitable recommendation, monitor their affiliations, expertise, experience, etc., to determine the potential of the surgeon."
      }
    }]
  }
  

  return (
    <>
     <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Tips for Finding the Best Home Visit Doctors | Kyno Health</title>
                    <meta name="description" content="Discover expert insights on finding the best home visit doctors with Kyno Health. Our blog offers invaluable tips and advice to ensure you receive top-quality medical care in the comfort of your own home. Trust Kyno Health for seamless, convenient, and personalized healthcare experiences." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/blog/tips-finding-best-home-visit-doctors" />

            </Helmet>
        
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}





export default index