import React from 'react'
import './Css/style.css'

function index() {
  return (

    <>
       <section className="baner-region_aboutus">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    {/* <h3>Meet our Doctors & Nurses</h3> */}
    <h1>Who we are</h1>
    <p>At Kyno, we are driven by the zeal to put &lsquo;kindness&rsquo; <br/> &amp; &lsquo;care&rsquo; back into modern medical services. Our mission is <br/> to make the best medical support accessible to society <br/> at large without burning a hole in their pocket.</p>
{/* <a className="btn btn-warning " href="#">Call us Now!</a> */}
  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  {/* <div className='aft'>
    <img src='images/Topology-1.svg'/>
    </div>
    <div className='tag'>
    <img src='images/Tags.png'/>
    </div> */}

    <img src='images/bnr_abt.svg' className='bnrimg img-fluid'/>
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
