import React from "react";
import "../../styles/howToBookAppointment.css"
import health from "../../utilities/images/health.png"
import heart from "../../utilities/images/heart.png"
import medicalEquipment from "../../utilities/images/medicalEquipment.png"
import one from "../../utilities/images/one.png"
import two from "../../utilities/images/two.png"
import three from "../../utilities/images/three.png"

export default function HowToBookAppointment() {


    return (
        <>
            <div className="howToBookAppointment">
                <div className="title">
                    How to book a consultation with <span>KYNO Health?</span>
                </div>
                <div className="howToBookCards">
                    <div>
                        <div className="img"><img src={health} /></div>
                        <p>Call us at<br/> 01206056623</p>
                        <img src={one} />
                    </div>
                    <div>
                        <div className="img"><img src={heart} /></div>
                        <p>Our team will connect with you to understand your concerns</p>
                        <img src={two} />
                    </div>
                    <div>
                        <div className="img"><img src={medicalEquipment} /></div>
                        <p>Metting with your preffered doctor</p>
                        <img src={three} />
                    </div>
                </div>
            </div>
        </>
    )
}