// import React, { useEffect, useState } from "react";
// import Footer from "./homePage/footer";
// import Meetoutdoctors from "./homePage/meetOurdoctors";
// import Navbar from "./homePage/Navbar";
// import Testimonials from "./homePage/testimonials";
// import WhyChooseKynoHealth from "./homePage/wckh";
// import "../styles/ad.css"
// import adPicture from "../utilities/images/adPicture.png"
// import axios from 'axios'
// import { useNavigate } from "react-router-dom";

// export default function AdPage() {

//     const navigate = useNavigate()

//     const openInNewTab = (url) => {
//         window.open(url, '_black', 'noreferrrer')
//     }

//     const [name, setName] = useState("")
//     const [phone, setPhone] = useState(0)
//     const [concern, setConcern] = useState("")

//     const [failedBookingMsg, setFailedBookingMsg] = useState("")
//     const [showFailedBookingMsg, setShowFailedBookingMsg] = useState(false)

//     let guid = () => {
//         let s4 = () => {
//             return Math.floor((1 + Math.random()) * 0x10000)
//                 .toString(16)
//                 .substring(1);
//         }
//         //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
//         return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
//     }

//     const gtag_report_conversion = (url) => {
//         var callback = function () {
//             if (typeof (url) != 'undefined') {
//                 window.location = url;
//             }
//         };

//         // gtag('event', 'conversion', {
//         //     'send_to': 'AW-11084448289/lT1OCOWLhpEYEKGEvaUp',
//         //     'event_callback': callback
//         // });
//         return false;

//     }

//     const onLeadGenerated = async () => {
//         const id = guid()
//         console.log(name, phone)
//         // let body = [
//         //     {
//         //         "Attribute": "FirstName",
//         //         "Value": name
//         //     },
//         //     {
//         //         "Attribute": "Mobile",
//         //         "Value": phone
//         //     },
//         //     {
//         //         "Attribute": "mx_Symptoms_or_Disease",
//         //         "Value": concern
//         //     },
//         //     {
//         //         "Attribute": "Source",
//         //         "Value": "Google"
//         //     }
//         // ]

//         let body = {
//             "name": name,
//             "phone": phone,
//             "concern": concern,
//             "guid": id
//         }
//         const result = false
//         await axios.post("http://localhost:8080/createNewLead", body, { headers: { "Content-Type": "application/json" } }).then((resp) => {
//             console.log(resp)
//             if (resp && resp.data.statusCode == 1) {
//                 navigate("/bookingConfirmed")
//                 result = true

//             }
//             else {
//                 setFailedMsg("Something went wrong!!")
//             }

//         }).catch((err) => {
//             console.log(err)
//             setFailedMsg("Something went wrong!!")
//         })

//         return result
//     }


//     const setFailedMsg = (msg) => {
//         setShowFailedBookingMsg(true);
//         setFailedBookingMsg(msg);
//         setTimeout(() => {
//             setShowFailedBookingMsg(false)
//         }, 2000);
//     }

//     const gtag_report_conversion_call = (url) => {
//         var callback = function () {
//             if (typeof (url) != 'undefined') {
//                 window.location = url;
//             }
//         }

//         // gtag('event', 'conversion', {
//         //     'send_to': 'AW-11084448289/KBFFCOiLhpEYEKGEvaUp',
//         //     'event_callback': callback
//         // });

//         return false;

//     }

//     useEffect(() => {
//         document.title = "Doctor at Home in 60 minutes | KYNO HEALTH"
//     }, [])

//     return (
//         <>
//             <div>
//                 <Navbar />

//                 <div className="ad-block">
//                     <div className="ad-content">
//                         <div className="heading">Looking for a Doctor Consultation?</div>
//                         <div className="description">What if the best doctor comes to your house?</div>
//                         <ul className="points">
//                             <li>Avoid long queues</li>
//                             <li>No more wasting energy on commuting</li>
//                             <li>Get treated at the familiar surroundings of your own home </li>
//                         </ul>
//                         <div className="call-btn" onClick={() => {
//                             gtag_report_conversion_call(window.location)
//                             openInNewTab("tel:7996353531")
//                         }}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white" ><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Us
//                         </div>
//                     </div>
//                     <img src={adPicture} />
//                     <div className="ad-form">
//                         <div className="name">
//                             <div>Your Name</div>
//                             <input onChange={(e) => {
//                                 setName(e.target.value)
//                             }} type="text" />
//                         </div>
//                         <div className="phone">
//                             <div>Your phone Number</div>
//                             <input type="number" onChange={(e) => {
//                                 setPhone(e.target.value)
//                             }} />
//                         </div>
//                         <div className="concern">
//                             <div>Concern</div>
//                             <input type="text" onChange={(e) => {
//                                 setConcern(e.target.value)
//                             }} />
//                         </div>
//                         <div className="book-btn" onClick={async () => {
//                             await onLeadGenerated()
//                             gtag_report_conversion(window.location)
//                         }}>Book Appointment</div>
//                     </div>
//                 </div>

//                 <Meetoutdoctors />
//                 <WhyChooseKynoHealth />
//                 <Testimonials />
//                 <Footer />

//                 <div className='mobile-bottom'>
//                     <div onClick={() => {
//                         openInNewTab("https://wa.me/01206056623")
//                     }}>Connect on WhatsApp</div>
//                     <div onClick={() => {
//                         openInNewTab("tel:7996353531")
//                     }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Us</div>
//                 </div>

//                 {
//                     showFailedBookingMsg &&
//                     <div className='failed-booking-message'>
//                         {failedBookingMsg}
//                     </div>
//                 }
//             </div>
//         </>
//     )
// }

import React, { useEffect, useRef, useState } from 'react'
import Footer from './homePage/footer'
import Meetoutdoctors from './homePage/meetOurdoctors'
import Navbar from './homePage/Navbar'
import OurServices from './homePage/OurServices'
import OverviewCarousel from './homePage/Overview'
import WhyChooseKynoHealth from './homePage/wckh'
import FAQ from './homePage/faq'
import Testimonials from './homePage/testimonials'
import "../styles/home.css"
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { localBackend } from '../utilities/constants'
import Popup from 'reactjs-popup'

import oc1 from "../utilities/images/overview.png"


export default function Adpage() {

    const navigate = useNavigate()
    const [name, setName] = useState("")
    const [phone, setPhone] = useState(0)
    const [concern, setConcern] = useState("")

    const [phoneErr, setPhoneErr] = useState("")
    const [failedBookingMsg, setFailedBookingMsg] = useState("")
    const [showFailedBookingMsg, setShowFailedBookingMsg] = useState(false)
    const [showFooter, setShowFooter] = useState(false)
    const [callbackSubmitSuccess, setCallbackSubmitSuccess] = useState(false)

    const callbackRef =  useRef()

    const onLeadGenerated = async () => {

        let p1 = validatePhone(callbackRef.current.value)
        let p2 = validatePhone(phone)
        let _phone  = 0
        if(p1){_phone = callbackRef.current.value}else{
            _phone = phone
        }
        let body = {
            "name": name,
            "phone": _phone,
            "concern": concern,
            "source": "Google Form"
        }
        const result = false;
        await axios.post(`${localBackend}createLead`, body, { headers: { "Content-Type": "application/json" } }).then((resp) => {
            console.log(resp)
            if (resp && resp.data.statusCode === 1) {
                navigate("/bookingConfirmed")
                setCallbackSubmitSuccess(true)
            }
            else {
                const elem = document.getElementsByClassName("modal")[0];
                elem.blur()
                setFailedMsg("Something went wrong!!")
            }
        }).catch((err) => {
            console.log(err)
            // const elem = document.getElementsByClassName("modal")[0];
            // elem.blur()
            setFailedMsg("Something went wrong!!")

        })

        // await axios.get("https://api.agify.io?name=michael").then((resp) => {
        //     console.log(resp)
        // }).catch((err) => {
        //     console.log(err)
        // })

        return result
    }

    const setFailedMsg = (msg) => {
        setShowFailedBookingMsg(true);
        setFailedBookingMsg(msg);
        setTimeout(() => {
            setShowFailedBookingMsg(false)
        }, 2000);
    }

    const aboutUsClicked = () => {
        const elem = document.getElementById("aboutUs");
        if (elem) {
            elem.scrollIntoView({ 'behavior': "smooth" })
        }
    }

    const ourServicesClicked = () => {
        const elem = document.getElementById("ourServices");
        if (elem) {
            elem.scrollIntoView({ 'behavior': 'smooth' })
        }
    }

    const openInNewTab = (url) => {
        window.open(url, '_black', 'noreferrrer')
    }


    const gtag_report_conversion = (url) => {
        console.log(url.href)
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        }

        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/KBFFCOiLhpEYEKGEvaUp',
            'event_callback': callback
        });

        return false;

    }

    const validatePhone = (phoneNo) => {
        var plen = Math.log(phoneNo) * Math.LOG10E + 1 | 0;
        if (plen !== 10) {
            setPhoneErr("Enter a 10-digit number")
            return "LENGTH-ERR"
        }

        var msb = Math.trunc(phoneNo / 1000000000)

        if (msb < 6 || msb > 9) {
            setPhoneErr("Phone Number Invalid")
            return "MSB-ERR"
        }

        setPhoneErr("")
        return "TRUE"  
    }

    const submitLead = async () => {

        const vr = validatePhone(phone)

        if (vr === "TRUE") {
            const result = await onLeadGenerated()
            console.log(result)
            gtag_report_conversion(window.location)
            if (!result) {
                return false
            }
        }
    }

    const requestCallback = async () => {

        const vr = validatePhone(callbackRef.current.value)

        if (vr === "TRUE") {
            const result = await onLeadGenerated()
            console.log(result)
            gtag_report_conversion(window.location)
            if (!result) {
                return false
            }
        }
    }

    // const gtag_report_conversion_wa = (url) => {
    //     var callback = function () {
    //         if (typeof (url) != 'undefined') {
    //             window.location = url;
    //         }
    //     };
    //     gtag('event', 'conversion', {
    //         'send_to': 'AW-11084448289/WTK8CKiy0ZMYEKGEvaUp',
    //         'event_callback': callback
    //     });
    //     return false;
    // }


    function gtag() {
        window.dataLayer.push(arguments);
    }



    useEffect(() => {
        document.title = "Doctor at Home in 60 minutes | Kyno Health";
        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', 'AW-11084448289')
        gtag('config', 'G-96HFKDRG6C');

        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            // console.log(scrollY)
            let elem = document.getElementsByClassName("mobile-bottom")[0]
            if (scrollY > 320) { // Set your desired scroll threshold here
                
                // elem.style.display = "flex"
                setShowFooter(true);
            } else {
                // elem.style.display = "none"
                setShowFooter(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [])

    return (
        <>
            <div >
                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    <script>
                        {`

                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:3520415,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                        `}
                    </script>
                    <title>Doctor at Home in 60 Minutes | Kyno Health</title>
      <meta name="description"
      content="Get medical care at your doorstep within 60 minutes with Kyno Health on-call doctor service. Experienced doctors, prompt response, and personalized care for your health needs." />
      <link rel="canonical" href="https://www.kynohealth.com/doctor-at-home-in-60-minutes/" />

                </Helmet>
                <Navbar aboutUsClicked={aboutUsClicked} ourServicesClicked={ourServicesClicked} Source="Google Form" isCallBtn={true} />
                {/* <OverviewCarousel phone_number={"7996353531"} /> */}
                <div className="overview-item">
                    <div className="overview-content">
                        <div className="title">
                            Doctor at your doorsteps in <span>60 minutes 24X7</span>
                        </div>
                        <div className="description">
                            KYNO Health provides expert doctor consultations at home in under an hour. Experience the top-quality medical treatment at the comfort of your home.
                        </div>
                        <div className="links">
                                <div className="book-btn" onClick={() => {
                                    gtag_report_conversion(window.location)
                                    openInNewTab(`tel:+917996643888`)
                                }}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg> Call Us</div>

                            {/*<div className='callback'>
                                <div className='head'>Enter Your Number To Get A CallBack</div>
                                <div className='input'>
                                    <div className='number'>
                                        <span>+91</span>
                                        <input ref={callbackRef} placeholder='Phone No.' type='number'></input>
                                    </div>
                                    <div className='phone-icon' onClick={(e) => {requestCallback()}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill={"#068c69"}><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
                                    </div>
                                </div>

                                {phoneErr !== "" && 
                                    <div className='callback-phone-err'>{phoneErr}</div>
                                }
                                {callbackSubmitSuccess && <div className='callback-submit-success'>
                                    Our Team Will Contact You Soon
                                    </div>}
                            </div>*/}

                            {/* <div className="book-btn" onClick={() => {
                                // gtag_report_conversion(window.location)
                                openInNewTab(`tel:+917996643888`)
                            }}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Us</div> */}

                            {/* <Popup trigger={<div className="contact-btn">Book Appointment</div>} modal nested >
                                {
                                    (close) => (
                                        <div className='modal'>
                                            <div className='content'>
                                                <div className='title'>Book Appointment</div>
                                                <div className='name-input'>
                                                    <div>Name</div>
                                                    <input type="text" onChange={(e) => {
                                                        setName(e.target.value)
                                                    }} />
                                                </div>
                                                <div className='phone-input'>
                                                    <div>Phone Number</div>
                                                    <input type="number" className={phoneErr !== "" ? "err-active" : "err-inactive"} onChange={(e) => {
                                                        setPhone(e.target.value)
                                                    }} />
                                                    {phoneErr !== "" &&
                                                        <div className='phone-err'>
                                                            {phoneErr}
                                                        </div>
                                                    }
                                                </div>
                                                <div className='concern-input'>
                                                    <div>Concern</div>
                                                    <input type="text" onChange={(e) => {
                                                        setConcern(e.target.value)
                                                    }} />
                                                </div>
                                                <div className='submit' onClick={() => {
                                                    const res = submitLead()
                                                    if (res === false) {
                                                        close()
                                                    }
                                                }}>Submit</div>

                                            </div>
                                        </div>
                                    )
                                }
                            </Popup> */}

                        </div>
                    </div>
                    <img src={oc1} />
                </div>
                <OurServices id="ourServices" />
                <WhyChooseKynoHealth />
                {/* <CheckBox /> */}
                {/* <ConsultUsFor /> */}
                {/*<Meetoutdoctors />*/}
                <Testimonials />
                {/* <DoctorAtDoorstep /> */}
                <FAQ />
                <Footer phone_number={"7996643888"} />


                {
                    <div className={`sticky-bottom ${showFooter == true ? "show" : "unshow"}` } id='mobile-bottom' >
                        <div className='phone' onClick={() => {
                            gtag_report_conversion(window.location);
                            openInNewTab("tel:+917996643888")
                        }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg> Call Us</div>
                        {/*<Popup trigger={
                            <div onClick={() => {
                                gtag_report_conversion(window.location)}}>Book Appointment</div>
                        } modal nested >{
                                (close) => (
                                    <div className='modal'>
                                        <div className='content'>
                                            <div className='title'>Book Appointment</div>
                                            <div className='name-input'>
                                                <div>Name</div>
                                                <input type="text" onChange={(e) => {
                                                    setName(e.target.value)
                                                }} />
                                            </div>
                                            <div className='phone-input'>
                                                <div>Phone Number</div>
                                                <input type="number" className={phoneErr !== "" ? "err-active" : "err-inactive"} onChange={(e) => {
                                                    setPhone(e.target.value)
                                                }} />
                                                {phoneErr !== "" &&
                                                    <div className='phone-err'>
                                                        {phoneErr}
                                                    </div>
                                                }
                                            </div>
                                            <div className='concern-input'>
                                                <div>Concern</div>
                                                <input type="text" onChange={(e) => {
                                                    setConcern(e.target.value)
                                                }} />
                                            </div>*/}
                                            {/* <div className='time-input'>
                                        <div>Expected Time</div>
                                        <input type="time" onChange={(e) => {
                                            setTime(e.target.value)
                                        }} />
                                    </div> */}
                                           {/*} <div className='submit' onClick={() => {
                                                const res = submitLead()
                                                if (res === false) {
                                                    close()
                                                }
                                            }}>Submit</div>

                                        </div>
                                        {/* <div>
                                    <button onClick=
                                        {() => close()}>
                                        Close modal
                                    </button>
                                </div> */}
                                    {/*</div>
                                )
                            }</Popup>
                        {/*<div onClick={() => {
                            gtag_report_conversion(window.location);
                            openInNewTab("tel:+917996643888")
                        }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Call Us</div>*/}
                        {showFailedBookingMsg &&
                            <div className='failed-booking-message'>
                                {failedBookingMsg}
                            </div>
                        }
                    </div>

                }

            </div>
        </>
    )
}