import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why General Health Check-Up?</h2>
<p>Regular health check-ups are instrumental in preventing and managing various health conditions. Even if you feel healthy, underlying issues may be developing without noticeable symptoms. Our General Health Check-Up is a proactive approach to ensure you stay ahead of potential health concerns.</p>

<div className='ml-70'>
<div className='row'>
<h3>What Does the General Health Check-Up Include?
</h3>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Physical Examination</h4>
<p>Our experienced healthcare professionals conduct a detailed physical examination to assess your overall health. This includes measurements of vital signs such as blood pressure, heart rate, and body mass index (BMI).</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Blood Tests</h4>
<p>A comprehensive panel of blood tests is conducted to evaluate various aspects of your health, including cholesterol levels, blood sugar, liver function, kidney function, and complete blood count (CBC).
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Imaging Studies</h4>
<p>State-of-the-art imaging studies, such as X-rays and ultrasound, may be included to provide a detailed look at internal organs and identify any abnormalities.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Cardiac Evaluations</h4>
<p>Electrocardiogram (ECG) and other cardiac assessments are performed to evaluate the health of your heart and detect any signs of cardiovascular issues.
</p>
</div>
</div>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Cancer Screenings</h4>
<p>Based on age, gender, and risk factors, cancer screenings such as mammography, Pap smear, and prostate-specific antigen (PSA) tests may be recommended to detect cancer at early, more treatable stages.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Vision and Hearing Tests</h4>
<p>These tests assess your sensory health, identifying any issues with vision or hearing that may require further attention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Nutritional Assessment</h4>
<p>Our healthcare professionals provide guidance on your nutritional habits and offer recommendations for a balanced diet to support overall health.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Lifestyle Counseling</h4>
<p>Receive personalized advice on maintaining a healthy lifestyle, including exercise, stress management, and sleep hygiene.
</p>
</div>
</div>

</div>
</div>
</div>

</section>


{/* <section className='white-bg'>
<div className='container'>
<h2>Benefits of a Full Body Health Check-Up</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<p><strong>Our Home Injection Services cover a wide range of injections, including but not limited to:</strong>
</p>
<ul>
  <li><strong>Insulin Injections:</strong> For individuals managing diabetes, receiving insulin injections at home allows for better blood sugar control.</li>
  <li><strong>Biologic Therapies:</strong> Patients undergoing treatment with biologic medications, such as those for autoimmune conditions, can receive their injections at home, promoting adherence to the prescribed treatment plan.</li>
  <li><strong>Hormone Injections:</strong> Individuals receiving hormone therapy, such as fertility treatments or hormone replacement therapy, can benefit from the convenience of home injections.</li>
  <li><strong>Vitamin and Nutrient Injections:</strong> Some individuals may require regular injections of vitamins or nutrients, and our Home Injection Services can accommodate these needs.</li>
  <li><strong>Anticoagulant Injections:</strong> Patients prescribed anticoagulant medications for conditions like deep vein thrombosis (DVT) can receive their injections at home, ensuring proper and timely administration.</li>
</ul>
</div>
</div>
</div>
</div>

</section> */}

<section className='white-bg'>
<div className='container'>
  <h2>Benefits of General Health Check-Up</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Early Detection of Health Issues</h4>
<p>Identifying potential health problems at an early stage allows for timely intervention and better treatment outcomes.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Preventive Care</h4>
<p>Our comprehensive approach focuses on preventing health issues before they arise, helping you adopt a proactive stance towards your well-being.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Personalized Health Plan</h4>
<p>Based on your health assessment, we provide personalized recommendations to improve your overall health and prevent future risks.

</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Peace of Mind</h4>
<p>Regular health check-ups offer peace of mind, knowing that you are actively taking steps to safeguard your health.
</p>
</div>
</div>
</div>
</div>
</div>
</section>

<section className='third-fold'>
<div className='container'>
<h2>How to Schedule Your Full Body Health Check-Up</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<p>Scheduling your Comprehensive General Health Check-Up at Kyno Health Center is simple. Contact our friendly staff to book an appointment at your convenience. Our team is dedicated to providing a comfortable and confidential environment for your health assessment.
</p>
<p>
Invest in your health today to enjoy a more vibrant and fulfilling tomorrow. At Kyno Health Center, we are committed to being your partner in wellness. Take the first step towards a healthier, happier life with our Comprehensive General Health Check-Up service.

</p>
</div>
</div>
</div>
</div>
</section>




<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index