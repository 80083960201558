import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Bed Sore Dressing at Home: Compassionate Care Where You Need It</h3>
    
    <p className='d-md-none d-lg-none'>Dealing with bed sores can be physically and emotionally challenging, especially when it comes to the intricate process of wound care. Our "Bed Sore Dressing at Home" service is designed to provide professional and compassionate wound care in the comfort of your own space, ensuring that you or your loved ones receive the attention and treatment necessary for a smoother recovery.
</p>

<p className='mob-noee'>Dealing with bed sores can be physically and emotionally challenging, especially when it comes to the intricate process of wound care. Our "Bed Sore Dressing at Home" service is designed to provide professional and compassionate wound care in the comfort of your own space, ensuring that you or your loved ones receive the attention and treatment necessary for a smoother recovery.
</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
