import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'
import Sales_auth from './Sales_auth'
import "../../../styles/sales_home.scss"
import Cookies from 'js-cookie'
import Sales_all_leads from './Sales_all_leads'
import Sales_team_management from './Sales_team_management'
import logo from "../../../utilities/images/logo.png"
import { useNavigate } from 'react-router'
import Sales_All_Appointments from './Sales_all_appointments'
import Sales_All_Calls from './Sales_All_calls'
import Sales_doctor_management from './sales_doctor_management'

export default function Sales_home(props) {

    const patientInfoTempLeadsFilters = [
        {"filter": "PHONE_FILTER", value: ""},
        {"filter": "DATE_FILTER", value1: "", value2: "", value: ""},
        {"filter": "LEAD_STATUS_FILTER", value: ""},
        {"filter": "MARKETING_SOURCE_FILTER", value: ""},
        {"filter": "NAME_FILTER", value: ""},
    ]


    const SE = Cookies.get("salesEmployee")

    const [onboardingState, setOnboardingState] = useState(false)
    const [salesEmployeeId, setSalesEmployeeId] = useState("")
    const [salesEmployee, setSalesEmployee] = useState(null)
    const [localities, setLocalities] = useState(null)
    const [homeMsg, setHomeMsg] = useState("")

    const [tempLeadFilter_, setTempLeadFilter_] = useState(patientInfoTempLeadsFilters)

    const [tabSelected, setTabSelected] = useState("LEADS")

    const navigate = useNavigate()


    const fetchEmployeeDetails = async (id) => {
        const res = await axios.get(`${localBackend}getSalesEmployee?id=${id}`).then((resp) => {
            return resp.data.data
        })
        setSalesEmployee(res)
    }

    const fetchLocalities = async () => {
        const res = await axios.get(`${localBackend}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                return resp.data.data
            }
        })
    }


    const loggedIn = async (id) => {
        setSalesEmployeeId(id)
        Cookies.set('salesEmployee', id, { expires: 1 })
        fetchEmployeeDetails(id);
        setOnboardingState(false);
    }

    const logout = async () => {
        Cookies.remove('salesEmployee');
        setOnboardingState(true)
    }


    const sethomeMsg = (msg) => {
        setHomeMsg(msg);
        setTimeout(() => {
            setHomeMsg("")
        }, 2000)
    }

    const clearFilter = () => {
        setTempLeadFilter_(patientInfoTempLeadsFilters)
    }


    useEffect(() => {

        console.log(SE, salesEmployeeId)
        if (SE === undefined || salesEmployeeId === undefined) {
            console.log("the onboarding state is true")
            setOnboardingState(true);
        } else {
            console.log("the onboarding state is False false false")
            fetchLocalities();
            setSalesEmployeeId(SE);
            fetchEmployeeDetails(SE);
        }

        // if (salesEmployeeId !== "") {
        //     fetchTemporaryLeads();
        //     const intervalId = setInterval(fetchTemporaryLeads, 30000)
        //     return () => clearInterval(intervalId)
        // }

        document.title = "Kyno Health | Sales Panel"

        if(window.location.hash && window.location.hash.substring(1) == "appointments"){
            setTabSelected("APPOINTMENTS")
        }


    }, [salesEmployeeId])
    return (
        <>
            <div className='sales-home'>

                <div className='head'>
                    <img src={logo} />
                    <div className='home-btn' onClick={(e) => {

                        navigate("/dashboard")
                    }}>Home</div>
                </div>


                <div className='title'>Welcome to the sales page for kyno Health
                    <span className='logout' onClick={(e) => {
                        logout()
                    }}>Logout</span>
                </div>

                {onboardingState === true ? (<div>
                    <Sales_auth loggedIn={loggedIn} />
                </div>) : (<div className='home'>
                    <div className='content'>
                        <div className='sales-navbar'>
                            <div className='tabs'>
                                <div className={tabSelected === "LEADS" ? "active" : ""} onClick={() => { setTabSelected("LEADS") }}>Leads</div>
                                <div className={tabSelected === "APPOINTMENTS" ? "active" : ""} onClick={() => { setTabSelected("APPOINTMENTS") }}>Appoinements</div>
                                <div className={tabSelected === "CALLS" ? "active" : ""} onClick={() => { setTabSelected("CALLS") }}>Calls</div>
                                <div className={tabSelected === "ENQUIRIES" ? "active" : ""} onClick={() => { setTabSelected("ENQUIRIES") }}>Enquiries</div>
                                <div className={tabSelected === "DOCTOR_SCHEDULE" ? "active" : ""} onClick={() => { setTabSelected("DOCTOR_SCHEDULE") }}>Doctor Schedule</div>
                                <div className={tabSelected === "DRIVERS" ? "active" : ""} onClick={() => { setTabSelected("DRIVERS") }}>Drivers</div>
                                <div className={tabSelected === "LEAD_ASSIGNMENT_PANEL" ? "active" : ""} onClick={() => { setTabSelected("LEAD_ASSIGNMENT_PANEL") }}>Lead Assignment Panel</div>
                                <div className={tabSelected === "SALES_TEAM_MANAGEMENT" ? "active" : ""} onClick={() => { setTabSelected("SALES_TEAM_MANAGEMENT") }}>Sales Team Management Panel</div>
                                <div className={tabSelected === "DOCTOR_MANAGEMENT" ? "active" : ""} onClick={() => { setTabSelected("DOCTOR_MANAGEMENT") }}>Doctor Management Panel</div>

                            </div>
                            {/* <div className='search-bar'>
                        <input type='text' />
                    </div> */}
                        </div>

                        {/* <div className='something'></div> */}

                        {tabSelected === "LEADS" &&
                            <Sales_all_leads salesEmployeeId={SE} setHomeMsg={setHomeMsg} localities={localities} TempLeadFilter={tempLeadFilter_} SetTempLeadFilter={setTempLeadFilter_} clearFilter={clearFilter} />
                            // <div>

                            //     <div className='title'>Here is the list of all Temporary Leads</div>
                            //     <div className='temp-leads'>
                            //         {temporaryLeads !== null &&
                            //             temporaryLeads.map((block, index) => {
                            //                 return (
                            //                     <div className='temp-lead' key={index}>
                            //                         <div className='name'>{block.name}</div>
                            //                         <div className='phone'>{block.phone}</div>
                            //                         <div className='source'>{block.source}</div>
                            //                         <div className='concern'>{block.concern}</div>
                            //                         <Popup trigger={
                            //                             <div>hey</div>
                            //                         } modal nested>{
                            //                                 (close) => (
                            //                                     <div className='temp-lead-modal'>
                            //                                         <div>Select Locality : {selectedLocality}</div>
                            //                                         <select value={selectedLocality} onChange={(e) => {
                            //                                             setSelectedLocality(e.target.value);
                            //                                             fetchAvailableDoctorsOfLocality(e.target.value)
                            //                                             setmodalmsg("")
                            //                                         }} >
                            //                                             <option key={-1} value="">Select Locality</option>
                            //                                             {localities.map((options, index) => (
                            //                                                 <option key={index} value={options._id}>{options.name}</option>
                            //                                             ))}</select>

                            //                                         {docsOfLocality !== null &&
                            //                                             <div className='docs-of-locality'>
                            //                                                 <div className='title'>Assign Doctor to the lead : {selectedDoc}</div>
                            //                                                 <select value={selectedDoc} onChange={(e) => {
                            //                                                     setSelectedDoc(e.target.value)
                            //                                                 }}>
                            //                                                     <option key={-1} value="">Select Doctor</option>
                            //                                                     {docsOfLocality.map((doc, index) => (
                            //                                                         <option key={index} value={doc._id}>{doc.name}</option>
                            //                                                     ))}
                            //                                                 </select>
                            //                                             </div>
                            //                                         }

                            //                                         <div>Enter Address of the Patient</div>
                            //                                         <input className='address-input' type='textarea' value={patientAddress} onChange={(e) => {
                            //                                             setPatientAddress(e.target.value)
                            //                                         }} />

                            //                                         <div>Change the lead to Active Lead</div>
                            //                                         <div className='submit-btns'>
                            //                                             <div className='yes' onClick={(e) => {
                            //                                                 if (selectedLocality === "") {
                            //                                                     setmodalmsg("Select a Locality")
                            //                                                 }
                            //                                                 else if (selectedDoc === "") {
                            //                                                     setmodalmsg("Select a Doctor")
                            //                                                 } else {
                            //                                                     changeLeadToActive(block._id, close)
                            //                                                 }
                            //                                             }}>Yes</div>
                            //                                             <div className='no' onClick={(e) => {
                            //                                                 setDocsOfLocality(null)
                            //                                                 setSelectedLocality("")
                            //                                                 setSelectedDoc("")
                            //                                                 setPatientAddress("")
                            //                                                 close()
                            //                                             }}>No</div>
                            //                                         </div>
                            //                                         <div className='modal-msg'>{modalmsg}</div>
                            //                                     </div>
                            //                                 )
                            //                             }</Popup>
                            //                     </div>
                            //                 )
                            //             })
                            //         }
                            //     </div>

                            // </div>
                        }

                        {tabSelected === "APPOINTMENTS" && 
                            <Sales_All_Appointments salesEmployeeId={SE} tabSelected={tabSelected} />
                        }

                        {tabSelected === "CALLS" && 
                            <Sales_All_Calls salesEmployeeId={SE} />
                        } 

                        {tabSelected == 'SALES_TEAM_MANAGEMENT' &&
                            <Sales_team_management salesEmployeeId={SE} />
                        }
                        {tabSelected === "DOCTOR_MANAGEMENT" && <Sales_doctor_management salesEmployeeId={SE} /> }
                    </div>

                    {homeMsg !== "" &&
                        <div className='home-msg'>{homeMsg}</div>
                    }

                </div>)}
            </div>
        </>

    )

}