import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose Our Home Visit Doctors Service?</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience and Comfort</h4>
<p>Our home visit doctors eliminate the need for travel, waiting rooms, and long commutes. You can receive expert medical care without the stress and discomfort of leaving your home.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Care</h4>
<p>Our team of qualified physicians tailors their approach to your specific needs. With dedicated attention, they take the time to understand your medical history, concerns, and preferences.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Comprehensive Services</h4>
<p>Our home visit doctors offer a wide range of medical services, from diagnosing acute illnesses to managing chronic conditions, administering medications, wound care, vaccinations, and more.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Elderly and Special Needs Care</h4>
<p>We specialize in providing compassionate care for the elderly and those with special needs. Our doctors are experienced in addressing the unique medical requirements of these populations.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Appointments</h4>
<p>We understand that your schedule may vary. Our team strives to accommodate your preferred appointment times, ensuring that you receive care when it's most convenient for you.</p>
</div>
</div>


</div>
</div></div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Services Offered by Our Home Visit Doctors</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem3'>
<h4>Primary Care</h4>
<p>Routine check-ups, health assessments, and preventive care to monitor your overall well-being.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>
<h4>Chronic Disease Management</h4>
<p>Expert care for conditions such as diabetes, hypertension, heart disease, and more, to help you lead a healthier life.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>
<h4>Post-Operative Care</h4>
<p>Our doctors provide follow-up care and monitoring after surgeries to ensure a smooth recovery.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Urgent Care</h4>
<p>Prompt medical attention for acute illnesses and injuries, right at your doorstep.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Medication Management</h4>
<p>Ensuring proper medication administration and monitoring for optimal treatment outcomes.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div>


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>How It Works</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Contact Us</h4>
<p>Reach out to us via phone, email, or through our website to schedule a home visit.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Appointment Confirmation</h4>
<p>Our team will coordinate a suitable date and time for the visit and provide you with all the necessary details.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Doctor's Visit</h4>
<p>Our experienced physician will arrive at your home at the scheduled time. They will conduct a thorough examination, discuss your medical history, and address any concerns you may have.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Diagnosis and Treatment</h4>
<p>Based on the assessment, the doctor will provide a diagnosis, treatment plan, and any necessary prescriptions or recommendations.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will schedule follow-up visits as needed to monitor your progress and make adjustments to your treatment plan if required.</p>
</div>
</div>


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Book Your Home Visit Today!</h5>
<p>Don't let health concerns limit your access to quality medical care. With our Home Visit Doctors service, you can receive the attention you need, all within the familiar surroundings of your own home. Contact us today to schedule your appointment and experience the convenience and personalized care our dedicated team has to offer.</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index