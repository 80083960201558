import React from "react";
import heart from "../../utilities/images/heart.png"
import "../../styles/wckh.css"
import wckh1 from "../../utilities/images/What_sets_kyno_apart/img1.png"
import wckh2 from "../../utilities/images/What_sets_kyno_apart/img2.png"
import wckh3 from "../../utilities/images/What_sets_kyno_apart/img3.png"
import wckh4 from "../../utilities/images/What_sets_kyno_apart/img4.png"
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'


export default function WhyChooseKynoHealth() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        //   slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 500 },
          items: 2,
        //   slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 500, min: 0 },
          items: 1,
        //   slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <>
            <div className="wckh">
                <div className="wckh-title">
                    <div>What sets</div>
                    <div>
                        <span>Kyno</span> Health Apart? <img src={heart} />
                    </div>
                    <div className="wckh-title-content">
                    Everything can be found online nowadays, but there are still a few things that are better with a human touch. One of them is your doctor’s appointment. Nothing beats having an in-depth conversation with your doctor in the comfort of your own home.
                    With Kyno Health, you can schedule a doctor’s visit to your home within the next 60 minutes.
                    With us, you have

                    </div>
                </div>
                <Carousel responsive={responsive} showDots={false} swipeable={true} infinite={true} autoPlay={true} autoPlaySpeed={2000} className="wckh-cards">

                    <div className="wska">
                        <img src={wckh1} />
                        <div>Compassionate and <br /> Expericenced Doctors</div>
                        <span>Our doctors with their vast experience and expertise in handling all type of patients with utmost care and patience sets them apart providing a holistic experience where every patient can feel comfortable.</span>
                    </div>
                    <div className="wska">
                        <img src={wckh2} />
                        <div>
                            Longer Appointments
                        </div>
                        <span>
                        Our doctors spend adequate time with our patients to identify the root cause of the problem so that we can not only cure but also prevent the problem from recurring ensuring our patient's questions are answered.
                        </span>
                    </div>
                    <div className="wska">
                        <img src={wckh3} />
                        <div>
                            No more waiting in line
                        </div>
                        <span>
                        Usually a clinic consultation means reaching there an hour earlier and waiting in line and hoping the doctor is not late. With Kyno health there is no more waiting and you get to select the time as per your choice.
                        </span>
                    </div>
                    <div className="wska">
                        <img src={wckh4} />
                        <div>
                            Showering care even if you are far away
                        </div>
                        <span>
                        You could be in another part of the country and yet book an appointment for your loved ones with just a phone call while simultaneously getting all updates regarding their recovery bringing care from far.
                        </span>
                    </div>

                </Carousel>
            </div>
        </>
    )
}