import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Can you have an IV drip at home?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Absolutely! Our IV Drip at Home service brings the convenience of IV therapy directly to you. Our skilled healthcare professionals administer a range of tailored IV treatments in the comfort of your own home.</p>

                      <p>Whether you're seeking hydration, immunity boost, or post-exercise recovery, we've got you covered. Experience the benefits of IV therapy without the hassle of visiting a clinic. Contact us to schedule your at-home IV drip session and rejuvenate your well-being.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Which IV drip is best for weakness?
                    </Accordion.Header>
                    <Accordion.Body>

                      <p>For combating weakness, our "Energy Boost" IV drip is highly recommended. Packed with essential vitamins and nutrients, this specialized blend is designed to revitalize your energy levels effectively.</p>
                      <p>Our skilled healthcare professionals can administer this IV drip in the comfort of your home, ensuring rapid absorption and quick relief from weakness. Contact us to schedule your session and experience the rejuvenating effects of our tailored IV therapy.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  


                  


                </Accordion>

              </div>

              <div className="col-md-6">
              <Accordion>
              <Accordion.Item eventKey="2">
                    <Accordion.Header>Is IV drip healthy?</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, IV drips can be a healthy way to provide your body with essential nutrients, vitamins, and hydration. Administered by trained professionals, IV therapy ensures direct absorption into the bloodstream for quick results. However, it's crucial to consult a healthcare provider before undergoing any treatment to ensure it's suitable for your individual health needs.
</p>



                    </Accordion.Body>
                  </Accordion.Item>


              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion.Item eventKey="3">
                    <Accordion.Header>Which drip is good for strength?
</Accordion.Header>
                    <Accordion.Body>

                    <p>For enhancing strength, our "Vitality Plus" IV drip is an excellent choice. Loaded with a blend of key nutrients, amino acids, and electrolytes, this drip is specifically formulated to promote muscle function and overall physical strength.</p>

                    <p>Our healthcare professionals can administer this drip in the comfort of your home, providing you with a convenient and effective way to boost your strength and well-being. Contact us to schedule your session and experience the benefits.</p>



                    </Accordion.Body>
                  </Accordion.Item>

               


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index