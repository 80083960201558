import React, { useState, useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './BookingForm.css';
import { Helmet } from 'react-helmet'
import logo from "../../utilities/images/home_v2/logo.png"
import Nav from '../home_page_v2/Nav/index'
import Footer from '../home_page_v2/Footer/index'
import InputWithLabel from './inputWithLabel';
import BookingDetails from './bookingDetails';
import LabelComponent from './labelComponent';
// import ButtonGroup from './buttonGroup';
import CustomButtonGroup from './customButtonGroup';
import useRazorpay from "react-razorpay";
import PaymentStatusPopup from './PaymentStatusPopup';
import { useNavigate, useParams } from 'react-router-dom';
import { Fab } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import axios from 'axios';
// import { makeStyles } from '@mui/styles';
// import logo from "src/utilities/images/home_v2/logo.png"

const BookingForm = () => {
  // const { city } = useParams();
  const urlCity = useParams().city
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [bookActive, setBookActive] = useState(false)
  const [slottype, setSlotType] = useState("Within 60 Mins")
  const [currentOrderId, setCurrentOrderId] = useState(null)
  const [openPopup, setOpenPopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [starttimeSelected, setStarttimeSelected] = useState(null)
  const [timeFormat, setTimeFormat] = useState(null)
  const [city, setCity] = useState(urlCity || '');
  const [cityError, setCityError] = useState(false);
  const [requestNonce, setRequestNonce] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingError, setPollingError] = useState(null);
  const [tcName, setTcName] = useState('');
  const [tcPhone, setTcPhone] = useState('');
  const [tcEmail, setTcEmail] = useState('');
  const isPlumPage = !!window.location.href.includes("plum")
  const maxPollingAttempts = 5;
  const navigate = useNavigate();

  let android = /Android/.test(navigator.userAgent) && !window.MSStream;

  // useEffect(() => {
  //   const handlePopState = () => {
  //     // Navigate to the root ("/") when the back button is clicked
  //     navigate("/", { replace: true });
  //   };

  //   // Listen for browser back button (popstate event)
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     // Clean up event listener on component unmount
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);


  useEffect(() => {
    if (slottype === "Within 60 Mins") {
      setStarttimeSelected(new Date().toISOString())
      setTimeFormat(null)
    }
  }, [slottype])

  const handleNameChange = (value) => {
    setName(value);
    console.log(value)
    // setNameError(!event.target.value);
  };

  const handlePhoneChange = (value) => {
    // const phoneValue = event.target.value;
    setPhone(value);
    // setPhoneError(!/^\d{10}$/.test(phoneValue)); // Validate phone number as 10 digits
  };

  // const handleSubmit = () => {
  //   if (!name) setNameError(true);
  //   if (!/^\d{10}$/.test(phone)) setPhoneError(true);
  //   if (!nameError && !phoneError && name && phone) {
  //     // Handle form submission
  //     alert('Booking Confirmed!');
  //   }
  // };
  const [Razorpay, isLoaded] = useRazorpay();
  const createOrder = async (params) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      "name": name,
      "mobile": phone,
      "slottype": slottype,
      "starttime": starttimeSelected,
      "city": city,
      "url": window.location.href,
      "tc_name": tcName,
      "tc_email": tcEmail,
      "tc_mobile": tcPhone
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    let response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/create_order", requestOptions)
    response = response.text()
    return response;
  };

  const handlePayment = async () => {
    if (!name) {
      setNameError("Name is mandatory")
    }
    if (!phone) {
      setPhoneError("Phone Number is mandatory")
    }
    if (!city) {
      setCityError(true);
    } else {
      setCityError(false)
    }
    if (nameError || phoneError || !name || !phone || !city) {
      return
    }
    window.dataLayer.push({ 'event': 'initiate_checkout' });
    const { data: { grandTotal, orderId, secret } } = JSON.parse(await createOrder());
    // console.log({ order, name, phone })
    setPaymentStatus("pending")
    setCurrentOrderId(orderId)
    const options: RazorpayOptions = {
      key: secret,
      order_id: orderId,
      amount: grandTotal,
      currency: "INR",
      name: name,
      description: slottype,
      image: "https://www.kynohealth.com/images/Kyno-footer-logo.png",
      handler: (res) => {
        if (res.razorpay_payment_id) {
          setPaymentStatus('pending');
        } else {
          setPaymentStatus('failure');
        }
        console.log(res);
        setOpenPopup(true);
      },
      modal: {
        ondismiss: function () {
          console.log("hello closing")
          setPaymentStatus('failure');
          setOpenPopup(true);
        },
      },
      prefill: {
        name: name,
        email: "aditya@kynohealth.com",
        contact: phone,
      },
      notes: {
        address: "HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const resetPayment = () => {
    setPaymentStatus('pending');
    setOpenPopup(false);
  };

  const handleTimeSelection = ({ selectedHour, selectedMinute, selectedPeriod }) => {
    const isoString = convertToISOTime(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype);
    setTimeFormat(convertToCustomFormat(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype))
    console.log({ isoString })
    setStarttimeSelected(isoString)
  }

  useEffect(() => {
    if (paymentStatus === "success") {
      window.location.href = (isPlumPage ? '/booking/plum-success?bookedSlot=' : '/booking/confirmation?bookedSlot=') + starttimeSelected;
  // navigate('/booking/confirmation?bookedSlot=' + starttimeSelected);
}
  }, [paymentStatus])

// Truecaller integration

// Function to start the Truecaller flow and set requestNonce
const handleInputClick = () => {
  console.log({ android })
  if (!android) return
  const nonce = generateRequestNonce();  // Generate a unique nonce
  setRequestNonce(nonce);
  setIsPolling(true); // Start polling
  initiateTruecallerFlow(nonce);  // Redirect to Truecaller SDK
};

// Function to generate a unique nonce
const generateRequestNonce = () => {
  return 'nonce_' + Math.random().toString(36).substr(2, 9);
};

// Function to initiate Truecaller SDK flow
const initiateTruecallerFlow = (nonce) => {
  const partnerKey = 'j7jJnfdd002127115425894a9a16500489778';
  const partnerName = 'Kynohealth.com';
  const privacyUrl = 'https://www.kynohealth.com/privacy-policy/'; // Link to your privacy policy
  const termsUrl = 'https://www.kynohealth.com/terms-conditions/';     // Link to your terms of 
  const language = 'en';
  const ttl = 600000;

  const truecallerUrl = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${nonce}&partnerKey=${partnerKey}&partnerName=${partnerName}&lang=${language}&privacyUrl=${encodeURIComponent(privacyUrl)}&termsUrl=${encodeURIComponent(termsUrl)}&ttl=${ttl}`;

  window.location = truecallerUrl;
};

// Poll the Truecaller status API every 3 seconds
useEffect(() => {
  if (isPolling && requestNonce && pollingCount < maxPollingAttempts) {
    const interval = setInterval(() => {
      pollTruecallerStatus(requestNonce);
    }, 1500);  // Poll every 3 seconds

    return () => clearInterval(interval);  // Clear the interval after polling stops
  }
}, [isPolling, requestNonce, pollingCount]);

// Function to poll Truecaller status API
const pollTruecallerStatus = async (nonce) => {
  try {
    const response = await axios.post(process.env.REACT_APP_NODE_BASE_URL + '/api/truecaller-status', { requestId: nonce })
    console.log({ response })
    const { status, data } = response.data;

    if (status === 'user_verified') {
      setIsPolling(false);  // Stop polling
      console.log('User profile data:', data);
      const { name: { first, last }, onlineIdentities: { email }, phoneNumbers } = data
      setName((first + " " + last)?.trim() || name)
      setTcName((first + " " + last)?.trim())
      setPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : phone)
      setTcPhone(phoneNumbers.length ? String(phoneNumbers[0]).slice(-10) : '')
      setTcEmail(email)

    } else if (status === 'user_rejected') {
      setIsPolling(false);  // Stop polling
      console.log('User rejected the flow.');
    } else if (status === 'flow_invoked') {
      setPollingCount(prev => prev + 1);  // Continue polling
    } else {
      setIsPolling(false);
      console.log('something went wrong with status 200');
    }
  } catch (error) {
    setPollingError('Error fetching Truecaller status');
    console.error('Polling error:', error);
    setIsPolling(false);  // Stop polling on error
  }

  // Stop polling after max attempts
  if (pollingCount >= maxPollingAttempts) {
    setIsPolling(false);
    console.log('Max polling attempts reached');
  }
};


// useEffect(() => {
//   if (isLoaded) {
//     handlePayment();
//   }
// }, [isLoaded, handlePayment])
console.log({ paymentStatus })
return (
  <div>
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

      <title>Medical Consultation | Best Physician Doctor in Noida</title>
      <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
      <link rel="canonical" href="https://www.kynohealth.com/" />

      <meta property='og:image' content={logo} />
      {isPlumPage ? <meta name="robots" content="noindex, nofollow" /> : ''}
      <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
    </Helmet>
    <Nav isBookSlot={true} isPaidPage={true} />
    <div className='booking_slot' style={{ width: '90%', maxWidth: "500px", margin: '20px auto', paddingTop: '50px 0' }}>
      <SlickCarousel />
      {(<><Accordion defaultExpanded>
        <AccordionDetails>
          <InputWithLabel inputValue={phone} handleInputClick={handleInputClick} error={phoneError} setError={setPhoneError} handleInputChange={handlePhoneChange} label="Enter Phone Number *" placeholder="Ex: 9876543210" type="phone" />
          <InputWithLabel inputValue={name} error={nameError} setError={setNameError} handleInputChange={handleNameChange} label="Enter Name *" placeholder="Ex: Prachi Pancholi" type="name" />
          <FormControl fullWidth sx={{ marginBottom: '20px', display: urlCity ? 'none' : '' }} error={cityError}>
            <LabelComponent text={"Select Your City *"} />
            <Select style={{ borderRadius: "10px" }} value={city} onChange={(e) => {
              setCity(e.target.value)
              setCityError(false)

            }} displayEmpty >
              <MenuItem value="">
                <em>Choose your city</em>
              </MenuItem>
              <MenuItem value="delhi">Delhi</MenuItem>
              <MenuItem value="bangalore">Bangalore</MenuItem>
              <MenuItem value="gurgaon">Gurgaon</MenuItem>
              <MenuItem value="noida">Noida</MenuItem>
              <MenuItem value="ghaziabad">Ghaziabad</MenuItem>
            </Select>
            {cityError && <FormHelperText sx={{ color: "#d32f2f" }}>City selection is mandatory.</FormHelperText>}
          </FormControl>
        </AccordionDetails>
      </Accordion>
        <Accordion defaultExpanded>
          <AccordionDetails>
            <LabelComponent text="When you want to schedule you visit?" />
            <CustomButtonGroup setBookActive={setBookActive} setSlotType={setSlotType} slottype={slottype} />
            {bookActive && <BookingDetails handleTimeSelection={handleTimeSelection} slotType={slottype} timeFormat={timeFormat} />}
          </AccordionDetails>
        </Accordion>
        <div className="content">Book at ₹99 Now, Pay Rest at Visit!</div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              margin: "0 auto",
              marginTop: '20px',
              maxWidth: "343px",
              height: "46px",
              fontWeight: "600",
              padding: "12px 16px 12px 16px",
              gap: "8px",
              borderRadius: "10px",
              background: (nameError || phoneError || !name || !phone || !city) ? "#dcdcdc" : "#FB9F40"
            }}
            disabled={(nameError || phoneError || !name || !phone || !city)}
            onClick={() => handlePayment()}
          >
            Confirm Booking
          </Button>
        </div>
        <PaymentStatusPopup open={openPopup} onClose={() => setOpenPopup(false)} orderId={currentOrderId} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} /></>)}
    </div>
    {<FloatingCallButton />}
    {/* <Footer isHideSticky={true} isPaidPage={true} /> */}
  </div>
);
};


function convertToISOTime(timeString, day = "today") {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }
  const currentDate = new Date();
  if (day === 'Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 1);
  } else if (day === 'Day after Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 2);
  }

  currentDate.setHours(hours, minutes, 0, 0);

  return currentDate.toISOString();
}

function convertToCustomFormat(timeString, day = 'today') {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  const currentDate = new Date();

  if (day === 'Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 1);
  } else if (day === 'Day after Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 2);
  }

  let period;
  const hour = parseInt(hours, 10);

  if (modifier === 'AM') {
    period = hour < 12 ? 'morning' : 'afternoon';
  } else {
    if (hour < 17) {
      period = 'afternoon';
    } else if (hour < 20) {
      period = 'evening';
    } else {
      period = 'night';
    }
  }

  // Format the date part without changing the time zone
  const dayOfMonth = currentDate.getDate();
  const month = currentDate.toLocaleString('en-US', { month: 'long' });
  const suffix = dayOfMonth === 1 ? 'st' : dayOfMonth === 2 ? 'nd' : dayOfMonth === 3 ? 'rd' : 'th';

  const formattedTime = `${time.replace(':', ' : ')} ${modifier} in the ${period} of ${dayOfMonth}${suffix} ${month}`;

  return formattedTime;
}

const FloatingCallButton = () => {

  const handleCallClick = () => {
    // Handle the call action here
    alert("Call button clicked!");
  };

  return (
    <a href="tel:01206056620" style={{ color: 'inherit', textDecoration: 'none' }}>
      <Fab
        // color="rgb(251, 159, 64)" 
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
          background: 'rgb(251, 159, 64)'
        }}
      >
        <PhoneIcon />
      </Fab>
    </a>
  );
};



const SlickCarousel = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // You can change the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const isPlumPage = !!window.location.href.includes("plum")
  return (
    <div className='carousel-container'>
      {isPlumPage ? <Slider {...settings}>
        <div>
          <img src="/images/kh_desktop6.jpg" alt="slide 4" />
        </div>
      </Slider> :
        <Slider {...settings}>
          <div>
            <img src="/images/kh_desktop1.jpg" alt="slide 1" />
          </div>
          <div>
            <img src="/images/kh_desktop2.jpg" alt="slide 2" />
          </div>
          <div>
            <img src="/images/kh_desktop3.jpg" alt="slide 3" />
          </div>
          <div>
            <img src="/images/kh_desktop4.jpg" alt="slide 4" />
          </div>
        </Slider>
      }
    </div>
  );
};

// const timeString = "03:08 PM";
// const isoString = convertToISOTime(timeString);
// console.log(isoString);

// Example usage
// const utcDateString = '2024-08-15T17:45:37.649Z';
// const localFormattedDate = convertToLocalTime(utcDateString);

export default BookingForm;
