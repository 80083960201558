import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className="five-fold">
  <div className="container">
  <h5>The Kyno Advantage</h5>
 
  <div className='five-l-side ml-70'>
    
    <h2>Compassionate Care, <span>Redefined</span></h2>
    <h3>We put yours & your family's comfort first.</h3>
   
  
  </div>  

  <div className='table-portion'>

    <div className='row'>

      <div className='col-md-4 col-4 brder-right'>

<h4 className='radus'>&nbsp;</h4>
    <div className='tble'>
      <img src='../images/car.svg'/>
      <h6>Travelling</h6>
    </div>

    <div className='tble'>
      <img src='../images/waiting.svg'/>
      <h6>Waiting</h6>
    </div>

    <div className='tble'>
      <img src='../images/medicine.svg'/>
      <h6>Consultation</h6>
    </div>

    <div className='tble'>
      <img src='../images/report.svg'/>
      <h6>Followup</h6>
    </div>

      </div>

      <div className='col-md-4 col-4'>

<h4><img src='../images/at-kyno.png'/>Kyno Health</h4>
    <div className='tble'>
      <h6><span>Attentive care at <br/> your home</span></h6>
    </div>

    <div className='tble'>
      <h6><span>Immediate help </span></h6>
    </div>

    <div className='tble'>
      <h6><span>Detailed consultation, <br/> lasting 30-35 minutes</span></h6>
    </div>

    <div className='tble'>
      <h6><span>Proactive Follow-ups till recovery</span></h6>
    </div>

      </div>

      <div className='col-md-4 col-4 brder-left'>

<h4 className='radus-r'>Clinic Visits</h4>
    <div className='tble'>
      <p>Needs travel & time<br/> management</p>
    </div>

    <div className='tble'>
      <p>Wait for your turn</p>
    </div>

    <div className='tble'> 
      <p>Little knowledge sharing, <br />5-10 minutes consultations</p>
    </div>

    <div className='tble'>
      <p>No Proactive Follow-up Care</p>
    </div>

      </div>


    </div>
    
    </div>




  </div>
  </section>
    </>
  )
}

export default index