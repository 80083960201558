import React from "react";
import Nav from "../Nav/index";
import Footer from "../Footer/index";
import Hero from "./Hero/index";
import Content from "./Content/index";

import { Helmet } from "react-helmet";

function index() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"
        ></script>

        <title>Terms & Conditions</title>
        <meta
          name="description"
          content="Welcome to our dedicated service page focused on providing comprehensive care for animal bites. At Kyno Health, we understand the urgency and concern that arises when dealing with animal bites."
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://www.kynohealth.com/" />
      </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Footer />
    </>
  );
}

export default index;
