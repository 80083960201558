import React, { useEffect, useState } from 'react'
import Ops_auth from './Ops_auth'
import "../../../styles/ops_home.scss"
import axios from 'axios'
import Cookies from 'js-cookie'
import { localBackend } from '../../../utilities/constants'
import LeadQueriesTab from './LeadQueriesTab'
import Ops_All_Booked_Appointments from './Ops_all_Booked_appointments'

export default function Ops_home() {

    const OE = Cookies.get("operationsEmployee")
    const [onboardingState, setOnboardingState] = useState(false)
    const [operationsEmployeeId, setOperationsEmployeeId] = useState("")
    const [operationsEmployee, setOperationsEmployee] = useState(null)
    const [contentOptionSelected, setContentOptionSelected] = useState(1)

    const fetchEmployeeDetails = async (id) => {
        const res = await axios.get(`${localBackend}getOperationsEmployee?id=${id}`).then((resp) => {
            setOperationsEmployee(resp.data.data)
            return resp.data.data
        })
        // console.log("Operations Employee", res)
    }

    

    const loggedIn = async (id) => {
        // console.log("Operations employee: ", id)
        setOperationsEmployeeId(id)
        Cookies.set('operationsEmployee', id, { expires: 1 })
        fetchEmployeeDetails(id);
        setOnboardingState(false);
    }

    const logout = async () => {
        // console.log("logging out")
        Cookies.remove('operationsEmployee');
        setOnboardingState(true)
    }


    useEffect(() => {

        console.log("OE: ", OE)
        if (OE === undefined || operationsEmployeeId === null) {
            setOnboardingState(true);
        } else {
            setOperationsEmployeeId(OE)
            fetchEmployeeDetails(OE)
        }

        document.title = "Kyno Health | Operations Panel"
    }, [operationsEmployeeId, onboardingState])

    return (
        <>
            <div className='ops-home'>
                <div className='title'>Welcome to the operations page for kyno health</div>
                {onboardingState === true ? (
                    <div>
                        <Ops_auth loggedIn={loggedIn} />
                    </div>
                ) : (
                    <div className='home' >
                        <div className='head'>
                            {operationsEmployee !== null && <div className='title'>Hey there, {operationsEmployee.name}</div>}
                            <div className='logout' onClick={(e) => {
                                logout()
                            }}>Logout</div>
                        </div>

                        <div className='content'>

                            <div className='content-options'>
                                <div className={contentOptionSelected === 1 ? "active" : ""} onClick={(e) => { setContentOptionSelected(1) }}>Manage Active Leads</div>
                                <div className={contentOptionSelected === 2 ? "active" : ""} onClick={(e) => { setContentOptionSelected(2) }}>Manage Lead Queries</div>
                                <div className={contentOptionSelected === 3 ? "active" : ""} onClick={(e) => { setContentOptionSelected(3) }}>Manage Task 3</div>
                                <div className={contentOptionSelected === 4 ? "active" : ""} onClick={(e) => { setContentOptionSelected(4) }}>Manage Task 4</div>

                            </div>

                            {contentOptionSelected === 1 &&
                                <Ops_All_Booked_Appointments operationsEmployeeId={OE} />
                            }

                            {
                                contentOptionSelected === 2 &&
                                <LeadQueriesTab />
                            }
                        </div>

                    </div>
                )}
            </div>
        </>
    )
}
