import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is pain management, and when should I consider seeing a doctor for it?</Accordion.Header>
                    <Accordion.Body>
                      <p>Pain management is a medical specialty focused on assessing and treating various types of pain. You should consider seeing a pain management doctor if you experience chronic or severe pain that affects your daily life, or if your pain is not adequately managed with over-the-counter medications.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What can I expect during my first visit to a pain management doctor?</Accordion.Header>
                    <Accordion.Body>

                      <p>During your initial appointment, the doctor will conduct a thorough evaluation, which may include a review of your medical history, a physical examination, and discussions about your pain symptoms. This evaluation helps determine the cause of your pain and the most suitable treatment options.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What types of pain conditions can a pain management doctor treat?</Accordion.Header>
                    <Accordion.Body>
                      <p>Pain management doctors can address a wide range of pain conditions, including chronic back pain, arthritis, neuropathic pain, headaches, migraines, cancer-related pain, and more. They are trained to manage various types of pain to improve your quality of life.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What treatment options are available for pain management?</Accordion.Header>
                    <Accordion.Body>
                    <p>Pain management treatment options vary depending on the cause and severity of your pain. They may include medications, physical therapy, interventional procedures (such as injections), complementary therapies, and lifestyle modifications. Your doctor will create a personalized treatment plan for you.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Is it possible to manage pain without relying on medications, especially opioids?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, pain management doctors aim to reduce reliance on opioid medications due to their potential for addiction and side effects. They explore alternative therapies, such as physical therapy, nerve blocks, and cognitive-behavioral approaches, to effectively manage pain.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>How long will pain management treatment take to work, and what results can I expect?</Accordion.Header>
                    <Accordion.Body>

                      <p>The effectiveness and duration of pain management treatment can vary depending on the individual and the type of pain. While some people experience relief quickly, others may require ongoing treatment. Your doctor will discuss realistic expectations and monitor your progress.</p>

                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
           
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Can pain management help with chronic conditions like fibromyalgia or complex regional pain syndrome (CRPS)?
  </Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, pain management doctors are experienced in addressing chronic pain conditions, including fibromyalgia and CRPS. They can develop treatment plans to improve your symptoms and quality of life.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Are there risks associated with pain management treatments, and how are they mitigated?</Accordion.Header>
                    <Accordion.Body>
                      <p>Most pain management treatments have potential risks and side effects, which your doctor will discuss with you. They will take steps to minimize risks and closely monitor your progress to ensure your safety.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Is pain management only for adults, or can children also benefit from these services?</Accordion.Header>
                    <Accordion.Body>
                      <p>Pain management services are available for both adults and children. Pediatric pain management specialists are trained to address pain in children, offering age-appropriate care and treatments.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How can I find the right pain management doctor for my needs?</Accordion.Header>
                    <Accordion.Body>
                      <p>You can start by asking for referrals from your primary care physician, friends, or family. Ensure that the doctor is board-certified in pain management and has experience treating your specific type of pain. Personal comfort and good communication with your doctor are also important factors to consider.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index