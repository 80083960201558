import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I remove dandruff permanently at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you wish to get a permanent solution for dandruff at home, you will be required to switch to healthier dietary and hair care habits. Consume a lot of green vegetables, meat, and fish to get your daily dose of protein. Also, you will need to use anti-dandruff shampoos, separate combs, and fewer hair styling tools to keep your scalp free from dandruff."
      }
    },{
      "@type": "Question",
      "name": "What is the most effective way to stop dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Using natural remedies such as lemon juice, Aloe vera gel, coconut oil, yogurt, etc for dandruff removal can provide you with amazing results. Also, if you are suffering from severe dandruff issues, you can seek medical assistance for a better cure."
      }
    },{
      "@type": "Question",
      "name": "Does hot water cause dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Hot water is not at all suitable for scalp health! It can lead to inflammation on the scalp, thereby resulting in dandruff. Also, it can pose great damage to the hair follicles by reducing the natural hair oils of the scalp."
      }
    },{
      "@type": "Question",
      "name": "Should I oil my hair daily if I have dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Certain hair oils are highly beneficial in combating dandruff by imparting the scalp with moisture. Also, the anti-inflammatory properties in the hair oils can reduce dandruff. However, applying hair oil regularly can make the condition worse by producing oily dandruff."
      }
    }]
  }
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Top 10 Home Remedies for Effective Dandruff Treatment | Kyno Health</title>
        <meta name="description" content="Explore the best home remedies for dandruff treatment. This blog is your one-stop destination to learn about the most effective dandruff remedies of all time!" data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/top-10-home-remedies-for-dandruff-treatment"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index