import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians for home visits in Kapashera, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Finding the best general physicians for home visits in Kapashera, Delhi, is now easier than ever. With Kyno Health, top physicians will come to your house for various medical assistance needs."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and certifications should I look for in a general physician offering home services in Kapashera, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians need to have a strong academic background with valid credentials as recognized by medical authorities. This helps them be capable of offering quality home services in Kapashera, Delhi."
      }
    },{
      "@type": "Question",
      "name": "What is the average consultation fee for a general physician visiting homes in Kapashera, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Consultation fees for general physicians coming to your house in Kapashera usually range from ₹500 to ₹1500 per visit."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians in Kapashera, Delhi, equipped to handle common medical emergencies during home visits?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, Kyno Health's general physicians in Kapashera are equipped with the expertise to cater to a variety of medical emergencies. They can come right to your house to help you with various issues."
      }
    },{
      "@type": "Question",
      "name": "How can I book an appointment with a reputable general physician for a home visit in Kapashera, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book an appointment with a reputable general physician for a home visit in Kapashera, Delhi, by using the Kyno Health website."
      }
    }]
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Kapashera, Delhi | Kyno Health</title>
        <meta name="description" content="Experience top-notch medical care from the comfort of your home with Kyno Health's best general physicians in Kapashera, Delhi. Get quality healthcare services delivered right to your doorstep." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/kapashera-general-physicians-at-home" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians At Home In Kapashera, Delhi" hero_paragraph="Kapashera is Delhi's bustling locality. The convenience of healthcare at home is now becoming possible through the best general physicians at home in Kapashera, Delhi. People trying to manage chronic conditions or wanting regular check-ups can now rely on Kyno Health for their various needs. Kyno only brings forward the most qualified and experienced staff for our range of services. Kyno consistently reinvents its offerings to cater to all medical needs of the Kapashera locality."/>
      
      <Content content_h3="When Do You Need At-home Medical Care?" content_p="At-home medical care is the lifeline of modern healthcare. In many scenarios where visiting a clinic or a hospital does not pose as feasible or comfortable, at-home medical services come to the rescue. You just have to find the right general physicians near me with Kyno to get immediate medical attention. Elderly individuals and those with mobility issues rely heavily on healthcare becoming available at home. Those recovering from surgeries or illnesses also benefit from our at-home care services since they help them recover in a familiar environment. " content_p1="When healthcare professionals come straight to your doorstep, it becomes a convenient way to receive medical attention. This way, the need for care is not compromised, and all needs are met thoroughly. Such an approach adds to the maximum comfort of the patient who has already been through a lot. Our doctors adopt a personalized care plan for each of their patients so that better health outcomes are received."
      content_h3_2="Choose Kyno's Team Of General Physicians For Expert Care At Home" content_p_2="For the vibrant locality of Kapashera looking for general physician home visit near me, Kyno Health is an important resource. We stand out as providers of excellence in medical care at home. Our team of general physicians is committed to providing comprehensive healthcare services. These are essential professionals who provide solutions that are tailored to individual needs. " content_p_2a="Preventive care, chronic issue management, and urgent medical consultations all become possible in the comfort of your home with Kyno. Prominent issues like diabetes and hypertension are catered to best in the safety of your home. Our healthcare services are truly reliable and compassionate. We mean to understand you thoroughly and deliver the best care at home. Our physicians come equipped with the right medical tools at all times, no matter what kind of consultation it is. We are well-prepared so that your needs are met comprehensively." />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index