import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is the root cause of dandruff and itchy scalp?</Accordion.Header>
                    <Accordion.Body>
                      <p>The root cause for dandruff and itchy scalp stands to be yeast overgrowth and dry or oily skin. However, if you are suffering from an itchy scalp without dandruff, it might be due to genetic factors or an immune system malfunctioning. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I stop my scalp from itching and dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>You can use ingredients such as lemon juice, orange peel, olive oil, etc., to combat itching and dandruff. Moreover, if you are having trouble managing it all alone, you can reach out to a certified hair clinic to get the best cure for dandruff and a dry itchy scalp. </p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                 


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>

              <Accordion.Item eventKey="2">
                    <Accordion.Header>How do you permanently get rid of dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>You can seek the medical supervision of a renowned hair clinic or specialist to get a commendable cure for itchy scalp and dandruff. Also, using medicated anti-dandruff shampoos can be beneficial. </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Why have I started getting dandruff and an itchy scalp?</Accordion.Header>
                    <Accordion.Body>

                      <p>Some of the primary causes behind sudden dandruff formation and itchiness are stress, sensitive hair products, and unfavorable weather conditions. Seek medical attention to manage the situation if necessary.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index