import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Do doctors have to be on call all the time?
</Accordion.Header>
                    <Accordion.Body>

                      <p>No, doctors are not on call all the time. They usually have scheduled on-call shifts, during which they're available to handle emergencies or urgent cases outside regular hours. This system allows for a balance between patient care and personal time.
                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How long will it take for a doctor to arrive?
</Accordion.Header>
                    <Accordion.Body>

                      <p>The arrival time of a doctor varies based on factors like location, urgency, and availability. In emergency cases, they strive to arrive promptly. In non-emergencies, wait times might be longer. Telemedicine is an option for quicker consultations.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  


                  


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion.Item eventKey="2">
                    <Accordion.Header>What do you say when visiting a doctor?</Accordion.Header>
                    <Accordion.Body>

                      <p>When visiting a doctor, describe your symptoms and any relevant medical history. Be honest and specific about your concerns. Prepare questions about your condition, treatment options, and potential side effects. Discuss any medications or supplements you're taking. Open communication helps the doctor provide accurate diagnosis and appropriate care.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>Why is visiting the doctor important?
</Accordion.Header>
                    <Accordion.Body>

                    <p>Visiting the doctor is crucial for maintaining good health. Regular check-ups can catch potential issues early, increasing chances of successful treatment. Doctors provide expert guidance, offer preventive measures, and update vaccinations. Their advice and medical exams help manage existing conditions and ensure overall well-being.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <Accordion.Item eventKey="4">
                    <Accordion.Header>How do I call a doctor from home?</Accordion.Header>
                    <Accordion.Body>

                      <p>Calling a doctor from home is easy with our service. Simply dial our hotline or use our online booking platform to schedule a home visit. Our experienced doctors will then arrive at your doorstep at the appointed time. Enjoy the convenience of receiving expert medical care without leaving your home. Experience personalized healthcare on your terms – schedule a home visit today.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index