import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>
      <section className='fourth-fold'>

        <div className='container'>

          <div className='row d-flex align-items-center'>

            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>Therefore, choose Kyno Health when you need us and get treated by the top doctors of Delhi at the convenience of your home. From emergency doctor home visit to physiotherapy services, we are here every step of the way, taking care of you and getting you back to living a healthy life.</p>
                {/* <div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div> */}
                {/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>
      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What are the costs associated with a doctor's home visit in Delhi?</Accordion.Header>
                    <Accordion.Body>
                      <p>Doctor's home visit fees in Delhi vary depending on the doctor's fee and services rendered. Typically, the first visit is Rs 900. Additional charges may apply for medications or specialized procedures. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the benefits of using a home visit doctor service in Delhi?</Accordion.Header>
                    <Accordion.Body>

                      <p>Home visit doctor services in Delhi offer convenience, personalized care, timely assistance, comprehensive treatment, continuity of care, and peace of mind for patients in the comfort of their homes. </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>How quickly can a home visit doctor arrive at my location in Delhi?</Accordion.Header>
                    <Accordion.Body>

                      <p>Home visit doctors in Delhi aim to arrive within 1 to 2 hours of booking, offering prompt medical assistance in emergencies or for scheduled consultations.</p>
                    </Accordion.Body>
                  </Accordion.Item>



                </Accordion>

              </div>

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
                <Accordion>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What kind of medical conditions can home visit doctors in Delhi treat?</Accordion.Header>
                    <Accordion.Body>

                      <p>Home visit doctors in Delhi can treat a wide range of medical conditions, including minor illnesses, chronic diseases, acute injuries, infections, and palliative care for terminal illnesses..</p>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What should I prepare before the doctor's visit?</Accordion.Header>
                    <Accordion.Body>

                      <p>Before the doctor's visit, ensure a comfortable and well-lit space, gather medical records or relevant information, list symptoms or concerns, and have any medications or medical equipment readily accessible.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default index