import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When should I consult a doctor for dizziness?
</Accordion.Header>
                    <Accordion.Body>
                      <p>If you experience persistent or recurrent dizziness, especially if it interferes with your daily activities, it's advisable to consult a doctor specializing in dizziness treatment.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What types of doctors specialize in treating dizziness?</Accordion.Header>
                    <Accordion.Body>

                      <p>Physicians specializing in otolaryngology (ENT), neurology, or vestibular rehabilitation are often well-equipped to diagnose and treat various causes of dizziness.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What are common causes of dizziness that these doctors address?</Accordion.Header>
                    <Accordion.Body>
                      <p>Causes may include inner ear disorders, vestibular dysfunction, neurological issues, medications, or cardiovascular conditions. Specialized doctors can help identify the specific cause.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How is dizziness diagnosed by these doctors?</Accordion.Header>
                    <Accordion.Body>
                    <p>Diagnosis involves a thorough medical history review, physical examination, vestibular testing, imaging studies, and, in some cases, blood tests to identify the underlying cause.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Are there lifestyle changes that can help with dizziness?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, doctors may recommend lifestyle modifications, including changes in diet, hydration, and activity levels, to minimize triggers and improve overall well-being.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>What treatments do doctors offer for dizziness?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Treatment options may include medications, physical therapy, vestibular rehabilitation exercises, and, in some cases, surgical intervention depending on the cause.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Can stress or anxiety contribute to dizziness?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, stress and anxiety can exacerbate or contribute to dizziness. Specialized doctors may consider the psychological aspects of dizziness in their treatment approach.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>How long does it take to see improvement with dizziness treatment?

</Accordion.Header>
                    <Accordion.Body>

                    <p>The timeline varies based on the underlying cause and individual response to treatment. Some may experience improvement relatively quickly, while others may require ongoing management.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Can dizziness be a symptom of a serious medical condition?
</Accordion.Header>
                    <Accordion.Body>
                    <p>Yes, dizziness can be a symptom of serious conditions such as vestibular disorders, neurological issues, or cardiovascular problems. Seeking timely medical attention is essential for proper diagnosis and management.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Are there preventive measures to avoid recurring dizziness?</Accordion.Header>
                    <Accordion.Body>
                    <p>Depending on the cause, doctors may recommend preventive measures such as avoiding triggers, staying hydrated, managing stress, and adhering to prescribed medications or exercises to minimize the risk of recurrent episodes.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index