import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose Home Wound Dressing Services?</h2>
  

<div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Comfortable Healing Environment</h4>
<p>Healing is often more effective when it takes place in a familiar and comfortable environment. Our Home Wound Dressing Services allow you to receive expert wound care without the need to travel to a healthcare facility.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Personalized Care Plans</h4>
<p>Every wound is unique, and our healthcare professionals develop personalized care plans tailored to your specific wound type, size, and healing stage. This ensures that you receive the most appropriate and effective care for your individual needs.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Convenient Scheduling</h4>
<p>We understand the importance of consistency in wound care. Our flexible scheduling options make it easy for you to receive the necessary dressing changes at times that suit your routine.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Expertise at Your Doorstep</h4>
<p>Our skilled healthcare professionals bring their expertise to your doorstep, equipped with the necessary supplies and knowledge to perform wound dressing changes with precision and care.
</p>
</div>
</div>

</div>
</div>
</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Types of Wounds Covered</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Post-Surgical Wounds</h4>
<p>After surgical procedures, proper wound care is essential for preventing infections and promoting optimal healing. Our Home Wound Dressing Services cover post-surgical wounds, ensuring that you receive the care needed for a smooth recovery.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Chronic Wounds</h4>
<p>Individuals with chronic wounds, such as those associated with conditions like diabetes, can benefit from regular wound care at home. Our services focus on managing chronic wounds to prevent complications and promote healing.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Medication Management</h4>
<p>Depending on your blood pressure readings and specific circumstances, medication may be a part of your treatment plan. Our doctors will carefully select and monitor the use of medications to achieve the best results with minimal side effects.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Minor Injuries</h4>
<p>Even minor injuries, such as cuts, abrasions, or burns, require proper wound care to prevent infection and support healing. Our healthcare professionals are equipped to handle a variety of minor wounds in the comfort of your home.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Pressure Ulcers</h4>
<p>For individuals with limited mobility or those at risk of pressure ulcers, our Home Wound Dressing Services provide specialized care to manage and prevent pressure ulcers.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Incisions and Lacerations</h4>
<p>Whether you have surgical incisions or lacerations that require careful attention, our team ensures that wound dressing changes are performed with precision and in accordance with your healthcare provider's recommendations.
</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
  <h2>How Home Wound Dressing Services Work
</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Initial Assessment</h4>
<p>The process begins with an initial assessment conducted by our healthcare professionals. They will evaluate the type, size, and stage of your wound, as well as any specific care instructions provided by your healthcare provider.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Care Plan</h4>
<p>Based on the assessment, our team will create a customized care plan that outlines the frequency of dressing changes, the type of dressings to be used, and any additional care instructions.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Scheduling Appointments</h4>
<p>Work with our team to schedule wound dressing appointments at times that are convenient for you. We understand the importance of consistency in wound care and aim to accommodate your schedule.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Home Visit</h4>
<p>On the scheduled date and time, our healthcare professional will visit your home with all the necessary supplies to perform the wound dressing change. They will ensure a sterile and comfortable environment for the procedure.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Dressing Change and Monitoring</h4>
<p>The wound dressing change will be performed with precision, and our healthcare professional will monitor the wound for any signs of infection or complications. They will also provide guidance on wound care between appointments.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Communication with Healthcare Provider</h4>
<p>Our team maintains open communication with your healthcare provider, sharing updates on the wound's progress and ensuring that the care plan aligns with their recommendations.
</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Benefits of Home Wound Dressing Services</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized and Specialized Care</h4>
<p>Home Wound Dressing Services provide personalized and specialized care tailored to your specific wound type, ensuring optimal healing.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience and Comfort</h4>
<p>Receive wound care in the comfort of your home, eliminating the need to travel to a healthcare facility.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Consistent and Timely Care</h4>
<p>Our flexible scheduling options make it easy for you to receive consistent and timely wound care, promoting a more effective healing process.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Expert Healthcare Team</h4>
<p>Our skilled healthcare professionals bring their expertise to your doorstep, equipped with the knowledge and supplies needed for proper wound care.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Open Communication with Healthcare Providers</h4>
<p>We maintain open communication with your healthcare provider to ensure that the wound care plan aligns with their recommendations, fostering a collaborative approach to your health.
</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>How to Get Started with Home Wound Dressing Services</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Consultation</h4>
<p>Contact our healthcare team to schedule a consultation. During this initial discussion, we will assess your wound, discuss your healthcare provider's recommendations, and create a personalized care plan.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Care Plan</h4>
<p>Based on the consultation, our team will create a customized care plan outlining the recommended frequency of wound dressing changes and any specific care instructions.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Scheduling Appointments</h4>
<p>Work with our team to schedule wound dressing appointments at times that are convenient for you. We prioritize consistency and flexibility to accommodate your schedule.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Home Visit</h4>
<p>On the scheduled date and time, our healthcare professional will visit your home with all the necessary supplies to perform the wound dressing change. You can relax and receive expert wound care in a familiar environment.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Pressure Ulcers</h4>
<p>For individuals with limited mobility or those at risk of pressure ulcers, our Home Wound Dressing Services provide specialized care to manage and prevent pressure ulcers.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Incisions and Lacerations</h4>
<p>Whether you have surgical incisions or lacerations that require careful attention, our team ensures that wound dressing changes are performed with precision and in accordance with your healthcare provider's recommendations.
</p>
</div>
</div>

</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index