import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'


function index() {


  return (
    <>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Comprehensive Pain Management Care for a Pain-Free Life</title>
                    <meta name="description" content="Find expert pain management solutions with our specialized doctors. Get relief from chronic pain and regain your quality of life." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/"/>

            </Helmet>
        
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}





export default index