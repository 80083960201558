import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a good hair routine for dandruff?</Accordion.Header>
                    <Accordion.Body>
                      <p>A good hair routine for dandruff involves regular brushing, adequate hair cleansing, and weekly moisturizing. Also, consume a healthy diet rich in protein, vitamins, and minerals. You can include food items such as eggs, meat, milk, etc in your diet. Cut down on sugary food items rich in carbohydrates and fats as much as possible. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How can I get rid of dandruff in 7 days?</Accordion.Header>
                    <Accordion.Body>

                      <p>If you are confused about how to remove dandruff in 7 days, you can switch to a healthy hair routine. Brush your hair regularly, use a clarifying shampoo, and avoid using excessive chemicals on the scalp. Also, refer to a well-renowned hair clinic for expert guidance on dandruff management and prevention.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                 


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>

              <Accordion.Item eventKey="2">
                    <Accordion.Header>How to stop dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>Follow the best home remedy tips to get rid of dandruff. You can apply a mask of egg yolk, yogurt, olive oil, green tea, lemon juice, etc., to moisturize your hair and remove dandruff. Also, implement accurate dietary supplements in your diet, such as proteins and biotin to enhance your scalp's health.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can I remove dandruff permanently at home?</Accordion.Header>
                    <Accordion.Body>

                      <p>If you want information on how to remove dandruff permanently at home, you will need to adopt a good care routine for dandruff that involves regular brushing of hair, gentle hair cleansing using a certified anti-dandruff shampoo, and avoiding excessive heat styling. You can also try out medicated shampoos for extra dandruff protection.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index