import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Frequently Asked Questions (FAQs) of Diabetes</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is diabetes?</Accordion.Header>
                    <Accordion.Body>
                      <p>Diabetes is a chronic medical condition characterized by the body's inability to properly regulate blood sugar (glucose) levels. This can occur due to insufficient insulin production (Type 1 diabetes) or the body's ineffective use of insulin (Type 2 diabetes).</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the common symptoms of diabetes?</Accordion.Header>
                    <Accordion.Body>

                      <p>Common symptoms of diabetes include increased thirst, frequent urination, unexplained weight loss, fatigue, blurred vision, and slow wound healing. However, symptoms can vary, and some individuals may not experience noticeable signs.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What causes diabetes?</Accordion.Header>
                    <Accordion.Body>

                      <p>The causes of diabetes differ between Type 1 and Type 2. Type 1 diabetes is primarily caused by an autoimmune reaction, while Type 2 diabetes is linked to insulin resistance, genetics, lifestyle factors (poor diet, lack of exercise), and age.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How is diabetes diagnosed?</Accordion.Header>
                    <Accordion.Body>

                      <p>Diabetes is diagnosed through blood tests that measure blood sugar levels. Fasting blood sugar tests, oral glucose tolerance tests, and HbA1c tests are commonly used for diagnosis.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What is the difference between Type 1 and Type 2 diabetes?</Accordion.Header>
                    <Accordion.Body>
                      <p>Type 1 diabetes is an autoimmune condition where the body doesn't produce insulin. Type 2 diabetes involves insulin resistance, where the body's cells don't respond effectively to insulin or insufficient insulin is produced.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Can diabetes be prevented?</Accordion.Header>
                    <Accordion.Body>
                      <p>While Type 1 diabetes cannot be prevented, Type 2 diabetes can often be prevented or delayed through lifestyle modifications, including a healthy diet, regular exercise, and weight management.</p>
                    </Accordion.Body>
                  </Accordion.Item>



                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>

              <Accordion.Item eventKey="6">
                    <Accordion.Header>How is diabetes treated?</Accordion.Header>
                    <Accordion.Body>
                      <p>Treatment varies based on the type of diabetes. Type 1 diabetes is managed with insulin therapy, while Type 2 diabetes may involve lifestyle changes, oral medications, injectable medications, and insulin, depending on the severity.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>What is insulin, and why is it important in diabetes?</Accordion.Header>
                    <Accordion.Body>
                      <p>Insulin is a hormone produced by the pancreas that regulates blood sugar levels by allowing cells to use glucose for energy. In diabetes, there is either a lack of insulin (Type 1) or the body's resistance to its effects (Type 2), leading to elevated blood sugar levels.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Can diabetes lead to complications?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, diabetes can lead to complications such as heart disease, stroke, kidney disease, nerve damage, and vision problems. Proper management is crucial to prevent or minimize these complications.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How often should individuals with diabetes monitor their blood sugar?</Accordion.Header>
                    <Accordion.Body>
                      <p>The frequency of blood sugar monitoring varies, but it is typically recommended to monitor levels regularly, especially for those on insulin therapy. Continuous glucose monitoring systems provide real-time data for better management.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* ---- */}

                  <Accordion.Item eventKey="10">
                    <Accordion.Header>Is diabetes hereditary?</Accordion.Header>
                    <Accordion.Body>
                      <p>There is a genetic component to both Type 1 and Type 2 diabetes. Having a family history of diabetes may increase the risk, but lifestyle factors also play a significant role, especially in Type 2 diabetes.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="11">
                    <Accordion.Header>Can diabetes be cured?</Accordion.Header>
                    <Accordion.Body>
                      <p>While there is no cure for diabetes, effective management through lifestyle changes, medications, and insulin therapy can allow individuals to lead healthy lives and prevent complications. Research into potential cures is ongoing.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index