import React from 'react'
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (
    <>
      <section className="ms">
        <div className="container">
          <h2 className='text-center'>Why Is Kyno Health The Perfect Option For In-home Care?</h2>
          <p className='text-center'>Kyno Health ensures convenience, punctuality, all-in-one solutions, and privacy. What else do you need? Let us see how we make this happen:</p>
          <ul>
            <li><strong>Convenient At-home Medical Care: </strong>Kyno Health's home doctor service provides you with the maximum convenience in getting the right medical care. You can say goodbye to long waiting times, crowded waiting rooms, and uncomfortable commutes to a physical clinic. Instead, call us to have a specialized doctor visit you and help you through your medical issues without ever having to leave your house.</li>
            <li><strong>Reaching You On Time, Always: </strong>Kyno Health is strict about punctuality when reaching you. Medical care is the right of every person, which is why we ensure that we reach you on time. Whether you are calling for an appointment or for an emergency, Kyno Health's experienced doctors will show up on time whenever you want.</li>
            <li><strong>All Treatment At Home: </strong>Your home is now your very own clinic! With Kyno Health, you will receive all types of medical treatment at home under the guidance of a skilled doctor. Get routine check-ups done, receive diagnostic results, undergo specialized treatment, or even experience medical procedures without having to go out of the house. We bring every medical treatment at home so you do not have to go to different places for each medical need.</li>
            <li><strong>Patient Privacy Maintained: </strong>Kyno Health takes patient privacy very seriously. We are here to treat you and help you achieve well-being in a comfortable setting at home. Our qualified doctors ensure your privacy and confidentiality is maintained by only focusing on your concerns and keeping your reports accessible to only you at all times. Following the privacy protocols is what we do best, after providing the finest at-home services of course.</li>

          </ul>
        </div>
      </section>


      <section className="seven-fold">
        <div className="container">
          <div className="gradss">
            <Image
              src={require('../../Performance/images/select-Gradient.png')}
              webp={require('../../Performance/images/select-Gradient.webp')}
              alt='gradientImg'
            />
            <img src="images/select-Gradient.png" alt='select' />
          </div>

          <h5>Our Various Services</h5>

          <div className='seven-l-side'>

            <h2 className='text-center'>What Is Kyno Health Offering In Bangalore?</h2>
            <p className='text-center'>Kyno Health is making a name for itself across Bangalore by offering top-class services, which you would have previously had to go to various clinics and hospitals for. Let us make your house a comfortable clinic with these home doctor service:</p>

          </div>

          <div className='thrs-part ms-0 '>
            <div className='row justify-content-center'>


              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Deep Diagnostics </h4>
                  <img src='images/Vaccine.svg' alt='vaccine' />

                  <p>Kyno Health boasts deep diagnostics services that eliminate the need to commute to various pathology labs for screenings. We bring blood and urine test sets at home for extra convenience; we even perform imaging studies and cardiac assessments on the spot so you can get checked right from home. Our team gets reports ready faster than anywhere else so you can have accurate results on time.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Anytime Ambulance</h4>
                  <img src='images/trained.svg' alt='trained' />
                  <p>Kyno Health is your anytime ambulance when regular ambulance services cannot reach you on time. We are accessible 24x7 for at-home emergency care, so you do not have to wait too long to receive medical attention.</p>
                </div></div>

                <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Comprehensive ECG/ X-ray</h4>
                  <img src='images/trained.svg' alt='Comprehensive ECG/ X-ray' />
                  <p>Comprehensive procedures like X-rays and ECG have so far been a thing for clinics and hospitals only. With Kyno Health, these are brought to your home, so you do not have to commute anywhere. We will monitor your heart rhythms and check for any pulmonary conditions in the comfort of your home using high-end machinery. After all, we come equipped with all the apparatus that may be required.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Precise Physiotherapy</h4>
                  <img src='images/ambulance.svg' alt='ambulance' />
                  <p>Our physiotherapy services are precisely curated to help patients recover from injuries, chronic conditions, and surgeries. Even people who want to improve their overall physical mobility and functions can rely on Kyno Health's physiotherapy treatment plans, which are made to suit everyone.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Necessary Nursing</h4>
                  <img src='images/need.svg' alt='need' />
                  <p>Nursing is absolutely necessary in a lot of scenarios when you have a person at home who has acute or chronic health conditions. Wound care, medication management, and assistance with daily activities are part of Kyno Health's at-home nursing services. We understand that the family members of such patients may not always be equipped with the medical knowledge to take care of their loved ones. This is why Kyno Health provides judgment-free nursing care for such patients, helping them with everything from going about their lives to engaging in light exercise. </p>
                </div></div>

            </div>

          </div>


        </div>
      </section>
    </>
  )
}

export default index