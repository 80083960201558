import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is a Full Body Health Check-Up, and why is it important?</Accordion.Header>
                    <Accordion.Body>
                      <p>A Full Body Health Check-Up is a comprehensive examination that assesses the health of various organ systems in the body. It is important for early detection of potential health issues, even in the absence of noticeable symptoms, allowing for timely intervention and improved health outcomes.</p>
                    </Accordion.Body>

                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How often should I undergo a Full Body Health Check-Up?
</Accordion.Header>
                    <Accordion.Body>

                      <p>The frequency of Full Body Health Check-Ups depends on factors such as age, gender, family history, and existing health conditions. Typically, an annual check-up is recommended for adults, but your healthcare provider will customize the schedule based on your individual health needs.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What tests are included in a Full Body Health Check-Up?
</Accordion.Header>
                    <Accordion.Body>
                      <p>A Full Body Health Check-Up includes a range of assessments such as physical examinations, comprehensive blood tests (lipid profile, blood sugar, etc.), imaging studies (X-rays, ultrasound), cardiac evaluations, cancer screenings, vision and hearing tests, nutritional assessments, and lifestyle counseling.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How long does a Full Body Health Check-Up take?</Accordion.Header>
                    <Accordion.Body>
                    <p>The duration varies based on the tests included in the check-up. On average, a Full Body Health Check-Up may take several hours to ensure a thorough assessment. It's recommended to allocate sufficient time for a comprehensive evaluation.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header> Is fasting required before a Full Body Health Check-Up?</Accordion.Header>
                    <Accordion.Body>
                      <p>Fasting may be required for certain blood tests included in the Full Body Health Check-Up, such as lipid profile and blood sugar tests. Your healthcare provider will provide specific instructions regarding fasting or any other preparations before the check-up.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              
              <Accordion.Item eventKey="5">
                    <Accordion.Header>Can a Full Body Health Check-Up detect all health issues?
</Accordion.Header>
                    <Accordion.Body>

                      <p>While a Full Body Health Check-Up is comprehensive, it may not cover every possible health concern. Specialized screenings may be required for specific conditions. Your healthcare provider will tailor the tests based on your age, gender, and risk factors.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Are the results of a Full Body Health Check-Up immediate?</Accordion.Header>
                    <Accordion.Body>
                      <p>The turnaround time for results varies depending on the tests performed. Some results may be available on the same day, while others may take a few days. Your healthcare provider will inform you of the expected timeline for receiving results and schedule a follow-up appointment to discuss them.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Are Full Body Health Check-Ups covered by insurance?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Many insurance plans cover Full Body Health Check-Ups, but coverage varies. It's essential to check with your insurance provider to understand the specific tests covered and any associated costs.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Can I discuss specific health concerns during my Full Body Health Check-Up?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Absolutely. A Full Body Health Check-Up provides an excellent opportunity to discuss any health concerns or questions you may have. Be open and honest with your healthcare provider to ensure a comprehensive assessment.

</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How can I schedule a Full Body Health Check-Up at Kyno Health Center?</Accordion.Header>
                    <Accordion.Body>
                      <p>Scheduling a Full Body Health Check-Up at Kyno Health Center is easy. Simply contact our friendly staff to book an appointment at your convenience. We prioritize your comfort and confidentiality throughout the process, ensuring a positive and personalized experience.
If you have additional questions or concerns, feel free to reach out to our healthcare team. We are committed to supporting you on your journey to optimal health.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index