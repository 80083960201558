import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How can I permanently get rid of dandruff on my scalp naturally?</Accordion.Header>
                    <Accordion.Body>
                      <p>Besides trying out natural dandruff remedies, you will need to implement certain changes in your hair care routine to permanently get rid of dandruff, such as using dandruff-free shampoos, using your separate hair comb, keeping the scalp clean, covering your hair while traveling out, etc. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How can I make my scalp healthy and dandruff-free?</Accordion.Header>
                    <Accordion.Body>

                      <p>Follow the accurate dietary habits to combat dandruff. Eat a diet rich in protein and vitamins such as biotin, Zinc, Copper, etc. You can also try out home remedies to make your scalp dandruff-free such as using green tea, hung curd, honey, basil leaves, apple cider vinegar, etc as they are some of the best home remedy for dandruff. </p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                 


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>

              <Accordion.Item eventKey="2">
                    <Accordion.Header>How can I regrow my dandruff hair?</Accordion.Header>
                    <Accordion.Body>

                      <p>To make your hair regrow after dandruff, you can switch to following a proper dietary habit comprising food items such as meat, dish, egg, tomatoes, basil, lemon, etc. Also, consult a renowned hair specialist and follow certain dandruff home remedies to enhance hair growth. Consulting a specialist will help you understand the complexities of your scalp condition better.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can I clean my scalp naturally at home?</Accordion.Header>
                    <Accordion.Body>

                      <p>You can naturally and effortlessly clean up your scalp at home using natural dandruff remedies. Use egg yolk, neem juice, lemon juice, etc., to make your scalp completely free of dandruff. Use these remedies twice a week for visible results.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index