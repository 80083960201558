import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Heatwave Alert!! 5 Expert Tips to Beat the Heat</h3>
   
    <p>As a tormenting heatwave engulfs the entire nation, it feels like we're all being slow-cooked in a human tandoor&mdash;no exaggeration needed. According to reports, several parts of India are experiencing record-breaking temperatures, with Delhi recording an unbearable reading of 52.3 degrees Celsius in the last week. While the heat is intolerable, that&rsquo;s not the only inconvenience it accompanies. Along the heatwave comes a range of health issues, which, when left ignored, can lead to significant consequences.</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
