import React, {useState} from 'react' 
import "../../../styles/leadQueriesTab.css"

export default function LeadQueriesTab(){
 
    return(
        <>
            <div className='manage-lead-queries'>

                <div className='title'>Here is the list of all Lead Queries</div>


            </div>
        </>
    )
}
