import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians providing home services in Dwarka, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "With Kyno Health, your search for the best general physician providing home services in Dwarka comes to an end. We provide top-quality home services given by trained physicians."
      }
    },{
      "@type": "Question",
      "name": "What are the benefits of consulting a general physician at home in Dwarka?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Consulting a general physician at home in Dwarka holds many benefits. There is an enhanced convenience and personalized care for various individuals, especially those who cannot visit physical clinics."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a top general physician in Dwarka for a home visit?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book a consultation with a top general physician in Dwarka for a home visit through the Kyno Health website."
      }
    },{
      "@type": "Question",
      "name": "What services are typically offered by general physicians at home in Dwarka, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Services typically offered by general physicians at home in Dwarka, Delhi, include nursing care, ECG, X-ray, diagnostics, 24-hour caregiving, physiotherapy, and ambulance assistance."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians providing home services in Dwarka equipped to handle emergency situations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the general physicians providing home services in Dwarka are trained to handle each emergency situation with care and are equipped for every emergency."
      }
    }]
  }
  
  
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Dwarka, Delhi | Kyno Health</title>
        <meta name="description" content="Get professional medical care in the comfort of your home with Kyno Health's best general physicians in Dwarka, Delhi. Enjoy convenient and quality healthcare services without leaving your home." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/dwarka-general-physicians-at-home" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians At Home In Dwarka, Delhi" hero_paragraph="The best general physicians at home in Dwarka, Delhi, are the new-age solution to accessible healthcare. The residents of this beautiful urban area are now more cognizant than ever about their healthcare decisions. Home healthcare services are now their go-to for personalized medical attention directly at home. Kyno Health is a premier solution that brings medical care at home to make healthcare more accessible. " hero_paragraph_1="Those with mobility issues and chronic conditions can now get treated in the comfort of their homes! Patients can avoid the challenges of traveling to clinics and hospitals. This reduces the risks of infections and promotes a more relaxed environment for consultations. Home visits are the key to better communication between the doctor and their patients. Disease management and treatment planning are now much more convenient."/>
      
      <Content content_h3="How Doctor Visits Can Enhance Healthcare at Home?" content_p="General physician home visit near me hold various advantages for patients. These contribute to better healthcare for many individuals, all set in a homely environment. The most important of these advantages is the personalized care. This tailored care is specially made after an assessment of the individual needs of patients. Doctors can observe your living conditions and give more precise recommendations that can enhance your quality of life. Such an approach is important to immediately address health concerns to promote long-term wellness. " content_p1="Immunocompromised patients can contract infections in a foreign environment, such as a hospital. These people are more comfortable in a safer, homely environment where the doctor can visit them for treatment. Kyno Health understands the need for a patient-centric approach in modern healthcare, which is why we have doctors come to your house for a closer consultation. "

      content_h3_2="Experience Kyno's Dedicated At-home Medical Services Today" content_p_2="Kyno Health stands out as a premier at-home medical service solution when you are seeking general physicians near me. We are redefining convenience and quality in healthcare. We specialize in bringing only expert medical care directly to your home. Kyno Health ensures that every patient in Dwarka receives the prompt and compassionate attention they deserve. Our skilled physicians help cater to you at home, so there is no need to travel to a clinic. " content_p_2a="Your routine check-ups, chronic issue treatment, and urgent medical care needs can now be fulfilled directly from home! Kyno Health is dedicated to delivering comprehensive healthcare services through our host of professionals. Medical expertise with personalized attention is very important. Kyno Health helps patients receive better attention towards their health in the familiarity of their homes." />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index