import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Your Partner for Healthy Blood Pressure Management

</h3>    
    <p className='d-md-none d-lg-none'>High blood pressure, also known as hypertension, is a silent but potentially deadly condition that affects millions of people worldwide. It's a leading cause of heart disease, stroke, and other serious health issues. At Kyno Health clinic, we're dedicated to helping you manage your blood pressure, keeping it within a healthy range, and reducing the risk of complications. Our team of experienced doctors specializes in blood pressure management, and we're here to guide you on the path to better heart health.</p>

<p className='mob-noee'>High blood pressure, also known as hypertension, is a silent but potentially deadly condition that affects millions of people worldwide. It's a leading cause of heart disease, stroke, and other serious health issues. At Kyno Health clinic, we're dedicated to helping you manage your blood pressure, keeping it within a healthy range, and reducing the risk of complications. Our team of experienced doctors specializes in blood pressure management, and we're here to guide you on the path to better heart health.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tags1Img'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
