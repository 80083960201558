import React, { useState } from 'react'
import "../../../styles/sales_all_calls.scss"
import { localBackend } from '../../../utilities/constants'
import axios from 'axios'

export default function Sales_All_Calls(props) {

    const [allCalls, setAllCalls] = useState(null)
    const [allCallsTabSeledcted, setAllCallsTabSelected] = useState("ALL")
    const [salesEmployeeId, setSalesEmployeeId] = useState(props.salesEmployeeId)
    const [salesEmployees, setSalesEmployees] = useState(null)
    const [srNumbers, setSrNumbers] = useState(null)


    const [pageMsg, setPageMsg] = useState("")

    const fetchAllCalls = async () => {
        const result = await axios.get(`${localBackend}getAllCalls?salesEmployeeId=${props.salesEmployeeId}`).then((resp) => {
            // console.log(resp.data.data)

            setAllCalls(resp.data.data[0])
        })
    }

    const fetchAllSalesEmployee = async () => {
        const result = await axios.get(`${localBackend}getAllSalesEmployee?salesEmployeeId=${props.salesEmployeeId}`).then((resp) => {
            // console.log(resp.data.data)
            setSalesEmployees(resp.data.data)
        })
    }
    const convertSecondsToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return {
            hours,
            minutes,
            seconds: remainingSeconds
        };
    }

    const getSalesEmployeeName = (number) => {
        // console.log(number)
        var obj = salesEmployees.find(s => s.phone === number) || null
        // console.log(obj, " obj")
        var name = obj === null ? "" : obj.name
        return name
    }

    const fetchAllSRNumbers = async () => {
        const result = await axios.get(`${localBackend}getSRNumbers?salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            // console.log(resp.data.data)
            if (resp.data.statusCode == 1) {
                setSrNumbers(resp.data.data.sr_numbers)
            }
        })
    }

    const changSRNumberSource = async (srnumber, source) => {

        let updates = [...srNumbers]
        const toUpdate = updates.find(u => u.number == srnumber)

        if (toUpdate) {
            toUpdate.source = source
            console.log(updates)
            let body = {
                "data": updates,
                "salesEmployeeId": salesEmployeeId
            }

            const result = await axios.post(`${localBackend}changeSRNumberSource`, body).then((resp) => {
                console.log(resp.data)
                if (resp.data.statusCode === 1) {
                    setSrNumbers(updates)
                }
            })
        }
    }

    const setpagemessage = (msg) => {
        setPageMsg(msg)
        setInterval(() => {
            setPageMsg("")
        }, 3000)
    }


    useState(() => {
        fetchAllCalls()
        fetchAllSalesEmployee()
        fetchAllSRNumbers()

    }, [])

    return (
        <>
            <div className='sales-all-calls'>

                <div className='all-calls-tabs'>
                    <div className={allCallsTabSeledcted === "ALL" ? "active" : ''} onClick={(e) => {
                        setAllCallsTabSelected("ALL")
                    }}>All</div>
                    <div className={allCallsTabSeledcted === "INCOMING" ? "active" : ''} onClick={(e) => {
                        setAllCallsTabSelected("INCOMING")
                    }}>Incoming</div>
                    <div className={allCallsTabSeledcted === "OUTGOING" ? "active" : ''} onClick={(e) => {
                        setAllCallsTabSelected("OUTGOING")
                    }}>Outgoing</div>
                    <div className={allCallsTabSeledcted === "SR_NUMBER_MANAGEMENT" ? "active" : ""} onClick={(e) => {
                        setAllCallsTabSelected("SR_NUMBER_MANAGEMENT")
                    }}>SR Number Management</div>
                </div>

                {allCallsTabSeledcted === "ALL" &&

                    <div className='all-calls-content'>


                        <div className='all-calls-headers'>
                            <div className='sno'>S.No.</div>
                            <div className='patient-name'>Patient Name</div>
                            <div className='patient-id'>Patient Id</div>
                            <div className='date'>Date</div>
                            <div className='phone-number'>Phone Number</div>
                            <div className='call-type'>Call Type</div>
                            <div className='duration'>Duration</div>
                            <div className='sr-no'>SR. No.</div>
                            <div className='answered-by'>Answered By</div>
                            <div className='bd-assigned'>BD Assigned</div>
                            <div className='lead-status'>lead-status</div>
                        </div>

                        {allCalls !== null &&
                            <div className='all-calls'>

                                {allCalls !== null && salesEmployees !== null && allCalls.incoming.map((call, callIndex) => {
                                    // console.log(call)
                                    let _time = convertSecondsToTime(call.call_duration)
                                    let time_ = `${_time.hours} hr ${_time.minutes} min ${_time.seconds} sec`
                                    return (
                                        <div className='call' key={callIndex}>
                                            <div className='sno'>{callIndex + 1}</div>
                                            <div className='patient-name'>{call.patient.name !== undefined ? call.patient.name : "[no-name]"}</div>
                                            <div className='patient-id'>{call.patient._id}</div>
                                            <div className='date'>{call.start_time.split(" ")[0]} {call.start_time.split(" ")[1].split(".")[0]}</div>
                                            <div className='phone-number'>{call.caller_id}</div>
                                            <div className='call-type'>{call.call_type}</div>
                                            <div className='duration'>{time_}</div>
                                            <div className='sr-no'>{call.dispnumber}</div>
                                            <div className='answered-by'>{getSalesEmployeeName(call.destination)}</div>
                                            <div className='bd-assigned'>{call.lead !== null && call.lead.currentSalesEmployee !== undefined ? getSalesEmployeeName(call.lead.currentSalesEmployee) : ""}</div>
                                            <div className='lead-status'>{call.lead !== null && call.lead.status !== undefined ? call.lead.status : ""}</div>
                                        </div>
                                    )

                                })}

                            </div>
                        }
                    </div>
                }


                {allCallsTabSeledcted == "SR_NUMBER_MANAGEMENT" &&
                    <div className='sr-number-management'>
                        <div className='headers'>
                            <div className='sr-number'>SR Number</div>
                            <div className='marketing-source'>Marketing Source</div>
                        </div>
                        <div className='all-sr-numbers'>
                            {srNumbers !== null && srNumbers.map((number, index) => {

                                return (
                                    <div className='sr-number' key={index}>
                                        <div className='sr-number'>{number.number}</div>
                                        {/* <div className='marketing-source'>{number.source}</div> */}
                                        <select value={number.source} onChange={(e) => {
                                            changSRNumberSource(number.number, e.target.value)
                                        }}>
                                            <option value="">Select SR Number Marketing Source</option>
                                            <option value="Google Call">Google Call</option>
                                            <option value="Organic">Organic</option>
                                        </select>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }


                {allCallsTabSeledcted == "INCOMING" &&
                    <div className='incoming-calls'>

                        <div className='incoming-calls-headers'>
                            <div className='sno'>S.No.</div>
                            <div className='patient-name'>Patient Name</div>
                            <div className='patient-id'>Patient Id</div>
                            <div className='date'>Date</div>
                            <div className='phone-number'>Phone Number</div>
                            <div className='call-type'>Call Type</div>
                            <div className='duration'>Duration</div>
                            <div className='sr-no'>SR. No.</div>
                            <div className='answered-by'>Answered By</div>
                            <div className='bd-assigned'>BD Assigned</div>
                            <div className='lead-status'>lead-status</div>
                        </div>
                        <div className='all-calls'>
                            {allCalls !== null && salesEmployees !== null && allCalls.incoming.map((call, callIndex) => {
                                // console.log(call)
                                let _time = convertSecondsToTime(call.call_duration)
                                let time_ = `${_time.hours} hr ${_time.minutes} min ${_time.seconds} sec`
                                return (
                                    <div className='call' key={callIndex}>
                                        <div className='sno'>{callIndex + 1}</div>
                                        <div className='patient-name'>{call.patient.name !== undefined ? call.patient.name : "[no-name]"}</div>
                                        <div className='patient-id'>{call.patient._id}</div>
                                        <div className='date'>{call.start_time.split(" ")[0]} {call.start_time.split(" ")[1].split(".")[0]}</div>
                                        <div className='phone-number'>{call.caller_id}</div>
                                        <div className='call-type'>{call.call_type}</div>
                                        <div className='duration'>{time_}</div>
                                        <div className='sr-no'>{call.dispnumber}</div>
                                        <div className='answered-by'>{getSalesEmployeeName(call.destination)}</div>
                                        <div className='bd-assigned'>{call.lead !== null && call.lead.currentSalesEmployee !== undefined ? getSalesEmployeeName(call.lead.currentSalesEmployee) : ""}</div>
                                        <div className='lead-status'>{call.lead !== null && call.lead.status !== undefined ? call.lead.status : ""}</div>
                                    </div>
                                )

                            })}
                        </div>
                    </div>
                }


                {allCallsTabSeledcted == "OUTGOING" &&
                    <div className='outgoing-calls'>

                        <div className='outgoing-calls-headers'>
                            <div className='sno'>S.No.</div>
                            <div className='patient-name'>Patient Name</div>
                            <div className='patient-id'>Patient Id</div>
                            <div className='date'>Date</div>
                            <div className='phone-number'>Phone Number</div>
                            <div className='call-type'>Call Type</div>
                            <div className='duration'>Duration</div>
                            <div className='sr-no'>SR. No.</div>
                            <div className='initiated-by'>Answered By</div>
                        </div>
                        <div className='all-calls'>
                            {allCalls !== null && salesEmployees !== null && allCalls.outgoing.map((c, index) => {

                                let date = c.start_time.split(" ")[0]
                                let _time = convertSecondsToTime(c.call_duration)
                                let time_ = `${_time.hours} hr ${_time.minutes} min ${_time.seconds} sec`
                                let _salesEmpName = getSalesEmployeeName(c.caller_id)
                                console.log(c)

                                if (c.destination == "Customer Missed") {

                                    return (
                                        <div className='call missed'>
                                            <div className='sno'>{index + 1}</div>
                                            <div className='patient-name'>Patient Name</div>
                                            <div className='patient-id'>Patient Id</div>
                                            <div className='date'>{date}</div>
                                            <div className='phone-number'>{c.customer_missed_number}</div>
                                            <div className='call-type'>Outgoing</div>
                                            <div className='duration'>{time_}</div>
                                            <div className='sr-no'>SR. No.</div>
                                            <div className='initiated-by'>{_salesEmpName}</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='call'>
                                            <div className='sno'>{index + 1}</div>
                                            <div className='patient-name'>Patient Name</div>
                                            <div className='patient-id'>Patient Id</div>
                                            <div className='date'>{date}</div>
                                            <div className='phone-number'>{c.destination}</div>
                                            <div className='call-type'>Outgoing</div>
                                            <div className='duration'>{time_}</div>
                                            <div className='sr-no'>SR. No.</div>
                                            <div className='initiated-by'>{_salesEmpName}</div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                }

                <div className='page-msg'>{pageMsg}</div>
            </div>
        </>
    )
}
