import React from 'react'
import { Link } from "react-router-dom";
import Image from 'react-image-webp';
 

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Benefits of Choosing Doctor at Home Services for Noida Residents: <br/> Convenience, Comfort, and Quality Care</h3>    
    <p className='d-md-none d-lg-none'>In the lively city of Noida, life moves quickly. This is why having access to good healthcare is important. Traditional clinic visits sometimes include long wait times, crowded waiting rooms, and the inconvenience of commuting. They end up creating serious problems for locals seeking medical care. However, the rise of <a href="https://www.kynohealth.com/doctor-home-visit-noida"><strong>doctor at home services in Noida </strong></a>is changing the healthcare scene. This upcoming service provides unparalleled convenience, personalized attention, and fast healthcare right at your door. This article delves into the many benefits of using home medical services that allow Noida residents to prioritize their health. Let&rsquo;s get started without further ado.</p>


<p className='mob-noee'>In the lively city of Noida, life moves quickly. This is why having access to good healthcare is important. Traditional clinic visits sometimes include long wait times, crowded waiting rooms, and the inconvenience of commuting. They end up creating serious problems for locals seeking medical care. However, the rise of <a href="https://www.kynohealth.com/doctor-home-visit-noida"><strong>doctor at home services in Noida </strong></a>is changing the healthcare scene. This upcoming service provides unparalleled convenience, personalized attention, and fast healthcare right at your door. This article delves into the many benefits of using home medical services that allow Noida residents to prioritize their health. Let&rsquo;s get started without further ado.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tags1Img'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
