import React, { useEffect, useState } from 'react';
import LabelComponent from './labelComponent';
// import CustomTextField from './CustomTextField';
import { TextField, Typography } from '@mui/material';

const InputWithLabel = ({ label, placeholder, type,handleInputChange, error, setError, inputValue='', handleInputClick=() => {} }) => {
  const [value, setValue] = useState(inputValue);
  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])
  // const [error, setError] = useState(error);

  const handleValidation = (inputValue) => {
    if (type === 'name') {
      // Simple name validation (only letters and spaces allowed)
      const nameRegex = /^[a-zA-Z\s]+$/;
      if (!nameRegex.test(inputValue)) {
        setError('Invalid name. Only letters and spaces are allowed.');
      } else {
        setError('');
      }
    } else if (type === 'phone') {
      // Simple phone number validation (only digits, length 10)
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(inputValue)) {
        setError('Invalid phone number. Must be 10 digits.');
      } else {
        setError('');
      }
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Only allow digits (0-9) to be entered
    if ((!(/^\d*$/.test(inputValue)) || inputValue.length > 10) && type === "phone") {
      setValue(removeLastCharacter(inputValue))
      return
  }
    setValue(inputValue);
    handleInputChange(inputValue)
    handleValidation(inputValue);
  };
  
  const removeLastCharacter = (str) => {
      return str.slice(0, -1);
  };

  return (
    <div style={{ marginBottom: "20px"}}>
      <LabelComponent text={label} />
      <TextField
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        onClick={() => handleInputClick()}
        InputProps={{
          sx: {
            backgroundColor: '#f7f7f7',
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? 'red' : 'transparent',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? 'red' : 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? 'red' : 'transparent',
            },
            '& .MuiInputBase-input': {
              color: '#424242',
              fontSize: '16px',
            },
          },
        }}
      />
      {/* {error && (
        <Typography
          variant="body2"
          sx={{ color: 'red', marginTop: '5px', marginLeft: '10px' }}
        >
          {error}
        </Typography>
      )} */}
    </div>
  );
};

export default InputWithLabel;
