import React, { useState } from 'react'
import PageNotFound from '../PageNotFound'
import { useParams } from 'react-router-dom'
import Home from '../Home'


export default function Disease_symptoms_treatment(props) {
    const a = useParams()
    console.log(a)
    console.log(props.normal)
    return (
        <>
            <div>
                <Home />
                
            </div>
        </>
    )
}
