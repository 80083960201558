import React from "react";
import { Accordion } from 'react-bootstrap';

function createMarkup(text) {
  if (text.includes('\n')) {
    const listItems = text.split('\n').map(item => `<li>${item}</li>`).join('');
    return { __html: `<ul>${listItems}</ul>` };
  }
  if (text.includes('<br>')) {
    const withBreaks = text.replace(/<br>/g, '<br/>');
    return { __html: withBreaks };
  }
  return { __html: text };
}

function FAQ({ items }) {
  const faqItems = items.mainEntity; // Access the mainEntity array

  return (
    <section className="nine-fold acc-h ms">
      <div className="container">
        <h5>Questions for kyno</h5>
        <div className="ml-70">
          <h2>FAQs</h2>
        </div>

        <div className="mlt fqs">
          {faqItems.length > 0 ? (
            <div className="row">
              <div className="col-md-6">
                <Accordion>
                  {faqItems.filter((item, index) => index % 2 === 0).map((item, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index} className="flex-grow-1">
                      <Accordion.Header>{item.name}</Accordion.Header>
                      <Accordion.Body>
                        <div dangerouslySetInnerHTML={createMarkup(item.acceptedAnswer.text)} />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="col-md-6">
                <Accordion>
                  {faqItems.filter((item, index) => index % 2 !== 0).map((item, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index} className="flex-grow-1">
                      <Accordion.Header>{item.name}</Accordion.Header>
                      <Accordion.Body>
                        <div dangerouslySetInnerHTML={createMarkup(item.acceptedAnswer.text)} />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          ) : (
            <p className="d-none">No items to display</p>
          )}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
