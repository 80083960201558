import React, { useEffect, useState } from 'react'
import "../../styles/dashboardHome.css"
import Sales_home from './sales/Sales_home'
import Ops_home from './operations/Ops_home'
import Doctor_home from './doctor/Doctor_home'
import logo from '../../utilities/images/logo.png'
import { useNavigate } from 'react-router'

export default function DashboardHome() {

    const [dashboard, setDashboard] = useState("")
    const [dashboardSelected, setDashboardSelected] = useState(false)

    const navigate = useNavigate()


    useEffect(() => {
        document.title = "Kyno Health | Dashboard"
    }, [])

    return (
        <>
            <div>
                <div className='dashboard'>
                    <img src={logo} />
                    <div className='home-btn' onClick={(e) => {
                        setDashboard("")
                        setDashboardSelected(false)
                    }}>Home</div>
                </div>

                {dashboardSelected === false ? (
                    <div className='dashboard-home'>
                        <div className='head'>Welcome to Kyno Health Dashboard Panel</div>
                        <div className='title'>Select the dashboard you want to open</div>
                        <div className='selected-board'>Dashboard Selected: {dashboard}</div>
                        <div className='dashboard-options'>
                            <select value={dashboard} onChange={(e) => {
                                setDashboard(e.target.value)
                            }}>
                                <option value="">Select Dashboard</option>
                                <option value="SALES">Sales Dashoard</option>
                                <option value="OPERATIONS">Operations Dashoard</option>
                                <option value="DOCTORS">Doctors Dashboard</option>
                            </select>
                            <div className='submit' onClick={(e) => {
                                navigate("/dashboard/sales")
                                setDashboardSelected(true)
                            }}>SUBMIT</div>
                        </div>
                    </div>
                ) : (
                    <div>
                        {dashboard === "SALES" ? (
                            <Sales_home />
                        ) :
                            dashboard === "OPERATIONS" ? (
                                <Ops_home />
                            ) : (
                                <Doctor_home />
                            )
                        }
                    </div>
                )}
            </div>
        </>
    )
}
