import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose In-Home IV Drip Services?</h2>
  

<div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Convenience and Comfort</h4>
<p>Our In-Home IV Drip Services eliminate the need to travel to a clinic, allowing you to receive intravenous therapy in the comfort of your own home. This convenience is particularly beneficial for individuals with busy schedules, limited mobility, or those seeking a more private and relaxed environment.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Personalized Wellness Plans</h4>
<p>Our experienced healthcare professionals work closely with you to create personalized wellness plans tailored to your specific health goals and concerns. Whether you're seeking hydration, nutrient replenishment, immune support, or energy boosters, our IV Drip Services can be customized to meet your unique needs.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Time-Saving Solution</h4>
<p>In-Home IV Drip Services offer a time-saving solution for busy individuals who want to prioritize their health without sacrificing valuable time commuting to and from a healthcare facility. Our efficient and professional team ensures a streamlined experience without compromising on the quality of care.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Tailored Formulas for Optimal Results</h4>
<p>We offer a variety of IV drip formulas designed to address specific health and wellness goals. From hydration and vitamin infusions to immune support and detoxification, our tailored formulas are crafted to help you achieve optimal results.</p>
</div>
</div>

</div>
</div>
</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Popular IV Drip Formulas</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Hydration Boost</h4>
<p>Ideal for replenishing fluids and electrolytes, our Hydration Boost formula is perfect for combating dehydration, whether caused by physical exertion, travel, or illness.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Energy Revitalize</h4>
<p>This formula is designed to boost energy levels and enhance overall vitality. It includes a blend of essential vitamins and minerals to promote sustained energy and mental clarity.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Immune Support</h4>
<p>Strengthen your immune system with our Immune Support formula, containing a mix of immune-boosting vitamins and antioxidants to help you stay resilient against illness.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Recovery and Detox</h4>
<p>Our Recovery and Detox formula is crafted to aid in post-exercise recovery, support detoxification, and promote overall well-being.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Beauty and Anti-Aging</h4>
<p>Nourish your skin and support anti-aging with our Beauty and Anti-Aging formula, which includes a blend of vitamins and antioxidants for a radiant complexion.
</p>
</div>
</div>


</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
  <h2>How In-Home IV Drip Services Work</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Consultation</h4>
<p>Begin with a consultation with our healthcare professionals to discuss your health goals, concerns, and preferences. During this consultation, we will determine the most suitable IV drip formula for your needs.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Plan</h4>
<p>Based on the consultation, our team will create a customized plan that outlines the recommended frequency of IV drip sessions and the specific formula to be administered during each session.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Scheduling Appointments</h4>
<p>Work with our team to schedule IV drip appointments at times that are convenient for you. Our flexible scheduling options are designed to accommodate your lifestyle.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Home Visit</h4>
<p>On the scheduled date and time, our healthcare professional will arrive at your home equipped with all the necessary supplies to administer the IV drip. They will ensure a sterile and safe environment for the procedure.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Administration and Monitoring</h4>
<p>The IV drip will be administered by our skilled healthcare professional, who will monitor the process to ensure your comfort and safety. Throughout the session, you can relax and enjoy the benefits of intravenous therapy in the privacy of your home.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Post-Drip Guidance</h4>
<p>After the IV drip session, our team will provide any necessary post-drip guidance, including recommendations for hydration and activities to optimize the benefits of the therapy.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Benefits of In-Home IV Drip Services</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Wellness</h4>
<p>Tailored IV drip formulas address your specific health and wellness goals, promoting a personalized approach to care.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience and Time Efficiency</h4>
<p>Save time and experience the convenience of receiving IV drip therapy at home, eliminating the need for travel to a healthcare facility.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Comfort and Privacy</h4>
<p>Enjoy the comfort and privacy of your own home during the IV drip session, creating a relaxed environment for wellness.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Professional and Skilled Healthcare Team</h4>
<p>Our experienced healthcare professionals ensure the safe and effective administration of IV drip therapy, providing expert care in the comfort of your home.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Scheduling</h4>
<p>Our flexible scheduling options accommodate your lifestyle, making it easy to incorporate IV drip sessions into your routine.</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>How to Get Started with In-Home IV Drip Services</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Consultation</h4>
<p>Contact our healthcare team to schedule a consultation. During this initial discussion, we will assess your health goals, answer any questions you may have, and determine the most suitable IV drip formula for your needs.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Plan</h4>
<p>Based on the consultation, our team will create a customized plan outlining the recommended IV drip formulas and scheduling options.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Scheduling Appointments</h4>
<p>Work with our team to schedule IV drip appointments at times that are convenient for you. Our goal is to make wellness easily accessible to fit your lifestyle.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Home Visit</h4>
<p>On the scheduled date and time, our healthcare professional will visit your home with all the necessary supplies to administer the IV drip. You can relax and enjoy the benefits of intravenous therapy in a familiar environment.
</p>
</div>
</div>


<div className='col-md-12'>
<p>Invest in your well-being with the convenience and comfort of In-Home IV Drip Services at Kyno Health Center. Contact us today to explore how our personalized IV drip therapy can elevate your health and enhance your overall wellness journey.</p>
</div>
</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index