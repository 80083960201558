import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are the top general physicians available for home visits in Civil Lines, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kyno Health is wholly committed to delivering dependable, high-quality, and cost-effective services directly in the residences of our patients. We place a significant emphasis on service quality, meticulously selecting and training our staff through a rigorous process."
      }
    }, {
      "@type": "Question",
      "name": "What services do the best general physicians offer for patients at home in Civil Lines, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "\n We value and comprehend patients' unique needs. \n We prioritize ongoing training and careful selection of our personnel. \n We uphold a stringent code of conduct and foster a positive work culture. \n We are adaptable to individual circumstances. \n We are dedicated to providing exceptional care tailored to the specific requirements of each individual we serve."

      }
    }, {
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for a home visit in Civil Lines, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can schedule the appointment by making a call to the clinic and talking with the personnel. You can also book the appointment by sending the mail to the office."
      }
    }, {
      "@type": "Question",
      "name": "What are the consultation fees for general physicians providing home visits in Civil Lines, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Consultation fee depends on the diagnosis and services provided by the physicians. The visiting fee of the physician is 1000/-."
      }
    }, {
      "@type": "Question",
      "name": "Do the general physicians in Civil Lines, Delhi offer telemedicine options in addition to home visits?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Normally telemedicine option is not available. But in case of emergency after making the payment through online mode this facility can be availed."
      }
    }]
  }


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Civil Lines | Kyno Health</title>
        <meta name="description" content="Experience premium healthcare from the comfort of your home with Kyno Health. Our best general physicians at home in Civil Lines offer comprehensive consultations and treatments, ensuring you receive the highest quality care. Choose Kyno Health for convenient and trusted home healthcare services." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-civil-lines" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

                <h3>Best General Physicians at Home in Civil Lines</h3>

                <p>If someone finds it difficult to visit a doctor for consultation or diagnosis, you can book a general physician's team available at Kyno Health in civil lines to come to your home place. The team travels around the Civil line to provide high-quality care where it's most comfortable and convenient — in your home.</p>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>
          <h3>Our services include:</h3>
          <ul>
            <li>Ongoing primary medical care and management of chronic illness.</li>
            <li>Prescription refills.</li>
            <li>Arranging testing or specialist visits.</li>
            <li>Geriatric consults.</li>
            <li>Arranging social support services.</li>
            <li>We can help with end-of-life discussions and determining goals of care.</li>
          </ul>
          <p>The patients who receive medical care at home function better at home and have a lower rate of moving into a skilled nursing facility. In addition, they are 20 to 30 percent less likely to be hospitalized each year.</p>
          <h3>Why choose Kyno Health at Civil Lines</h3>
          <ul>
            <li><strong>Dedicated mobile team.</strong> We have a keen staff of mobile central care physicians (including certified geriatricians), physician assistants, and nurse practitioners. All of them focus on helping people who are isolated at home or in bed.&nbsp; Our team also has a social worker and registered nurses who help manage services for patients with complex needs.</li>
            <li><strong>Coordinated care. </strong>Our team will coordinate with the person's physician and update them on the progress of your diagnosis All at-home care is recorded on the same medical record used by your other Kyno health doctors. And if you need additional medical services, we can arrange them with other providers.</li>
            <li><strong>Doctors that know your neighborhood.</strong> Our providers are appointed at various places in Delhi civil lines, so you can build a relationship with a regular caregiver who has working knowledge of hospitals, specialists, and other services in your area.</li>
          </ul>
          <p>Kyno Health is situated in the Delhi civil line and is the only one selected to participate in the Independence at Home Demonstration conducted by the Centers for Medicare &amp; Medicaid Services. This designation recognizes that our program provides high-quality care while lowering medical costs.</p>
        </div>
      </section>

      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index