import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How do I really find a good doctor?</Accordion.Header>
                    <Accordion.Body>
                      <p>The internet can help you find a good doctor. Open your browser and type ' best home visit doctors near me', and you will be presented with top-notch recommendations from where you can browse their profiles and choose the one you find the best to fit your medical requirements.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I make a good doctor's appointment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Once you find a good doctor, simply reach out to their website and book an appointment. You can also call out the helpline numbers if provided for making an appointment with the home visit doctors based on their availability.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What type of doctor is best for primary care?</Accordion.Header>
                    <Accordion.Body>

                      <p>A family medical specialist or internal medicine specialist is the best kind of home visit doctors to look up to for primary care at the earliest.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How do I find the best surgeon in my area?</Accordion.Header>
                    <Accordion.Body>
                      <p>You can easily search for the best surgeon in your area by using the web. Once you find a suitable recommendation, monitor their affiliations, expertise, experience, etc., to determine the potential of the surgeon.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index