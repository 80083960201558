import React, { useState, useEffect } from 'react'
import Doctor_auth from './Doctor_auth'
import Cookies from 'js-cookie'
import { localBackend } from '../../../utilities/constants'
import axios from 'axios'
import "../../../styles/doctor_home.scss"
import Doctor_lead from './Doctor_lead'

export default function Doctor_home() {

    const DE = Cookies.get("doctorEmployee")

    const [onboardingState, setOnboardingState] = useState(false)
    const [doctorId, setDoctorId] = useState("")
    const [doctorEmployee, setDoctorEmployee] = useState(null)
    const [contentOptionSelected, setContentOptionSelected] = useState(1)



    const fetchEmployeeDetails = async (id) => {
        const res = await axios.get(`${localBackend}getDoctorEmployee?id=${id}`).then((resp) => {
            setDoctorEmployee(resp.data.data)
            return resp.data.data
        })
        console.log("Doctor Employee", res)
    }

    const loggedIn = async (id) => {
        console.log("Doctor employee: ", id)
        setDoctorId(id)
        Cookies.set('doctorEmployee', id, { expires: 1 })
        fetchEmployeeDetails(id);
        setOnboardingState(false);
    }

    const logout = async () => {
        console.log("logging out")
        Cookies.remove('doctorEmployee');
        setOnboardingState(true)
    }

    useEffect(() => {

        console.log("DE: ", DE)
        if (DE === undefined || doctorId === null) {
            setOnboardingState(true);
        } else {
            setDoctorId(DE)
            fetchEmployeeDetails(DE)
        }

        // if (operationsEmployeeId !== "" && onboardingState === false) {
        //     fetchActiveLeads();
        //     const intervalId = setInterval(fetchActiveLeads, 30000);
        //     return () => clearInterval(intervalId)
        // }

        document.title = "Kyno Health | Doctors Panel"
    }, [doctorId, onboardingState])



    return (
        <>
            <div className='doctor-home'>
                <div className='title'>Welcome to Doctor Dashboard for Kyno Health</div>

                {onboardingState === true ? (
                    <div>
                        <Doctor_auth loggedIn={loggedIn} />
                    </div>
                ) : (
                    <div className='home'>
                        <div className='head'>
                            {doctorEmployee !== null && <div className='title'>Hey there, {doctorEmployee.name}</div>}
                            <div className='logout' onClick={(e) => {
                                logout()
                            }}>Logout</div>

                        </div>
                        <div className='content'>
                            <div className='content-options'>
                                <div className={contentOptionSelected === 1 ? "active" : ""} onClick={(e) => { setContentOptionSelected(1) }}>Active Leads</div>
                                <div className={contentOptionSelected === 2 ? "active" : ""} onClick={(e) => { setContentOptionSelected(2) }}>Lead Queries</div>
                                <div className={contentOptionSelected === 3 ? "active" : ""} onClick={(e) => { setContentOptionSelected(3) }}>Manage Task 3</div>
                            </div>

                            {contentOptionSelected === 1 && 
                                <Doctor_lead doctorId={doctorId} doctorEmployee={doctorEmployee} />
                            }
                        </div>
                    </div>
                )}

            </div>
        </>
    )
}
