import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians offering home services in Yamuna Vihar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can find the best general physicians offering home services in Yamuna Vihar, Delhi at Kyno as they have the most expert lot."
      }
    }, {
      "@type": "Question",
      "name": "What qualifications and certifications should I look for in a general physician providing home care in Yamuna Vihar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A valid license for medicine and years of experience are the qualifications and certifications you should look for in a general physician providing home care in Yamuna Vihar. Kyno doctors fulfill this criteria, having been qualified and trained by top medical institutions."
      }
    }, {
      "@type": "Question",
      "name": "Are the general physicians at home in Yamuna Vihar, Delhi, equipped to handle emergencies?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, Kyno's general physicians at home in Yamuna Vihar, Delhi, are equipped to handle emergencies such as fever, asthma, and heart attacks, to name a few."
      }
    }, {
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for home consultation in Yamuna Vihar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can schedule an appointment with a general physician for a home consultation in Yamuna Vihar by visiting Kyno's official website and clicking on the call now button."
      }
    }, {
      "@type": "Question",
      "name": "What are the typical services offered by the best general physicians at home in Yamuna Vihar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At-home treatments, regular checkups, bed-sore treatment, IV drip at home, and follow-ups are some of the typical services offered by the best general physicians at home in Yamuna Vihar, Delhi."
      }
    }]
  }


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Yamuna Vihar, Delhi | Kyno Health</title>
        <meta name="description" content="Experience top-notch medical care from the comfort of your home in Yamuna Vihar, Delhi. Kyno Health offers highly skilled general physicians for convenient and personalized home visits." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-yamuna-vihar-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Yamuna Vihar, Delhi" hero_paragraph="General practitioners' home calls are now regarded as a part of the delivery of primary healthcare services. These visits help deliver medical services to the patient's homes, which offers a more individualistic service. Besides,  Best General Physicians at Home in Yamuna Vihar, Delhi increases satisfaction among patients and their compliance with the prescribed treatment regimens and appointments. It makes appointments with physicians for prolonged treatment and control of chronic diseases more convenient. " hero_paragraph_1="Specialist home visits are convenient for elderly and disabled patients, which ensures that the clients can resolve their health issues without having to attend hospital visits. Kyno adopts this approach to improve the population's health standards and decrease the expenditure on medical treatment, which identifies it as a useful supplement to conventional medical care."/>
      
      <Content content_h3="Understanding How Home Visits by General Physicians Can Reduce Hospital Readmissions " content_p="The general physician home visit near me can be relied upon to help minimize hospitalization recurrence due to the provision of constant care. These are important because they enable the physicians to assess the patient's status frequently, identify developing problems before they progress, and manage them. Since home visits entail educating the patient on how to take care of chronic illnesses and take the correct doses of medicine, among others, home visits assist the patient in living healthier. Further, the comfort of receiving care at home relieves much of the stress and anxiety that people with diseases face while opting for hospitalization, making a recovery faster. This proactive approach also helps in improving patient benefits while lightening the load of healthcare organizations, which also experience a reduction in readmissions. Avail Kyno's home-visit general physicians for receiving the finest healthcare in Yamuna Vihar. " 

      content_h3_2="Discover Kyno's Comprehensive Care With the Best Home-Visit General Physicians in Yamuna Vihar " content_p_2="You will get the best possible medical attention from our competent and professional general physician near me, who is well-equipped to attend to your unique needs. You can receive treatment while being in the comfort of your own home. Hospital visits can be utterly time-consuming; with Kyno, however, one can get professional medical advice and treatment when needed. Our physicians are dedicated to providing comprehensive care for you that involves your overall good and health. We promise you that with Kyno, home healthcare services will become easy for you to secure since you will get only the best services. Opt for Kyno Healthcare to avoid the numerous difficulties related to hospital visits. "/>

      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index