import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import logo from "../../../utilities/images/logo.png"
import "../../../styles/sales_book_appointment.scss"
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'
import edit from "../../../utilities/images/edit.png"

import Select from "react-select"
import Popup from 'reactjs-popup'

export default function BookAppointment() {

    const [pid] = useSearchParams()
    const navigate = useNavigate()

    const [patientId, setPatientId] = useState(pid.get("pid").substring(3))
    const [salesEmployeeId, setSalesEmployeeId] = useState(pid.get("se"))
    // console.log(patientId)


    const [patientFamily, setPatientFamily] = useState(null)
    const [patient, setPatient] = useState(null)
    const [doctors, setDoctors] = useState(null)
    const [msg, setMsg] = useState("")
    const [localities, setLocalities] = useState(null)
    const [isNewPatientSelected, setIsNewPatientSelected] = useState(false)


    const serviceTypeOptions = [{
        label: "Home Consultation",
        value: "HOME_CONSULTATION"
    },
    {
        label: "Online Consultation",
        value: "ONLINE_CONSULTATION"
    },
    {
        label: "Doctor Procedure",
        value: "DOCTOR_PROCEDURE"
    },
    {
        label: "Nursing Procedure",
        value: "NURSING_PROCEDURE"
    }
    ]

    const consultationOptions = [{
        label: "New Consultation",
        value: "NEW_CONSULTATION"
    },
    {
        label: "Follow Up Consultation",
        value: "FOLLOW_UP_CONSULTATION"
    }
    ]

    const diseaseOptions = [
        {
            "label": "Loss of appetite",
            "value": "LOSS_OF_APPETITE"
        },
        {
            "label": "Body pain",
            "value": "BODY_PAIN"
        },
        {
            "label": "Stomach pain",
            "value": "STOMACH_PAIN"
        },
        {
            "label": "Loose Motions",
            "value": "LOOSE_MOTIONS"
        },
        {
            "label": "Tiredness and weakness",
            "value": "TIREDNESS_AND_WEAKNESS"
        },
        {
            "label": "High BP",
            "value": "HIGH_BP"
        },
        {
            "label": "Low BP",
            "value": "LOW_BP"
        },
        {
            "label": "Headache",
            "value": "HEADACHE"
        },
        {
            "label": "High Fever",
            "value": "HIGH_FEVER"
        },
        {
            "label": "Breathing difficulty",
            "value": "BREATHING_DIFFICULTY"
        },
        {
            "label": "Allergy Redness through the body, Itching",
            "value": "ALLERGY_REDNESS_ITCHING"
        },
        {
            "label": "Abdominal pain",
            "value": "ABDOMINAL_PAIN"
        },
        {
            "label": "Constipation",
            "value": "CONSTIPATION"
        },
        {
            "label": "Jaundice",
            "value": "JAUNDICE"
        },
        {
            "label": "Insomnia (Sleeplessness)",
            "value": "INSOMNIA"
        },
        {
            "label": "Edema (Swelling in body )",
            "value": "EDEMA_SWELLING"
        },
        {
            "label": "Vomiting",
            "value": "VOMITING"
        },
        {
            "label": "Minor bleeding from any body part",
            "value": "MINOR_BLEEDING"
        },
        {
            "label": "High or low Sugar",
            "value": "HIGH_LOW_SUGAR"
        },
        {
            "label": "Unconcious or unresponsive",
            "value": "UNCONSCIOUS"
        },
        {
            "label": "Unables to pass urine",
            "value": "UNABLES_PASS_URINE"
        },
        {
            "label": "Dizziness",
            "value": "DIZZINESS"
        },
        {
            "label": "Chills/Sweats",
            "value": "CHILLS_SWEATS"
        },
        {
            "label": "Cough and cold",
            "value": "COUGH_COLD"
        },
        {
            "label": "Sore Throat",
            "value": "SORE_THROAT"
        },
        {
            "label": "Burning while urination",
            "value": "BURNING_URINATION"
        },
        {
            "label": "Fungal infections / Skin issues",
            "value": "FUNGAL_INFECTIONS"
        },
        {
            "label": "Minor Wound Dressing",
            "value": "MINOR_WOUND_DRESSING"
        },
        {
            "label": "Animal Bite",
            "value": "ANIMAL_BITE"
        },
        {
            "label": "Insect bite",
            "value": "INSECT_BITE"
        },
        {
            "label": "To Administer IM Or IV Inj",
            "value": "ADMINISTER_INJECTION"
        },
        {
            "label": "Pain Management",
            "value": "PAIN_MANAGEMENT"
        },
        {
            "label": "PC Enema",
            "value": "PC_ENEMA"
        },
        {
            "label": "Ear ache",
            "value": "EAR_ACHE"
        },
        {
            "label": "Uncertainity or fracture",
            "value": "UNCERTAINITY_FRACTURE"
        },
        {
            "label": "Seizure/Fits",
            "value": "SEIZURE_FITS"
        },
        {
            "label": "Back pain",
            "value": "BACK_PAIN"
        },
        {
            "label": "Sports Injury",
            "value": "SPORTS_INJURY"
        },
        {
            "label": "Minor Burns",
            "value": "MINOR_BURNS"
        },
        {
            "label": "Chest pain",
            "value": "CHEST_PAIN"
        },
        {
            "label": "Knee Pain",
            "value": "KNEE_PAIN"
        },
        {
            "label": "Throat Infection",
            "value": "THROAT_INFECTION"
        },
        {
            "label": "General Checkup",
            "value": "GENERAL_CHECKUP"
        },
        {
            "label": "Anxiety",
            "value": "ANXIETY"
        },
        {
            "label": "Lump on skin",
            "value": "LUMP_ON_SKIN"
        },
        {
            "label": "Old Age issues",
            "value": "OLD_AGE_ISSUES"
        },
        {
            "label": "Muscle Pain",
            "value": "MUSCLE_PAIN"
        },
        {
            "label": "Medical Certificate",
            "value": "MEDICAL_CERTIFICATE"
        },
        {
            "label": "Dental Pain",
            "value": "DENTAL_PAIN"
        },
        {
            "label": "Depression",
            "value": "DEPRESSION"
        },
        {
            "label": "Stone",
            "value": "STONE"
        },
        {
            "label": "Pelvic cramps",
            "value": "PELVIC_CRAMPS"
        },
        {
            "label": "Injury",
            "value": "INJURY"
        },
        {
            "label": "Abscess or I&D",
            "value": "ABSCCESS_I_D"
        },
        {
            "label": "Post Surgery care",
            "value": "POST_SURGERY_CARE"
        },
        {
            "label": "Low Oxygen Level",
            "value": "LOW_OXYGEN_LEVEL"
        },
        {
            "label": "Fitness Certificate",
            "value": "FITNESS_CERTIFICATE"
        },
        {
            "label": "Thyroid",
            "value": "THYROID"
        },
        {
            "label": "Heart Related issues",
            "value": "HEART_RELATED_ISSUES"
        },
        {
            "label": "Joint Pain",
            "value": "JOINT_PAIN"
        },
        {
            "label": "Acidity",
            "value": "ACIDITY"
        },
        {
            "label": "Gyne Issue",
            "value": "GYNE_ISSUE"
        },
        {
            "label": "High Oxygen Level",
            "value": "HIGH_OXYGEN_LEVEL"
        }
    ]

    const slotOptions = [
        { "label": "12 AM - 1 AM", "value": "1" },
        { "label": "1 AM - 2 AM", "value": "2" },
        { "label": "2 AM - 3 AM", "value": "3" },
        { "label": "3 AM - 4 AM", "value": "4" },
        { "label": "4 AM - 5 AM", "value": "5" },
        { "label": "5 AM - 6 AM", "value": "6" },
        { "label": "6 AM - 7 AM", "value": "7" },
        { "label": "7 AM - 8 AM", "value": "8" },
        { "label": "8 AM - 9 AM", "value": "9" },
        { "label": "9 AM - 10 AM", "value": "10" },
        { "label": "10 AM - 11 AM", "value": "11" },
        { "label": "11 AM - 12 PM", "value": "12" },
        { "label": "12 PM - 1 PM", "value": "13" },
        { "label": "1 PM - 2 PM", "value": "14" },
        { "label": "2 PM - 3 PM", "value": "15" },
        { "label": "3 PM - 4 PM", "value": "16" },
        { "label": "4 PM - 5 PM", "value": "17" },
        { "label": "5 PM - 6 PM", "value": "18" },
        { "label": "6 PM - 7 PM", "value": "19" },
        { "label": "7 PM - 8 PM", "value": "20" },
        { "label": "8 PM - 9 PM", "value": "21" },
        { "label": "9 PM - 10 PM", "value": "22" },
        { "label": "10 PM - 11 PM", "value": "23" },
        { "label": "11 PM - 12 AM", "value": "24" }
    ]

    const paymentOptions = [
        { "label": "Online", "value": "ONLINE" },
        { "label": "Cash", "value": "CASH" },
    ]

    const procedureOptions = [
        { "label": "Injectables IM", "value": "INJECTABLES_IM", "cost": "1000" },
        { "label": "Injectables IV", "value": "INJECTABLES_IV", "cost": "1000" },
        { "label": "IV Drip Infusion", "value": "IV_DRIP_INFUSION", "cost": "1000" },
        { "label": "Dressing Minor", "value": "DRESSING_MINOR", "cost": "1000" },
        { "label": "Dressing Major", "value": "DRESSING_MAJOR", "cost": "1000" },
        { "label": "Catheter Insertion or Change", "value": "CATHETER_INSERTION_CHANGE", "cost": "1000" },
        { "label": "Ryles Tube Insertion or Change", "value": "RYLES_TUBE_INSERTION_CHANGE", "cost": "1000" },
        { "label": "Enema", "value": "ENEMA", "cost": "1000" },
        { "label": "Others", "value": "OTHERS", "cost": "1000" },
    ]




    const getDetails = async () => {
        const result = await axios.get(`${localBackend}allBookAppointmentDetails?patientId=${patientId}&salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            console.log(resp.data.data)
            setPatient(resp.data.data[0].patient)
            setPatientFamily(resp.data.data[0].patientFamily)
            const activeDocs = resp.data.data[0].doctors.filter(doc => doc.currentStatus == "AVAILABLE")
            setDoctors(activeDocs)
            setLocalities(resp.data.data[0].locality)

            // for(let i = 0; i < resp.data.data[0].patientFamily.length; i++){
            //     setPatientFamily([...patientFamily, resp.data.data[0].patientFamily[i]])
            // }
        })


        // setPatientFamily([...patientFamily, {"_id": "NEW_PATIENT", "name": "New Patient"}])
    }
    const getDetailsEdit = async () => {
        console.log("getting details")
        const result = await axios.get(`${localBackend}getBookedAppointmentDetails?id=${pid.get("id")}&salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            console.log(resp.data)
            if(resp.data.statusCode == 1){
                console.log(resp.data.data)
                setBookAppointmentDetails(resp.data.data)
            }

            // for(let i = 0; i < resp.data.data[0].patientFamily.length; i++){
            //     setPatientFamily([...patientFamily, resp.data.data[0].patientFamily[i]])
            // }
        })

        console.log("fetched")

        // setPatientFamily([...patientFamily, {"_id": "NEW_PATIENT", "name": "New Patient"}])
    }

    const [bookAppointmentDetails, setBookAppointmentDetails] = useState({
        "patientId": "",
        "patientAddress": {},
        "serviceType": {},
        "consultationType": {},
        "diseaseType": [],
        "doctorId": "",
        "appointmentDate": "",
        "appointmentStartTime": "",
        "appointmentEndTime": "",
        "amountToBeCollected": "",
        "paymentMode": {},
        "procedureType": [],
        "amountBreakdown": {},
        "salesEmployeeId": salesEmployeeId
    })

    const [newPatient, setNewPatient] = useState({
        "name": "",
        "age": "",
        "gender": "",
        "relation": "",
        "address": "",
        "diseaseHistory": []
    })

    const newPatientName = useRef()
    const newPatientAge = useRef()
    const newPatientGender = useRef()
    const newPatientRelation = useRef()
    const newPatientAddress = useRef()

    const startTimeRef = useRef();
    const endTimeRef = useRef()


    const consumablesTotalCost = useRef(null)
    const  [totalProcedureConsumableCost, setTotalProcedureConsumableCost] = useState(0)
    const [consumablecost, setconsumablescost]  =  useState(0)

    // const getTotalProcedureConsumableCost  = () => {

    //     let _val  =  0
    //     if(bookAppointmentDetails.serviceType.value != "HOME_CONSULTATION" && bookAppointmentDetails.serviceType.value != "ONLINE_CONSULTATION"){

    //         for(let i = 0; i < bookAppointmentDetails.procedureType.length; i++){
    //             let _b = bookAppointmentDetails.procedureType[i]
    //             _val += _b.cost
    //         }
    //         _val += consumablesTotalCost.current.value
    //     }
    //     return _val
    // }

    const onNewPatientSubmit = async (close) => {
        let name = newPatientName.current.value;
        let age = newPatientAge.current.value;
        let gender = newPatientGender.current.value;
        let relation = newPatientRelation.current.value;
        let address = newPatientAddress.current.value;

        if (name == "" || age == "" || gender == "" || relation == "" || address == "") {
            setmsg("Enter all new patient fields")
        }

        else {
            let body = {
                name: name,
                age: age,
                gender: gender,
                relation: relation,
                address: address,
                salesEmployeeId: salesEmployeeId,
                diseaseHistory: newPatient.diseaseHistory.map(d => d.value),
                phone: `+91${patientId}`
            }

            const result = await axios.post(`${localBackend}createPatientFamilyMember`, body).then((resp) => {
                if (resp.data.statusCode === 1) {
                    setmsg("Patient Created  Successfully")
                    getDetails()
                    close()
                } else {
                    setmsg(resp.data.data)
                }
            })
        }

    }


    const handlePatientChange = async (patient) => {
        if (patient._id == "NEW_PATIENT") {
            setIsNewPatientSelected(true)
        } else {
            setBookAppointmentDetails(prevState => ({
                ...prevState, "patientId": patient
            }))
        }
    }
    const handleAddressChange = async (patientaddress) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "patientAddress": patientaddress
        }))
    }
    const handleDoctorChange = async (doctor) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "doctorId": doctor
        }))
    }
    const handleServiceTypeChange = async (serviceType) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "serviceType": serviceType
        }))
    }
    const handleConsultationTypeChange = async (ctype) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "consultationType": ctype
        }))
    }

    const handleDiseaseChange = async (doptions) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "diseaseType": doptions
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const handleProcedureChange = async (poptions) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "procedureType": poptions
        }))

        // console.log(poptions)
        let  _val = 0;
        poptions.forEach(element => {
            _val += Number(element.cost)
        });
        setTotalProcedureConsumableCost(_val)
    }
    const handlenewpatientdiseasechange = async (doptions) => {
        setNewPatient(prevState => ({
            ...prevState, "diseaseHistory": doptions
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const handleDateChange = async (date) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "appointmentDate": date
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const handleAmountChange = async (amount) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "amountToBeCollected": amount
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const handleSlotChange = async (slot) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "appointmentSlot": slot
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const handlePaymentModeChange = async (mode) => {
        setBookAppointmentDetails(prevState => ({
            ...prevState, "paymentMode": mode
        }))
        console.log(bookAppointmentDetails.diseaseType)
    }
    const onPatientEditClick = async () => {
        console.log("hmm")
    }


    const onSubmitClick = async () => {
        let b = bookAppointmentDetails
    if (b.patientId == "" || startTimeRef.current.value == "" || endTimeRef.current.value == "" || b.serviceType == {} || b.patientAddress == {} || b.consultationType == {} || b.diseaseType == [] || b.doctorId == "" || b.appointmentDate == ""  || b.amountToBeCollected == "" || b.paymentMode == {}) {
            setmsg("Please Fill All The Fields")
        } else {
            // console.log(b)

            let _amountbreakdown  = {
                "procedureCost":  totalProcedureConsumableCost,
                "consumableCost": consumablecost,
            }

            let _b = {
                patientId: b.patientId._id,
                patientName: b.patientId.name,
                patientAddress: b.patientAddress.address,
                patientPhone: b.patientId.phone,
                serviceType: b.serviceType.value,
                consultationType: b.consultationType.value,
                diseaseType: b.diseaseType.map(b => b.value),
                doctorId: b.doctorId._id,
                appointmentDate: b.appointmentDate,
                appointmentStartTime: startTimeRef.current.value,
                appointmentEndTime: endTimeRef.current.value,
                amountToBeCollected: b.amountToBeCollected,
                paymentMode: b.paymentMode.value,
                procedureType: b.procedureType.map(b => b.value),
                amountBreakdown: b.amountBreakdown,
                salesEmployeeId: b.salesEmployeeId,
                template: bookAppointmentDetails,
                type: `${pid.get("type")}__${pid.get("id")}`,
            }

            console.log(_b)
            const result = await axios.post(`${localBackend}bookAppointment`, _b).then((resp) => {
                if (resp.data.statusCode === 1) {
                    setmsg("Appointment Has Been Booked")
                    // setInterval(() => {
                    //     navigate(`/dashboard/patient-information-page?pid=+91${patientId}`)
                    // }, 3000)

                }
                else {
                    setmsg("Book Appointment Falied")
                }
            }).catch((err) => {
                console.log(err)
                setmsg("Something Went Wrong")
            })

        }
    }

    const setmsg = (msg) => {
        setMsg(msg)
        setInterval(() => {
            setMsg("")
        }, 5000)
    }

    useEffect(() => {
        if(pid.get("type") == "edit"){
            getDetailsEdit()
            getDetails()
        }else{    
            getDetails()
        }
    }, [])


    return (
        <>
            <div className='sales-book-appointment'>

                <div className='logo'>
                    <img src={logo} alt="Logo" onClick={() => {
                        navigate(`/dashboard/patient-information-page?pid=+91${patientId}`)
                    }} />

                    <div className='back-btn' onClick={() => {
                        navigate(`/dashboard/patient-information-page?pid=+91${patientId}`)
                    }}>Go Back</div>
                </div>

                <div className='book-appointment-form'>

                    <div className='title'>{pid.get("type") == 'edit' ? "Edit Appointment Details": "Book Appointment"} </div>

                    <div className='patient'>
                        <div className='patient-name'>Patient Name</div>
                        {patientFamily !== null &&
                            // <select>
                            //     <option value="">Select Patient</option>
                            //     {patientFamily.map((p, pindex) => {

                            //         return (
                            //             <option  value={p._id}  key={pindex}>{p.name}
                            //                 <span>
                            //                     <img src={edit} alt='edit' />
                            //                 </span>
                            //             </option>
                            //         )
                            //     })}
                            // </select>
                            <Select
                                options={patientFamily}
                                components={nameOption}
                                value={bookAppointmentDetails.patientId}
                                onChange={handlePatientChange}
                                isOptionSelected={(option) =>
                                    bookAppointmentDetails.patientId && option.value == bookAppointmentDetails.patientId
                                }

                                getOptionValue={(option) => option._id}
                                getOptionLabel={(option) => option.name}
                                blurInputOnSelect={true}

                            />
                        }
                    </div>

                    <div className='patient-address'>
                        <div className='patient-address-title'>Select Patient Address</div>
                        {patientFamily !== null &&

                            <Select
                                options={patientFamily}
                                components={nameOption}
                                value={bookAppointmentDetails.patientAddress}
                                onChange={handleAddressChange}
                                isOptionSelected={(option) => bookAppointmentDetails.patientAddress && option.value == bookAppointmentDetails.patientAddress}
                                getOptionValue={(option) => option.address}
                                getOptionLabel={(option) => option.address}
                                blurInputOnSelect={true}
                            />

                        }
                    </div>

                    {isNewPatientSelected == true &&
                        <Popup modal nested open={isNewPatientSelected} className='create-new-patient'>{(close) => {

                            return (
                                <div className='new-patient'>
                                    <div className='title'>Create New Patient</div>
                                    <div className='content'>


                                        <div>Enter New Patient Name</div>
                                        <input ref={newPatientName} type='text' />
                                        <div>Enter Age</div>
                                        <input ref={newPatientAge} type="number" />
                                        <div>Enter Relation</div>
                                        <input ref={newPatientRelation} type='text' />
                                        <div>Enter Gender</div>
                                        <select ref={newPatientGender}>
                                            <option value={""}>Select Gender</option>
                                            <option value={"MALE"}>Male</option>
                                            <option value={"FEMALE"}>Female</option>
                                        </select>
                                        <div>Enter Address</div>
                                        <input ref={newPatientAddress} type='text' />
                                        <div>Enter Medical History</div>
                                        <Select
                                            options={diseaseOptions}
                                            value={newPatient.diseaseHistory}
                                            onChange={handlenewpatientdiseasechange}
                                            isMulti={true}
                                            className='select-disease-container'
                                        />

                                        <div className='submit' onClick={async () => {
                                            onNewPatientSubmit(close)
                                        }} >Submit</div>
                                    </div>


                                </div>
                            )

                        }}</Popup>
                    }

                    {bookAppointmentDetails.patientId !== null &&
                        <div className='service-type'>
                            <div className='service-type-title'>Select Service Type</div>

                            <Select options={serviceTypeOptions}
                                components={serviceOptionsTag}
                                value={bookAppointmentDetails.serviceType !== null ? bookAppointmentDetails.serviceType : {}}
                                onChange={handleServiceTypeChange}
                                isOptionSelected={(option) => bookAppointmentDetails.serviceType && option.value == bookAppointmentDetails.serviceType}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                blurInputOnSelect
                            />
                        </div>
                    }

                    {(bookAppointmentDetails.serviceType.value == "HOME_CONSULTATION" || bookAppointmentDetails.serviceType.value == "ONLINE_CONSULTATION") ? (
                        <div className='consultation_type'>
                            <div className='consultation-type-title'>Select Type Of Consultation</div>
                            <Select options={consultationOptions}
                                components={consultationTypeTag}
                                value={bookAppointmentDetails.consultationType}
                                onChange={handleConsultationTypeChange}
                                isOptionSelected={(option) => bookAppointmentDetails.consultationType && option.value == bookAppointmentDetails.consultationType}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                blurInputOnSelect
                            />
                        </div>
                    ) : (
                        <div className='procedure-type'>
                            <div className='procedure-type-title'>Select Procedure Type</div>
                            <Select
                                options={procedureOptions}
                                value={bookAppointmentDetails.procedureType}
                                onChange={handleProcedureChange}
                                isMulti={true}
                            />
                        </div>
                    )}

                    {(bookAppointmentDetails.serviceType.value == "HOME_CONSULTATION" || bookAppointmentDetails.serviceType.value == "ONLINE_CONSULTATION") && bookAppointmentDetails.consultationType.value !== null &&
                        <div className='disease-type'>
                            <div className='disease-type-title'>Select Disease</div>
                            <Select
                                options={diseaseOptions}
                                value={bookAppointmentDetails.diseaseType}
                                onChange={handleDiseaseChange}
                                isMulti={true}
                            />
                        </div>
                    }

                    {(bookAppointmentDetails.diseaseType !== null) &&
                        <div className='doctor-type'>
                            <div className='doctor-type-title'>Select Doctor</div>
                            <Select
                                options={doctors}
                                components={nameOption}
                                value={bookAppointmentDetails.doctorId}
                                onChange={handleDoctorChange}
                                isOptionSelected={(option) =>
                                    bookAppointmentDetails.doctorId && option.value == bookAppointmentDetails.doctorId
                                }

                                getOptionValue={(option) => option._id}
                                getOptionLabel={(option) => option.name}
                                blurInputOnSelect={true}

                            />
                        </div>
                    }

                    {
                        (bookAppointmentDetails.doctorId !== null) &&
                        <div className='select-date'>
                            <div className='select-date-title'>Appoitment Date</div>
                            <input value={bookAppointmentDetails.appointmentDate !== null ? bookAppointmentDetails.appointmentDate : ""} onChange={(e) => {
                                handleDateChange(e.target.value)
                            }} type='date' />
                        </div>
                    }
                    {
                        (bookAppointmentDetails.appointmentDate !== null) &&
                        <div className='select-slot'>
                            <div className='select-slot-title'>Select Slot</div>
                            {/* <Select
                                options={slotOptions}
                                components={slotTag}
                                value={bookAppointmentDetails.appointmentSlot}
                                onChange={handleSlotChange}
                                isOptionSelected={(option) =>
                                    bookAppointmentDetails.appointmentSlot && option.value == bookAppointmentDetails.appointmentSlot
                                }

                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                blurInputOnSelect={true}

                            /> */}
                            <span>Start Time</span>
                            <input type='time' ref={startTimeRef} value={bookAppointmentDetails.appointmentStartTime} onChange={(e) => {
                                setBookAppointmentDetails(prevstate => ({
                                    ...prevstate, appointmentStartTime: e.target.value
                                }))
                            }} />
                            <span>End Time</span>
                            <input type='time' ref={endTimeRef} value={bookAppointmentDetails.appointmentEndTime} onChange={(e) => {
                                setBookAppointmentDetails(prevState => ({
                                    ...prevState, appointmentEndTime: e.target.value
                                }))
                            }} />
                        </div>
                    }

                    {
                        (bookAppointmentDetails.serviceType.value == "HOME_CONSULTATION" || bookAppointmentDetails.serviceType.value == "ONLINE_CONSULTATION") ?
                            (<div className='amount-to-be-collected'>
                                <div className='amount-to-be-collected-title'>Amount to be Collected</div>
                                <input type='number' value={bookAppointmentDetails.amountToBeCollected} onChange={(e) => {
                                    handleAmountChange(e.target.value)
                                }} />
                            </div>) : (
                                <div className='procedure-amount'>
                                    <div className='procedure-amount-title'>Amount To Be Collected</div>
                                    {bookAppointmentDetails.procedureType.map((ptype, pindex) => {

                                        return (

                                            <div className='procedure-cost' key={pindex}>
                                                <div className='procedure-cost-title'>{ptype.label}</div>
                                                <div className='procedure-cost-cost'>
                                                    <input type='number' value={ptype.cost} onChange={(e) => {
                                                        const _b = [bookAppointmentDetails.procedureType]
                                                        const toB = _b.find(item => item.label = ptype.label)
                                                        if(toB){
                                                            toB.cost = e.target.value;
                                                            handleProcedureChange(_b)
                                                            // setBookAppointmentDetails(prevState => ({
                                                            //     ...prevState, procedureType: _b
                                                            // }))
                                                        }

                                                    }} />
                                                </div>

                                            </div>
                                        )
                                    })}

                                    <div className='consumables'>
                                        <div className='consumables-cost '>Consumables Cost</div>
                                        <div><input type='number' ref={consumablesTotalCost} onChange={(e) => {
                                            setconsumablescost(e.target.value)
                                            handleAmountChange(totalProcedureConsumableCost+e.target.value)
                                        }} /></div>
                                    </div>

                                    <div className='subtotal'>
                                        <div className='subtotal-title'>Subtotal</div>
                                        <div className='cost'>
                                            {(Number(totalProcedureConsumableCost)+Number(consumablecost))}
                                        </div>
                                    </div>

                                </div>
                            )
                    }

                    <div className='payment-mode'>
                        <div className='payment-mode-title'>Payment Mode</div>
                        <Select
                            options={paymentOptions}
                            components={paymentModeTag}
                            value={bookAppointmentDetails.paymentTag}
                            onChange={handlePaymentModeChange}
                            isOptionSelected={(option) =>
                                bookAppointmentDetails.paymentMode && option.value == bookAppointmentDetails.paymentModeTag
                            }

                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            blurInputOnSelect={true}

                        />
                    </div>

                    <div className='submit' onClick={() => {
                        onSubmitClick()
                    }}>Submit</div>


                </div>

                <div className='msg'>{msg}</div>
            </div>
        </>
    )
}


const nameOption = ({ label, onClick }) => {
    return (
        <div className='name-option'>
            {label}
            <img src={edit} alt="edit" onClick={onClick} />
        </div>
    )
}
const doctorOption = ({ label, onClick }) => {
    return (
        <div className='doctor-option'>
            {label}
        </div>
    )
}

const serviceOptionsTag = ({ label, onClick }) => {
    return (
        <div className='service-type-option'>
            {label}
        </div>
    )
}
const consultationTypeTag = ({ label, onClick }) => {
    return (
        <div className='consultation-type-option'>
            {label}
        </div>
    )
}
const slotTag = ({ label, onClick }) => {
    return (
        <div className='slot-type-option'>
            {label}
        </div>
    )
}
const paymentModeTag = ({ label, onClick }) => {
    return (
        <div className='payment-type-option'>
            {label}
        </div>
    )
}