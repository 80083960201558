import React, {useEffect, useState} from 'react' 
import "../../../styles/ops_all_booked_appointments.scss"
import Popup from 'reactjs-popup'
import Active_Lead from './Active_Lead'
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'


export default function Ops_All_Booked_Appointments(props){

    const [activeLeads, setActiveLeads] = useState(null)
    const [operationsEmployeeId, setOperationsEmployeeId] = useState(props.operationsEmployeeId)


    const fetchActiveLeads = async () => {
        // console.log(operationsEmployeeId)
        const res = await axios.get(`${localBackend}getBookedAppointmentsByLocality?employeeId=${operationsEmployeeId}`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setActiveLeads(resp.data.data);
                return resp.data.data
            }
        })
        // console.log(res)
    }



    useEffect(() => {
        if (operationsEmployeeId !== "") {
            fetchActiveLeads();
            const intervalId = setInterval(fetchActiveLeads, 3000);
            return () => clearInterval(intervalId)
        }
    }, [])
 
    return(
        <>
            <div className='manage-active-leads'>
                <div className='title'>Here is the list of all Active Leads</div>
                <div className='active-leads'>
                    <div className='active-leads-headers'>
                        <div>Name</div>
                        <div>Status</div>
                        <div>Phone</div>
                        <div>Handling Doctor</div>
                    </div>
                    {
                        activeLeads !== null && activeLeads.map((lead, index) => {
                            return (
                                <Popup trigger={
                                    <div className='active-lead'>
                                        <div>{lead.name}</div>
                                        <div>{lead.status}</div>
                                        <div>{lead.phone}</div>
                                        <div>{lead.assignedDoctor}</div>
                                    </div>
                                } modal nested key={index}>
                                    {
                                        (close) => (
                                            <Active_Lead lead={lead} operationsEmployeeId={ operationsEmployeeId } />
                                        )
                                    }
                                </Popup>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
