import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are the top-rated general physicians providing home services in Najafgarh, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We have an exclusive list of the top-rated general physician home visit near me in Najafgarh, Delhi. Here are some of our doctors who excel at their work and are compassionate and ethical in their services. Dr Prabhu M, Dr Gaurav Sharma, Dr Ankesh Rawat, Dr Ashish Kochekar, and Dr Happy Kumar are our leading doctors and have treated hundreds of patients, offering them a better quality of life."
      }
    },{
      "@type": "Question",
      "name": "What criteria should I consider when selecting a general physician to visit me at home in Najafgarh?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Some of the criteria that you should take note of are that the doctor is board-certified and has a proper license. In this context, we have already verified the details. Secondly, assess things like whether your condition is suitable for a home up or needs something like an emergency surgery. Last but not least, ask us about follow-up visits, diagnostic support, ambulance assistance, etc. We will be happy to help you with all your questions."
      }
    },{
      "@type": "Question",
      "name": "Can I book an appointment with a general physician for a home visit in Najafgarh, Delhi, online?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we do provide online consultations but only in two situations. Firstly, we schedule online consultations in case of an emergency, such as an oncoming heart attack. Secondly, online appointments are done for proactive follow-up sessions and in case you have any additional queries after the home-visit session. However, we specialize in home-visit consultations."
      }
    },{
      "@type": "Question",
      "name": "Are the home visit services provided by general physicians in Najafgarh safe and reliable?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, absolutely. Our doctors treat a wide range of conditions and have provided a safe and reliable experience to each of them. You can read about the positive experiences our patients have had in our testimonials. Be it a viral fever that you are suffering from or a physical injury, we are here to help you and take care of your health."
      }
    },{
      "@type": "Question",
      "name": "How does the pricing structure work for general physician home visits in Najafgarh?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At Kyno Health, your first consultation will be priced at Rs 999 only. However, if the consultations take place at night or during immediate home emergencies, the prices might be slightly higher. It would be best to call us so that we can guide you through the pricing details."
      }
    }]
  }
  
  


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Najafgarh, Delhi | Kyno Health</title>
        <meta name="description" content="Experience top-quality medical care from the comfort of your home in Najafgarh, Delhi. Kyno Health offers the best general physicians at home, ensuring you receive expert healthcare without the hassle of travel." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/najafgarh-general-physicians-home" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Najafgarh, Delhi" hero_paragraph="If you have been searching for the Best General Physicians at Home in Najafgarh, Delhi, we have come to your rescue. We understand that it may not always be possible to go to hospitals, especially if someone is chronically ill or has restricted mobility. Moreover, hospital visits can be hectic and take up a huge portion of our day, especially for people with busy schedules. This is where services like general physicians at home come of help. At Kyno Health, we provide at-home consultations with a top general physician at your residence in Najafgarh."/>
      
      <Content content_h3="Criteria to Consider While Selecting a General Physician at Home" content_p="When selecting a general physician for home care, several crucial criteria should guide your decision. First, ensure the physician is licensed and board-certified, verifying their credentials to ensure they meet professional standards. Experience is paramount; choose a physician with a robust background in general medicine, ideally with specific expertise in managing conditions similar to yours or your family's." content_p1="Secondly, consider the physician's availability and responsiveness. Opt for a provider who offers flexible scheduling and prompt responses to inquiries or emergencies. Compatibility in communication and bedside manner is also vital for effective care delivery at home. Lastly, inquire about the range of services offered—whether they include routine check-ups, urgent care, or specialized treatments. By evaluating these factors, you can select a general physician who not only meets your medical needs but also provides compassionate and reliable care in the comfort of your home."
      content_h3_2="Receive In-Home Treatment by Kyno Health's General Physicians in Najafgarh" content_p_2="You can discover and avail of our services today itself by scheduling a home-visit consultation with our general physician near me in Najafgarh. With Kyno Health, you are in safe hands and will be provided with top-notch medical care at your home in Najafgarh. From diagnostic support to proactive follow-up consultations, our experts give detailed consultations and all-round treatment. So cut those lengthy and exhausting hospital visits and schedule your first appointment today. We strive to make medical care accessible to you at a very affordable price." />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index