import React from "react";
import "../../styles/testimonials.css"
import bg from "../../utilities/images/moedbg.png"
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

export default function Testimonials() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
        //   slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        //   slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        //   slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <>
            <div className="testimonials">
                <div className="testimonials-bg">
                    <img src={bg} />
                </div>
                <div className="heading">Testimonials</div>
                <Carousel responsive={responsive} showDots={false} swipeable={true} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/nxUA1_VowHE"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/tg8e761AUQY"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/q_Fz55zusC8"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/RFOh4Z6XMGc"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/a55xgJzgl1I"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>
                    <div className="testimonial">
                        <div>
                            <iframe src="https://www.youtube.com/embed/eWaWgg0Ptu4"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Our Satisfied patients"
                            />
                        </div>
                    </div>


                </Carousel>

            </div>
        </>
    )
}