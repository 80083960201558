import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Understanding Hypertension</h2>
  <p>Hypertension is a medical term for high blood pressure, which is the force of blood pushing against the walls of your arteries. Blood pressure is typically recorded as two values: systolic pressure (the pressure when the heart beats) and diastolic pressure (the pressure when the heart rests between beats). A normal blood pressure reading is around 120/80 mm Hg.
</p>

  

<div className='ml-70'>
<div className='row'>
  <h3>The Importance of Managing Hypertension</h3>
  <p>Hypertension is a significant health concern because it can lead to a range of complications, including:
</p>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Heart Disease</h4>
<p>High blood pressure is a leading cause of heart disease, which includes conditions like heart attacks, heart failure, and coronary artery disease.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Stroke</h4>
<p>Hypertension increases the risk of strokes by damaging the blood vessels in the brain.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Kidney Problems</h4>
<p>Prolonged hypertension can lead to kidney damage and may contribute to chronic kidney disease.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Eye Issues</h4>
<p>High blood pressure can damage the blood vessels in the eyes, potentially leading to vision problems or even vision loss.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Artery Damage</h4>
<p>Elevated blood pressure can result in hardening and narrowing of the arteries, making it difficult for blood to flow to vital organs and tissues, potentially affecting various systems.</p>
</div>
</div>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Other Health Issues</h4>
<p>Hypertension has also been linked to an increased risk of vascular dementia, sexual dysfunction, and more.</p>
</div>
</div>

</div>
</div>
</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Our Approach to Hypertension Management
</h2>
  <p>At Kyno Health clinic, we take a comprehensive and patient-focused approach to managing hypertension. Our primary goal is to help you maintain a healthy blood pressure level, reduce your risk of complications, and enhance your overall well-being. Here's what you can expect when you choose our hypertension management services:</p>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Evaluation</h4>
<p>Our experienced doctors will conduct a comprehensive assessment of your blood pressure, overall health, and lifestyle factors. This evaluation helps determine the cause of your hypertension and the most suitable treatment options.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Individualized Treatment</h4>
<p>Recognizing that every patient is unique, our treatment plans are tailored to your specific needs. This takes into account your current blood pressure levels, any underlying medical conditions, and your personal goals for managing your blood pressure.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Medication Management</h4>
<p>Depending on your blood pressure readings and specific circumstances, medication may be a part of your treatment plan. Our doctors will carefully select and monitor the use of medications to achieve the best results with minimal side effects.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Lifestyle Modifications</h4>
<p>Lifestyle changes are a cornerstone of hypertension management. We will work with you to develop a plan that includes dietary adjustments, regular physical activity, stress management, and weight management.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Regular Monitoring</h4>
<p>Hypertension management is an ongoing process. Our team will ensure regular follow-up appointments to monitor your progress and make necessary adjustments to your treatment plan.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Preventing Hypertension
</h2>
<p>Prevention is a crucial aspect of our approach. In addition to managing existing hypertension, we are committed to educating you about how to prevent high blood pressure. Our team provides guidance on:
</p>
<div className=''>
<div className='row'>
<div className='col-md-12'>
<div className=''>
<ul>
  <li>Adopting a heart-healthy diet</li>
  <li>Incorporating regular exercise into your routine</li>
  <li>Practicing stress-reduction techniques</li>
  <li>Reducing sodium intake</li>
  <li>Limiting alcohol consumption</li>
  <li>Maintaining a healthy weight</li>
</ul>

</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
  <h2>Why Choose Us for Your Hypertension Management Needs?
</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Expertise</h4>
<p>Our doctors are specialists in hypertension management, providing you with the highest level of care.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Compassion</h4>
<p>We understand the importance of supporting you in your journey to better heart health. Our team is committed to providing compassionate care throughout your treatment.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>We have modern facilities and the latest technology to ensure accurate diagnosis and effective treatments.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Holistic Approach</h4>
<p>We focus not only on managing blood pressure but also on improving your overall well-being and heart health.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Appointments</h4>
<p>We offer convenient appointment scheduling, making it easier for you to access the care you need.</p>
</div>
</div>

<div className='col-md-12'>
  <h4>Take Control of Your Hypertension</h4>
  <p>Managing your blood pressure is essential for maintaining good heart health and overall well-being. Don't let hypertension go untreated or unmanaged. Take the first step towards better heart health by scheduling an appointment with our expert team.</p>

<p>Contact us today to book your consultation and embark on your journey towards achieving a healthy blood pressure and a healthier you. Your heart health is our priority.</p>
</div>

</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index