import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Home Visit Doctors Near Me</h3>
    <p className='d-md-none d-lg-none'>At Kyno Health, we understand that access to quality healthcare is essential, especially when mobility or other factors make it difficult to visit a medical facility. Our Home Visit Doctors service is designed to bridge this gap by bringing experienced and compassionate healthcare professionals right to your doorstep. Whether you're dealing with chronic conditions, post-operative care, or simply in need of routine check-ups, our dedicated team is here to provide personalized medical attention in the comfort of your own home.
</p>

<p className='mob-noee'>At Kyno Health, we understand that access to quality healthcare is essential, especially when mobility or other factors make it difficult to visit a medical facility. Our Home Visit Doctors service is designed to bridge this gap by bringing experienced and compassionate healthcare professionals right to your doorstep. Whether you're dealing with chronic conditions, post-operative care, or simply in need of routine check-ups, our dedicated team is here to provide personalized medical attention in the comfort of your own home.
</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
