import React from "react";
import Accordian from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.css'
import "../../styles/faq.css"

export default function FAQ(props) {

    var faqCount = props.faqCount === undefined ? 100 : props.faqCount

    return (
        <>
            <div className="faqs">
                <div className="title">FAQ</div>
                <div className="content">
                    <Accordian className="faq-box" bs-prefix="faq">
                        {
                            faqCount >= 1 &&
                            <Accordian.Item className="t" eventKey="0">
                                <Accordian.Header>What types of services are available through Kyno Health?</Accordian.Header>
                                <Accordian.Body>Kyno Health offers a wide range of medical services, including consultations with a doctor, delivery of medications, and diagnostic sample collection from home. You can discuss your specific needs with a doctor during your appointment to determine the best course of treatment.</Accordian.Body>
                            </Accordian.Item>
                        }
                        {
                            faqCount >= 2 &&
                            <Accordian.Item className="t" eventKey="1">
                                <Accordian.Header>How do I pay for services through Kyno Health?</Accordian.Header>
                                <Accordian.Body>You can pay for Kyno Health services through our secure online payment systems and UPI. Our team will work with you to determine the best payment option for your needs.</Accordian.Body>
                            </Accordian.Item>
                        }
                        {
                            faqCount >= 3 &&
                            <Accordian.Item className="t" eventKey="2">
                                <Accordian.Header>Can I request a specific doctor or type of doctor?</Accordian.Header>
                                <Accordian.Body>We have full time physicians working exclusively with us, they're going to come & see you. If any specialist doctor is felt to be required post consultation then you'll be referred for the same</Accordian.Body>
                            </Accordian.Item>
                        }
                        {
                            faqCount >= 4 &&
                            <Accordian.Item className="t" eventKey="3">
                                <Accordian.Header>Who are the doctors to come from Kyno Health?</Accordian.Header>
                                <Accordian.Body>All of the doctors who work with Kyno Health are licensed and experienced medical professionals. You can view their profiles and qualifications on our website before booking an appointment.</Accordian.Body>
                            </Accordian.Item>
                        }
                        {faqCount >= 5 &&
                            <Accordian.Item className="t" eventKey="4">
                                <Accordian.Header>Is there a limit to the number or type of medical issues that can be addressed during the consultation?</Accordian.Header>
                                <Accordian.Body>The doctor with their vast experience will thoroughly examine the patient and answer all questions ranging from nutritional requirements to physical requirements of every patient as per their diagnosis</Accordian.Body>
                            </Accordian.Item>
                        }
                        {faqCount >= 6 &&
                            <Accordian.Item className="t" eventKey="5">
                                <Accordian.Header>What if there is a need of hopsitalization?</Accordian.Header>
                                <Accordian.Body>If any patient requires hospital support then we can refer you to our partner hospitals for best treatment.</Accordian.Body>
                            </Accordian.Item>
                        }
                        {faqCount >= 7 &&
                            <Accordian.Item className="t" eventKey="6">
                                <Accordian.Header>Can I request follow-up consultations or ongoing care?</Accordian.Header>
                                <Accordian.Body>You can call the patient care number(8920457429) in order to connect with the doctor. If required, doctor will visit the patient again</Accordian.Body>
                            </Accordian.Item>
                        }
                    </Accordian>
                </div>
            </div>
        </>
    )
}