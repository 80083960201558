import React from 'react'

function index() {
  return (
    <>
      <section className='ms'>
        <div className='container'>


        <p>Exposure to heat accompanies a host of health issues, including exhaustion and heat stroke. Heatstroke is a condition that causes faintness and dry, warm skin due to the inability of the body to control high temperatures. Other symptoms of a heatstroke include a throbbing headache, confusion, nausea, dizziness, body temperature above 103&deg;F, and rapid and strong pulse. It recently claimed the life of a 40-year-old, Bihar-based man, whose body temperature, the doctors said, crossed the 107 degrees Fahrenheit mark, which is nearly 10 degrees above normal. Unfortunately, he's not the only one. Recently, Sunstroke killed 54 people urging Rajasthan court to declare an emergency.</p>
<p>Given the severity of the heatwave, it&rsquo;s important we ensure our safety, and that of those around us. This involves following expert advice to combat the heatwave, and keep its potential health impacts at bay. That's why Kyno Health's team of expert doctors has compiled 5 essential tips to help you safeguard yourself and your loved ones during this ongoing heatwave.</p>
<ul>
<li><strong>Keep Yourself Hydrated</strong></li>

<p>Hydration is key- and it can't be stressed enough! Kyno Health experts recommend drinking at least 8 to 10 glasses of water each day. Whether or you're stepping out, or escaping the heat by staying home, ensure you keep a water bottle around to sip regularly without fail. You can also have coconut water, lemon juice, or buttermilk (<em>chaas) </em>to keep yourself hydrated.</p>

<li><strong>Apply Generous Amounts of Sunscreen </strong></li>

<p>While staying indoors is advised in this heat, if you must step out, slather on that sunscreen generously to shield your skin from harm. Our experts recommend using a sunscreen with SPF 30 or above for the utmost protection. Oh, but there's more to it! t Make sure that you re-apply it every two hours to keep that defence strong.</p>

<li><strong>Avoid Direct Sun-Exposure </strong></li>

<p>Let's face it- The sun's turning up the heat like it's hosting a summer barbecue. So, it's important that you avoid direct sun exposure. Stay indoors during peak hours (10 am to 4 pm), wear wide-brimmed hats and sunglasses, or take breaks in shaded areas when outside.</p>

<li><strong>Take ORS When Required</strong></li>

<p>Heat causes you to sweat. But too much heat can cause you to sweat, A LOT! When you sweat, you're not just losing water; you're also losing electrolytes- minerals in the body that carry an electric charge and play vital roles in maintaining hydration, regulating nerve impulses, and supporting muscle function. Losing electrolytes means losing hydration. This is precisely where a superhero drink called Oral Rehydration Solution or ORS helps save the day. It's a drink that packs the right amount of salts and sugars your body needs to maintain hydration. Having ORS gives your body a refreshing boost of the essential electrolytes it has lost and fixes dehydration in absolutely no time. However, ensure you only take genuine, WHO-approved ORS.</p>

<li><strong>Wear Breathable Clothing </strong></li>

<p>Wearing light, breathable clothes can be very helpful in getting a respite from the heat. Go for clothes that are loose-fitting, light-coloured, and made of cotton or rayon to remain comfortable. These are breathable fabrics and allow sweat and heat to escape through the material.</p>
<p>These essential tips will help keep your spirits high, and temperature low at this time. However, if you, or someone around you shows symptoms of a heatstroke, or a related illness, ensure you seek emergency care. Or, you can always reach out to Kyno Health, and our doctors will reach your doorstep within 60 minutes.</p>
</ul>
        </div>

      </section>

    </>

  )
}

export default index