import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Frequently Asked Questions (FAQs) About Doctor Appointments</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Why are regular doctor appointments important?</Accordion.Header>
                    <Accordion.Body>
                      <p>Regular doctor appointments are essential for preventive care, early detection of health issues, and building a strong relationship with your healthcare provider. They help monitor your health, address concerns, and promote overall well-being.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How often should I schedule a doctor appointment?</Accordion.Header>
                    <Accordion.Body>

                      <p>The frequency of doctor appointments may vary based on age, health status, and individual needs. As a general guideline, adults should aim for an annual check-up, while those with chronic conditions or specific health concerns may require more frequent visits.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What should I bring to a doctor appointment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Bring a list of current medications, any relevant medical records, information about your family's medical history, and a list of questions or concerns you want to discuss. This helps your healthcare provider better understand your health and address your specific needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What can I expect during a routine doctor appointment?</Accordion.Header>
                    <Accordion.Body>

                      <p>A routine doctor appointment typically involves checking vital signs, discussing your health history, addressing any concerns you may have, and performing necessary screenings or tests. It's also an opportunity to discuss lifestyle factors and set health goals.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How can I manage the cost of doctor appointments?</Accordion.Header>
                    <Accordion.Body>
                      <p>Check with your healthcare provider about insurance coverage and inquire about any available discounts or payment plans. Some clinics also offer sliding scales or reduced fees for those without insurance. Investing in preventive care can often save on potential future healthcare costs.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>What if I don't have any specific health concerns? Should I still go for a check-up?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, even if you feel healthy, regular check-ups are important for preventive care. They help detect potential health issues before symptoms arise and establish a baseline for your health. Preventive care is about maintaining and optimizing your well-being.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How do I choose the right healthcare provider for my needs?</Accordion.Header>
                    <Accordion.Body>
                      <p>Consider factors such as location, office hours, communication style, and any specific medical needs you may have. Ask for recommendations from friends, family, or colleagues, and schedule a meet-and-greet appointment to assess compatibility.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can I discuss mental health concerns during a doctor appointment?</Accordion.Header>
                    <Accordion.Body>
                      <p>Absolutely. Doctor appointments are not limited to physical health; they encompass mental health as well. Feel free to discuss stressors, emotional well-being, or any mental health concerns with your healthcare provider. They can offer support, resources, or referrals to mental health professionals.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What should I do if I'm nervous or anxious about doctor appointments?</Accordion.Header>
                    <Accordion.Body>
                      <p>It's common to feel nervous about medical visits. Communicate your concerns with your healthcare provider, and they can offer reassurance, explain procedures, and work with you to make the experience more comfortable. Open communication is key to a positive healthcare experience.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How can I make the most out of my doctor appointments?</Accordion.Header>
                    <Accordion.Body>
                      <p>Prepare for your appointments by bringing relevant information, asking questions about your health, and actively participating in discussions about preventive measures and lifestyle choices. Building a collaborative relationship with your healthcare provider ensures you receive comprehensive care tailored to your needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index