import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>The Best Dandruff Fighting Routine: Daily, Weekly, and Monthly Tips</h3>    
    <p>A healthy dandruff-free scalp with good hair quality is the result of a promising hair care routine. To eliminate dandruff from your scalp and restore the health of your scalp, you are required to adopt the most effective dandruff hair care routine. In this blog, you will get a detailed weekly hair care routine to combat dandruff along with the best daily and monthly do's and don'ts tips for reducing dandruff. So, check out this blog right away!</p>
<p>Are you confused about how to remove dandruff from your scalp permanently? </p>

<p><strong>Also Read : </strong><a href="https://www.kynohealth.com/blog/top-10-home-remedies-for-dandruff-treatment">Top 10 Home Remedies for Effective Dandruff Treatment</a></p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tags1Img'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
