import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Comprehensive Pain Management Care for a Pain-Free Life
</h3>    
    <p className='d-md-none d-lg-none'>Are you or a loved one struggling with chronic pain that affects your daily life? At our clinic Kyno Health, we are dedicated to providing expert pain management solutions tailored to your unique needs. Our team of skilled and compassionate doctors specializes in helping you find relief and regain your quality of life.</p>

<p className='mob-noee'>Are you or a loved one struggling with chronic pain that affects your daily life? At our clinic Kyno Health, we are dedicated to providing expert pain management solutions tailored to your unique needs. Our team of skilled and compassionate doctors specializes in helping you find relief and regain your quality of life.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
