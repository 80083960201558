import React from "react";
import BookAppointmentCard from "./bookAppointment/bookAppointmentCard";
import HowToBookAppointment from "./bookAppointment/howToBookAppointment";

export default function BookAppointmentPage(){


    return (

        <>
            <div>
                <BookAppointmentCard />
                <HowToBookAppointment />
            </div>
        </>
    )
}