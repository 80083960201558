import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import SecondSection from './Second_Section/index'
import { Helmet } from 'react-helmet';



function index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kyno Health Privacy Policy</title>
        <meta name="description"
          content="Discover how Kyno Health protects your personal information. Read our comprehensive privacy policy to understand the measures we take to ensure the confidentiality and security of your data." />
        <link rel="canonical" href="https://www.kynohealth.com/privacy-policy" />
      </Helmet>
      <Nav />

      <SecondSection />





      <Footer />

    </>
  )
}

export default index
