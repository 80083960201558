import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose On-Call Doctor Services?</h2>
<p>In the modern world, time is of the essence. Juggling work, family, and personal commitments can make it challenging to prioritize healthcare. Our on-call doctors offer a solution that bridges the gap between your busy life and your health:
</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Immediate Attention</h4>
<p>With our on-call service, you don't have to wait days or weeks for an appointment. Our doctors are just a call away, ready to provide medical assistance when you need it most.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience Redefined</h4>
<p>Avoid the hassle of commuting, waiting rooms, and rigid clinic hours. Our doctors come to you, providing care in the comfort of your chosen location.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Care</h4>
<p>Each individual's health needs are unique. Our on-call doctors take the time to understand your medical history, concerns, and preferences, tailoring their approach to ensure you receive the best care possible.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Comprehensive Services</h4>
<p>From minor illnesses and injuries to preventive care and follow-up consultations, our doctors offer a wide range of services to meet your healthcare requirements.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Peace of Mind</h4>
<p>Having a trusted medical professional on call provides reassurance that you can access medical expertise whenever health issues arise.
</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>The On-Call Doctor Experience</h2>
<p>Our on-call doctor service goes beyond the typical healthcare experience. Here's what you can expect when you choose our service:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem3'>
<h4>Easy Booking</h4>
<p>Simply reach out to us through our dedicated hotline or online platform to request an on-call doctor. We'll gather some basic information and match you with a doctor who suits your needs.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Prompt Response</h4>
<p>Our doctors prioritize timely responses. They will contact you to gather more details about your condition and provide an estimated arrival time.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>In-Person Consultation</h4>
<p>When the doctor arrives, they will conduct a thorough in-person consultation. They'll listen to your concerns, ask relevant questions, and perform necessary examinations.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Diagnosis and Treatment</h4>
<p>Based on the evaluation, the doctor will offer a diagnosis and recommend a suitable treatment plan. They can provide prescriptions, administer necessary medications, and offer guidance on managing your condition.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Preventive Advice</h4>
<p>Our on-call doctors believe in the power of preventive care. They'll provide insights on maintaining a healthy lifestyle and offer guidance to prevent future health issues.</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Committed to Quality Care</h2>
<p>Our on-call doctor service is backed by a commitment to quality, professionalism, and your well-being:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Licensed Professionals</h4>
<p>All our on-call doctors are licensed, experienced, and qualified to provide medical care. They adhere to the highest standards of medical practice.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Emergency Preparedness</h4>
<p>While our service caters to non-emergencies, our doctors are trained to identify when a situation requires urgent attention. In such cases, they can guide you to appropriate resources.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Follow-Up Care</h4>
<p>If your condition requires follow-up or additional care, our doctors will ensure you receive the necessary information and referrals.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Patient-Centered Approach</h4>
<p>Your comfort and understanding matter. Our doctors communicate clearly, answer your questions, and involve you in the decision-making process.</p>
</div>
</div>




</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Access Quality Healthcare on Your Terms
</h5>
<p>At "On-Call Doctor," we believe that healthcare should adapt to your lifestyle, not the other way around. Our on-call doctors prioritize your well-being, providing accessible, personalized medical care when and where you need it. Experience healthcare that puts you in control – contact us to schedule an on-call doctor visit and discover the convenience of quality medical care on your terms.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index