import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I book an appointment with a general physician for a home visit in Rajouri Garden, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To schedule an appointment with us at Kyno Health, simply call us at this number- 01206056623, and we will get you started and help you schedule your appointment. We will help you find the best general physician for a home visit in Rajouri Garden. You can also email us at  support@kynohealth.com to start the scheduling process for your appointment."
      }
    },{
      "@type": "Question",
      "name": "What is the typical response time for a general physician to arrive for a home visit in Rajouri Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you are living in Rajouri Garden, then our average response time is just a mere 45 minutes. We understand that there can be emergency situations and hence, we have vowed to reach your home and provide you with medical treatment only in 45 minutes. We are quick and efficient."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians offering home services in Rajouri Garden experienced and qualified?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our general physicians who are offering home services in Rajouri Garden are highly qualified and experienced. They have had an extensive background in the medical field, treating patients and providing consultations. In the past, many of them have also been attached to reputable hospitals in the country."
      }
    },{
      "@type": "Question",
      "name": "How can I verify the credentials and background of a general physician offering home services in Rajouri Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You may contact us if you are unsure about the credentials and background of our general physicians who are offering home services in Rajouri Garden. We can assure you that we have conducted thorough background check and have carefully selected our doctors. We are committed to providing you the highest standards of care and so are our doctors."
      }
    },{
      "@type": "Question",
      "name": "What types of medical conditions can be treated by general physicians at home in Rajouri Garden?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our general physician near me treats a wide range of conditions. Be it a viral fever that you are suffering from or a physical injury, we are here to help you and take care of your health. Hence, be it hypertension, diabetes, chronic illnesses, or bed sores, you need not worry anymore. Our doctors are also experts in providing you with the following services at your doorstep: consultations, urgent care, prescriptions, and diagnostic tests."
      }
    }]
  }



  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Rajouri Garden, Delhi | Kyno Health</title>
        <meta name="description" content="Access premium healthcare services at home with Kyno Health's best general physicians in Rajouri Garden, Delhi. Enjoy the convenience and quality of personalized medical care right at your doorstep." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/rajouri-garden-general-physicians-at-home" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Rajouri Garden, Delhi" hero_paragraph="If you are a resident of Rajouri Garden and are looking for a general physician who can come to your home, you need not worry anymore. At Kyno Health, we have a selected list of the Best General Physicians at Home in Rajouri Garden, Delhi who are committed to providing the highest standards of medical care. With our home-visit services, we are also striving to make healthcare more accessible and affordable to all."/>
      
      <Content content_h3="Making Healthcare Easily Accessible - The Need for General Physicians at Home" content_p="Healthcare is a basic need that should be made available to all. However, in a country such as India, there are huge disparities in our economic and social conditions. This has also led to the unfair distribution of healthcare services to the common public. With an increasing number of expensive treatments and newly built hospitals, we have seen how medical services are becoming inaccessible and unaffordable." content_p1="General physicians providing home visits can bridge this healthcare accessibility gap by offering convenient, affordable medical care. Patients, especially the elderly and those with mobility issues, benefit from receiving treatment in the comfort of their homes. This approach also reduces the burden on hospitals and clinics, making healthcare more inclusive. By eliminating travel costs and time, home visits ensure that quality medical care reaches everyone, regardless of their economic or social conditions. " 

      content_h3_2="Discover the Top General Physicians at Kyno Health in Rajouri Garden" content_p_2="At Kyno Health, we believe in the motto of “kindness” and “care”. We think medical services should be made accessible to all. Moreover, one doesn't need to spend huge amounts of money in order to receive treatments. It should also be accessible in the comfort of your own home, which is the primary service we provide. So reach out to us or call us whenever you need a general physician home visit near me. Be it assessing the elderly or checking on a baby, we are here for you. We carry the latest advanced medical tech and provide extensive consultation. So trust in our doctors to experience the best of at-home medical care."/>

      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index