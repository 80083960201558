import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Third_Section from './Third_Section/index'
import Seventh_Section from './Seventh_Section/index'
import Faqs from './Faqs/index'


import { Helmet } from 'react-helmet'

export default function Home_New(){

    const faqscheme = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What are the costs associated with a doctor's home visit in Delhi?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Doctor's home visit fees in Delhi vary depending on the doctor’s fee and services rendered. Typically, the first visit is Rs 900. Additional charges may apply for medications or specialized procedures."
          }
        },{
          "@type": "Question",
          "name": "What are the benefits of using a home visit doctor service in Delhi?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Home visit doctor services in Delhi offer convenience, personalized care, timely assistance, comprehensive treatment, continuity of care, and peace of mind for patients in the comfort of their homes."
          }
        },{
          "@type": "Question",
          "name": "How quickly can a home visit doctor arrive at my location in Delhi?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Home visit doctors in Delhi aim to arrive within 1 to 2 hours of booking, offering prompt medical assistance in emergencies or for scheduled consultations."
          }
        },{
          "@type": "Question",
          "name": "What kind of medical conditions can home visit doctors in Delhi treat?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Home visit doctors in Delhi can treat a wide range of medical conditions, including minor illnesses, chronic diseases, acute injuries, infections, and palliative care for terminal illnesses."
          }
        },{
          "@type": "Question",
          "name": "What should I prepare before the doctor's visit?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Before the doctor's visit, ensure a comfortable and well-lit space, gather medical records or relevant information, list symptoms or concerns, and have any medications or medical equipment readily accessible."
          }
        }]
      }
      

    return(
        <div>
                <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Emergency Home Visit Doctor Service in Delhi | Kyno Health</title>
                    <meta name="description" content="Need medical assistance at home? Kyno Health offers emergency home visit doctor services in Delhi. Our experienced doctors provide prompt and reliable medical care in the comfort of your home. Contact us now for immediate assistance."/>
                    <link rel="canonical" href="https://www.kynohealth.com/emergency-home-visit-doctor-delhi"/>

            </Helmet>
            <Nav/>
            <Hero/> 
            <Third_Section/>

            <Seventh_Section/>
            <Faqs/>
     
    <Footer/>

        </div>
    )
}