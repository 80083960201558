import React from "react";
import ba from "../../utilities/images/bookAppointment.png"
import "../../styles/bookAppointmentCard.css"
export default function BookAppointmentCard() {


    return (
        <>
            <div className="bookAppointmentCard">
                <div className="bookAppointmentForm">
                    <div className="title">Book Appointment</div>
                    <div className="name">
                        <div className="fn">
                            <div>First Name</div>
                            <input type="text" placeholder="First Name" />
                        </div>
                        <div className="ln">
                            <div>Last Name</div>
                            <input type="text" placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="phone">
                        <div className="pno">Phone Number</div>
                        <div className="pnoinput">
                            <div>+91</div>
                            <input type="number" placeholder="00000-00000" />
                        </div>
                    </div>
                    <div className="message">
                        <div>Message</div>
                        <input type="textarea" />
                    </div>
                    <div className="btn">
                        Book an Appointment
                    </div>
                </div>
                <img src={ba} alt="" />
            </div>
        </>
    )
}