import React from 'react'
import Slider from "react-slick";
import './Css/style.css'



function index() {
  const settings = {

    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
  
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    
   };

 return (
  <>
  <section className='sixth-fold'>
  <div className='container'>
  <div className="grad"></div>
  <div className="grad1"></div>
  <div className="grad2"></div>
  <h5>OUR DOCTORS</h5>
  <div className='ml-70'>
    <h2>Putting 'care' back in <br/> healthcare</h2>
    <p>Our doctors come with years of experience & training at top institutes & hospitals such as Fortis, Medanta, AIIMS, Safdarjung, etc. </p>
    </div>
    <Slider {...settings}>



     <div className='col-md-4'>
          <div className="putting-profile">
          <div className='doct'>
          <img src="images/doc/drgaurav-1.png" alt='drgaurav' /></div> 

        <div className='vgy'>
          <h6>Dr Gaurav Sharma</h6>
            <p><span>General Physician</span></p>
            <p> MBBS</p>
            <hr/>
          <div className='row'>
            <div className='col-md-8 col-6'>
              <div className='tww-p'>
               <img src='images/putting-tick.png' alt='putting' />
                <p>Ex- CK Birla, Delhi<br /> Guru Gobind Singh , Delhi</p>
              </div>
            </div>

            <div className='col-md-4 col-6'>
              <div className='tww-p'>
              <div className="frame">
      <div className="text-wrapper">5+</div>
    </div>
                {/* <img src='images/putting-tick-plus.png'/> */}
                <p>Years of Experience</p>
              </div>
            </div>
          </div>

        {/* <div className="alls"><a className="btn btn-warning" href="#">Know more</a></div> */}
        </div>
        </div>
      </div>

     <div className='col-md-4'>
          <div className="putting-profile">
          <div className='doct'>
          <img src="images/doc/drprabhu-1.png" alt='drprabhu' /></div> 

<div className='vgy'>
      <h6>Dr Prabhu M</h6>
      <p><span>General Physician</span></p>
      <p> MBBS</p>

      <hr/>
      

      <div className='row'>
      <div className='col-md-8 col-6'>
        <div className='tww-p'>
        <img src='images/putting-tick.png' alt='putting' />
        <p>Ex-KM speciality, Coimbatore<br />HEM Multi speciality, Coimbatore</p>
      </div></div>

      <div className='col-md-4 col-6'>
      <div className='tww-p'>
      <div className="frame">
      <div className="text-wrapper">5+</div>
    </div>
        {/* <img src='images/putting-tick-plus.png'/> */}
      <p>Years of Experience</p></div></div>

        </div>

        {/* <div className="alls"><a className="btn btn-warning" href="#">Know more</a></div> */}

     </div></div></div>

     <div className='col-md-4'>
          <div className="putting-profile">
          <div className='doct'>
          <img src="images/doc/drashish-1.png" alt='drashish' /></div> 

<div className='vgy'>
      <h6>Dr Ashish Kochekar</h6>
      <p><span>General Physician</span></p>
      <p> MBBS</p>

      <hr/>
      

      <div className='row'>
      <div className='col-md-8 col-6'>
        <div className='tww-p'>
        <img src='images/putting-tick.png' alt='putting' />
        <p>Ex- Deen Dayal upadhayay, Delhi <br />Safdarjung, Delhi</p>
      </div></div>

      <div className='col-md-4 col-6'>
      <div className='tww-p'>
      <div className="frame">
      <div className="text-wrapper">7+</div>
    </div>
        {/* <img src='images/putting-tick-plus.png'/> */}
      <p>Years of Experience</p></div></div>

        </div>

        {/* <div className="alls"><a className="btn btn-warning" href="#">Know more</a></div> */}

     </div></div></div>

     <div className='col-md-4'>
          <div className="putting-profile">
          <div className='doct'>
          <img src="images/doc/drankesh-1.png" alt='drankesh' /></div> 

<div className='vgy'>
      <h6>Dr Ankesh Rawat</h6>
      <p><span>General Physician</span></p>
      <p> MBBS</p>

      <hr/>
      

      <div className='row'>
      <div className='col-md-8 col-6'>
        <div className='tww-p'>
        <img src='images/putting-tick.png' alt='putting' />
        <p>Ex- Medanta, Gurgaon<br />Fortis, Gurgaon</p>
      </div></div>

      <div className='col-md-4 col-6'>
      <div className='tww-p'>
      <div className="frame">
      <div className="text-wrapper">9+</div>
    </div>
        {/* <img src='images/putting-tick-plus.png'/> */}
      <p>Years of Experience</p></div></div>

        </div>

        {/* <div className="alls"><a className="btn btn-warning" href="#">Know more</a></div> */}

     </div></div></div>

     <div className='col-md-4'>
          <div className="putting-profile">
          <div className='doct'>
          <img src="images/doc/drhappy-1.png" alt='drhappy' /></div> 

<div className='vgy'>
      <h6>Dr Happy Kumar</h6>
      <p><span>General Physician</span></p>
      <p> MBBS</p>

      <hr/>
      

      <div className='row'>
      <div className='col-md-8 col-6'>
        <div className='tww-p'>
        <img src='images/putting-tick.png' alt='putting' />
        <p>Ex-Jaypee hospital, Noida
<br />Metro Hospital, Delhi</p>
      </div></div>

      <div className='col-md-4 col-6'>
      <div className='tww-p'>
      <div className="frame">
      <div className="text-wrapper">5+</div>
    </div>
        {/* <img src='images/putting-tick-plus.png'/> */}
      <p>Years of Experience</p></div></div>

        </div>

        {/* <div className="alls"><a className="btn btn-warning" href="#">Know more</a></div> */}

     </div></div></div>

     

    </Slider>
   </div>
   </section>
   </>
 )
}

export default index