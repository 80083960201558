
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MobileInputWithTruecaller = () => {
  const [requestNonce, setRequestNonce] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);
  const [pollingError, setPollingError] = useState(null);
  const maxPollingAttempts = 5;
  
  // Function to start the Truecaller flow and set requestNonce
  const handleInputClick = () => {
    const nonce = generateRequestNonce();  // Generate a unique nonce
    setRequestNonce(nonce);
    setIsPolling(true); // Start polling
    initiateTruecallerFlow(nonce);  // Redirect to Truecaller SDK
  };

  // Function to generate a unique nonce
  const generateRequestNonce = () => {
    return 'nonce_' + Math.random().toString(36).substr(2, 9);
  };

  // Function to initiate Truecaller SDK flow
  const initiateTruecallerFlow = (nonce) => {
    const partnerKey = 'j7jJnfdd002127115425894a9a16500489778';
    const partnerName = 'Kynohealth.com';
    const privacyUrl = 'https://www.kynohealth.com/privacy-policy/'; // Link to your privacy policy
    const termsUrl = 'https://www.kynohealth.com/terms-conditions/';     // Link to your terms of 
    const language = 'en';
    const ttl = 600000;

    const truecallerUrl = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${nonce}&partnerKey=${partnerKey}&partnerName=${partnerName}&lang=${language}&privacyUrl=${encodeURIComponent(privacyUrl)}&termsUrl=${encodeURIComponent(termsUrl)}&ttl=${ttl}`;

    window.location = truecallerUrl;
  };

  // Poll the Truecaller status API every 3 seconds
  useEffect(() => {
    if (isPolling && requestNonce && pollingCount < maxPollingAttempts) {
      const interval = setInterval(() => {
        pollTruecallerStatus(requestNonce);
      }, 3000);  // Poll every 3 seconds

      return () => clearInterval(interval);  // Clear the interval after polling stops
    }
  }, [isPolling, requestNonce, pollingCount]);

  // Function to poll Truecaller status API
  const pollTruecallerStatus = async (nonce) => {
    try {
        const response = await axios.post('/api/truecaller-status', { requestId: nonce })
      console.log({response})
      const { status, data } = response.data;

      if (status === 'user_verified') {
        setIsPolling(false);  // Stop polling
        console.log('User profile data:', data);
      } else if (status === 'user_rejected') {
        setIsPolling(false);  // Stop polling
        console.log('User rejected the flow.');
      } else if (status === 'flow_invoked') {
        setPollingCount(prev => prev + 1);  // Continue polling
      } else {
        setIsPolling(false);
        console.log('something went wrong with status 200');
      }
    } catch (error) {
      setPollingError('Error fetching Truecaller status');
      console.error('Polling error:', error);
      setIsPolling(false);  // Stop polling on error
    }

    // Stop polling after max attempts
    if (pollingCount >= maxPollingAttempts) {
      setIsPolling(false);
      console.log('Max polling attempts reached');
    }
  };


  return (
    <div>
      <label htmlFor="mobile-input">Mobile Number</label>
      <input
        type="tel"
        id="mobile-input"
        placeholder="Enter your mobile number"
        onClick={handleInputClick}  // Trigger Truecaller flow on first click
      />
      {pollingError && <p>{pollingError}</p>}
    </div>
  );
};

export default MobileInputWithTruecaller;

