import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Top Home Remedies for Dandruff: Natural Ways to Restore Scalp Health</h3>
    <p>Are you suffering from dandruff? This hair fungus makes the scalp excessively dry and flaky, degrading your hair's health. However, you can seamlessly combat dandruff with the best dandruff treatment home remedies. Check out this blog right away to learn more about these remedies in detail!</p>
<p>Also, you can consult Kyno Health, the best hair care clinic, to get the most catered solutions for your dandruff issues.</p>
<p><strong>Also Read : </strong><a href="https://www.kynohealth.com/blog/causes-of-dandruff-and-itchy-scalp"><strong>Understanding the Causes of Dandruff and Itchy Scalp: A Holistic View</strong></a></p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
