import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is considered a minor injury, and when should I seek medical attention?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Minor injuries encompass a range of conditions such as sprains, strains, cuts, bruises, and minor fractures. While some can be managed at home with basic first aid, it's advisable to seek medical attention if you experience persistent pain, swelling, or limited mobility.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How quickly can I get an appointment for a minor injury at Kyno Health?
</Accordion.Header>
                    <Accordion.Body>

                      <p>We understand the urgency of minor injuries. At Kyno Health, we strive to provide prompt care. You can typically schedule an appointment quickly through our online booking system or by contacting our clinic directly.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header> Do I need to bring anything specific to my appointment for a minor injury?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Bringing any relevant medical history, insurance information, and a list of current medications can be helpful. However, it's not mandatory. Our doctors will conduct a thorough examination and may perform diagnostic tests based on your injury.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I walk in for treatment, or do I need to make an appointment for a minor injury?</Accordion.Header>
                    <Accordion.Body>
                    <p>While walk-ins are welcome, scheduling an appointment ensures that you receive timely care and reduces waiting times. For non-emergency minor injuries, we recommend making an appointment to streamline your visit.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How can I manage pain at home before my appointment for a minor injury?</Accordion.Header>
                    <Accordion.Body>
                      <p>Over-the-counter pain relievers, rest, ice, compression, and elevation (R.I.C.E.) are common methods to manage pain at home. However, if pain persists or worsens, it's crucial to consult with our doctors for a more comprehensive assessment and tailored pain management plan.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What types of diagnostic tests might be performed for minor injuries?</Accordion.Header>
                    <Accordion.Body>
                      <p>Depending on the nature of the injury, diagnostic tests such as X-rays may be recommended to assess fractures or dislocations. Our doctors will determine the necessity for such tests based on the initial evaluation.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header> Are minor injuries more susceptible to infections, and how can I prevent them?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Open wounds, such as cuts or abrasions, can increase the risk of infection. Our doctors provide guidance on proper wound care, including cleaning, disinfection, and dressing, to minimize the risk of infection and promote optimal healing.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can I return to work or normal activities immediately after receiving treatment for a minor injury?</Accordion.Header>
                    <Accordion.Body>
                      <p>The timeline for returning to normal activities varies depending on the nature and severity of the injury. Our doctors will provide personalized guidance on activity restrictions, rehabilitation exercises, and when it's safe to resume regular activities.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Is physical therapy recommended for minor injuries, and do you provide these services?</Accordion.Header>
                    <Accordion.Body>
                      <p>In some cases, our doctors may recommend physical therapy to aid in the recovery of certain minor injuries. We collaborate with skilled physical therapists to ensure a comprehensive approach to your treatment and recovery.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Are there any signs that a minor injury might be more severe than initially thought?</Accordion.Header>
                    <Accordion.Body>
                      <p>If you experience persistent or worsening pain, swelling, numbness, or difficulty moving the injured area, it's essential to seek medical attention promptly. These could be signs of a more significant underlying issue that requires expert evaluation and intervention.
</p>
                      <p>For any additional questions or concerns about minor injuries and their treatment, please feel free to reach out to Kyno Health. Our dedicated team is here to provide the answers and care you need for a quick and effective recovery.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index