import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians offering home visits in Kalkaji, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Searching for the best general physician in the Kalkaji area, you can take the help of your social circle or social media, can contact friends or relatives as they can give the true picture of the physicians. You can also check the reviews on the website or contact the residents in the particular area."
      }
    },{
      "@type": "Question",
      "name": "What services are typically offered by general physicians at home in Kalkaji, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Conducting physical exams, monitoring vital signs, and assessing overall health status \nManaging and monitoring chronic conditions like diabetes, hypertension, asthma, and heart disease. \nDiagnosing and treating acute conditions such as infections, flu, colds, and minor injuries. \nAdministering vaccines to protect against illnesses such as flu, pneumonia, and other preventable diseases."
      }
    },{
      "@type": "Question",
      "name": "How much does it cost to avail the services of a general physician at home in Kalkaji, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The fee structure varies from INR 500 to 5000 depending on the type of services availed."
      }
    },{
      "@type": "Question",
      "name": "Can I schedule regular check-ups with a general physician at home in Kalkaji, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can avail of the monthly package having 6 visits every month and including 1 emergency visit. For that, you have to pay a lump sum amount which varies from INR 4000 to 6000."
      }
    },{
      "@type": "Question",
      "name": "How can I book an appointment with a reputable general physician at home in Kalkaji, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book the appointment by making the call or sending the mail to the office. The details of the same are available on the website."
      }
    }]
  }
  


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Kalkaji | Kyno Health</title>
        <meta name="description" content="Kyno Health provides exceptional general physician services at home in Kalkaji. Our skilled doctors offer comprehensive medical care, consultations, and treatments right at your doorstep. Experience convenient and reliable home healthcare with Kyno Health in Kalkaji." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-kalkaji" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

                <h3>Best General Physicians at Home in Kalkaji</h3>
                <p>The concept of receiving medical care at home has gained significant traction in the evolving landscape of healthcare delivery. Home healthcare services, including those provided by general physicians at Kyno Health in Kalkaji, offer a personalized and convenient alternative to traditional clinic visits. By bringing healthcare professionals directly to the patient's doorstep, search for the in-home general physicians near me who strive to enhance accessibility, comfort, and quality of care.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>
          <h3>Advantages offered by General Physicians at Home in Kalkaji</h3>
          <ul>
            <li><strong>Convenience and Comfort of Home Healthcare:</strong> Instead of sitting in a sterile waiting room, you're cozied up on your couch with a cup of tea, waiting for your doctor to arrive. Home healthcare eliminates the hassle of commuting and allows you to focus on your health without the added stress of travel.</li>
            <li><strong>Personalized Care and Familiar Environment: </strong>We all know that feeling of being in a doctor's office, surrounded by unfamiliar faces and the incessant sound of ringing phones. With in-home general physicians, you can receive personalized care in a familiar setting, making the whole experience more comfortable and less daunting.</li>
          </ul>

          <h3>Top Factors to Consider When Choosing a Home General Physician in Kalkaji</h3>
       
            <h4>Accessibility and Availability</h4>
        
          <p>What good is a doctor if they're impossible to reach when you need them the most? When selecting a home general physician, make sure they are accessible and available to address your medical issues promptly. After all, health concerns don't always adhere to a 9-to-5 schedule.</p>
          <h4>Compatibility with Patient's Needs and Preferences</h4>
        
          <p>Beyond medical expertise, it's important to choose a general physician who understands and respects your needs and preferences. Whether it's communication style, treatment approach, or cultural considerations, finding a doctor who aligns with your values can make a world of difference in your healthcare journey.&nbsp;</p>
       
           <h4>Open Communication Channels</h4>
          
          <p>Communication is essential for effective healthcare. Make sure to express your concerns clearly and ask questions freely. Remember, there's no such thing as a silly question &ndash; unless you're asking if tomatoes are technically a fruit. They are, by the way.</p>
        </div>
      </section>


      <section className='fourth-fold pt-3'>

        <div className='container'>

          <div className='row d-flex align-items-center'>


            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>Home general physicians offer a convenient and personalized approach to healthcare. In conclusion, the availability of general physicians at home presents a promising avenue for enhancing healthcare delivery and patient experience. By prioritizing personalized care, convenience, and effective communication, in-home general physicians can make a significant difference in maintaining and improving individuals' health and well-being. Embracing this innovative approach to healthcare not only reflects a patient-centered mindset but also contributes to a more accessible and efficient healthcare system for all.</p>

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>


      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index