import React, {useEffect, useState} from 'react' 
import "../../../styles/doctor_lead.scss"
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'

export default function Doctor_lead(props){
 
    const fetchActiveLeads = async () => {
        const result = await axios.get(`${localBackend}activeLeadsOfDoctor?doctorId=Doctors/${props.doctorId}`);
        console.log(result, result.data)
    }
    
    useEffect(() => {
        console.log(props.doctorId)
        fetchActiveLeads()
    }, [])

    return(
        <>
            <div className='manage-doc-leads'>
                <div className='title'>Here is a list of Active Leads</div>
            </div>
        </>
    )
}
