import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose Kyno Health for Minor Injuries?</h2>
 
<div className='ml-70'>
<div className='row'>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Experienced Medical Team</h4>
<p>At Kyno Health, we have a team of experienced doctors specializing in emergency medicine and minor injuries. Our medical professionals are skilled in diagnosing and treating a variety of minor injuries, ensuring you receive the highest quality care.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>Our clinic (Kyno Health) is equipped with state-of-the-art facilities, providing advanced diagnostic tools and treatment options for minor injuries. From digital imaging to on-site minor surgical procedures, we offer comprehensive services under one roof.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Patient-Centric Approach</h4>
<p>We understand that minor injuries can be inconvenient and disrupt your daily life. Our patient-centric approach ensures that we listen to your concerns, thoroughly evaluate your condition, and develop personalized treatment plans to meet your unique needs.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Efficient and Timely Care</h4>
<p>Time is of the essence when it comes to minor injuries. We prioritize efficiency without compromising on the quality of care. Our streamlined processes and experienced staff ensure that you receive prompt attention and treatment.
</p>
</div>
</div>
</div>
</div>
</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Common Types of Minor Injuries We Treat</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Sprains and Strains</h4>
<p>Whether it's a twisted ankle, a strained muscle, or a sprained ligament, our doctors are skilled in assessing and treating a variety of musculoskeletal injuries. We focus on relieving pain, reducing inflammation, and promoting optimal healing.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Cuts and Abrasions</h4>
<p>Minor cuts and abrasions can happen in everyday situations. Our doctors provide thorough cleaning, disinfection, and proper wound care to prevent infections and promote efficient healing.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Bruises and Contusions</h4>
<p>Bruises, or contusions, can be painful and unsightly. Our medical team evaluates the extent of the bruising, offers pain management solutions, and provides guidance on care to minimize swelling and discoloration.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Minor Fractures and Dislocations</h4>
<p>For minor fractures and dislocations, our doctors employ diagnostic imaging to assess the extent of the injury. We then provide appropriate splinting, casting, or relocation procedures to support healing.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Burns and Scalds</h4>
<p>Whether it's a minor burn from hot surfaces or scalding liquids, our doctors offer immediate care to minimize pain and prevent infection. We provide guidance on wound care and follow-up appointments to monitor healing progress.
</p>
</div>
</div>
</div>
</div>
</div>

</section>



<section className='thrd-fold'>
<div className='container'>
  <h2>Our Approach to Treatment:
</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Assessment</h4>
<p>Our doctors conduct a comprehensive assessment of your minor injury, considering factors such as the mechanism of injury, pain levels, and any associated symptoms. This evaluation guides the development of an individualized treatment plan.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Diagnostic Imaging</h4>
<p>When necessary, we utilize diagnostic imaging, such as X-rays, to obtain a detailed view of the injury. This helps in accurate diagnosis and ensures the most appropriate course of action.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Pain Management</h4>
<p>Addressing pain is a key component of our approach. Our doctors employ various pain management techniques, including medications and localized interventions, to alleviate discomfort associated with minor injuries.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Wound Care</h4>
<p>For cuts, abrasions, and other open wounds, our doctors provide meticulous wound care. This includes cleaning, disinfection, and, if needed, suturing or adhesive closure to promote optimal healing and minimize scarring.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Rehabilitation and Follow-Up</h4>
<p>Depending on the nature of the minor injury, our doctors may recommend rehabilitation exercises to restore function and prevent long-term complications. We schedule follow-up appointments to monitor your progress and make any necessary adjustments to the treatment plan.</p>
</div>
</div>

<div className='col-md-12'>
  <h4>Conclusion:</h4>
  <p>At Kyno Health, we prioritize your comfort and well-being when it comes to treating minor injuries. Our experienced doctors, advanced facilities, and patient-centric approach make us your go-to destination for prompt and effective care. If you've experienced a minor injury, don't let it hinder your daily activities. Contact Kyno Health today for expert care tailored to your needs.
</p>

<p>Whether it's a sprain, cut, or bruise, our team is here to ensure you receive the highest quality treatment for a speedy recovery. Book an appointment with our dedicated doctors and take the first step toward healing at Kyno Health.
</p>
</div>

</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index