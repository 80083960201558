import React, {useEffect, useState} from 'react' 
import "../../../styles/opsActiveLead.scss"
export default function Active_Lead(props){
 
    useEffect(() => {
        console.log(props.lead)
    }, [])

    return(
        <>
            <div className='active-lead'>
                <div className='details-1'>
                    <div className='patient-name'>
                        <div>Patient Name</div>
                        <div>{props.lead.name}</div>
                    </div>

                    <div className='patient-phone'>
                        <div>Patient Phone</div>
                        <div>{props.lead.phone}</div>
                    </div>

                    <div className='patient-source'>
                        <div>Lead Source</div>
                        <div>{props.lead.source}</div>
                    </div>

                    <div className='patient-concern'>
                        <div>Patient Concern</div>
                        <div>{props.lead.concern}</div>
                    </div>

                    <div className='patient-address'>
                        <div>Patient Address</div>
                        <div>{props.lead.patientAddress}</div>
                    </div>

                </div>
                <div className='details-2'>
                    <div className='assigned-doctor'>
                        <div>Assigned Doctor</div>
                        <div>{props.lead.assignedDoctor}</div>
                    </div>

                    <div className='lead-status'>
                        <div>Lead Status</div>
                        <div>{props.lead.status}</div>
                    </div>
                </div>
                <div></div>

            </div>
        </>
    )
}
