import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Home Doctor Service: Bringing Medical Care to Your Doorstep</h3>
    
    <p className='d-md-none d-lg-none'>In today's fast-paced world, finding the time to visit a medical facility when you're feeling unwell can be a challenge. That's where our Home Doctor Service steps in - providing you with quality medical care in the comfort of your own home. Our dedicated team of experienced physicians is committed to delivering timely and compassionate healthcare services, ensuring your well-being is our top priority.
</p>

<p className='mob-noee'>In today's fast-paced world, finding the time to visit a medical facility when you're feeling unwell can be a challenge. That's where our Home Doctor Service steps in - providing you with quality medical care in the comfort of your own home. Our dedicated team of experienced physicians is committed to delivering timely and compassionate healthcare services, ensuring your well-being is our top priority.
</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
