import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose IV Drip at Home?</h2>
<div className='ml-70'>
<div className='row'>
  <div className='col-md-12'>
    <p>Imagine receiving a tailored IV treatment in the cozy familiarity of your own home. No need to battle traffic, navigate waiting rooms, or disrupt your schedule. Our professional healthcare team comes to you, equipped with the expertise and supplies to administer a range of IV therapies designed to address your specific needs.</p>
    <h3>The Benefits of IV Therapy</h3>
    <p>Intravenous (IV) therapy has gained popularity for its ability to provide rapid and direct nourishment to the body. Unlike oral supplements or medications, IV therapy delivers essential nutrients, vitamins, and minerals directly into the bloodstream, ensuring maximum absorption and quicker results. This method can:</p>
  </div>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Boost Hydration</h4>
<p>Dehydration can lead to a myriad of health issues, from fatigue to cognitive fog. Our hydration-focused IV drips infuse your body with a balanced blend of fluids and electrolytes, helping you regain vitality and mental clarity.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Enhance Immunity</h4>
<p>Give your immune system the support it needs to ward off illness. Our immune-boosting IV cocktails are packed with vitamins and antioxidants that fortify your body's defenses, helping you stay resilient year-round.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Recover and Recharge</h4>
<p>Whether you're recovering from a strenuous workout, a long flight, or a night of celebration, our recovery IV drips provide your body with the essential nutrients it craves for a speedy recuperation.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Combat Fatigue</h4>
<p>Feelings of tiredness and burnout are all too common in today's world. Our energy-boosting IV treatments are designed to combat fatigue and help you regain the vitality needed to conquer your day.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Beauty from Within</h4>
<p>Radiate beauty from the inside out with our specialized IV drips formulated to support healthy skin, hair, and nails. The nourishing ingredients work synergistically to enhance your natural glow.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>The IV Drip Experience</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
  <p>When you choose our IV Drip at Home service, you're choosing more than just a treatment - you're choosing a comprehensive wellness experience. Here's what you can expect:</p>
  </div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Personalized Consultation</h4>
<p>Our healthcare professionals begin by assessing your individual needs and goals. We work with you to determine the ideal IV drip formulation that aligns with your health objectives.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Comfort and Convenience</h4>
<p>Say goodbye to crowded waiting rooms and lengthy appointment times. We bring the clinic to you, creating a serene environment where you can relax and unwind while receiving your IV treatment.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Expert Administration</h4>
<p>Our skilled nurses are experienced in administering IV therapies, ensuring a safe and comfortable experience. The process is quick and virtually painless, with the added benefit of immediate nutrient absorption.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Tailored Formulations</h4>
<p>No two bodies are the same, which is why we offer a variety of IV drip formulations. From hydration-focused blends to immune-boosting cocktails, we have a solution that's perfect for you.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Prompt Relief</h4>
<p>Experience the benefits of IV therapy almost instantly. Whether you're seeking relief from a hangover, jet lag, or a busy week, our IV drips deliver rapid results that leave you feeling refreshed and revitalized.

</p>
</div>
</div>

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Our Commitment to Quality</h2>
<p>At IV Drip at Home, your health and safety are our top priorities. We uphold the highest standards of quality in every aspect of our service:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Premium Ingredients</h4>
<p>We source only the finest pharmaceutical-grade ingredients for our IV formulations. Each drip is carefully crafted to provide optimal results.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Professional Team</h4>
<p>Our team of licensed healthcare professionals is not only skilled in IV administration but also deeply committed to ensuring your well-being.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Sterile Practices</h4>
<p>We adhere to strict sterile practices throughout the IV administration process, maintaining a clean and controlled environment to prevent any risk of infection.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Care</h4>
<p>Your health journey is unique, and we treat it as such. Our team works with you to create a personalized IV drip plan that aligns with your goals.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenience Redefined</h4>
<p>Busy schedules and commitments shouldn't stand in the way of your wellness. Our at-home service brings convenience to a whole new level, allowing you to prioritize self-care without disruption.</p>
</div>
</div>


</div>
</div></div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Embark on Your Journey to Wellness</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
  <p>Are you ready to experience the transformative power of IV therapy in the comfort of your home? Embark on a journey to enhanced well-being with IV Drip at Home. Whether you're seeking hydration, immunity support, post-exercise recovery, or an energy boost, our tailored IV drips offer a solution for every need. Say goodbye to feeling fatigued and rundown – say hello to a revitalized, reenergized you.</p>
  <h3>How to Get Started</h3>
  </div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Contact Us</h4>
<p>Reach out to our friendly team to inquire about our services and discuss your wellness goals.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Personal Consultation</h4>
<p>Schedule a consultation with our healthcare professionals, where we'll discuss your needs and recommend a suitable IV drip formulation.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Choose a Time</h4>
<p>Select a convenient time for our team to visit your home and administer the IV drip.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Relax and Rejuvenate</h4>
<p>On the scheduled day, prepare to unwind as we bring the IV therapy experience to your doorstep. Sit back, relax, and enjoy the process.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Notice the Difference</h4>
<p>Experience the immediate effects of IV therapy as your body absorbs the nourishing blend of nutrients. Feel revitalized, reenergized, and ready to take on the world.

</p>
</div>
</div>

<div className='col-md-12'>
  <p>Invest in your well-being with IV Drip at Home. We believe that optimal health should be accessible and convenient, and we're dedicated to helping you achieve it. Contact us today to take the first step towards a healthier, more vibrant you. Your journey to wellness begins here.
Regenerate</p>
</div>


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index