import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
  <h2>Understanding Fever: A Common Symptom</h2>
  <p>Fever is the body's natural response to infections, inflammation, or other medical conditions. It's characterized by an elevated body temperature above the normal range. While fever itself isn't an illness, it's often a sign that something is amiss within the body. Consulting a doctor when you have a fever is crucial to identify the underlying cause and receive appropriate treatment.</p>
<h2>Why Choose Fever Doctor Services?</h2>
<p>Our Fever Doctor services are designed to provide you with expert medical attention when you need it most:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Timely Evaluation</h4>
<p>A fever can be alarming, especially when it comes on suddenly. Our doctors are available to evaluate your symptoms promptly, providing reassurance and swift guidance.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Accurate Diagnosis</h4>
<p>Fever can be caused by a wide range of factors, from infections to autoimmune conditions. Our experienced doctors can accurately diagnose the underlying cause, ensuring proper treatment.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Customized Treatment</h4>
<p>Treatment for fever varies based on the underlying condition. Our doctors create personalized treatment plans, which may include medications, rest, fluids, or further tests as needed</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Prevention Strategies</h4>
<p>Our doctors not only address the current fever but also offer insights into preventive measures to reduce the risk of future infections.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Peace of Mind</h4>
<p>When fever strikes, it's natural to feel anxious. Our Fever Doctor services provide the peace of mind that comes with professional medical guidance and care.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>The Fever Doctor Experience</h2>
<p>When you choose our Fever Doctor service, you're choosing expert care delivered with compassion and expertise:</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem3'>
<h4>Easy Booking</h4>
<p>Getting in touch with our doctors is simple. Whether you prefer a phone call or an online consultation, we're here to assist you promptly.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Comprehensive Assessment</h4>
<p>Our doctors conduct thorough assessments to understand your symptoms, medical history, and any relevant factors. This comprehensive approach ensures an accurate diagnosis.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Virtual Consultations</h4>
<p>Through our online platform, you can consult with our doctors via video calls or chat, receiving the same level of care as an in-person visit.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Treatment Recommendations</h4>
<p>Based on the assessment, our doctors provide treatment recommendations tailored to your condition. They'll explain the options and answer any questions you may have.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Follow-Up Care</h4>
<p>Our commitment to your health doesn't end with the consultation. Our doctors ensure you receive appropriate follow-up care and support.

</p>
</div>
</div>

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Your Health, Our Priority</h2>
<p>At "Fever Doctor," we believe that seeking medical attention for a fever is a responsible step towards maintaining your health and well-being. Our dedicated healthcare professionals are here to provide accurate diagnoses, personalized treatment plans, and guidance to help you recover quickly and effectively.</p>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Qualified Medical Professionals</h4>
<p>Our team of Fever Doctors consists of licensed and experienced healthcare professionals who specialize in fever management. They uphold the highest standards of medical practice.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Accurate Assessments</h4>
<p>While online consultations have limitations, our doctors utilize their expertise to gather thorough information and provide reliable medical advice. In-person visits are recommended for more complex cases.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Emergency Awareness</h4>
<p>If your fever is accompanied by severe symptoms or is of a high grade, seeking immediate in-person medical care is essential.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Prioritize Your Health</h4>
<p>Don't let fever disrupt your life. With "Fever Doctor" services, you can access expert medical care to address your symptoms and ensure a swift recovery. Contact us today to experience the convenience, expertise, and peace of mind that come with our dedicated Fever Doctor services. Your health is our priority, and we're here to support you every step of the way.</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will schedule follow-up visits as needed to monitor your progress and make adjustments to your treatment plan if required.</p>
</div>
</div> */}


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index