import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Can a doctor visit at home in Noida?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Absolutely! We provide home visit doctor services in Noida. Our experienced doctors offer medical consultations, urgent care, prescriptions, and diagnostic tests right at your doorstep. Skip the hassle of traveling and waiting rooms. Schedule a home visit now for personalized and convenient healthcare.
                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Can doctors come at home?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, our dedicated home visit doctor service allows qualified medical professionals to come to your residence. Whether you require medical consultations, urgent care, prescriptions, or diagnostic tests, our doctors provide comprehensive healthcare in the comfort of your home. Experience convenience and personalized medical attention – schedule a home visit today.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What is the doctor who comes to your house?</Accordion.Header>
                    <Accordion.Body>

                      <p>The doctor who comes to your house is a qualified medical professional from our home visit service. They offer a range of medical services, including consultations, urgent care, prescriptions, and diagnostic tests, all provided at your doorstep. This convenient and personalized healthcare approach ensures you receive expert medical attention in the comfort of your own home. Schedule a visit now for comprehensive and hassle-free medical care.
</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>What Is an Emergency Medicine Doctor?
</Accordion.Header>
                    <Accordion.Body>

                    <p>An Emergency Medicine Doctor is a specialized medical professional trained to handle urgent and critical medical situations. They are skilled in rapid diagnosis, immediate treatment, and management of various emergencies, such as injuries, trauma, severe illnesses, and life-threatening conditions. These doctors work in emergency departments, providing quick and efficient care to stabilize patients before they are transferred to appropriate medical departments for further treatment.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How do I call a doctor from home?</Accordion.Header>
                    <Accordion.Body>

                      <p>Calling a doctor from home is easy with our service. Simply dial our hotline or use our online booking platform to schedule a home visit. Our experienced doctors will then arrive at your doorstep at the appointed time. Enjoy the convenience of receiving expert medical care without leaving your home. Experience personalized healthcare on your terms – schedule a home visit today.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index