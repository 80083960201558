import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians available for home visits in Kanjhawala, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "With the help of recommendations from friends and checking online reviews, you can find the best general physicians available for home visits in Kanjhawala, Delhi"
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians offering home services in Kanjhawala, Delhi, licensed and certified?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the general physicians offering home services in Kanjhawala, Delhi, are licensed and certified."
      }
    },{
      "@type": "Question",
      "name": "What medical specialties do the top general physicians in Kanjhawala, Delhi, excel in?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians often have expertise in diagnosing and managing a wide range of adult health conditions, including chronic diseases like diabetes, hypertension, and respiratory disorders."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with the best general physician for a home visit in Kanjhawala?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Visit support@kynohealth.com to select the doctor who matches your requirements and book your appointment accordingly."
      }
    },{
      "@type": "Question",
      "name": "What are the typical consultation fees for general physicians providing home services in Kanjhawala, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For routine check-ups and minor illnesses, the consultation fees can range from ₹500 to  ₹1000 per visit."
      }
    }]
  }


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Kanjhawala | Kyno Health</title>
        <meta name="description" content="Kyno Health offers top-notch general physician services at home in Kanjhawala. Our experienced doctors provide quality medical consultations and treatments right at your doorstep, ensuring convenience and comfort for all patients. Choose Kyno Health for trusted home healthcare services in Kanjhawala." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-kanjhawala" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

                <h3>Best General Physicians at Home in Kanjhawala</h3>
                <p>Imagine when you wake up feeling unwell, you simply pick up your phone and schedule a home visit from a <strong>Best General Physician in Kanjhawala</strong>, instead of dragging yourself out of bed and driving to the nearest clinic. No more sitting in uncomfortable chairs surrounded by sick strangers, just personalized care in the comfort of your own home. But what exactly does a general physician home visit from Kyno Health entail? Well, let me break it down for you. First, you schedule an appointment at a time that works for you. Then, a qualified doctor arrives at your doorstep with all the necessary equipment to diagnose and treat your illness. From checking your vitals to prescribing medication, they've got you covered.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>
         
        <h3>The Benefits of a General Physician Home Visit</h3>
<p>In today's fast-paced world, finding time to prioritize your health can be challenging. That's where a general physician who offers home visits can make a difference. Imagine receiving top-notch medical care without leaving the comfort of your home.</p>
<h4>Convenience and Comfort</h4>
<p>A general physician at Kanjhawala home visit provides unparalleled convenience and comfort. No more waiting rooms or long commutes to the doctor's office. Instead, you can relax in familiar surroundings while receiving personalized medical attention.</p>
<h4>Personalized Care</h4>
<p>During a home visit, your general physician at Kanjhawala can focus solely on you, providing undivided attention and tailored care. This personalized approach allows for a deeper understanding of your health needs and concerns.</p>
<h4>Building Trust and Rapport</h4>
<p>Having a general physician come to your home fosters a sense of trust and rapport. The bond formed during home visits can lead to better communication, improved health outcomes, and a stronger doctor-patient relationship.</p>
<h4>Comprehensive Assessments</h4>
<p>General physicians who offer home visits can conduct thorough assessments of your health in the comfort of your own home. From routine check-ups to managing chronic conditions, a home visit ensures that all aspects of your health are addressed.</p>
<h4>Peace of Mind</h4>
<p>Knowing that a general physician is just a phone call away can provide peace of mind, especially during times of illness or medical emergencies. Having access to quality healthcare at home can alleviate stress and ensure prompt medical attention when needed.</p>

        </div>
      </section>


      <section className='fourth-fold pt-3'>

        <div className='container'>

          <div className='row d-flex align-items-center'>


            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>So, say goodbye to long wait times and hello to personalized, convenient healthcare with general physician at-home visits. It's the future of medicine, and it's here to make your life easier and healthier. But wait, there's more! General physician at-home visits are not just for when you're feeling sick. They can also be a great option for routine check-ups, vaccinations, or even managing chronic conditions. It's like having a doctor on call whenever you need them. One of the best doctors' home visit service providers is Kyno Health, which is known for its reliability. Give Kyno Health a chance to serve you. Visit their website to learn more.</p>

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>


      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index