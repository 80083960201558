import axios from "axios";
import React, { useEffect } from "react";
import {backendURL} from "../utilities/constants"

export default function Test(){

    useEffect(() => {
        axios.get(`${backendURL}test`).then((resp) => {
            console.log(resp.data);
        }).catch(err => {
            console.log(err)
        })
    }, [])


    return(
        <>
            <div>
                Hi, this is the test page
            </div>
        </>
    )
}