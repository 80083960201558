import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Schedule an Appointment</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Personalized Care</h4>
<p>Scheduling a doctor's appointment allows you to receive personalized attention tailored to your unique health needs. Our skilled and compassionate medical professionals take the time to listen, understand, and address your concerns comprehensively.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Timely Attention</h4>
<p>Avoid the uncertainty of walk-in clinics. By scheduling an appointment, you ensure that a dedicated medical professional is ready to see you at the designated time, minimizing waiting times and ensuring timely medical attention.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Efficient Service</h4>
<p>Scheduled appointments streamline our services, allowing us to allocate the right resources and medical expertise for your specific requirements. This approach enhances the efficiency and quality of your healthcare experience.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Continuity of Care</h4>
<p>For patients with chronic conditions or ongoing treatments, scheduling appointments ensures consistent follow-up and monitoring, leading to better health outcomes and a stronger doctor-patient relationship.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>How to Schedule an Appointment</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem3'>
<h4>Online Booking</h4>
<p>Visit our user-friendly website and access our online booking portal. Choose your preferred date, time, and healthcare provider based on availability and your convenience.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Phone Call</h4>
<p>Reach out to our dedicated appointment scheduling team via phone. Our friendly staff will assist you in finding a suitable appointment slot and answer any questions you may have.
</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem3'>
<h4>Reduced Risk of Infection</h4>
<p>Especially during times of epidemics or when you're feeling unwell, avoiding crowded medical facilities can lower the risk of exposure to infections.
</p>
</div>
</div> */}

<div className='col-md-4'>
<div className='programitem3'>

<h4>Patient Portal</h4>
<p>For existing patients, our secure patient portal allows you to schedule appointments, view medical records, and communicate with your healthcare provider directly.
</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem3'>

<h4>Family-Focused</h4>
<p>Our home doctor service caters to the entire family, from young children to the elderly. We understand the importance of holistic healthcare for all age groups.

</p>
</div>
</div> */}

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>


<section className='thrd-fold'>
<div className='container'>
<h2>What to Expect</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem3'>
<h4>Confirmation</h4>
<p>Once your appointment is scheduled, you'll receive a confirmation via your preferred communication method – email, text, or phone call.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>
<h4>Reminder</h4>
<p>A friendly reminder will be sent prior to your appointment, ensuring you don't miss your scheduled time.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>
<h4>Check-In</h4>
<p>Arrive a few minutes before your appointment. Our welcoming staff will guide you through the check-in process.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Comprehensive Care</h4>
<p>During your appointment, your healthcare provider will conduct a thorough examination, discuss your medical history, address your concerns, and recommend appropriate treatments or further tests if needed.
</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem3'>

<h4>Family-Focused</h4>
<p>Our home doctor service caters to the entire family, from young children to the elderly. We understand the importance of holistic healthcare for all age groups.

</p>
</div>
</div> */}

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Tips for a Successful Appointment</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Be Prepared</h4>
<p>Make a list of your symptoms, medications, and questions you may have for the doctor.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Bring Records</h4>
<p>If you're a new patient, bring any relevant medical records, test results, or referrals.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Arrive Early</h4>
<p>Arriving a bit early helps ensure a smooth check-in process and prevents unnecessary delays.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Ask Questions</h4>
<p>Don't hesitate to ask your healthcare provider any questions you may have about your health or treatment options.</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will schedule follow-up visits as needed to monitor your progress and make adjustments to your treatment plan if required.</p>
</div>
</div> */}


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Schedule Your Appointment Today
</h5>
<p>Taking charge of your health has never been easier. Schedule a doctor's appointment with [Your Healthcare Center] and experience healthcare that revolves around you. Our commitment to excellence, personalized care, and efficient services ensures that you receive the attention you deserve. Don't wait – book your appointment now and embark on a journey towards better health and well-being.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index