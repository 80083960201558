import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What kind of dressing is good for bed sores?
</Accordion.Header>
                    <Accordion.Body>

                      <p>For bed sores, hydrocolloid and foam dressings are often recommended. Hydrocolloid dressings promote moist healing, while foam dressings offer cushioning and absorbency. However, the choice depends on the sore's stage and characteristics. A healthcare professional can determine the most suitable dressing for effective wound management.
                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is the best dressing for pressure sores?
</Accordion.Header>
                    <Accordion.Body>

                      <p>The optimal dressing for pressure sores depends on the sore's severity. Hydrocolloid or foam dressings are common choices. Hydrocolloids maintain a moist environment, while foams offer cushioning. Consult a healthcare professional to determine the best dressing for your specific pressure sore condition.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>What is the doctor who comes to your house?</Accordion.Header>
                    <Accordion.Body>

                      <p>The doctor who comes to your house is a qualified medical professional from our home visit service. They offer a range of medical services, including consultations, urgent care, prescriptions, and diagnostic tests, all provided at your doorstep. This convenient and personalized healthcare approach ensures you receive expert medical attention in the comfort of your own home. Schedule a visit now for comprehensive and hassle-free medical care.
</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                  


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>What is the meaning of bed sore?
</Accordion.Header>
                    <Accordion.Body>

                    <p>A bed sore, also known as a pressure sore or decubitus ulcer, is a skin and tissue injury that develops due to prolonged pressure on certain body areas, often in people with limited mobility. It can range from mild redness to severe wounds. Preventive measures and proper care are crucial to avoid complications.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What are the types of bed sores?</Accordion.Header>
                    <Accordion.Body>

                      <p>Bed sores come in different stages: Stage 1 (redness), Stage 2 (blister or shallow ulcer), Stage 3 (deeper ulcer), and Stage 4 (extensive tissue damage). Early detection and proper wound care are vital to prevent progression. Consult a healthcare professional for accurate assessment and treatment recommendations.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index