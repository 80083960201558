
import './Css/style.css'

function index() {
  return (
    <>

    <section className='sec_fold_abt'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h3>Return policy</h3>
            <h3>Return</h3>
<p>Product must be returned to us within 30 days from the date it has been delivered to the customer. Product must be returned with all tags attached in its original condition along with all packing material, courier receipt, invoice & other papers.</p>
<h3>Refund</h3>
<p>Once the Product is received to the company successfully, Kyno Health will instantly initiate the refund to your source account or chosen method of refund.</p>
<h3>Refund  and Cancellation for Service Provider Company</h3>
<p>Due to service providers in nature “NO REFUND”,“NO CANCELLATION”  will be entertained once the Payment has been made.</p>
<h3>Cancellation Policy</h3>
<p>Please note an order can only be canceled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.However return is possible for all orders/products.</p>
<p>OR</p>
<p>Please note an order can only be canceled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained. However return is possible for all orders/products.</p>
<p>OR</p>
<p>Customers can CANCEL order only before the Order has been shipped/Dispatched. After the Product/s have been shipped, The Customer CANNOT Cancel the Orders. However return  is possible for all orders/products.</p>
<h3>Shipping &amp; Delivery Policies </h3>
<p>Kyno Health ships its products to almost all parts of India. Orders placed will be shipped within 24* hrs. We ship on all days except Sunday and National Holidays.</p>
<p>For all areas serviced by reputed couriers, the delivery time would be within 3 to 4 business days of shipping (business days exclude Sundays and other holidays). For other areas the products will be shipped through DTDC and may take 1-2 weeks depending on location. At times there might be unexpected delays in the delivery of your order due to unavoidable and undetermined logistics challenges beyond our control for which Kyno Health is not liable and would request its users to cooperate as Kyno Health continuously tries to nought such instances. Also, Kyno Health reserves the right to cancel your order at its sole discretion in cases where it takes longer than usual delivery time or the shipment is physically untraceable and refund the amount paid for cancelled product(s) to your source account.</p>

<h3>Contact Us</h3>
<p><strong>Kyno Health</strong></p>
<p>H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001</p>
<p>01206056623</p>
<p>support@kynohealth.com</p>


          </div>
        </div>
      </div>
    </section>
    
    </>
  )
}

export default index