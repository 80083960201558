import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is a good hair routine for dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A good hair routine for dandruff involves regular brushing, adequate hair cleansing, and weekly moisturizing. Also, consume a healthy diet rich in protein, vitamins, and minerals. You can include food items such as eggs, meat, milk, etc in your diet. Cut down on sugary food items rich in carbohydrates and fats as much as possible."
      }
    },{
      "@type": "Question",
      "name": "How can I get rid of dandruff in 7 days?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you are confused about how to remove dandruff in 7 days, you can switch to a healthy hair routine. Brush your hair regularly, use a clarifying shampoo, and avoid using excessive chemicals on the scalp. Also, refer to a well-renowned hair clinic for expert guidance on dandruff management and prevention."
      }
    },{
      "@type": "Question",
      "name": "How to stop dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Follow the best home remedy tips to get rid of dandruff. You can apply a mask of egg yolk, yogurt, olive oil, green tea, lemon juice, etc., to moisturize your hair and remove dandruff. Also, implement accurate dietary supplements in your diet, such as proteins and biotin to enhance your scalp’s health."
      }
    },{
      "@type": "Question",
      "name": "How can I remove dandruff permanently at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "If you want information on how to remove dandruff permanently at home, you will need to adopt a good care routine for dandruff that involves regular brushing of hair, gentle hair cleansing using a certified anti-dandruff shampoo, and avoiding excessive heat styling. You can also try out medicated shampoos for extra dandruff protection."
      }
    }]
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>The Best Dandruff Fighting Routine: Daily, Weekly, and Monthly Tips | Kyno Health</title>
        <meta name="description" content="Discover the ultimate dandruff-fighting routine with daily, weekly, and monthly tips from Kyno Health. Achieve a healthy scalp and say goodbye to dandruff for good." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/best-dandruff-fighting-routine"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index