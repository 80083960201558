import React from 'react'

function index() {
  return (
    <>
      <section className='ms'>
        <div className='container'>
          <h2>Understanding Seizures</h2>
          <p>Seizures stem from abnormal brain activity, presenting a panicking and often frightening situation for both sufferers and those around them. These episodes occur unexpectedly, ranging from convulsions to brief loss of consciousness. While epilepsy is the most common cause of seizures, they may also result from other factors, including, but not limited to, head injuries and infections.</p>
          <p>Recognizing the signs of a seizure is essential for identification. Common signs range from jerking movements of the arms and legs to stiffening of the body, loss of consciousness, breathing problems or cessation, loss of bowel or bladder control, or sudden falls. Understanding these symptoms allows proactive management of the condition.</p>
          <h2>Dos For Supporting Someone During Seizures</h2>
          <p>When someone around you experiences a seizure, being calm and proactive is incredibly important. With the right strategies, you can provide support to anyone having a seizure episode and, in turn, help them remain safe and calm. We have curated some essential Dos for helping someone experiencing a seizure.</p>
          <ul>
            <li><h5>Remain Calm &amp; Composed</h5> It's natural to feel frightened witnessing a seizure episode. But it's essential to keep your cool during this time. In addition to providing comfort to the person having a seizure, your presence of mind ensures a secure atmosphere for them. Fear can make things worse and cause needless anxiety for the person experiencing the seizure as well as for you.</li>

            <li><h5>Stay with The Person</h5> Remain by the person's side until the seizure stops and the person is completely awake. Once it's over, help the person find a safe place to sit.</li>

            <li><h5>Note the Time</h5> Accurately tracking the duration of a seizure requires noting when it begins. This information can be passed on to the medical personnel to assess the severity of the seizure and choose the best course of action or treatment.</li>

            <li><h5>Remove Dangerous Objects</h5> The next essential thing to do is proactively identify any dangerous objects around the person suffering a seizure and remove them. This may include sharp or heavy objects, as they may put the person at risk of injury.</li>

            <li><h5>Protect the Head</h5> To avoid injuries from hitting hard surfaces during a seizure, gently cushion the person's head. Place a folded jacket or anything similar soft and flat beneath their head. Remove any ties or objects around the neck that might impede breathing.</li>

            <li><h5>Move them to a Sitting or Lying Position</h5> If the person is standing at the time of seizure, move them to a sitting or lying position. This is important to reduce the risk of injuries or falls.</li>

            <li><h5>Place in Recovery Position</h5> Once the seizure ends, turn the affected individual in the recovery position to help them breathe. It involves gently placing the individual on their side. This is important to keep the airways open and prevent the risk of choking on saliva and vomiting.</li>

            <li><h5>Make them Comfortable</h5> After a seizure, a person may feel disoriented. Therefore, you must make them feel safe and comfortable by giving them reassurance. This can help alleviate anxiety and make them feel better.</li>

            <li><h5>Get Medical Attention</h5> It is essential to get immediate medical attention if you do not know how to help the individual, the seizure lasts for more than 5 minutes, or the person has experienced an injury during the episode.</li>
          </ul>

          <h2>Don'ts for Supporting Someone During Seizures</h2>
          <p>In addition to knowing what to do, understanding what not to do is equally important for ensuring the safety and well-being of a person during or after a seizure. Here are essential actions to avoid:</p>
          <ul>
            <li>Don't Restrain the Person: Trying to stop the person from moving or holding them down while they are having a seizure may worsen the situation. Retaining them can worsen the circumstance, cause anxiety, and lead to harm. Let the seizure proceed on its natural course.</li>

            <li>Don't Attempt Mouth-to-Mouth Breathing: Refrain from attempting mouth-to-mouth breathing, or CPR, during or after a seizure episode. Artificial respiration is unnecessary unless the patient exhibits signs of respiratory distress or has difficulty breathing, as most people resume breathing independently after a seizure.</li>

            <li>Don't Put Objects in Their Mouth: When an individual is experiencing a seizure, avoid putting anything in their mouth. Doing so may hurt their jaw, gums, or teeth. There's no need to intervene this way because, contrary to popular belief, a person experiencing a seizure cannot swallow their tongue.</li>

            <li>Don't Give Food or Water Immediately: Wait until the person is entirely conscious of a seizure and can swallow safely before offering food or water. Consuming food or water immediately after a seizure can increase the risk of choking, mainly if the person is still confused or has trouble swallowing.</li>
          </ul>
        </div>

      </section>


      <section className='fourth-fold'>

        <div className='container'>

          <div className='row d-flex align-items-center'>

            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>Ensuring the safety and well-being of the person experiencing a seizure requires an understanding of the Dos and Don'ts when providing support during one. Your efforts aid in creating a safe, encouraging atmosphere that promotes their efficient recuperation. Following these recommendations can help to create a culture of compassion and understanding around seizure management in addition to ensuring the person's  overall well-being. </p>

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>


    </>

  )
}

export default index