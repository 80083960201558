import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Sewcod_Section from './Second_Section/index'
import { Helmet } from 'react-helmet';



function index() {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Return policy</title>
      <meta name="description"
      content="Access quality healthcare with home visit doctors near you. Convenient and personalized medical care in the comfort of your home. Schedule an appointment today." />
      <link rel="canonical" href="https://www.kynohealth.com/doctors-near-me/" />
    </Helmet>
    <Nav/>
    
    <Sewcod_Section/>



    

    <Footer/>

    </>
  )
}

export default index
