import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Comprehensive General Health Check-Up Services</h3>    
    <p className='d-md-none d-lg-none'>At Kyno Health Center, we understand that your health is your most valuable asset. To empower you to take control of your well-being, we offer a state-of-the-art Comprehensive General Health Check-Up service. This thorough examination is designed to assess your overall health, detect potential issues early, and provide personalized recommendations for a healthier life.</p>

<p className='mob-noee'>At Kyno Health Center, we understand that your health is your most valuable asset. To empower you to take control of your well-being, we offer a state-of-the-art Comprehensive General Health Check-Up service. This thorough examination is designed to assess your overall health, detect potential issues early, and provide personalized recommendations for a healthier life.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
