import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians at home in Sarita Vihar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can find the best general physicians at Kyno Health, Sarita Vihar, located in Delhi. Kyno Health should be your first and final choice when it comes to selecting a top-rated home physician. With their best-in-class services, they provide affordable home visit doctors. Additionally, you can avail their services 24 hours a day. This is beneficial for all those who face an emergency at late hours."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and experience should I look for in a general physician providing home services in Sarita Vihar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The minimum qualification that you should look for while selecting a general physician is that they should have a board-certified medical degree like an MBBS, BAMS, or BHMS. Normally, a general physician is trained to treat all kinds of diseases and conditions like fever, injuries, bed sores, etc. Their experience may vary with the number of years they have been in the medical field."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians at home in Sarita Vihar, Delhi, licensed and certified?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, at Kyno Health, we provide you with a licensed and certified general physician near me. The onus is on us so we can assure you that we have selected our doctors after an extensive procedure of checking and verifying their licenses and certifications. With our general physicians, you will be provided with top-notch treatment and that too within the comforts of your home. You would not need to step foot outside to avail of our services."
      }
    },{
      "@type": "Question",
      "name": "What services do the best general physicians at home in Sarita Vihar, Delhi, typically offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our doctors from Kyno Health provide a wide range of services. Firstly, we are available any time of the day and provide immediate help. Our consultations are also quite detailed and on average last for 30 to 35 minutes. We also offer proactive follow-ups till your recovery. Now the services you can avail from us are nursing care, diagnostic support at home, ECG/X-Ray at Home, Ambulance Assistance, Physiotherapy at home, and 24-hour caregivers."
      }
    },{
      "@type": "Question",
      "name": "Can I book an appointment with a general physician at home in Sarita Vihar Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, you can easily book a consultation with us by contacting us at this number- +91-9355618123. Alternatively, you may also email us at support@kynohealth.com with any kind of queries or for assistance. Our customer care is efficient and quick and they will set you up with your first consultation in no time."
      }
    }]
  }
  
  


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Sarita Vihar, Delhi</title>
        <meta name="description" content="Access top-notch medical care in the comfort of your home. Kyno Health offers the best general physicians in Sarita Vihar, Delhi for convenient and comprehensive home visits." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-sarita-vihar-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Sarita Vihar, Delhi" hero_paragraph="We at Kyno Health are an exclusive medical treatment provider that offers you the Best General Physicians at Home in Sarita Vihar, Delhi. We have qualified and reputed general physicians with us who will diagnose and treat you, offering you the best solution for your condition. You would not need to step outside of your home as our doctors will reach you in no time and get you checked. Trust us to make quality medical home care effective and affordable for all,"/>
      
      <Content content_h3="Diseases and Injuries That Can Be Treated by General Physicians at Home" content_p="At Kyno Health, we are dedicated to delivering extensive medical care right to your doorstep. Our team of highly qualified general physicians excels in treating a diverse array of conditions and injuries. From managing fevers and treating injuries to addressing chronic conditions such as bed sores and illnesses requiring IV drips, our doctors treat you as per your specific health needs. Trust Kyno Health for reliable, affordable, and convenient medical home care, prioritizing your well-being and comfort at every step." content_p1="Managing conditions like diabetes, hypertension, asthma, and other chronic ailments also necessitates dedicated and specialized care. A general physician home visit near me is crucial in providing comprehensive support. They will ensure regular monitoring, timely interventions, and personalized treatment plans tailored to individual health needs. This approach includes adjusting medications, offering lifestyle recommendations, and coordinating with specialists as required. The focus remains on improving health outcomes and enhancing quality of life. " 

      content_h3_2="Get Checked by Kyno Health's Top General Physicians at Home" content_p_2="You can, therefore, experience top-notch medical care from Kyno Health's esteemed general physicians. Forget about lengthy lines and hectic hospital visits as we bring medical care closer to you. We have been ensuring you receive thorough evaluations and personalized treatment plans tailored to your needs. So, benefit from our round-the-clock availability and exceptional service today itself in Sarita Vihar, Delhi."/>

      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index