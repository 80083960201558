import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Who is the Best General Physician in Noida?
 </Accordion.Header>
                    <Accordion.Body>

                      <p>Determining the best general physician depends on individual preferences and needs. Research reputable doctors, read patient reviews, and consider specialties. Schedule consultations to assess compatibility and expertise for your specific health requirements. Our clinic in Noida has experienced general physicians ready to provide expert care tailored to your well-being.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How Do I Find the Best Doctor Near Me?</Accordion.Header>
                    <Accordion.Body>

                      <p>To find the best doctor nearby, seek recommendations from friends, research online reviews, and consider specialties that match your needs. Schedule consultations to assess their approach and compatibility with your preferences. Our clinic offers skilled doctors ready to provide personalized care tailored to your health requirements.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                 


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>


                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How Can I Find a General Physician Online Near Me?</Accordion.Header>
                    <Accordion.Body>

                      <p>Finding a general physician online near you is easy. Search for reputable telemedicine platforms or healthcare websites. Enter your location and preferences to browse available doctors. Our online platform connects you with experienced general physicians, providing convenient virtual consultations for personalized medical care from the comfort of your home.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>What Is a Doctor Who Comes to Your House?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>A doctor who comes to your house is a home visit physician. They provide medical care in the comfort of your own home. These experienced professionals offer services ranging from routine check-ups to urgent care, making healthcare accessible and convenient, especially for the elderly, immobile, or those with special needs. Our clinic offers dedicated home visit doctors to address your health needs.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                 


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index