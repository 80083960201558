import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'
import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians who provide home visits in Model Town, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Search the general physicians who offer home visits in Model Town, Delhi by searching online or asking for recommendations from friends or family. Schedule an appointment for a home visit at a time that is convenient for you. Make sure to provide them with your address and any relevant medical history or information they may need beforehand."
      }
    },{
      "@type": "Question",
      "name": "Are there any specific medical conditions that these general physicians specialize in treating at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Some specific conditions and scenarios where general physicians commonly provide home-based care are Hypertension (High Blood Pressure), Diabetes, Asthma and Chronic Obstructive Pulmonary Disease, Heart Disease, Chronic Kidney Disease, and Respiratory Infections."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician in Model Town, Delhi for a home visit?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Choose the physician, contact their office through call online or email, provide the necessary information like personal details, reason for visit, date and time, and confirm the appointment."
      }
    },{
      "@type": "Question",
      "name": "What is the typical range of services offered by general physicians providing home visits in Model Town, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians providing home visits in Model Town, Delhi typically offer a broad range of medical services including General Medical Consultations, Prescriptions and Medication Management, Basic Diagnostics and Testing, Preventive Care, and Minor Procedures."
      }
    },{
      "@type": "Question",
      "name": "How do I ensure the safety and credibility of a general physician conducting home visits in Model Town, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Verify the physician's medical license through the relevant medical board or licensing authority. Ensure the physician has no history of malpractice or disciplinary actions. Verify their specialization and areas of expertise. Obtain references from previous patients or employers. Check online reviews and ratings from credible sources"
      }
    }]
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Model Town | Kyno Health </title>
        <meta name="description" content="Get professional medical care without leaving your home. Kyno Health provides the best general physicians at home in Model Town, offering expert consultations and treatments. Experience quality healthcare services delivered to your doorstep by our dedicated medical team." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-model-town" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

              <h3>Best General Physicians at Home in Model Town</h3>
<h5 className='text-white'>About General Physicians&nbsp;</h5>
<p>In today's fast-paced world, convenience in healthcare has become increasingly important, especially when it comes to the accessibility of medical services. One way to experience convenient health care is through the search for general physician home visits near me, an option that is gaining popularity among individuals who prioritize ease and comfort. This service not only saves precious time but also provides personalized medical attention right in your own home. Kyno Health in Model Town seeks to provide healthcare to all patients in the comfort of their own homes. Understanding the benefits, logistics, and tips on finding reliable general physician home services will help you better navigate this healthcare choice.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>

        <h3>Benefits of General Physician Home Visits in Model Town</h3>
<ul>
<li>The idea of receiving medical care without leaving the comforts of your own home is highly appealing. This is especially significant for those with mobility issues, severe illnesses, or those without straightforward access to transportation.</li>
<li>The physicians can spend more uninterrupted time with you compared to the typical clinic setting. This allows them to better understand your living situation and how it might affect your health.</li>
<li>Managing chronic illnesses such as diabetes, heart disease, or arthritis requires consistent, meticulous care. Home visits by a general physician can make a significant difference in such scenarios</li>
</ul>

<h3>Preparation Tips for a General Physician Home Visit at Model Town</h3>
<ul>
<li>Have all relevant medical documents accessible, including your medical history, list of medications, recent test results, and insurance information.&nbsp;</li>
<li> Before the visit, jot down any symptoms you're experiencing and any questions or concerns you have. This will help you stay on track during the discussion and ensure you cover all important points.</li>
<li> Choose a quiet and comfortable room for the consultation. Ensure the room is well-lit and has seating available both for you and the physician. If relevant, think about privacy and whether you'd like someone with you during the visit for support.</li>
<li> Check with the doctor&rsquo;s office if there's anything specific you need to do before the visit, such as fasting for blood tests. Also, ensure pets are secure and won&rsquo;t interrupt the consultation.</li>
</ul>
         

        </div>
      </section>

      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index