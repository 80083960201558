import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Which doctor is best for fever?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Internal Medicine doctors are often best for fever cases. They specialize in diagnosing and treating a wide range of medical conditions, including fever. If your fever is persistent or accompanied by severe symptoms, consulting a healthcare professional is recommended.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>When should I call the doctor for a fever?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Consider consulting a doctor if your fever is above 102°F (38.9°C), persists for more than three days, is accompanied by severe symptoms, or affects a young child. If you have underlying health conditions, seek medical attention sooner. Prompt medical evaluation is advised for infants, elderly individuals, and those with compromised immune systems.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>How high of a fever is too high?
</Accordion.Header>
                    <Accordion.Body>

                    <p>A fever above 104°F (40°C) is generally considered high and requires medical attention, especially for children. However, any fever above 102°F (38.9°C) in infants, elderly individuals, or those with underlying health conditions warrants prompt medical evaluation.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>When should I call my doctor about a fever?</Accordion.Header>
                    <Accordion.Body>

                      <p>Call your doctor if your fever is accompanied by severe symptoms, lasts more than three days, or is above 102°F (38.9°C). Seek immediate medical attention for a fever above 104°F (40°C) or for infants, elderly individuals, and those with compromised immune systems.
</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index