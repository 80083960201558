import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Navigating High Fever: Practical Tips for Relief and Recovery</h3>    
    <p>A high fever can be a concerning and uncomfortable experience, often signaling an underlying infection or illness. While it's crucial to consult with a healthcare professional for an accurate diagnosis and treatment plan, there are several practical tips you can follow at home to alleviate symptoms and support your body's recovery. In this comprehensive guide, we'll explore various strategies and tips for managing a high fever.</p>


 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
