import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How can I remove dandruff permanently at home?</Accordion.Header>
                    <Accordion.Body>
                      <p>If you wish to get a permanent solution for dandruff at home, you will be required to switch to healthier dietary and hair care habits. Consume a lot of green vegetables, meat, and fish to get your daily dose of protein. Also, you will need to use anti-dandruff shampoos, separate combs, and fewer hair styling tools to keep your scalp free from dandruff. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is the most effective way to stop dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>Using natural remedies such as lemon juice, Aloe vera gel, coconut oil, yogurt, etc for dandruff removal can provide you with amazing results. Also, if you are suffering from severe dandruff issues, you can seek medical assistance for a better cure. </p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                 


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>

              <Accordion.Item eventKey="2">
                    <Accordion.Header>Does hot water cause dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>Hot water is not at all suitable for scalp health! It can lead to inflammation on the scalp, thereby resulting in dandruff. Also, it can pose great damage to the hair follicles by reducing the natural hair oils of the scalp.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Should I oil my hair daily if I have dandruff?</Accordion.Header>
                    <Accordion.Body>

                      <p>Certain hair oils are highly beneficial in combating dandruff by imparting the scalp with moisture. Also, the anti-inflammatory properties in the hair oils can reduce dandruff. However, applying hair oil regularly can make the condition worse by producing oily dandruff.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index