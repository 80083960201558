import React, { useState } from 'react'
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'
import "../../../styles/sales_team_management.scss"
import ReactSwitch from 'react-switch'

export default function Sales_team_management(props) {

    const [salesEmployeeId, setSalesEmployeeId] = useState(props.salesEmployeeId)
    const [salesEmployees, setSalesEmployees] = useState(null)
    const [salesEmployee, setSalesEmployee] = useState(null)



    const fetchAllSalesEmployee = async () => {
        const result = await axios.get(`${localBackend}getAllSalesEmployee?salesEmployeeId=${props.salesEmployeeId}`).then((resp) => {
            // console.log(resp.data)
            setSalesEmployees(resp.data.data)

            var obj = resp.data.data;

            var e = obj.find(o => o._key === props.salesEmployeeId)
            setSalesEmployee(e)
            return resp.data.data
        })
    }


    const handleStatusToggle = async (id, status, key) => {
        
        let s = status == true ? "ACTIVE": "INACTIVE"
        let body = {
            salesHeadId: props.salesEmployeeId,
            salesEmployeeId: key,
            status: s
        }
        const result = await axios.post(`${localBackend}toggleSalesEmployeeStatus`, body).then((resp) => {
            console.log(resp.data)
            if(resp.data.statusCode === 1){

                const updates = [...salesEmployees]

                const toUpdate = updates.find(item => item._id == id)
                if(toUpdate){
                    if(status == false){
                        toUpdate.status = "INACTIVE"
                    }else{
                        toUpdate.status = "ACTIVE"
                    }
                    setSalesEmployees(updates)
                }
            }
        })

    }


    useState(() => {
        fetchAllSalesEmployee()
    }, [])

    return (
        <>
            <div className='sales-team-management'>
                {salesEmployees !== null && salesEmployee !== null && salesEmployee.position === "SALES_HEAD" ?
                    <div className='sales-employees'>

                        <div className='headers'>
                            <div>Name</div>
                            <div>Position</div>
                            <div>Phone</div>
                            <div>Status</div>
                            <div>Toogle Status</div>
                        </div>
                        {salesEmployees.map((se, index) => {

                            var employeeStatus = se.status == "ACTIVE"

                            return (
                                <div key={index} className='sales-employee'>
                                    <div>{se.name}</div>
                                    <div>{se.position}</div>
                                    <div>{se.phone}</div>
                                    <div className='status'>
                                        <span className={se.status === "ACTIVE" ? "active" : ""}>
                                            {se.status}
                                        </span>
                                    </div>
                                    <div className='status-toggle'>
                                        <ReactSwitch checked={se.status == "ACTIVE"} onChange={(e) => {
                                            // console.log("changeing state: ", e)
                                            handleStatusToggle(se._id, e, se._key)
                                            // employeeStatus = e
                                        }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div>You Are Not Allowed For This Action</div>
                }
            </div>
        </>
    )
}
