import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Frequently Asked Questions (FAQs) About Fever Tips</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is considered a high fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>A body temperature of 100.4°F (38°C) or higher is generally considered a high fever. It is a common symptom of various infections and illnesses.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Why is staying hydrated important during a fever?</Accordion.Header>
                    <Accordion.Body>

                      <p>Fever, sweating, and increased respiratory rate can lead to dehydration. Staying hydrated helps replenish fluids lost during the illness and supports the body's overall recovery.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Can I use both acetaminophen and ibuprofen to reduce fever?</Accordion.Header>
                    <Accordion.Body>

                      <p>While it's generally safe to use acetaminophen and ibuprofen alternately, it's advisable to consult with a healthcare professional to determine the appropriate dosage and frequency based on individual health conditions.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What are some natural remedies for fever relief?</Accordion.Header>
                    <Accordion.Body>

                      <p>Hydrating foods, herbal teas (such as peppermint or chamomile), and warm beverages with honey and lemon are some natural remedies that may provide additional relief during a fever.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How often should I monitor my body temperature during a fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>The frequency of temperature monitoring may vary, but it's generally advisable to check your body temperature regularly, especially when using fever-reducing medications. Keep a record to share with your healthcare provider if needed.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Is it safe to take a cool bath during a fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>A lukewarm bath or sponge bath can be effective in reducing body temperature. However, avoid using cold water, as it may cause shivering, which can raise body temperature.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>When should I seek medical attention for a fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>Seek medical attention if the fever persists for more than a few days, reaches a high temperature, or is accompanied by symptoms like difficulty breathing, severe headache, persistent vomiting, or a rash.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can I continue to work or go to school with a fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>It's generally advisable to rest and avoid strenuous activities when you have a fever. Going to work or school may not only hinder your recovery but also risk spreading the illness to others.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What precautions should I take to prevent dehydration during a fever?</Accordion.Header>
                    <Accordion.Body>
                      <p>Drink plenty of water, clear broths, and electrolyte-rich beverages. Small, frequent sips may be more manageable if drinking large amounts is challenging.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Can a fever be a sign of a serious underlying condition?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, a fever can be a symptom of various infections or illnesses, including more serious conditions. If you are concerned about your fever or if it is accompanied by other worrisome symptoms, consult with a healthcare professional for a proper diagnosis and treatment plan.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index