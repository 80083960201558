import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme =   {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I permanently get rid of dandruff on my scalp naturally?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Besides trying out natural dandruff remedies, you will need to implement certain changes in your hair care routine to permanently get rid of dandruff, such as using dandruff-free shampoos, using your separate hair comb, keeping the scalp clean, covering your hair while traveling out, etc."
      }
    },{
      "@type": "Question",
      "name": "How can I make my scalp healthy and dandruff-free?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Follow the accurate dietary habits to combat dandruff. Eat a diet rich in protein and vitamins such as biotin, Zinc, Copper, etc. You can also try out home remedies to make your scalp dandruff-free such as using green tea, hung curd, honey, basil leaves, apple cider vinegar, etc as they are some of the best home remedy for dandruff."
      }
    },{
      "@type": "Question",
      "name": "How can I regrow my dandruff hair?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To make your hair regrow after dandruff, you can switch to following a proper dietary habit comprising food items such as meat, dish, egg, tomatoes, basil, lemon, etc. Also, consult a renowned hair specialist and follow certain dandruff home remedies to enhance hair growth. Consulting a specialist will help you understand the complexities of your scalp condition better."
      }
    },{
      "@type": "Question",
      "name": "How can I clean my scalp naturally at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can naturally and effortlessly clean up your scalp at home using natural dandruff remedies. Use egg yolk, neem juice, lemon juice, etc., to make your scalp completely free of dandruff. Use these remedies twice a week for visible results."
      }
    }]
  }
  
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Top Home Remedies for Dandruff: Natural Scalp Health Solutions | Kyno Health</title>
        <meta name="description" content="Discover effective home remedies for dandruff to restore scalp health naturally. Kyno Health offers simple yet powerful solutions for a healthier scalp." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/top-home-remedies-for-dandruff"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index