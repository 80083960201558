import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is home wound dressing, and who can benefit from it?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Home wound dressing involves professional healthcare providers administering wound care services in the comfort of your home. It benefits individuals with various types of wounds, including post-surgical wounds, chronic wounds, minor injuries, pressure ulcers, and more.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Why choose home wound dressing over clinic-based wound care?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Home wound dressing offers the convenience of receiving specialized wound care in the familiar and comfortable environment of your home. It eliminates the need to travel to a healthcare facility, providing personalized care and promoting optimal healing.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header> What types of wounds can be treated through home wound dressing services?

</Accordion.Header>
                    <Accordion.Body>
                      <p>Home wound dressing services cover a wide range of wounds, including post-surgical wounds, chronic wounds associated with conditions like diabetes, minor injuries (cuts, abrasions, burns), pressure ulcers, and incisions or lacerations that require careful attention.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How does the initial assessment for home wound dressing work?</Accordion.Header>
                    <Accordion.Body>
                    <p>The initial assessment involves a healthcare professional evaluating the type, size, and stage of your wound. They will also review any specific care instructions provided by your healthcare provider to create a customized care plan.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How often will I need home wound dressing appointments?
</Accordion.Header>
                    <Accordion.Body>
                      <p>The frequency of home wound dressing appointments depends on the type and stage of your wound. Our healthcare professionals will create a personalized care plan outlining the recommended frequency of dressing changes, typically based on your healthcare provider's recommendations.
</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Can I schedule home wound dressing appointments at times convenient for me?</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, our flexible scheduling options are designed to accommodate your routine. We understand the importance of consistency in wound care and will work with you to schedule appointments at times that suit your schedule.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Are the healthcare professionals providing home wound dressing services qualified and trained?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, our healthcare professionals are qualified and highly trained in wound care. They bring their expertise to your doorstep, ensuring the safe and effective administration of wound dressing changes.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>What supplies are needed for home wound dressing, and will I need to purchase them?</Accordion.Header>
                    <Accordion.Body>
                      <p>Our healthcare professionals will bring all the necessary supplies for home wound dressing, including sterile dressings, bandages, and any specialized materials required for your specific wound. You won't need to purchase any supplies.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header> Is home wound dressing covered by insurance?</Accordion.Header>
                    <Accordion.Body>
                      <p>Coverage for home wound dressing services may vary depending on your insurance plan. It's recommended to check with your insurance provider to understand the extent of coverage and any associated costs. Our team can assist in verifying insurance coverage.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Can individuals with chronic wounds, such as those related to diabetes, benefit from home wound dressing services?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, individuals with chronic wounds can benefit significantly from home wound dressing services. Our healthcare professionals are experienced in managing chronic wounds to prevent complications and promote optimal healing.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>Is home wound dressing suitable for post-surgical wounds?

</Accordion.Header>
                    <Accordion.Body>
                      <p>Absolutely. Home wound dressing services are well-suited for post-surgical wounds, providing the necessary care to promote proper healing and prevent infections. Our healthcare professionals follow your healthcare provider's recommendations for post-surgical wound care.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="11">
                    <Accordion.Header> How is communication maintained with my healthcare provider during home wound dressing services?

</Accordion.Header>
                    <Accordion.Body>
                      <p>We maintain open communication with your healthcare provider to ensure that the wound care plan aligns with their recommendations. This collaborative approach ensures that your care is comprehensive and aligned with the overall treatment plan.</p>
<p>If you have additional questions or concerns about home wound dressing services, feel free to contact our healthcare team. We are here to provide support, answer your questions, and ensure a positive and personalized experience with home wound care.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index