import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>The Importance of Scheduling and Attending Doctor Appointments</h3>    
    <p>In our busy lives, filled with work commitments, social obligations, and personal responsibilities, taking the time for a doctor appointment might seem like a minor inconvenience. However, these appointments are not just routine check-ups; they are a vital component of proactive healthcare.</p>
<p>In this blog post, we'll delve into the significance of scheduling and attending regular doctor appointments, exploring the benefits, addressing common concerns, and emphasizing the role these visits play in maintaining overall well-being.</p>



 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
