import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Faqs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How do you search for a good doctor?</Accordion.Header>
                    <Accordion.Body>
                      <p>Start your research by looking up reliable doctors in your area. Then, ask for recommendations from your family and friends. You can check the reviews of potential doctors and verify their credentials before making an appointment with them.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Can a doctor put someone in a nursing home?</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, a doctor can recommend a nursing home for a patient if they think it's the best option for their medical needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>
              
                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="2">
                    <Accordion.Header>What does doctor recommended mean?</Accordion.Header>
                    <Accordion.Body>

                      <p>“Doctor recommended' simply means that a physician has suggested a particular treatment, medication, or healthcare product. These recommendations are based on their professional expertise and assessment of the patient's needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index