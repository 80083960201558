import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Choose a Full Body Health Check-Up?</h2>
<p>A Full Body Health Check-Up is a comprehensive and systematic approach to assess the health of all major organ systems and detect potential issues at an early stage. Even in the absence of apparent symptoms, this holistic examination allows for the identification of risk factors and promotes early intervention, leading to better health outcomes.</p>

<div className='ml-70'>
<div className='row'>
<h3>Key Components of Our Full Body Health Check-Up</h3>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Detailed Physical Examination</h4>
<p>Our skilled healthcare professionals conduct a meticulous physical examination, evaluating vital signs such as blood pressure, heart rate, respiratory rate, and body mass index (BMI). This forms the foundation of understanding your overall health.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Comprehensive Blood Tests</h4>
<p>A wide-ranging panel of blood tests is conducted to assess various aspects of your health, including lipid profile, blood sugar levels, liver function, kidney function, complete blood count (CBC), and markers for inflammation and infection.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Imaging Studies</h4>
<p>State-of-the-art imaging technologies, including X-rays and ultrasound, provide detailed insights into the condition of your internal organs, bones, and soft tissues, helping identify potential abnormalities.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Cardiac Evaluations</h4>
<p>Electrocardiogram (ECG) and other cardiac assessments are performed to evaluate the health of your heart, detecting any signs of cardiovascular issues.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Reduced Anxiety</h4>
<p>For individuals who may experience anxiety related to medical settings, receiving injections at home can significantly reduce stress and create a more positive and relaxed experience.

</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Cancer Screenings</h4>
<p>Tailored screenings based on age, gender, and risk factors may include mammography, Pap smear, prostate-specific antigen (PSA) tests, and other cancer screenings to detect malignancies at early, more treatable stages.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Vision and Hearing Tests</h4>
<p>Comprehensive vision and hearing tests are conducted to assess your sensory health and identify any issues that may require further attention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Nutritional Assessment</h4>
<p>Our healthcare professionals provide personalized guidance on your nutritional habits, offering recommendations for a balanced diet that supports your overall health.

</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Lifestyle Counseling</h4>
<p>Receive personalized advice on maintaining a healthy lifestyle, including exercise, stress management, and sleep hygiene. Our experts work with you to create a plan that fits your individual needs.</p>
</div>
</div>

</div>
</div>
</div>

</section>


{/* <section className='white-bg'>
<div className='container'>
<h2>Benefits of a Full Body Health Check-Up</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<p><strong>Our Home Injection Services cover a wide range of injections, including but not limited to:</strong>
</p>
<ul>
  <li><strong>Insulin Injections:</strong> For individuals managing diabetes, receiving insulin injections at home allows for better blood sugar control.</li>
  <li><strong>Biologic Therapies:</strong> Patients undergoing treatment with biologic medications, such as those for autoimmune conditions, can receive their injections at home, promoting adherence to the prescribed treatment plan.</li>
  <li><strong>Hormone Injections:</strong> Individuals receiving hormone therapy, such as fertility treatments or hormone replacement therapy, can benefit from the convenience of home injections.</li>
  <li><strong>Vitamin and Nutrient Injections:</strong> Some individuals may require regular injections of vitamins or nutrients, and our Home Injection Services can accommodate these needs.</li>
  <li><strong>Anticoagulant Injections:</strong> Patients prescribed anticoagulant medications for conditions like deep vein thrombosis (DVT) can receive their injections at home, ensuring proper and timely administration.</li>
</ul>
</div>
</div>
</div>
</div>

</section> */}

<section className='white-bg'>
<div className='container'>
  <h2>Benefits of a Full Body Health Check-Up</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Early Detection of Health Issues</h4>
<p>Detect potential health problems early, enabling timely intervention and more effective treatment.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Comprehensive Health Assessment</h4>
<p>A full body health check-up provides a comprehensive overview of your health status, covering multiple organ systems and risk factors.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Personalized Health Plan</h4>
<p>Receive personalized recommendations to improve your overall health and prevent future risks based on the results of your comprehensive assessment.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Peace of Mind</h4>
<p>Regular full body health check-ups offer peace of mind, knowing that you are actively managing your health and well-being.
</p>
</div>
</div>
</div>
</div>
</div>
</section>

<section className='third-fold'>
<div className='container'>
<h2>How to Schedule Your Full Body Health Check-Up</h2>
 
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<p>Scheduling your Comprehensive Full Body Health Check-Up at Kyno Health Center is easy. Contact our friendly and knowledgeable staff to book an appointment at your convenience. We prioritize your comfort and privacy, ensuring a seamless and confidential experience throughout the assessment.</p>
<p>
Invest in your health today to enjoy a more vibrant and healthier tomorrow. At Kyno Health Center, we are dedicated to being your partner in wellness. Take the first step towards optimal health with our Comprehensive Full Body Health Check-Up service.
</p>
</div>
</div>
</div>
</div>
</section>




<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index