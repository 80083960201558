import React from 'react'
import Nav from '../Nav/index'
// import {Link} from "react-router-dom"
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What are the benefits of home visit doctor services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are multiple benefits associated with getting your treatment or check-up done by Home Visit Doctors, such as increased convenience, personalized medical attention, expert support for chronic conditions, etc."
      }
    },{
      "@type": "Question",
      "name": "What is the purpose of a home visit?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A home visit allows the Home Visit Doctors to create a better bond with the patient in a familiar environment by acquiring their medical history. It readily reduces the defaulter rate and increases medical compliance."
      }
    },{
      "@type": "Question",
      "name": "What is the use of health care services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Healthcare services are associated with improving a patient's health by diagnosing, treating, and preventing all sorts of physical complications such as diseases, injuries, etc."
      }
    },{
      "@type": "Question",
      "name": "What is the main function of the healthcare system?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The primary aim of the healthcare system is to promote activities associated with maintaining and improving health. It also refers to the promotion of health-determinant effects."
      }
    }]
  }
  
  return (
    <>
    <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Top 10 Benefits of Home Visit Doctors | Kyno Health</title>
                    <meta name="description" content="Discover the top 10 advantages of home visit doctors with Kyno Health. Here's a brief overview of the benefits associated with calling a home visit doctor." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/blog/top-10-benefits-home-visit-doctors" />

            </Helmet>

    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}





export default index