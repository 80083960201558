import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Should I go to the doctor for vomiting?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Schedule a consultation with your healthcare provider if: Vomiting persists for more than two days in adults, 24 hours in children under age 2, or 12 hours in infants. If you've been experiencing bouts of nausea and vomiting for an extended period exceeding one month, seeking medical attention is advisable.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What can the doctor do for vomiting?</Accordion.Header>
                    <Accordion.Body>

                      <p>Your healthcare provider may administer an injectable antiemetic (anti-nausea medication) to alleviate vomiting, facilitating the subsequent use of a prescription antiemetic by mouth once you return home. This approach supports rehydration, preventing the development of dehydration and electrolyte imbalances that could lead to severe systemic issues.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>When should I call the doctor after vomiting?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Call your doctor if vomiting persists for over 24 hours (adults), 12 hours (children), or 6 hours (infants). Seek immediate medical attention for signs of severe dehydration, blood in vomit, severe abdominal pain, high fever, confusion, or difficulty breathing. Pregnant individuals with persistent vomiting or those unable to keep down fluids should contact their healthcare provider.</p>

                      <p>If you've recently had surgery or have underlying medical conditions, prompt consultation is advised. When in doubt or concerned about symptoms, always reach out to your doctor for personalized guidance.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How is dizziness diagnosed by these doctors?</Accordion.Header>
                    <Accordion.Body>
                    <p>Diagnosis involves a thorough medical history review, physical examination, vestibular testing, imaging studies, and, in some cases, blood tests to identify the underlying cause.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                 

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
           
              <Accordion.Item eventKey="4">
                    <Accordion.Header>Can lemon stop vomiting?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lemon may help alleviate nausea and vomiting for some individuals. Its scent and flavor can have a calming effect, and the acidity may neutralize stomach acids. However, it may not be effective for everyone, and citrus can sometimes worsen symptoms for those with acid reflux.</p>
                  <p>
                  It's essential to consult with a healthcare professional for persistent vomiting, as they can determine the underlying cause and recommend appropriate treatments.
                  </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>What is the fastest way to cure vomiting?

</Accordion.Header>
                    <Accordion.Body>

                      <p>The fastest way to alleviate vomiting depends on the cause. In general, stay hydrated with small sips of clear fluids, like water or electrolyte drinks. Gradually reintroduce bland foods. Over-the-counter antiemetic medications can help, but consult a doctor first.</p>
<p>
If vomiting is severe, persistent, or accompanied by concerning symptoms, seek medical attention promptly. Individual cases vary, and a healthcare professional can provide personalized advice based on the underlying cause.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>What fruits to eat after vomiting?
</Accordion.Header>
                    <Accordion.Body>
                      <p>After vomiting, opt for easily digestible and mild fruits to help replenish nutrients. Bananas are gentle on the stomach and provide potassium. Applesauce is another good choice. Choose ripe, peeled, and sliced apples. Other suitable options include melons (like watermelon), peaches, and pears.</p>
<p>
Avoid acidic or citrus fruits immediately after vomiting, as they may irritate the stomach. It's essential to reintroduce foods gradually and listen to your body's response. If symptoms persist, consult with a healthcare professional.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index