import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from "react-helmet";

function index() {
  return (
    <>
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Professional Bed Sore Dressing at Home | Expert Wound Care Services</title>
                    <meta name="description" content="Experience specialized bedsore dressing at home for effective wound care. Our professionals provide compassionate treatment in the comfort of your space." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/treatment-for-bed-sore"/>

            </Helmet>
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}

export default index