import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
       <section className='thrd-fold'>
<div className='container'>
<h2>Why Kyno?</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem'>
<img src='images/excellence.svg' alt='excellence' className='' />
<h4>Excellence Assured</h4>
<p>Expert doctors with proven experience, best quality medical supplies </p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem'>
<img src='images/Equipped.svg' alt='equipped' className='' />
<h4>Equipped & Ready</h4>
<p>We come prepared with emergency medical kits</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem'>
<img src='images/consultation.svg' alt='consultation' className='' />
<h4>Detailed Consultation</h4>
<p>Our doctors take time to answer your questions to your satisfaction</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem'>
<img src='images/follow.svg' alt='follow' className='' />
<h4>Follow-up Assistance</h4>
<p>Get free of cost post-consultation support till your full recovery</p>
</div>
</div>


</div>
</div></div>

</section>
    </>

  )
}

export default index