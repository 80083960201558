import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is an IV drip, and how does it work?
</Accordion.Header>
                    <Accordion.Body>
                      <p>An IV (intravenous) drip is a method of delivering fluids, vitamins, minerals, and other therapeutic substances directly into the bloodstream through a vein. It typically involves a small catheter and a bag of solution. In the context of in-home IV drip services, this process is administered in the comfort of your home by a healthcare professional.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the benefits of receiving an IV drip at home?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Receiving an IV drip at home offers convenience, comfort, and personalized wellness. It eliminates the need to travel to a healthcare facility, allows for a more relaxed environment, and provides a customized approach to address specific health and wellness goals.

</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header> What types of IV drip formulas are available for in-home services?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Various IV drip formulas are available to address different health needs. Common formulas include hydration boost, energy revitalization, immune support, recovery and detox, and beauty and anti-aging. These formulas are customized based on individual health goals and concerns.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How do I know which IV drip formula is right for me?
</Accordion.Header>
                    <Accordion.Body>
                    <p>During a consultation with our healthcare professionals, your health goals, concerns, and preferences will be discussed. Based on this information, a customized plan will be created, recommending the most suitable IV drip formula to address your specific needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Can anyone receive in-home IV drip services?
</Accordion.Header>
                    <Accordion.Body>
                      <p>In-home IV drip services are suitable for many individuals, but eligibility depends on individual health conditions and needs. During the consultation, our healthcare professionals will assess your suitability for the service and recommend an appropriate plan.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>How long does an in-home IV drip session take?</Accordion.Header>
                    <Accordion.Body>

                      <p>The duration of an in-home IV drip session can vary depending on the specific formula and individual needs. On average, sessions may take 30 minutes to an hour. Our healthcare professionals will provide a more accurate estimate during the consultation.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Is the administration of an IV drip at home safe?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, the administration of an IV drip at home by our skilled healthcare professionals is safe. They are trained to ensure the proper placement of the catheter, monitor the infusion process, and address any concerns or questions you may have during the session.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Do I need any special equipment at home for the IV drip session?</Accordion.Header>
                    <Accordion.Body>
                      <p>Our healthcare professionals will bring all the necessary supplies for home wound dressing, including sterile dressings, bandages, and any specialized materials required for your specific wound. You won't need to purchase any supplies.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Can I receive in-home IV drip services if I have a medical condition or take medications?
</Accordion.Header>
                    <Accordion.Body>
                      <p>In many cases, individuals with medical conditions or those taking medications can still receive in-home IV drip services. However, it is crucial to inform our healthcare professionals about your medical history and current medications during the consultation to ensure the service is safe and tailored to your needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How do I schedule an in-home IV drip session?
</Accordion.Header>
                    <Accordion.Body>
                      <p>To schedule an in-home IV drip session, contact our healthcare team to arrange a consultation. During the consultation, we will discuss your health goals and preferences, create a personalized plan, and schedule appointments at times that are convenient for you.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="10">
                    <Accordion.Header>Are in-home IV drip services covered by insurance?

</Accordion.Header>
                    <Accordion.Body>
                      <p>Coverage for in-home IV drip services may vary depending on your insurance plan. It's recommended to check with your insurance provider to understand the extent of coverage and any associated costs. Our team can assist in verifying insurance coverage.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>Can I receive in-home IV drip services for ongoing wellness, or is it only for specific conditions?</Accordion.Header>
                    <Accordion.Body>
                      <p>In-home IV drip services can be used for both specific health conditions and ongoing wellness. Whether you're seeking relief from a particular symptom or aiming to enhance your overall well-being, our healthcare professionals can customize IV drip formulas to meet your individual goals.</p>
<p>If you have additional questions or concerns about in-home IV drip services, please feel free to reach out to our healthcare team. We are here to provide support, answer your questions, and ensure a positive and personalized experience with in-home IV drip therapy.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index