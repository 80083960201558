import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is hypertension, and how is it defined?</Accordion.Header>
                    <Accordion.Body>
                      <p>Hypertension, or high blood pressure, is a medical condition characterized by elevated force of blood against the walls of the arteries. It is typically defined as having a blood pressure reading of 130/80 mm Hg or higher.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Why is it important to seek a doctor's help for hypertension?</Accordion.Header>
                    <Accordion.Body>

                      <p>Consulting a doctor for hypertension is crucial because high blood pressure can lead to serious health issues, including heart disease, stroke, and kidney problems. A doctor can provide proper evaluation, treatment, and guidance to manage your condition effectively.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>When should I see a doctor for hypertension management?
</Accordion.Header>
                    <Accordion.Body>
                      <p>You should see a doctor if you have high blood pressure or risk factors for hypertension, such as a family history of the condition. Regular check-ups are also important for monitoring your blood pressure and assessing your overall cardiovascular health.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What can I expect during a visit to a doctor for hypertension management?</Accordion.Header>
                    <Accordion.Body>
                    <p>During your visit, the doctor will measure your blood pressure, review your medical history, discuss your lifestyle, and assess potential risk factors. They will create a personalized treatment plan, which may include medication, lifestyle changes, or both.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Can I manage hypertension through lifestyle changes alone, or will I need medication?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lifestyle modifications, including a heart-healthy diet, regular exercise, stress management, and weight control, can help manage hypertension. However, some individuals may require medication to achieve optimal blood pressure control.
</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What lifestyle changes are most effective in managing hypertension?</Accordion.Header>
                    <Accordion.Body>

                      <p>The most effective lifestyle changes include adopting a heart-healthy diet low in sodium and saturated fats, engaging in regular physical activity, reducing stress, maintaining a healthy weight, and limiting alcohol consumption.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Are there risk factors for developing hypertension that I should be aware of?</Accordion.Header>
                    <Accordion.Body>
                      <p>Risk factors for hypertension include a family history of high blood pressure, obesity, a sedentary lifestyle, excessive sodium intake, smoking, excessive alcohol consumption, and chronic stress.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can children and young adults develop hypertension?</Accordion.Header>
                    <Accordion.Body>
                      <p>While less common, children and young adults can develop hypertension, which may be related to underlying medical conditions or lifestyle factors. Regular check-ups are important for early detection and management.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What are the potential complications of uncontrolled hypertension?</Accordion.Header>
                    <Accordion.Body>
                      <p>Uncontrolled hypertension can lead to heart disease, stroke, kidney problems, vision issues, sexual dysfunction, and other serious health concerns. Managing hypertension is essential to reduce these risks.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Can I discontinue my hypertension medication once my blood pressure is under control?</Accordion.Header>
                    <Accordion.Body>
                      <p>Decisions about medication should only be made under the guidance of your doctor. It's crucial to maintain blood pressure control to prevent complications. Changes to your treatment plan should be discussed with your healthcare provider.</p>
                      <p>Regular blood pressure monitoring, lifestyle changes, and medical treatment when necessary are vital for maintaining good heart health and overall well-being. If you have concerns or questions about hypertension, consult a doctor for guidance and a personalized management plan.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index