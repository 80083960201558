import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Who are the doctors providing the medical services? </Accordion.Header>
                    <Accordion.Body>

                      <p>Our team consists of highly-qualified General Physicians with a wide range of experience in top medical institutions. 
                         Meet Our Team
                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How long will the home consultation last?</Accordion.Header>
                    <Accordion.Body>

                      <p>Our doctors dedicate time to provide great treatment and answer all your questions. Our average consultations last 25-30 minutes.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>What are the consultation charges for a doctor at home?</Accordion.Header>
                    <Accordion.Body>

                      <p>Scheduled at-home consultation charges are ₹999 per visit. The charges may be slightly higher during the night or for immediate emergency home visits. </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I request a visit by the same doctor I consulted before?</Accordion.Header>
                    <Accordion.Body>

                    <p>Certainly! Our aim is to provide you with the comfort & reliability of a 'family doctor'.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>


                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Can I contact or follow up with the doctor after consultation?</Accordion.Header>
                    <Accordion.Body>

                      <p>Sure. Our team will proactively follow up with you. You may also reach out to your doctor for any queries after your consultation, free of charge.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index