import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../utilities/images/logo.png'
import '../../styles/navbar.css'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
// import { Helmet } from 'react-helmet'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { HashLink } from 'react-router-hash-link'
import { backendURL, localBackend } from '../../utilities/constants'
// import AnchorLink from "react-anchor-link-smooth-scroll"

// toast.configure()
const Navbar = (props) => {

    const navigate = useNavigate()

    const [name, setName] = useState("")
    const [phone, setPhone] = useState(0)
    const [concern, setConcern] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [width, setWidth] = useState(window.innerWidth)

    const [failedBookingMsg, setFailedBookingMsg] = useState("")
    const [showFailedBookingMsg, setShowFailedBookingMsg] = useState(false)

    const onLeadGenerated = async () => {
        console.log(name, phone, props.Source)

        let body = {
            "name": name,
            "phone": phone,
            "concern": concern,
            "source": props.Source
        }
        const result = false;
        await axios.post(`${localBackend}createLead`, body, { headers: { "Content-Type": "application/json" } }).then((resp) => {
            console.log(resp)
            if (resp && resp.data.statusCode === 1) {
                navigate("/bookingConfirmed")
                result = true

            }
            else {
                const elem = document.getElementsByClassName("modal")[0];
                elem.blur()
                setFailedMsg("Something went wrong!!")
            }
        }).catch((err) => {
            console.log(err)
            const elem = document.getElementsByClassName("modal")[0];
            elem.blur()
            setFailedMsg("Something went wrong!!")

        })

        // await axios.get("https://api.agify.io?name=michael").then((resp) => {
        //     console.log(resp)
        // }).catch((err) => {
        //     console.log(err)
        // })

        return result
    }


    const setFailedMsg = (msg) => {
        setShowFailedBookingMsg(true);
        setFailedBookingMsg(msg);
        setTimeout(() => {
            setShowFailedBookingMsg(false)
        }, 2000);
    }


    const gtag_report_conversion = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };

        gtag('event', 'conversion', {
            'send_to': 'AW-11084448289/lT1OCOWLhpEYEKGEvaUp',
            'event_callback': callback
        });

        return false;

    }
    function gtag() {
        window.dataLayer.push(arguments);
    }

    const validatePhone = (phoneNo) => {
        var plen = Math.log(phoneNo) * Math.LOG10E + 1 | 0;
        if (plen != 10) {
            setPhoneErr("Enter a 10-digit number")
            return "LENGTH-ERR"
        }

        var msb = Math.trunc(phoneNo / 1000000000)

        if (msb < 6 || msb > 9) {
            setPhoneErr("Phone Number Invalid")
            return "MSB-ERR"
        }

        setPhoneErr("")
        return "TRUE"
    }

    const submitLead = async () => {

        const vr = validatePhone(phone)

        if (vr === "TRUE") {
            const result = await onLeadGenerated()
            console.log(result)
            gtag_report_conversion(window.location)
            if (!result) {
                return false
            }
        }
    }

    const openInNewTab = (url) => {
        window.open(url, '_black', 'noreferrrer')
    }

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        gtag('js', new Date());
        gtag('config', 'AW-11084448289')

    }, [])

    return (

        <HelmetProvider>
            <div className='nbar'>
                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                </Helmet>
                <div className='logo' onClick={() => {
                    navigate("/")
                }}>
                    <img src={logo} alt="logo" />
                </div>

                <div className='nbar-tabs'>
                    <div>
                        <HashLink to="/#ourServices">

                            Our Services
                        </HashLink>
                    </div>
                    <div>
                        <HashLink to="/#aboutUs">
                            About Us
                        </HashLink>
                    </div>
                    {/* <div className='nbar-tab-3' onClick={() => {
                    // const navigate = useNavigate()
                    navigate("/bookAppointment")
                }}>Contact Us</div> */}
                    {/* {props.isCallback !== undefined && props.isCallback == true && <div  className='request-callback' onClick={(e) =>  {props.requestCallback()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16px"  height="16px" fill="#067c69"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg> Request Back
                    </div>} */}
                    {props.isCallBtn === true &&
                        <div className='request-callback' onClick={(e) => {
                            openInNewTab(`tel:+917996643888`)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16px"  height="16px" fill="#067c69"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z" /></svg>
                            Call Us
                        </div>}
                    <Popup trigger={
                        <div>Book Appointment</div>
                    } modal nested >{
                            (close) => (
                                <div className='modal'>


                                    <div className='content'>
                                        <div className='title'>Book Appointment</div>
                                        <div className='name-input'>
                                            <div>Name</div>
                                            <input type="text" onChange={(e) => {
                                                setName(e.target.value)
                                            }} />
                                        </div>
                                        <div className='phone-input'>
                                            <div>Phone Number</div>
                                            <input type="number" className={phoneErr !== "" ? "err-active" : "err-inactive"} onChange={(e) => {
                                                setPhone(e.target.value)
                                            }} />
                                            {phoneErr !== "" &&
                                                <div className='phone-err'>
                                                    {phoneErr}
                                                </div>
                                            }
                                        </div>
                                        <div className='concern-input'>
                                            <div>Concern</div>
                                            <input type="text" onChange={(e) => {
                                                setConcern(e.target.value)
                                            }} />
                                        </div>
                                        {/* <div className='time-input'>
                                        <div>Expected Time</div>
                                        <input type="time" onChange={(e) => {
                                            setTime(e.target.value)
                                        }} />
                                    </div> */}
                                        <div className='submit' onClick={() => {
                                            const res = submitLead()
                                            if (res === false) {
                                                close()
                                            }
                                        }}>Submit</div>

                                    </div>
                                    {/* <div>
                                    <button onClick=
                                        {() => close()}>
                                        Close modal
                                    </button>
                                </div> */}
                                </div>
                            )
                        }</Popup>

                </div>

                {showFailedBookingMsg &&
                    <div className='failed-booking-message'>
                        {failedBookingMsg}
                    </div>
                }
            </div>
        </HelmetProvider>
    )
}

export default Navbar;