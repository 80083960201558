import React from "react";
import Nav from "../Nav/index";
import Footer from "../Footer/index";
import Hero from "./Hero/index";
import Content from "./Content/index";
import Faqs from "./Faqs/index";
import { Helmet } from "react-helmet";

function index() {
  return (
    <>
<Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Doctor on Call Home Visit | Kyno Health</title>
                    <meta name="description" content="Get expert medical care at your doorstep with Kyno Health's doctor on call home visit service. Experienced doctors, prompt response, and personalized care for your health needs." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/doctor-on-call-home-visit"/>

            </Helmet>
      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  );
}

export default index;
