import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Expert Care for Minor Injuries: Your Trusted Doctors
</h3>    
    <p className='d-md-none d-lg-none'>Welcome to Kyno Health, where we understand that minor injuries, though often overlooked, require specialized attention. Our dedicated team of doctors is committed to providing comprehensive care for a range of minor injuries. From sprains and strains to cuts and bruises, we prioritize your well-being and offer tailored treatments to facilitate a swift and smooth recovery</p>

<p className='mob-noee'>Welcome to Kyno Health, where we understand that minor injuries, though often overlooked, require specialized attention. Our dedicated team of doctors is committed to providing comprehensive care for a range of minor injuries. From sprains and strains to cuts and bruises, we prioritize your well-being and offer tailored treatments to facilitate a swift and smooth recovery</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
