import React from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Helmet } from 'react-helmet'
import logo from "../../utilities/images/home_v2/logo.png"
import Nav from '../home_page_v2/Nav/index'
import Footer from '../home_page_v2/Footer/index'

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let bookedSlot = searchParams.get("bookedSlot")
    const isPlumPage = !!window.location.href.includes("plum")
    const onReset = () => {
        window.location.href = isPlumPage ? '/booking/plum' : '/booking';
    }
    return (
        <div>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

                <title>Medical Consultation | Best Physician Doctor in Noida</title>
                <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
                <link rel="canonical" href="https://www.kynohealth.com/" />

                <meta property='og:image' content={logo} />
                
                <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
            </Helmet>
            <Nav isPaidPage={true} />
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <Typography variant="h5" color="primary"><CenteredTickCircle /></Typography>
                <Typography variant="h6"><strong>Slot Booked Successfully!</strong></Typography>
                <Typography ><small>On {convertToLocalTime(bookedSlot)}</small></Typography>
                <br />
                <Typography style={{ fontSize: '14px', color: "#00000080" }}>Our team will reach out to you within <strong>5-10 mins</strong> to take more details, please reach out to <a href="tel:01206056623">01206056623</a> in case of any query.</Typography>
                <Button variant="contained" color="primary" onClick={() => onReset()} style={{
                    margin: "20px auto",
                    marginTop: '20px',
                    maxWidth: "343px",
                    height: "46px",
                    fontWeight: "600",
                    padding: "12px 16px 12px 16px",
                    gap: "8px",
                    borderRadius: "10px",
                    background: "#FB9F40"
                }}>
                    Back To Home page
                </Button>
            </div>
            <Footer isBookSlot={true}  isPaidPage={true} />
        </div>
    );
};

const CenteredTickCircle = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '30px', // Full height to center vertically
            }}
        >
            <Box
                sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    border: '10px solid #9C0D380D', // Outer circle color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        border: '10px solid ##9C0D381', // Inner circle color
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CheckCircleIcon sx={{ fontSize: '40px', color: '#9C0D38', width: "100%", height: "100%" }} />
                </Box>
            </Box>
        </Box>
    );
};

const convertToLocalTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);

    // Extract the parts needed for formatting
    const day = utcDate.getDate();  // Day of the month (e.g., 15)
    const month = utcDate.toLocaleString('default', { month: 'long' });  // Full month name (e.g., August)
    const hours = utcDate.getHours() % 12 || 12;  // Convert 24-hour to 12-hour format (e.g., 11)
    const minutes = String(utcDate.getMinutes()).padStart(2, '0');  // Pad minutes with leading zero if needed
    const ampm = utcDate.getHours() >= 12 ? 'PM' : 'AM';  // Determine AM/PM

    // Format the date as "15 August, 11:22 PM"
    return `${day} ${month}, ${hours}:${minutes} ${ampm}`;

    // return formattedDate;
};


export default PaymentSuccess