import React from 'react'
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (
    <>
      <section className="ms">
        <div className="container">
          <h2 className='text-center'>Why Should You Choose In-Home Kyno Health Services?</h2>
          <p className='text-center'>So we have told you about our capabilities so far. Still confused as to why you should choose us for in-home healthcare? We will tell you exactly why. Let us now see the various advantages we hold for you:</p>

          <ul>
            <li><strong>Your Comfort Comes First: </strong>
            Our home doctor service is meant to bring you comfort. We believe it is the ideal arrangement to bring proper care to you instead of the other way around. We cut out the time for long travels and sitting in waiting rooms. People with mobility problems, older folk, and pregnant women will find this the most comfortable option.</li>
            <li><strong>We Are Always On Time!: </strong>
            Ambulances can be late; doctors can be late to consultations. But not us; Kyno Health is available 24x7 for your convenience. We are the perfect choice of home doctors who will be here with just a call or a message. We are the epitome of life-saving medical care anytime and anywhere.</li>
            <li><strong>All Services At Home: </strong>
            When you call a home doctor in Gurgaon through us, you know we will be here to provide all types of services. Our physicians come equipped with the right tools and medication that can be used to treat various medical issues. You may have a minor or a chronic problem, but we have the top doctors to take care of each treatment step. We ensure you are being treated in the comfort of your home and do not need to step out.</li>
            <li><strong>Anytime Appointments: </strong>
            It is your home, which means you have the say in your appointment timings. Call us at 2 am or 3 pm; we will be right there to assist you. By giving you full authority over the consultation and treatment timings, we ensure you can have more flexible access to medical care.</li>
            <li><strong>Privacy Maintained Throughout: </strong>
            What happens in a consultation stays in a consultation. We are all for maintaining patient privacy, allowing you to vent out in a comfortable setting without any information breach. Your home is the safest place to consult about your health concerns, and we ensure that we uphold that integrity.</li>
            <li><strong>Peace Of Mind For You: </strong>
            With Kyno Health, there is no moment of worry, but many of peace of mind. Our qualified medical professionals are just a call away to help you with everything from a headaches to chronic illnesses. Along with expertise and good equipment, we bring the promise of reassurance.</li>

          </ul>

        </div>
      </section>


      <section className="seven-fold">
        <div className="container">
          <div className="gradss">
            <Image
              src={require('../../Performance/images/select-Gradient.png')}
              webp={require('../../Performance/images/select-Gradient.webp')}
              alt='gradientImg'
            />
            <img src="images/select-Gradient.png" alt='select' />
          </div>

          <h5>Our Various Services</h5>

          <div className='seven-l-side'>

            <h2 className='text-center'>Explore Our Home Care Services Today</h2>
            <p>All this talk about our home doctor service, so how can we forget to tell you everything that this service includes? Let us see the range of our home care services:</p>

          </div>

          <div className='thrs-part ms-0 '>
            <div className='row justify-content-center'>


              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Diagnostics</h4>
                  <img src='images/Vaccine.svg' alt='vaccine' />

                  <p>One of the most common reasons you may be calling for a home doctor in Gurgaon may be to get diagnostics on your health. This process often takes a lot of hassle since you have to run around clinics to get a test or two done. With Kyno Health, it does not matter if you want the services of a pathology lab or if you want to get an MRI imaging done. We bring the services of various clinics and centers to your home. We even develop your reports under professional guidance in no time. All you have to do is be present, and we will care for everything else.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Ambulance</h4>
                  <img src='images/trained.svg' alt='trained' />
                  <p>The times of emergency sure are scary. Time runs out fast in times like these when punctual medical help is very important. We shall be your personal at-home ambulance when and as you need it. We come equipped with emergency ambulance services with the best medical supplies, oxygen tanks, and emergency kits to execute life-saving care whenever required.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Physiotherapy</h4>
                  <img src='images/ambulance.svg' alt='ambulance' />
                  <p>People with orthopedic issues and accident-caused illnesses cannot be expected to visit a doctor constantly for their well-being. Kyno Health brings home the finest physiotherapy solutions to help your loved ones recover from their physical ailments in due time.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Nursing</h4>
                  <img src='images/need.svg' alt='need' />
                  <p>Amidst the busy corporate life and hustle-bustle of Gurgaon, it may be hard to take care of your loved ones. In many situations, you may see that you are not as medically skilled as you are to handle a patient at home. Kyno Health's comprehensive nursing services help you bring nursing care to your home. Our professionals will ensure timely medication and care of your loved ones.</p>
                </div></div>

                <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>ECG/ X-ray</h4>
                  <img src='images/Lungs-Infecion.svg' alt='ECG/ X-ray' />
                  <p>ECG and X-ray are important parts of testing for cardiovascular and internal problems. You no longer have to book an appointment at a clinic far away to get these procedures done. We bring along the finest ECG monitors and X-ray machines to perform these processes in the house itself.
Kyno Health goes over and beyond to bring you the assurance of quality medical care at the comfort of your home. Whether it is an emergency doctor home visit or regular physiotherapy sessions, we are accessible across Gurgaon, all for your well-being. It is our duty to bring you back to good health as soon as possible!
</p>
                </div></div>

            </div>

          </div>


        </div>
      </section>
    </>
  )
}

export default index