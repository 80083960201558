import React from 'react'
import CountUp from 'react-countup';
import Image from 'react-image-webp';

function index() {
  return (
    <>
      <section className="second-fold">
        <div className="sepreat"></div>
        <div className="sepreat1"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='secnd-l-side'>
                <h5>OUR COMMUNITY</h5>

              </div>
            </div>


          </div>

          <div className='cont ml-70'>
            <div className='row'>
              <div className='col-md-3 col-6'>
                <div className="count-block">
                  <h4><span className="counter-count"><CountUp start={0} end={5000} delay={2} /><b>+</b></span></h4>
                  <p>Happy patients</p>
                </div>
              </div>

              <div className='col-md-3 col-6'>
                <div className="count-block">
                  <h4><span className="counter-count"><CountUp start={0} end={25} delay={2} /><b>+</b></span></h4>
                  <p>Doctors</p>
                </div>
              </div>

              <div className='col-md-3 col-6'>
                <div className="count-block">
                  <h4><span className="counter-count"><CountUp start={0} end={45} delay={2} /><b> mins</b></span></h4>
                  <p>Average Response time</p>
                </div>
              </div>

              <div className='col-md-3 col-6'>
                <div className="count-block">
                  <h4><span className="counter-count"><CountUp start={0} end={5} delay={2} /><b>+</b></span></h4>
                  <p>Cities</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>


      <section className="seven-fold">
        <div className="container">
          <div className="gradss">
            <Image src={require('../../Performance/images/select-Gradient.png')} webp={require('../../Performance/images/select-Gradient.webp')} alt='GradientsImg' />

          </div>

          <h5>Our Various Services</h5>

          <div className='seven-l-side ml-70'>
            <h2 className="mb-4">Services By Kyno Health</h2>
          </div>

          <div className=''>
            <div className="row flex-wrap justify-content-center">

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>Nursing Care </h4>
                  <Image
                    src={require('../../Performance/images/Sanitizer.png')}
                    webp={require('../../Performance/images/Sanitizer.webp')}
                    alt='SanitizerImg'
                  />
                  {/* <img src='../../Performance/images/Sanitizer.png'/> */}
                  <p>Receive the finest nursing care right at home. This option is suitable for elderly people who need daily care or those who live alone.</p>
                </div></div>


              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>Diagnostics  support at home </h4>
  
                  <img src='/images/Vaccine.svg' alt='vaccine' />

                  <p>Get accurate diagnostic reports at home. We bring in the most advanced diagnostics tools to perform all procedures at home.</p>

                </div></div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>ECG / X- Ray at home</h4>
                  <img src='/images/Lungs-Infecion.svg' alt='lungs' />

                  <p>No need to seek ECG and X-ray procedures outside. Kyno Health performs deep ECG and X-rays in the comfort of your home with the most precise reports.</p>

                </div></div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>Ambulance   Assistance</h4>
                  <img src='/images/trained.svg' alt='trained' />

                  <p>Receive paramedic care at home in times of medical emergencies. We offer lesser waiting times and faster response times to help you as soon as required.</p>
                </div></div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>Physiotherapy at Home</h4>
                  <img src='/images/ambulance.svg' alt='ambulance' />
                  <p>Gain control over your mobility again without physiotherapy services at home. We help with various activities that can help you recover from an accident or surgery.</p>
                </div></div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='thrs-one'>
                  <h4>24-Hour Caregivers</h4>
                  <img src='/images/need.svg' alt='need' />

                  <p>Kyno boasts 24-hour caregivers who are here to help you as and when required. There is no limit on when you can call us for expert help and care.</p>
                </div></div>



            </div>

          </div>


        </div>
      </section>

      <section className='pt-5'>
        <div className='container'>
          <h2 className="mb-4">Why Choose Kyno For Your Healthcare Needs?</h2>
   
            <div className='row flex-wrap justify-content-center'>


              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='service-block shadow p-3 mb-5 bg-body rounded'>
                  <img src='/images/excellence.svg' alt='excellence' className='img-responsive' />
                  <h4>Attentive Care At Home</h4>
                  <p>We provide excellence and attentiveness in all care that we provide. Our services are made to provide the finest of medical care at your home.</p>
                </div>
              </div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='service-block shadow p-3 mb-5 bg-body rounded'>
                  <img src='/images/Medical_Supplies.svg' alt='equipped' className='img-responsive' />
                  <h4>Immediate Help</h4>
                  <p>With an average response time of 45 minutes, we bring timely assistance to your home faster than any regular on-site clinic.</p>
                </div>
              </div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='service-block shadow p-3 mb-5 bg-body rounded'>
                  <img src='/images/consultation.svg' alt='consultation' className='img-responsive' />
                  <h4>Detailed Consultation</h4>
                  <p>Do more with us than you would in any clinic visit. We ensure every consultation is detailed and fruitful.</p>
                </div>
              </div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='service-block shadow p-3 mb-5 bg-body rounded'>
                  <img src='/images/follow.svg' alt='follow' className='img-responsive' />
                  <h4>Proactive Follow-ups</h4>
                  <p>We bring proactive follow-ups after the principal consultation so you can better know your options and navigate comprehensive care.</p>
                </div>
              </div>

              <div className='col-md-4 col-sm-4 col-xs-12'>
                <div className='service-block shadow p-3 mb-5 bg-body rounded'>
                  <img src='/images/Equipped.svg' alt='follow' className='img-responsive' />
                  <h4>Equipped & Ready</h4>
                  <p>In each consultation, we are equipped with the finest apparatus. This helps us stay ready for any situation that may arise.</p></div>
              </div>


            </div>
          </div>

      </section>

    </>

  )
}

export default index