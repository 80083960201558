import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are some of the best general physicians providing at-home services in Defence Colony, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kyno has some of the best general physicians like Dr Ashish Kochekar, Dr Ankesh Rawat, and Dr. Happy Kumar to name a few providing at-home services in Defence Colony, Delhi."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for home visits in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Click on the 'call now' button to schedule an appointment with a general physician for home visits in Defence Colony by visiting Kyno's official website."
      }
    },{
      "@type": "Question",
      "name": "What services can I expect from a general physician during a home visit in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can expect bed sore treatment, appointment scheduling, home visits in 60 minutes, on-call doctors, and more from a general physician during a home visit in Defence Colony."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians in Defence Colony experienced and qualified to provide quality healthcare at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The general physicians in Defence Colony at Kyno are highly experienced and hold qualifications like MBBS to provide quality healthcare at home."
      }
    },{
      "@type": "Question",
      "name": "How do the fees for at-home general physician services in Defence Colony compare to traditional clinic visits?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The fees for at-home general physician services in Defence Colony compare to traditional clinic visits is more economic as you can save all the travel expenses."
      }
    }]
  }
  
  
  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Defence Colony, Delhi | Kyno Health</title>
        <meta name="description" content="Receive expert medical care without leaving your home. Kyno Health provides the best general physicians in Defence Colony, Delhi for convenient and personalized home visits." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-defence-colony-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Defence Colony, Delhi" hero_paragraph="General practitioners' home visits provide a suitable and efficient model of providing healthcare services to patients. Such visits are appreciated by the patients because they do not need to go to the clinic, and doctors take more time for each patient. It is especially suitable for geriatrics, chronic diseases, and patients with restricted mobility; specifically, the use of this method impacts the whole health sector positively. " hero_paragraph_1="Since home visits provide solutions at the patient's doorstep, the patient is exposed to a few hospital trips, which increases his/her comfort and overall satisfaction. Kyno's Best General Physicians at Home in Defence Colony, Delhi are adept at early diagnosis and, therefore, provide effective treatment of diseases, reduce health expenditures, and enhance the general quality of life."/>
      
      <Content content_h3="An Insight Into the Common Conditions Treated by Home Visit General Physicians" content_p="Home visit general physicians mainly deal with a variety of ailments that patients present at their homes, which they can attend to from the comfort of their homes. Diabetes, hypertension, and heart diseases are common and preferred long-term conditions often treated at home and with home visits to check on the progress of the patient's management and treatment regimens. " content_p1="Conditions that act upon the respiratory system, such as asthma and chronic obstructive pulmonary diseases, also involve home care since doctors can evaluate the physical and environmental causes of the disease. Minor ailments like infections, minor injuries, or flu can be managed at the client's home and administered without requiring the client to visit the hospital. Kyno understands this and hence facilitates general physician home visit near me, which involve reviewing medication, assessments, and management of patients with arthritis, dementia, and mobility problems to help patients receive the necessary and enough home care continuously."

      content_h3_2="Get Personalized Home Care in Defence Colony with Kyno's General Physicians" content_p_2="Get the best of health services from Kyno's home-visit general physicians located in Defence Colony. Our doctors will attend to your needs and those of your family without having to go through the strict hospital bureaucracies. Whether you are low on yearly check-ups, suffer from chronic conditions, or have an acute affliction, Kyno's staff is prepared to take on all challenges. As a health center, we ensure that the client is attended to in a convenient way with personalized care according to the need. Count on Kyno to offer you the finest healthcare services and the general physician near me to ensure that you get to be treated from the comfort of your home, thus improving the accessibility and credibility of quality medical services." />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index