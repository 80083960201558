import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>
     
      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Are home visit doctor services available 24/7 in Gurgaon?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, Kyno Health's healthcare professionals are available 24/7 in Gurgaon to provide you with all types of medical attention.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Do home visit doctors in Gurgaon handle emergency medical situations?</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, where ambulances are not accessible or are not on time, we come as the perfect resort to execute emergency medical attention with the highest quality equipment.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Can I receive ongoing care from a home visit doctor in Gurgaon?</Accordion.Header>
                    <Accordion.Body>

                      <p>Sure you can! Kyno Health is not just for one-time consultations. In fact, we are always here to give you regular consultations and provide deeper insights into your progress.</p>
                    </Accordion.Body>
                  </Accordion.Item>



                </Accordion>

              </div>

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
                <Accordion>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What preparations are needed for a doctor's home visit in Gurgaon?</Accordion.Header>
                    <Accordion.Body>

                      <p>Your presence is all that matters. If you are trying to get checked for a chronic or ongoing problem, you can compile your previous medical reports. You may even create a comfortable spot for you to get the consultation.</p>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What are the advantages of choosing a home visit doctor in Gurgaon?</Accordion.Header>
                    <Accordion.Body>

                      <p>A home visit saves you a lot of time during emergency situations where time is crucial. Even if it is not an emergency situation, sometimes time and circumstances keep you from visiting physical clinics. This is why a home visit doctor is great for helping you receive medical care right from home.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default index