import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Professional Home Wound Dressing Services for Optimal Healing</h3>    
    <p className='d-md-none d-lg-none'>At Kyno Healthcare Center, we understand that wounds require careful attention and specialized care to ensure proper healing. Our Home Wound Dressing Services bring skilled healthcare professionals to the comfort of your home, providing personalized wound care that promotes optimal recovery. Whether you have a post-surgical incision, a chronic wound, or a minor injury, our team is here to support your healing journey.</p>

<p className='mob-noee'>At Kyno Healthcare Center, we understand that wounds require careful attention and specialized care to ensure proper healing. Our Home Wound Dressing Services bring skilled healthcare professionals to the comfort of your home, providing personalized wound care that promotes optimal recovery. Whether you have a post-surgical incision, a chronic wound, or a minor injury, our team is here to support your healing journey.</p>

 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
