import React from 'react'
import Vert_Slider from "react-slick";
import './Css/style.css';
import Image from 'react-image-webp';


function index() {

  const vert_settings = {

    // dots: true,
    vertical : true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    // arrows: true,
    slidesToScroll: 1,
    autoplay: true,

    // verticalSwiping: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        }
      },
  
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    
   };

  
  return (
    <>
<section className='book-visit d-sm-none d-md-none d-lg-none'>
  <div className='container'>

  <h5>Your Needs</h5>
  <div className='ml-70'>
    <h2>We understand your unique needs</h2></div>

    <Vert_Slider {...vert_settings}>



<div className='offer'>
  <div className='row'>
    <div className='col-8'>
      <h6>01</h6>
      <h3>Get help for your 
      <span>loved one, from anywhere</span></h3>
    </div>
    <div className='col-4 bav'>
    <Image
      src={require('../../images/kyno1.png')}
      webp={require('../../images/kyno1.webp')}
      alt='kyno1Img'
    />
      {/* <img src="../images/kyno1.png"/> */}
    </div> 
  </div>

</div>

<div className='offer'>
  <div className='row'>
  <div className='col-8'>
  <h6>02</h6>
<h3>Expert assistance 24x7 
<span>for times of emergency</span></h3>
  </div>
  <div className='col-4 bav'>
  <Image
      src={require('../../images/kyno2.png')}
      webp={require('../../images/kyno2.webp')}
      alt='kyno2Img'
    />
      {/* <img src="../images/kyno2.png"/> */}
    </div> 
  </div>

</div>


<div className='offer'>
  <div className='row'>
  <div className='col-8'>
  <h6>03</h6>
<h3>Dont get lost in crowd,  get stress
<span> free consultation at home</span></h3>
  </div> 
  <div className='col-4 bav'>
  <Image
      src={require('../../images/kyno1.png')}
      webp={require('../../images/kyno1.webp')}
      alt='kyno1Img'
    />
      {/* <img src="../images/kyno1.png"/> */}
    </div>
  </div>

</div>


<div className='offer'>
  <div className='row'>
  <div className='col-8'>
  <h6>04</h6>
<h3>For chronic illness, request  repeat
<span> consultation from the same doctor</span></h3>
  </div>
  <div className='col-4 bav'>
  <Image
      src={require('../../images/kyno2.png')}
      webp={require('../../images/kyno2.webp')}
      alt='kyno2Img'
    />
      {/* <img src="../images/kyno2.png"/> */}
    </div> 
  </div>

</div>

</Vert_Slider>


  </div>
  
  </section>
    </>
  )
}

export default index