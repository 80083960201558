import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Understanding Pain Management</h2>
  <p>Pain management is a branch of medicine focused on alleviating pain, whether it's caused by injury, illness, surgery, or a chronic condition. Chronic pain can be physically and emotionally draining, impacting your ability to work, enjoy life, and maintain your overall well-being. Our goal is to help you effectively manage your pain and improve your daily function.</p>

  <h2>Our Approach to Pain Management</h2>
  <p>At our clinic Kyno Health, we take a comprehensive and patient-centered approach to pain management. Here's what you can expect when you choose our services:</p>

<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Evaluation</h4>
<p>Our experienced doctors will conduct a thorough assessment of your pain, medical history, and any relevant diagnostic tests. Understanding the source and nature of your pain is essential for creating an effective treatment plan.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Treatment</h4>
<p>We believe in individualized care. Your treatment plan will be tailored to your specific condition, needs, and goals. We offer a range of options, from medication management to physical therapy, interventional procedures, and more.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Minimizing Medication Reliance</h4>
<p>While medications can be part of pain management, we aim to minimize reliance on them and explore alternative therapies whenever possible to reduce side effects and risks.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Education and Support</h4>
<p>We're committed to educating you about your condition and pain management strategies. We want you to be an active participant in your care, making informed decisions about your treatment.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Continuous Monitoring</h4>
<p>Pain management is an ongoing process. We will regularly assess your progress and adjust your treatment plan as necessary to ensure the best outcomes.</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Conditions We Treat</h2>
<p>Our team is experienced in managing a wide range of pain conditions, including but not limited to:</p>
<div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<div className=''>
<ul>
  <li>Chronic Back Pain</li>
  <li>Arthritis and Joint Pain</li>
  <li>Neuropathic Pain</li>
  <li>Headaches and Migraines</li>
  <li>Cancer Pain</li>
  <li>Post-Surgical Pain</li>
  <li>Fibromyalgia</li>
  <li>Muscle and Nerve Pain</li>
  <li>Complex Regional Pain Syndrome (CRPS)</li>
  <li>Pain Related to Autoimmune Disorders</li>
</ul>

</div>
</div>
</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
  <h2>Why Choose Us for Your Pain Management Needs?</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Expertise</h4>
<p>Our doctors are specialists in the field of pain management, providing you with the highest level of care.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Compassion</h4>
<p>We understand the physical and emotional toll that chronic pain can take. Our team is committed to providing compassionate support throughout your journey to recovery.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>We have modern facilities and the latest technology to ensure accurate diagnosis and effective treatments.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Holistic Approach</h4>
<p>We focus not only on pain relief but also on improving your overall well-being and quality of life.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Appointments</h4>
<p>We offer convenient appointment scheduling, making it easier for you to access the care you need.</p>
</div>
</div>

<div className='col-md-12'>
  <p>Don't let pain control your life any longer. Whether you're dealing with acute pain from an injury or a long-standing chronic condition, we're here to help. Take the first step towards a pain-free life by scheduling an appointment with our expert pain management team.</p>

<p>Contact us today to book your consultation and start your journey toward a life without unnecessary pain. Your comfort and well-being are our top priorities.
</p>
</div>

</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Conclusion</h2>
<p>Don't let persistent vomiting disrupt your life. Seek the expertise of a specialized doctor for accurate diagnosis and personalized care. Whether your symptoms are acute or chronic, early intervention is key to finding relief and improving your overall well-being. Schedule a consultation today and take the first step towards a healthier, nausea-free life.</p>

</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index