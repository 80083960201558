import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians who provide home visits in Shahdara, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can find the best general physicians at Shahdara, Delhi, through our facility Kyno Health. You also need not travel long distances for treatment as we provide home visit services with an average response time of 45 minutes only. So, when it comes to finding a general physician who is offering home visits, reach out to us at Kyno Health."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and experience should I look for in a general physician offering home services in Shahdara?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are a few basic qualifications that you should look for in a general physician who is offering home services in Shahdara. These qualifications include medical degrees like an MBBS, BAMS, or BHMS and additional specialized degrees like an MS or MD. At Kyno Health, all our general physicians are board-certified doctors and have varying experiences related to their expertise."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians who conduct home visits in Shahdara, Delhi, licensed and certified?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we take pride in saying that at Kyno Health, all our general physicians who conduct home visits in Shahdara are licensed and certified. To ensure their authenticity, we have also conducted background checks and verified their licenses. You can always trust us to be fair and transparent when it comes to providing you with home-visit general physicians."
      }
    },{
      "@type": "Question",
      "name": "How can I book an appointment with a top general physician for a home visit in Shahdara, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Well, you can book an appointment from the comfort of your home in Shahdara just by dialing our number- +91-9355618123. Our receptionist will pick up your phone and set you up with a fixed appointment slot for the date and time concerned. You need not travel long distances anymore to get yourself treated."
      }
    },{
      "@type": "Question",
      "name": "What are the typical services offered by general physicians who visit patients at home in Shahdara, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our doctors offer a range of services. This includes a detailed consultation which can last around half an hour or 30 minutes. We also offer services like setting up IV drip at home, conducting diagnostic tests like X-rays with high-powered tech, or arranging ambulance services."
      }
    }]
  }
  


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Shahdara, Delhi | Kyno Health</title>
        <meta name="description" content="Experience superior home healthcare with Kyno Health's top general physicians in Shahdara, Delhi. Book a home visit for expert medical consultations and personalized care." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-at-home-in-shahdara-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Shahdara, Delhi" hero_paragraph="Access top-quality healthcare in the comfort of your home with Kyno Health's Best General Physicians at Home in Shahdara, Delhi. Our home visit services provide convenience, personalized attention, and immediate medical care. We ensure you receive the best treatment without leaving your house. "/>
      
      <Content content_h3="Why You Should Choose a General Physician Rather than Visiting a Hospital? " content_p="Opting for a general physician home visit near me rather than going to a hospital offers significant advantages. First, it eliminates the need for travel, saves time, and reduces stress, especially for those with mobility issues or severe illnesses. Home visits ensure you get immediate care without the hassle of commuting." content_p1="Home visits by general physicians offer a more personalized approach to healthcare. Physicians can observe the patient's living conditions. This can be crucial for diagnosing and treating certain conditions. This holistic understanding allows for customized treatment plans. This further enhances the effectiveness of the care provided." content_p2="Additionally, home care minimizes the risk of exposure to infections commonly found in hospitals. By receiving treatment at home, you can avoid crowded waiting rooms and reduce the likelihood of contracting illnesses. This is particularly beneficial for elderly patients and those with compromised immune systems. Overall, it ensures a safer healthcare experience."

      content_h3_2="Experience Top-Quality Medical Care at Your Home in Shahdara from Kyno Health" content_p_2="Receive exceptional medical care in Shahdara with Kyno Health's dedicated general physician near me. Our home visit services offer convenience, personalized attention, and expert treatment, ensuring your health needs are met efficiently. Choose Kyno Health for a seamless and comfortable healthcare experience at your doorstep."/>

      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index