import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I schedule an appointment with the best general physician at home in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To book the best general physician at home in Defence Colony, you can try contacting Kyno Health at support@kynohealth.com."
      }
    }, {
      "@type": "Question",
      "name": "How do I know if a general physician provides personalized treatment plans for patients in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can ask the support team on call for personalized treatment plans."
      }
    }, {
      "@type": "Question",
      "name": "What is the typical duration of a home visit consultation with a general physician in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": " The general physician generally spent a mean of 20 minutes with each patient. The time can be extended depending on the needs of the patient. ",
      }
    }, {
      "@type": "Question",
      "name": "How can I verify the credibility and reputation of a general physician offering home visits in Defence Colony, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Online reviews and testimonials are excellent resources for judging the reputation and dependability of doctors' home visit services. Dig into valid platforms and forums to read reviews from previous patients, paying attention to common themes and overall satisfaction levels. For example, finding several positive reviews praising a certain doctor's bedside manner and skill boosts confidence in their capacity to offer quality care. "
      }
    }, {
      "@type": "Question",
      "name": "Are there any additional fees or charges associated with home visits from a general physician in Defence Colony?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, when a general physician visits your home, it will be charged quite high in comparison to the office visit. "
      }
    }]
  }



  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Defence Colony | Kyno Health        </title>
        <meta name="description" content="Kyno Health offers expert general physician services at home in Defence Colony. Our dedicated doctors provide personalized medical care and consultations, ensuring top-quality healthcare in the comfort of your home. Choose Kyno Health for convenient and professional home healthcare services in Defence Colony." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-defence-colony" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

                <h3>Best General Physicians at Home in Defence Colony</h3>
                <p>For anyone undergoing health problems or has been diagnosed with one, it is important to start treatment as early as possible. If you are seeking the Best General Physicians at Home in Defence Colony, they can be found at Kyno Health. However, it is quite important to know for what disease you require the general physician and whom you are selecting. There are some essential criteria that a general physician must fulfill before you select them. General physicians can treat common infections like colds, flu, sinusitis, urinary tract infections, and skin infections. They can also manage chronic conditions such as diabetes, hypertension (high blood pressure), asthma, and heart disease. At Kyno Health, all the Best General Physicians at Home in Defence Colony are with us and have been treating our patients with quality care and treatment.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>
          <h3>Criteria to Consider for Selecting the Best General Physician</h3>
          <p>Before you decide to select a general physician, here are some factors that you can consider.</p>
          <h4>Qualifications and Experience</h4>
          <p>You should look for a general physician with extensive training and experience in treating specific health issues. Board certification and a strong educational background from reputable institutions are also essential. These indicators will prove their expertise in their specific field.</p>
          <h4>Patient Reviews and Testimonials</h4>
          <p>Reading reviews and testimonials from other patients can provide important insights. You should be checking for patient feedback to have an understanding of your doctor. Through these reviews, you can learn about the physician&rsquo;s bedside manner, communication skills, and overall patient satisfaction. Positive feedback from former patients is also a good sign of quality care. You can check for reviews by typing general physician near me at Defence Colony and get a well-rounded understanding.</p>
          <h4>Treatment Modalities</h4>
          <p>The other thing to consider is the physician&rsquo;s approach to treatment. Are they proficient in the latest protocols and precision medicine? A modern, patient-centric approach is vital for effective care, and at Kyno Health, you will find all these modalities in function.</p>
          <h3>Visit Our Reputed general physician at Kyno Health</h3>
          <p>At Kyno Health, we pride ourselves on having some of the best general physicians in the Defense colony in Delhi. Our team of experts is dedicated to providing compassionate and cutting-edge care. Schedule a consultation today and take the first step towards a healthier future with us.&nbsp;</p>

        </div>
      </section>


      <section className='fourth-fold pt-3'>

        <div className='container'>

          <div className='row d-flex align-items-center'>


            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>Are you tired of waiting hours in a crowded room just to see a doctor for a few minutes? Well, say goodbye to those days because the future of healthcare is here - general physician at-home visits!</p>

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>


      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index