import React from "react";
import {Link} from "react-router-dom";
// import Footer from "../Foot";
import Hero from "./Hero/index";
import Content from "./Content/index";
import Faqs from "./Faqs/index";
import { Helmet } from "react-helmet";
import Image from 'react-image-webp';

function index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pediatric Home Health: Delivering Quality Care to Your Doorstep</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      {/* Navigation */}
      <div className='main-menu'>
    <div className='container'>
    <nav className="navbar navbar-expand-lg navbar-light">
  <div className="container-fluid">
  <Link className="navbar-brand" to="#">
  <Image
      src={require('../Performance/images/head-logo.png')}
      webp={require('../Performance/images/head-logo.webp')}
      title='Kyno Health Logo'
      alt="headLogoImg"
    />
    {/* <img src="../images/head-logo.png" tittle="Kyno Health Logo" alt='Logo'/> */}
    </Link>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
     <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
    <div id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item">
        <Link className="nav-link" to="/">Home</Link>
        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/about-us">About Us</Link>        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>        
        </li>


        <li className="nav-item dropdown">
        <a href='/' className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'>Services <span className="caret"></span></a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-home-visit-noida">Doctors at Home in 60 mins</Link>
                </li>
                {/* <li>
                  <Link className="nav-link dropdown-item" to="/physician-near-me">Physician near me</Link>
                </li> */}
                <li>
                  <Link className="nav-link dropdown-item" to="/doctors-near-me">Best Doctors in Delhi - NCR</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/schedule-doctor-appointment">Appointment Scheduling</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/iv-therapy">IV Drip at Home</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/treatment-for-bed-sore">Bed Sore Treatment</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-on-call-home-visit">On-Call Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/twenty-four-hour-visiting-doctor">24*7 Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-for-fever-treatment">Doctors for Fever at Homet</Link>
                </li>
              </ul>
            </li>

        {/* <li className="nav-item">
        <Link className="nav-link" to="/services">Services</Link> */}
        
        {/* </li> */}
        {/* <li className="nav-item">
        <Link className="nav-link" to="/meet_our_doctors">Services</Link>
        </li> */}
        <li className="nav-item butn">
        {/*<Link className="nav-link" to="/booking_form">Book a visit</Link> */} {/*Book Visit*/}
        <Link className="nav-link" to="tel:+919818676103">Call: 98186 76103</Link>
        </li>

        <li className="nav-item butn seo">
        <Link className="nav-link" to="tel:+919818676103">Call: 98186 76103</Link>      
        </li>

      </ul>
    </div>
    </div>
  </div>
</nav>
</div>
</div>
      <Hero />
      <Content />
      <Faqs />

<section className="footer">
<div className="container">

<div className="row">



<div className="col-md-6">
  <div className='fooet'>
<img src='../images/Kyno-footer-logo.png' alt='logo'/> </div>
  
  <div className="row">
  <div className="col-md-3 col-6">
  <p><span><img src='../images/Phone.svg' alt='logo'/></span><a href='tel:+919818676103'>+91-98186 76103</a></p></div>
  <div className="col-md-3 col-6">
  <p><span><img src='../images/email.svg' alt='logo'/></span> support@kynohealth.com</p></div>
  </div>

  <div className="socials hidd-m">
  <Link to="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><img src='../images/InstagramLogo.svg' alt='logo'/></Link>
  <Link to="https://www.linkedin.com/company/kyno-health/"><img src='../images/LinkedinLogo.svg' alt='logo'/></Link>
  <Link to="https://twitter.com/Kynohealth"><img src='../images/TwitterLogo.svg' alt='logo'/></Link>
  <Link to="https://www.facebook.com/KynoHealth-106490852367301"><img src='../images/FacebookLogo.svg' alt='logo'/></Link>
  <Link to="https://youtube.com/@kynohealth"><img src='../images/YoutubeLogo.svg' alt='logo'/></Link>
  <Link to="https://wa.me/01206056623"><img src='../images/WhatsappLogo.svg' alt='logo'/></Link>
  <Link to="/"></Link>
  <p className='copyrgt'><a href="/terms-conditions/">Terms and Conditions</a> <a href="/return-policy/">Return Policy</a> <a href="/privacy-policy/">Privacy Policy</a></p>

<p className='copyrgt text-center'>Copyright @2023 KYNO Health | Trademark of Huami Technologies Private Limited</p>
</div>

</div>

<div className="col-md-3">

<ul>
  <li><Link to="/doctor-home-visit-noida">Doctor home visit noida</Link></li>
  <li><Link to="/doctors-near-me">Doctors near me</Link></li>
  <li><Link to="/iv-therapy">Iv therapy</Link></li>
  <li><Link to="/doctor-on-call-home-visit">Doctors on call home visit</Link></li>
  
 

</ul>
</div>

<div className="col-md-3">
<ul>
<li><Link to="/doctor-for-fever-treatment">Doctors for fever treatment</Link></li>
  {/* <li><Link to="/physician-near-me">Physician near me</Link></li> */}
  <li><Link to="/schedule-doctor-appointment">Schedule doctor appointment</Link></li>
  <li><Link to="/treatment-for-bed-sore">Treatment for bed sore</Link></li>
  <li><Link to="/twenty-four-hour-visiting-doctor">Twenty four hour visiting doctor</Link></li>
  

</ul>
</div>

<div className='col-md-12 d-sm-none d-md-none d-lg-none'>

<div className="socials">
<a href="https://instagram.com/kynohealth?igshid=YmMyMTA2M2Y="><img src='images/InstagramLogo.svg' alt='logo'/></a>
<a href="https://www.linkedin.com/company/kyno-health/"><img src='images/LinkedinLogo.svg' alt='logo'/></a>
<a href="https://twitter.com/Kynohealth"><img src='images/TwitterLogo.svg' alt='logo'/></a>
<a href="https://www.facebook.com/KynoHealth-106490852367301"><img src='images/FacebookLogo.svg' alt='logo'/></a>
<a href="https://youtube.com/@kynohealth"><img src='images/YoutubeLogo.svg' alt='logo'/></a>
<a href="https://wa.me/01206056623"><img src='images/WhatsappLogo.svg' alt='logo'/></a>
<p className='copyrgt'><a href="/terms-conditions/">Terms and Conditions</a> | <a href="/terms/">Return Policy</a> | <a href="/terms/">Privacy Policy</a> | </p>
<p className='copyrgt'>Copyright @2024 KYNO Health | Trademark of Huami Technologies Private Limited </p>
<div></div>
</div>
</div>

</div>

<div className='foot-stick'>
  {/*<a href='/booking_form'>Book appointment</a>*/} {/*For Book Appointment*/}
  <a href='tel:+919818676103'><i className='phone-icon fa fa-phone'></i><span>Call Us</span></a> {/*For Call*/}
</div>

</div>
</section>
    </>
  );
}

export default index;
