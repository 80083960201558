import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are the top general physicians providing home services in Preet Vihar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The top general physicians who are offering home services at Preet Vihar, Delhi are from Kyno Health. We have some of the best doctors in Preet Vihar who are experienced enough to treat a variety of diseases and conditions. You can check the list of our qualified doctors here. The names of our doctors are Dr. Prabhu M, Dr. Gaurav Sharma, Dr Ankesh Rawat, Dr Ashish Kochekar, and Dr Happy Kumar."
      }
    },{
      "@type": "Question",
      "name": "How can I book an appointment with the best general physician at home in Preet Vihar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can book an appointment with our best general physician near me at home in Preet Vihar by just calling us at this number- +91-9355618123. We will ask a few basic questions such as your name, address, and condition and quickly fix you an appointment at home. Or else, you may mail us at support@kynohealth.com."
      }
    },{
      "@type": "Question",
      "name": "What are the qualifications and experience of the general physicians offering home services in Preet Vihar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "All our doctors at Kyno Health are board-certified medical doctors who have completed their degrees from a recognized institute. In terms of their experience, it varies from one doctor to the other. Some may have 3-4 years of experience while others may have 10+ years of experience."
      }
    },{
      "@type": "Question",
      "name": "Are the home services provided by general physicians in Preet Vihar, Delhi, inclusive of diagnostic tests and medication delivery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, at Kyno Health, we provide diagnostic tests and medication delivery at your home in Preet Vihar, Delhi. Our diagnostic tests are also plenty in number and include tests like X-rays, USG, etc., at home. Whether you simply need a general physician or diagnostic services along with it depends a lot on your condition. For any queries, feel free to call us at +91-9355618123."
      }
    },{
      "@type": "Question",
      "name": "How do I know if a general physician at home in Preet Vihar is suitable for my medical needs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Firstly, we will assess your condition over a telephone call while setting up your consultation. Our general physician will visit you after that and if advised, you can go to a hospital. However, our doctors are well-prepared and trained to treat all kinds of diseases and situations at home."
      }
    }]
  }


  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Preet Vihar, Delhi | Kyno Health</title>
        <meta name="description" content="Get expert medical care at your doorstep with Kyno Health's top general physicians in Preet Vihar, Delhi. Book a home visit and ensure your health is in trusted hands without the hassle of clinic visits." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-at-home-preet-vihar-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Preet Vihar, Delhi" hero_paragraph="Finding the Best General Physicians at Home in Preet Vihar, Delhi ensures convenient, top-notch medical care right at your doorstep. These professionals offer a wide range of services, including routine check-ups, chronic disease management, and urgent care. Experience quality healthcare without the need to travel, bringing peace of mind and prompt attention to your health needs."/>
      
      <Content content_h3="General Physicians at Home: Handling Emergencies" content_p="In medical emergencies, having a general physician at home in Preet Vihar can be a lifesaver. They provide immediate attention, stabilizing patients before more intensive care is required. Their swift response can make a significant difference, especially in critical situations where every minute counts." content_p1="General physicians at home are equipped to handle various emergencies, from sudden illnesses to exacerbations of chronic conditions. They carry essential medical tools and medications, enabling them to offer immediate relief and professional care. Their expertise ensures that patients receive the best possible treatment promptly." content_p3="Additionally, these physicians coordinate with hospitals and specialists, ensuring seamless transitions if further care is needed. They provide valuable guidance and support during emergencies, helping families make informed decisions. With their help, patients can avoid unnecessary hospital visits and receive high-quality care in the comfort of their own homes." 

      content_h3_2="Discover Preet Vihar's Best General Physicians at Kyno Health" content_p_2="Are you living in Preet Vihar, Delhi, and want to know about the general physician home visit near me? Worry not, as Kyno Health has got your back. Be it any unprecedented emergency or a regular check-up for a chronic illness; you can contact us at Kyno Health and get an appointment with a general physician at home. With us, you will experience the best of medical care sitting within the four walls of your home." />


      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index