import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Stay Safe this Summer: Avoid Fake ORS, Choose WHO-Approved Hydration</h3>
    <p>As the weather gets hotter and summer sets in, it's natural to feel the heat more than ever. Staying hydrated during this time is incredibly important, primarily if you reside in regions that witness excruciating heat during this season.</p>
<p>When the temperature soars, the risk of heat strokes and dehydration skyrockets. Heatstroke occurs when the body overheats to dangerous levels, often due to prolonged exposure to high temperatures. Symptoms include dizziness, nausea, confusion, rapid heartbeat, and in severe cases, loss of consciousness. To combat it, Oral Rehydration Solution or ORS, a scientifically designed electrolyte solution, comes in. It's not just about replenishing lost fluids and electrolytes from sweating. ORS is about restoring your body's electrolyte balance, ensuring your vital organs and muscles function optimally. Whether you're under the scorching sun, working out, or just trying to beat the heat indoors, ORS is your quick and efficient rehydration solution. And it's not just for adults. It's for everyone, from kids playing outside to adults juggling daily routines.</p>
<p>Packaged ORS solutions are readily available on the market. But do they truly deliver on their promises? Join us as we shed light on the authenticity of ORS solutions, steer clear of over-advertised, fake products, and ensure optimal health during the summer months.</p>


  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
