import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians who offer home visits in Hauz Khas, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Begin your search by reaching out to your social circle, including family and friends, for personal recommendations, as these can provide honest insights into the doctor's bedside manner and reliability. Utilize online platforms to research reviews and testimonials about potential physicians' services, focusing on those that specifically mention home visit experiences."
      }
    },{
      "@type": "Question",
      "name": "What are the benefits of consulting a general physician at home in Hauz Khas?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Opting for a general physician home visit comes with a suite of advantages that cater specifically to the patient's comfort and convenience. Foremost among these is the elimination of the need to navigate the logistics of getting to a medical facility, which can be particularly challenging for individuals dealing with mobility issues or severe illness"
      }
    },{
      "@type": "Question",
      "name": "How can I ensure that the general physician visiting my home in Hauz Khas is qualified and experienced?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ensuring that a general physician visiting your home in Hauz Khas is qualified and experienced involves confirming that the physician has a valid medical degree from a recognized medical school and is registered with the Medical Council of India (MCI) or the respective state medical council."
      }
    },{
      "@type": "Question",
      "name": "Are home visits by general physicians in Hauz Khas suitable for all medical conditions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Home visits are generally suitable for conditions that do not require immediate hospitalization or specialized equipment that cannot be transported. Common conditions like fever, flu, minor injuries, respiratory infections, and chronic diseases that require routine check-ups can often be managed effectively through home visits."
      }
    },{
      "@type": "Question",
      "name": "How do I schedule an appointment with a general physician for a home visit in Hauz Khas, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can schedule the appointment by making a call to the clinic and talking with the personnel. You can also book the appointment by sending the mail to the office."
      }
    }]
  }
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Hauz Khas | Kyno Health</title>
        <meta name="description" content="Kyno Health offers exceptional general physician services at home in Hauz Khas. Our skilled doctors provide personalized care and thorough medical consultations, ensuring you receive quality healthcare in the comfort of your home. Choose Kyno Health for reliable and convenient home healthcare in Hauz Khas." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-hauz-khas" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

                <h3>Best General Physicians at Home in Hauz Khas</h3>
                <p>Are you tired of waiting for hours in a crowded waiting room just to see a doctor for a few minutes? Well, say goodbye to those days because the future of healthcare is here - general physician at-home visits in Haus Khas.&nbsp;</p>

                <h3>Choosing a General Physician for Home Visits in Hauz Khas</h3>
                <p>Finding time to prioritize your health can be challenging in today's fast-paced world. That's where a general physician who offers home visits can make a difference. Imagine receiving top-notch medical care without leaving the comfort of your home.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>


          <h3>Convenience and Comfort</h3>
          <p>A general physician's home visit provides unparalleled convenience and comfort. No more waiting rooms or long commutes to the doctor's office. Instead, you can relax in familiar surroundings while receiving personalized medical attention.</p>
          <h3>Personalized Care</h3>
          <p>During a home visit, your general physician can focus solely on you, providing undivided attention and tailored care. This personalized approach allows for a deeper understanding of your health needs and concerns.</p>
          <h3>Building Trust and Rapport</h3>
          <p>Having a general physician come to your home fosters a sense of trust and rapport. The bond formed during home visits can lead to better communication, improved health outcomes, and a stronger doctor-patient relationship.</p>
          <h3>Comprehensive Assessments</h3>
          <p>General physicians who offer home visits can conduct thorough assessments of your health in the comfort of your own home. From routine check-ups to managing chronic conditions, a home visit ensures that all aspects of your health are addressed.</p>
          <h3>Peace of Mind</h3>
          <p>Knowing that a general physician is just a phone call away can provide peace of mind, especially during times of illness or medical emergencies. Having access to quality healthcare at home can alleviate stress and ensure prompt medical attention when needed.</p>


        </div>
      </section>


      <section className='fourth-fold pt-3'>

        <div className='container'>

          <div className='row d-flex align-items-center'>


            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>In conclusion, opting for a general physician at Hauz Khas who offers home visits can revolutionize the way you approach healthcare. The convenience, personalized care, and peace of mind that come with a home visit are unparalleled. So why not prioritize your health and well-being by choosing a general physician at Kyno Healthcare who brings healthcare to your doorstep?</p>
                <p>Don't let a busy lifestyle hinder your access to quality medical care. Embrace the convenience and comfort of a general physician home visit today.</p>

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>

      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index