import React, {useState} from 'react' 
import axios from 'axios';
import { localBackend } from '../../../utilities/constants';

export default function Doctor_auth(props){

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [msg, setMsg] = useState("")


    const submitCredentials = async () => {

        let body = {
            "username": username,
            "password": password
        }

        const result = await axios.post(`${localBackend}authenticateDoctorEmployee`, body).then((resp) => {
            if(resp.data.statusCode === 1){
                setMsg("Login Success")
                props.loggedIn(resp.data.data)
            }

            else if(resp.data.statusCode === 0){
                setMsg("Invalid Username or Password")
            }
            else{
                setMsg("Something went wrong, Contact system administrator!")
            }
        })
    }

    return(
        <>
            <div>
                <div>Please enter your credentials to log in</div>

                <div>Username: </div>
                <input type="text" onChange={(e) => {
                    setUsername(e.target.value);
                }} />

                <div>Password: </div>
                <input type="password" onChange={(e) => {
                    setPassword(e.target.value);
                }} />

                <div onClick={(e) => {
                    submitCredentials()
                }}>Submit</div>

                <div>
                    {msg}
                </div>

            </div>
        </>
    )
}
