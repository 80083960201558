import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do you search for a good doctor?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Start your research by looking up reliable doctors in your area. Then, ask for recommendations from your family and friends. You can check the reviews of potential doctors and verify their credentials before making an appointment with them."
      }
    },{
      "@type": "Question",
      "name": "Can a doctor put someone in a nursing home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, a doctor can recommend a nursing home for a patient if they think it’s the best option for their medical needs."
      }
    },{
      "@type": "Question",
      "name": "What does doctor recommended mean?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "“Doctor recommended’ simply means that a physician has suggested a particular treatment, medication, or healthcare product. These recommendations are based on their professional expertise and assessment of the patient’s needs."
      }
    }]
  }
    
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>How to Find Reliable Home Visit Doctors in Your Area: A Comprehensive Guide</title>
        <meta name="description" content="Unlock the secrets to locating trustworthy home visit doctors in your area with Kyno Health's comprehensive guide. Discover expert tips and strategies for accessing convenient, personalized medical care from the comfort of your own home." data-react-helmet="true" />


        <link rel="canonical" href="https://www.kynohealth.com/blog/find-reliable-home-visit-doctors-guide" />
                             </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index