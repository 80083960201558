import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Get The Best of Medical Home Care: Kyno Health at your Service</h3>
   <p>What happens when there is an emergency situation at home and an ambulance isn't available? It is during these times that having the assurance of getting a doctor's consultation at home can provide much-needed relief. At Kyno Health, we are responsible for scheduling home visit doctors in delhi in the comfort of your house, ensuring quick options in times of medical crises. We have been transforming hundreds of lives daily, taking care of each and every patient who needs medical support as soon as possible. Hence, with Kyno Health, you can get a home visit from our experienced & dedicated General Physicians who are available 24X7. We bring to you the reliability of a 'family doctor' along with other at-home medical services.</p>
 <Link className="btn btn-warning " to="tel:+919355618123">Call me: 93556 18123</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='Topology' />
    </div>
    <div className='tag'>
    <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    {/* <img src='images/tags-1.png' alt='tags' /> */}
    </div>

    <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    {/* <img src={bnrImg} alt='bnr-img' className='bnrimg img-fluid'/> */}
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
