import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
  <h2>What is Loose Motion?</h2>
  <p>Loose motion, also known as diarrhea, is a common gastrointestinal problem that results in frequent, watery bowel movements. It can be caused by various factors, including infections, dietary issues, medications, and underlying health conditions. Loose motions often lead to dehydration and can be particularly concerning for children and the elderly.
</p>
<div className='ml-70'>
<div className='row'>
<h3>Expert Diagnosis and Treatment</h3>
<p>Our team of experienced doctors is dedicated to diagnosing the root cause of your loose motions and providing a comprehensive treatment plan. Here's what you can expect when you visit our clinic:</p>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Assessment</h4>
<p>We will conduct a detailed examination of your symptoms, medical history, and any recent changes in your lifestyle or diet. This helps us pinpoint the cause of your loose motions.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Diagnostic Tests</h4>
<p>In some cases, we may recommend diagnostic tests, such as stool analysis or blood tests, to identify any infections, inflammation, or underlying conditions contributing to your loose motions.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Treatment</h4>
<p>Once we've identified the cause, we'll create a personalized treatment plan tailored to your specific needs. This may include medications, dietary recommendations, and lifestyle changes.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Hydration Management</h4>
<p>Dehydration is a common concern with loose motions. Our team will provide guidance on staying adequately hydrated to prevent any complications.
</p>
</div>
</div>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Prevention and Education</h4>
<p>We believe in empowering our patients with knowledge. We will educate you on preventive measures and lifestyle changes to reduce the risk of recurrent loose motions.</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>When to Seek Medical Help</h2>
<p>While most cases of loose motion resolve on their own within a few days, there are situations when immediate medical attention is necessary. You should seek medical help if:
</p>
<div className=''>
<div className='row'>


<div className='col-md-12'>
<div className=''>
<ul>
  <li>Loose motions last for more than 48 hours</li>
  <li>You notice blood or mucus in your stool</li>
  <li>You experience severe abdominal pain or cramps</li>
  <li>Dehydration symptoms, such as extreme thirst, dry mouth, or dark urine, become apparent</li>
</ul>

</div>
</div>




{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
  
<div className='ml-70'>
<div className='row'>
<h3>Why Choose Us?</h3>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Experienced Professionals</h4>
<p>Our doctors have extensive experience in treating gastrointestinal conditions, including loose motions.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>We have modern facilities and equipment to ensure accurate diagnosis and effective treatment.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Compassionate Care</h4>
<p>We understand the stress and discomfort associated with loose motions, and our team provides caring and empathetic support.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Convenient Appointments</h4>
<p>We offer flexible appointment scheduling to accommodate your needs.
</p>
</div>
</div>
<div className='col-md-12'>
  <p>Don't let loose motions disrupt your life any longer. Take the first step toward relief by scheduling an appointment with our expert doctors. We are here to guide you on your journey to improved health, providing you with the care and attention you deserve.</p>
  <p>Contact us today to book your appointment, and let's work together to put an end to your loose motion discomfort. Your health is our priority.</p>
</div>

</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Our Commitment to Your Health</h2>
<p>At Kyno Health clinic, we are committed to providing compassionate and expert care to help you recover from loose motions. Our focus is on not only treating your immediate symptoms but also preventing their recurrence. We understand the discomfort and disruption loose motions can bring to your life, and we are dedicated to your well-being.</p>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index