import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
       <section className='thrd-fold'>
<div className='container'>
<h2 className='mb-2 text-center'>Get Treated by Doctors at the Comfort of your Home</h2>
<p className='text-center'>If you are wondering about our services, here is a quick guide to understanding us. Therefore, with Kyno Health, you can rely on compassionate, convenient, and comprehensive medical care. All of this is delivered directly to your home. With us, you will have peace of mind and timely assistance in need.</p>
<div className=''>
<div className='row'>


<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/excellence.svg' alt='excellence' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Excellence Assured</h4>
<p>At Kyno Health, we uphold the highest standards of medical care. This means we have been ensuring that each home visit is conducted with precision & expertise. Our team of experienced General Physicians undergo rigorous training & adhere to strict protocols. This is essential to guarantee excellence in every consultation. We prioritize patient safety & satisfaction foremost in our services. Hence, delivering personalized care customized to individual needs has become our daily ritual. Till today, we have served 5000 + happy patients, & each of them can vouch for our excellence & quality care. So you can have all the attentive care at your own home without even stepping outside. </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/Equipped.svg' alt='equipped' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Equipped & Ready</h4>
<p>Coming equipped and ready with medical kits or emergency medical kits is always on our list while visiting patients. We do this to ensure that we are always prepared to save our patient's lives and alleviate their suffering. This way, every patient will have a fair chance to be treated quickly before reaching the hospital. Even for general consultations, our medical supplies kit comes in handy to perform quick tests or evaluate our patients' vital signs. From diagnostic instruments to essential medications, we come prepared to address a wide range of medical concerns promptly and effectively. This minimizes the need for hospital visits whenever possible. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/consultation.svg' alt='consultation' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Detailed Consultation</h4>
<p>Having to walk to a clinic and wait for several hours to get a doctor's consultation can be a tedious and time-consuming process. Also, this may not be an ideal option for senior citizens or mothers having newborns. Also, there is an allocated time slot for every patient in such setups so that it doesn't encroach on any other patient's visit. However, with home visit doctors in delhi, you can have detailed consultations without the need to hurry or the need to step out of your home. You will be able to talk to our doctors in the utmost comfort and discuss your medical issues without being reminded to wrap up. We believe in thorough assessments and open communication to understand your medical history, concerns, and symptoms comprehensively.</p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/follow.svg' alt='follow' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Follow-up Assistance</h4>
<p>Our commitment to your well-being extends beyond the initial consultation. We offer dedicated follow-up assistance to ensure continuity of care and monitor your progress closely. Whether it is about scheduling additional visits, adjusting treatment plans, or providing ongoing support and guidance, we strive to be your trusted medical partner every step of the way. Hence, with a system of having a doctor at home, you will not only be assured of quick and detailed visits but also effective follow-up treatment plans. For people who are bedridden or suffering from any terminal illness, having follow-up assistance is a must, and at Kyno Health, we are happy to provide you with such constant service.</p>
</div>
</div>


</div>
</div></div>

</section>
    </>

  )
}

export default index