import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians at home in Gandhi Nagar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You will be able to find the best general physician near me at home in Gandhi Nagar, Delhi, through Kyno Health. We provide you with top-quality doctors who provide you with state-of-the-art treatment facilities at home. Our medical services are infused with the motto of kindness and compassion, and we believe in making them accessible and affordable."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and credentials should I look for when selecting a general physician for home visits in Gandhi Nagar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You should check whether the doctor has a degree like an MBBS, BAMS, or BHMS. Additionally, the doctor can have a specialization like an MD or an MS. At Kyno Health; all our doctors have undergone thorough checking of their background and qualifications. Many of our doctors have also been attached to reputable hospitals early in their careers."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians at home in Gandhi Nagar, Delhi experienced in handling various medical conditions and emergencies?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, all our general physicians are proficient when it comes to handling different medical conditions or emergencies. Trust in our doctors to provide you with the best solution, whether you are suffering from a common cold or going through an unfortunate medical emergency. We are here to make things easier for you, and we will do our best to ensure that you receive the best treatment possible."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for a home visit in Gandhi Nagar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can schedule an appointment by calling us at this number- +91-9355618123. Our receptionist will speak to you and quickly set you up with your first home visit consultation. If you are living in Gandhi Nagar, our doctors will reach you soon and get you checked. Our average response time is around 45 minutes."
      }
    },{
      "@type": "Question",
      "name": "What is the average cost of availing general physician services at home in Gandhi Nagar, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The average cost of a home visit consultation by a general physician at Gandhi Nagar, Delhi, is around Rs 999. We believe in making medical services reachable to all sections of society and hence have kept an affordable price. However, the cost may vary depending on emergencies or the time of the day you require our services."
      }
    }]
  }
  
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Gandhi Nagar, Delhi | Kyno Health</title>
        <meta name="description" content="Discover exceptional home healthcare with Kyno Health's leading general physicians in Gandhi Nagar, Delhi. Schedule a home visit for personalized medical care and peace of mind." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-at-home-in-gandhi-nagar-delhi" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Gandhi Nagar, Delhi" hero_paragraph="Are you looking for a doctor who can travel to your home and get you top-quality treatment? This is now possible with Kyno Health. We have some of the most experienced and Best General Physicians at Home in Gandhi Nagar, Delhi with us. They will provide you with top-notch medical care in the comfort of your home. We are an ideal solution for all your healthcare needs."/>
      
      <Content content_h3="Common Diseases and Conditions that General Physicians Treat at Home" content_p="General physicians treat a variety of common illnesses and conditions at home. They handle respiratory infections like the common cold, flu, and bronchitis. These ailments are common and can be effectively managed with home visits. General physicians ensure that patients get the necessary rest and treatment without visiting a clinic." content_p1="Chronic conditions such as diabetes and hypertension require regular monitoring and management. Home visits by general physicians allow for consistent care. They thus keep helping patients maintain their health and avoid complications. This approach ensures that treatment plans are customised to individual needs. This has also led to better health outcomes." content_p2="Minor injuries and acute conditions like headaches, gastrointestinal issues, and skin infections are also treated by general physicians at home. Immediate medical attention can prevent these conditions from worsening and provide quick relief. Home care ensures that patients receive timely treatment. It further reduces the risk of hospital visits."

      content_h3_2="Get Treated by Kyno Health's Best General Physicians at Your Home in Gandhi Nagar" content_p_2="You, too can now experience the best medical care in the comfort of your home. With Kyno Health's top general physician home visit near me in Gandhi Nagar, accessing home-visit general physicians has become easier. Our doctors offer personalized and convenient healthcare services for all. We ensure you receive quality treatment without the hassle of hospital visits. So book an appointment today itself! Prioritize your health with Kyno Health's home care services." />


      <Faqs items={faqscheme}/> 
      <Footer />
    </>
  )
}



export default index