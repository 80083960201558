import React from 'react'
import Slider from "react-slick";
import './Css/style.css';
import Image from 'react-image-webp';



function index() {
  const settings = {

    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
  
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

   };

 return (
  <>
  <section className='eight-fold'>
    <div className='side-l'> 
    <Image
      src={require('../Performance/images/side-l.png')}
      webp={require('../Performance/images/side-l.webp')}
      alt='sideLImg'
    />
    {/* <img src="images/side-l.png" alt='sidel' /> */}
    </div>
    <div className='side-r'> 
    <Image
      src={require('../Performance/images/side-r.png')}
      webp={require('../Performance/images/side-r.webp')}
      alt='sideRImg'
    />
    {/* <img src="images/side-r.png" alt='sider' /> */}
    </div>
  <div className='container'>
  <h5>Testimonials</h5>
  <div className='ml-70'>
    <h2>Transforming Lives -</h2>
    <h3>Words of Healing From Patients We've Served</h3></div>
    <Slider {...settings}>



     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/LSMathur-1.png')}
      webp={require('../Performance/images/testimonial/LSMathur-1.webp')}
      alt='LSMathurImg'
    />
          {/* <img src="images/testimonial/LSMathur-1.png" alt='LSmathur' />               */}
      <p>“Thank you Kyno health for helping me out when I was too weak to travel. I was suffering from diarrhea and dehydration and felt completely drained. Kyno's doctor visited and even started my IV treatment at home. They answered all my questions and were very polite and knowledgable.”</p>
      <h6>LS Mathur</h6>
      <p><span>Professional</span></p>
      </div>
     </div>

     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/Shibchetri-1.png')}
      webp={require('../Performance/images/testimonial/Shibchetri-1.webp')}
      alt='ShibchetriImg'
    />
          {/* <img src="images/testimonial/Shibchetri-1.png" alt='shibchetri' />               */}
      <p>“Kyno helped me at 2:30 AM in the night when my mother a heart and diabetes patient was suffering from brain fog. The doctor came within 45 minutes, understood her history and gave a detailed line of treatment. They followed up with me after the consultation too, to monitor my mother's health and ensured her recovery”</p>
      <h6>Shib Chetri</h6>
      <p><span>Professional</span></p>
      </div>
     </div>

     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/Ravishankar-1.png')}
      webp={require('../Performance/images/testimonial/Ravishankar-1.webp')}
      alt='RavishankarImg'
    />
          {/* <img src="images/testimonial/Ravishankar-1.png" alt='ravishankar' />               */}
      <p>“I’m glad I don’t have to wait in long queues in hospitals for my senior citizen parents’ regular health checkups. Kyno is my go-to for regular health checkups and periodic tests of my parents.”</p>
      <h6>Ravi Shankar Prasad</h6>
      <p><span>Professional</span></p>
      </div>
     </div>

     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/Savita-1.png')}
      webp={require('../Performance/images/testimonial/Savita-1.webp')}
      alt='SavitaImg'
    />
          {/* <img src="images/testimonial/Savita-1.png" alt='savita' />               */}
      <p>“Great service by doctors. Thank you Kyno Health's Doctor for proactive follow-ups after the consultation. We didn’t find the medicine prescribed by him, but he was quite polite and approachable and gave us alternatives for the same medicine.”</p>
      <h6>Savita</h6>
      <p><span>Housewife</span></p>
      </div>
     </div>

     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/Nirmala-1.png')}
      webp={require('../Performance/images/testimonial/Nirmala-1.webp')}
      alt='NirmalaImg'
    />
          {/* <img src="images/testimonial/Nirmala-1.png" alt='nirmala' />               */}
      <p>“My father suffers from Parkinson's and he is bedridden. He recently developed bed sores. Kyno Health's doctor guided us on how we can take care of his bed sores and ensure they do not reoccur. They even provided us with trained nursing staff who visits regularly for his dressing."</p>
      <h6>Nirmala</h6>
      <p><span>Professional</span></p>
      </div>
     </div>

     {/* <div className='col-md-4'>
          <div className="mentor-profile">
          <img src="images/testimonial/Nirmala-1.png"/>              
      <p>“My father suffers from Parkinson’s and is never comfortable going to the hospital. He recently developed bed sores. I reached out to Kyno as I didn’t know how to take him to the hospital. Dr. Indrajeet from Kyno helped with relieving her bed sores - and also advised for further tests and precautions. His consultation lasted more than half an hour and he patiently answered all our questions. They also sent a nurse for us, Shobha, who is also really well-trained and polite. She is careful during dressings and shifting. It was on Dr Indrajeet’s advice that we got physiotherapy for him at home, which has helped her immensely."</p>
      <h6>Alpesh Salva</h6>
      <p><span>Nirmala</span></p>
      </div>
     </div> */}

     <div className='col-md-4'>
          <div className="mentor-profile">
          <Image
      src={require('../Performance/images/testimonial/Aaliya-1.png')}
      webp={require('../Performance/images/testimonial/Aaliya-1.webp')}
      alt='AaliyaImg'
    />
          {/* <img src="images/testimonial/Aaliya-1.png" alt='Aaliya' />               */}
      <p>“I recently Shifted from South Africa to Delhi and my daughter fell sick. Kyno health's doctor came to our rescue with their service available at home. We were very satisfied with our experience that we have also scheduled visits for my 65 year old mother's regular diabetic check-ups.”</p>
      <h6>Aaliya</h6>
      <p><span>Professional</span></p>
      </div>
     </div>

    </Slider>
   </div>
   </section>
   </>
 )
}

export default index