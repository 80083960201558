import React from 'react'
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (
    <>
      <section className="ms">
        <div className="container">
          <h2 className='text-center'>Benefits of Home Doctors</h2>
          <p className='text-center'>Apart from the above-mentioned services, one may ask about the other potential benefits of home doctors. Here is a comprehensive list of the benefits.</p>
          <ul>
            <li><strong>Convenience: </strong>Home doctors offer unparalleled convenience by bringing medical care directly to your doorstep. This eliminates the need for travel, waiting rooms, and long wait times. This is especially beneficial for individuals with mobility issues, seniors, or new mothers.</li>
         
            <li><strong>Timely Assistance: </strong>In emergency situations where an ambulance may not be available, home doctors provide prompt medical attention. This guarantees timely intervention and potentially life-saving treatment right in the comfort of your home.</li>
          
            <li><strong>Comfort and Privacy: </strong>
            Patients can discuss their medical concerns in a comfortable and private setting. There won&rsquo;t be distractions and time constraints of a clinic. This promotes open communication and a deeper understanding of the patient's health needs.</li>
      
            <li><strong>Comprehensive Services: </strong>Home doctor in delhi come equipped with the necessary tools and medications to address a wide range of medical issues. Be it any minor ailment or a more complex condition, our top doctors can take care of everything. This approach minimizes the need for hospital visits whenever possible.</li>
        
            <li><strong>Continuity of Care: </strong>
            Home doctors provide ongoing follow-up assistance, ensuring continuity of care and monitoring patients' progress closely. This proactive approach to healthcare helps prevent complications and promotes better long-term health outcomes.</li>
         
            <li><strong>Peace of Mind: </strong>
            Knowing that a qualified medical professional is just a phone call away can provide peace of mind, especially during times of medical crises or when managing chronic illnesses. Home doctors offer reassurance and support. This can be beneficial to enhancing overall well-being and quality of life.</li>
  
            <li><strong>Flexibility: </strong>Home doctor services offer flexibility in scheduling appointments. This allows patients to receive care at a time that suits their schedule best. This flexibility is particularly valuable for busy individuals or those with demanding work or family commitments.</li>
            </ul>
        </div>
      </section>


      <section className="seven-fold">
        <div className="container">
          <div className="gradss">
            <Image
              src={require('../../Performance/images/select-Gradient.png')}
              webp={require('../../Performance/images/select-Gradient.webp')}
              alt='gradientImg'
            />
            <img src="images/select-Gradient.png" alt='select' />
          </div>

          <h5>Our Various Services</h5>

          <div className='seven-l-side'>

            <h2 className='text-center'>Avail Kyno Health's Services at Home Today</h2>
            <p>Apart from all of the benefits of having home doctor service, we also provide other health services at Kyno Health. All of these services combined can ensure we provide the utmost priority to your health and get you back to normalcy soon. Here is the list of other services that we provide.</p>

          </div>

          <div className='thrs-part ms-0 '>
            <div className='row'>


              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Diagnostics support at home </h4>
                  <img src='images/Vaccine.svg' alt='vaccine' />

                  <p>Diagnostics is an essential stage in any medical checkup or treatment to find out the nature of the illness and rule out other diseases. Oftentimes, diagnostics require people to visit multiple centers to get a test done. This may include visits to a pathology lab or an MRI imaging center. However, with Kyno Health, all of these services are easily available at your own home. You won't need to move even an inch to get your tests done. From collecting blood samples to performing an ECG or X-ray, we are here to help you navigate your medical journey. We send trained professionals who can take care of all these diagnostic evaluations and deliver the reports in no time.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Ambulance Assistance</h4>
                  <img src='images/trained.svg' alt='trained' />
                  <p>It can be scary when someone you care about needs an immediate hospital visit but aren't able to find a suitable vehicle to take them in. Again, we have emergency ambulance services that are equipped with state-of-the-art medical supplies, emergency kits, and oxygen supplies that can reach you in no time and take the patient to the nearest hospital. With our professional drivers and medical assistants, an ambulance will be ready to pick up the patient and keep providing constant support until you reach a hospital.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Physiotherapy at Home</h4>
                  <img src='images/ambulance.svg' alt='ambulance' />
                  <p>For people with orthopaedic conditions or accident-related illnesses, there may be the need to have physiotherapy services. It can become an obstacle if a person needs to constantly visit the clinic to get a therapy session. However, with Kyno Health, we have an easy solution to this issue as we have the top physiotherapists in Delhi who can reach your home in no time and conduct physiotherapy sessions daily.</p>
                </div></div>

              <div className='col-lg-6'>
                <div className='thrs-one w-100'>
                  <h4>Nursing Care</h4>
                  <img src='images/need.svg' alt='need' />
                  <p>Oftentimes, taking care of loved ones who are ill can become hectic and complicated. It is also natural to realize that common people aren't equipped with medical knowledge to take care of patients in such situations. It is during those times that nursing care is needed at home. At Kyno Health, our nursing care services ensure a nurse stays by your loved one all the time and takes day-to-day care of them. This would ensure things like giving daily medicines or knowing what to do during a medical emergency. </p>
                </div></div>

            </div>

          </div>


        </div>
      </section>
    </>
  )
}

export default index