import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Why is controlling blood pressure important?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Controlling blood pressure is crucial because high blood pressure, or hypertension, can lead to serious health issues, including heart disease, stroke, and kidney problems."
      }
    },{
      "@type": "Question",
      "name": "What is considered normal blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Normal blood pressure is typically around 120/80 mmHg. Blood pressure is measured in millimeters of mercury (mmHg) and consists of two numbers: systolic pressure (the force when the heart beats) and diastolic pressure (the force when the heart is at rest between beats)."
      }
    },{
      "@type": "Question",
      "name": "How can diet help control blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A heart-healthy diet, such as the DASH (Dietary Approaches to Stop Hypertension) diet, emphasizes fruits, vegetables, whole grains, lean proteins, and low-fat dairy. Additionally, reducing sodium intake and increasing potassium-rich foods contribute to blood pressure control."
      }
    },{
      "@type": "Question",
      "name": "Why is maintaining a healthy weight important for blood pressure control?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Being overweight or obese is a significant risk factor for hypertension. Losing even a small amount of weight can have a positive impact on blood pressure."
      }
    },{
      "@type": "Question",
      "name": "What types of exercise help with blood pressure control?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Regular physical activity, such as brisk walking, swimming, cycling, or jogging, can improve cardiovascular health and contribute to lower blood pressure. Aim for at least 150 minutes of moderate-intensity exercise per week."
      }
    },{
      "@type": "Question",
      "name": "How does alcohol consumption affect blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Excessive alcohol intake can contribute to high blood pressure. Moderation is key, with up to two drinks per day for men and up to one drink per day for women considered acceptable."
      }
    },{
      "@type": "Question",
      "name": "Does smoking impact blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, smoking damages blood vessels and contributes to the hardening of arteries, raising blood pressure. Quitting smoking is a crucial step towards better cardiovascular health and blood pressure control."
      }
    },{
      "@type": "Question",
      "name": "Can stress affect blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Chronic stress can contribute to elevated blood pressure. Stress-management techniques, such as deep breathing, meditation, and engaging in relaxing activities, can help with blood pressure control."
      }
    },{
      "@type": "Question",
      "name": "How does sleep affect blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Poor sleep quality and duration can impact blood pressure. Aim for 7-9 hours of quality sleep per night, establish a regular sleep routine, and create a comfortable sleep environment."
      }
    },{
      "@type": "Question",
      "name": "Is home blood pressure monitoring important?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, home blood pressure monitoring allows for proactive management. Regularly tracking your blood pressure at home with a reliable monitor can provide valuable information for you and your healthcare provider."
      }
    },{
      "@type": "Question",
      "name": "Are there specific foods to avoid for blood pressure control?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Foods high in sodium, saturated fats, and cholesterol should be limited for blood pressure control. Processed foods, fast foods, and excessive consumption of red meat are examples of items that may contribute to hypertension."
      }
    },{
      "@type": "Question",
      "name": "Can caffeine intake affect blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "While the relationship between caffeine and blood pressure is complex, excessive intake can lead to a temporary spike. Individuals sensitive to caffeine may consider limiting their intake, especially in the afternoon and evening."
      }
    },{
      "@type": "Question",
      "name": "Do I need medication to control blood pressure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In some cases, lifestyle changes alone may not be sufficient, and medication prescribed by your healthcare provider may be necessary. It's crucial to take prescribed medications consistently and attend follow-up appointments."
      }
    }]
  }
  
  
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>What is Diabetes? (+ Types, Causes, Symptoms & Treatment)</title>
        <meta name="description" content="Diabetes is a prevalent and chronic medical condition that affects millions of people worldwide. It occurs when the body struggles to regulate blood sugar levels, leading to a range of health issues." data-react-helmet="true" />

        <meta name="keywords" content="what is diabetes, what causes diabetes, what are the symptoms of diabetes, what foods to avoid with diabetes, what are 10 warning signs of diabetes" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/what-is-diabetes/"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index