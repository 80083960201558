import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'

import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {


  return (
    <>
      
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Stay Safe this Summer: Avoid Fake ORS, Choose WHO-Approved Hydration</title>
        <meta name="description" content="Learn how to stay hydrated and safe this summer by avoiding fake ORS products. Discover the benefits of choosing WHO-approved hydration solutions to keep you and your family healthy." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/stay-safe-this-summer-choose-who-approved-ors"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
  
      <Footer />
    </>
  )
}





export default index