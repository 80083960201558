import React from 'react'
import './Css/style.css'

function index() {
  return (

    <>
       <section className="baner-region_aboutus">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    {/* <h3>Meet our Doctors & Nurses</h3> */}
    <h1>Contact Us</h1>
    <p>Thank you for reaching out to us! We're here to assist you. Please feel free to contact us using the information below:</p>
    
    
{/* <a className="btn btn-warning " href="#">Call us Now!</a> */}
  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  {/* <div className='aft'>
    <img src='images/Topology-1.svg'/>
    </div>
    <div className='tag'>
    <img src='images/Tags.png'/>
    </div> */}

    <img src='images/bnr_abt.svg' className='bnrimg img-fluid'/>
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
