import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the process of taking online doctor consultations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To schedule an online doctor consultation, simply sign up on a telemedicine platform, select a doctor, and make an appointment. Then, join the virtual session via video call or chat to discuss your health concerns and receive medical advice."
      }
    }, {
      "@type": "Question",
      "name": "What benefits can you get from homecare Services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Homecare services provide convenience, personalized treatment, comfort, and high-quality medical attention in the privacy of your own home. They relieve the stress of clinic appointments while ensuring prompt and effective healthcare delivery."
      }
    }, {
      "@type": "Question",
      "name": "What is the cost of home care services in Noida?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The cost of home care services in Noida varies based on the type of services requested, the duration of care, and the provider. Prices can range from low hourly rates to extensive care packages."
      }
    }, {
      "@type": "Question",
      "name": "How do you have a successful home visit?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To ensure a successful home visit, discuss your medical needs and preferences clearly with the home care provider. Prepare your home setting, present all required medical data, and actively participate in treatment planning and follow-up recommendations."
      }
    }]
  }



  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Benefits of Choosing Doctor at Home Services for Noida Residents: Convenience, Comfort, and Quality Care</title>
        <meta name="description" content="Discover the unparalleled convenience, comfort, and quality care offered by Kyno Health's Doctor at Home services in Noida residents. Experience health care on your terms, where the doctor comes to you, ensuring personalized attention and peace of mind." data-react-helmet="true" />
        <link rel="canonical" href="https://www.kynohealth.com/blog/doctor-at-home-services-noida" />
      </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index