import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is the process of taking online doctor consultations?</Accordion.Header>
                    <Accordion.Body>
                      <p>To schedule an online doctor consultation, simply sign up on a telemedicine platform, select a doctor, and make an appointment. Then, join the virtual session via video call or chat to discuss your health concerns and receive medical advice.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What benefits can you get from homecare Services?</Accordion.Header>
                    <Accordion.Body>

                      <p>Homecare services provide convenience, personalized treatment, comfort, and high-quality medical attention in the privacy of your own home. They relieve the stress of clinic appointments while ensuring prompt and effective healthcare delivery.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What is the cost of home care services in Noida?</Accordion.Header>
                    <Accordion.Body>

                      <p>The cost of home care services in Noida varies based on the type of services requested, the duration of care, and the provider. Prices can range from low hourly rates to extensive care packages.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How do you have a successful home visit?</Accordion.Header>
                    <Accordion.Body>
                      <p>To ensure a successful home visit, discuss your medical needs and preferences clearly with the home care provider. Prepare your home setting, present all required medical data, and actively participate in treatment planning and follow-up recommendations.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index