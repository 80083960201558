import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is the meaning of doctor on call?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Doctor on call refers to a medical service where qualified healthcare professionals are available to provide immediate medical assistance outside regular clinic hours. They offer timely consultations, diagnoses, and treatments in a location convenient to the patient, ensuring access to expert medical care whenever needed.

                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the benefits of a doctor on call?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Benefits of doctor on call include prompt medical assistance, convenience, personalized care, and access to expert advice.
</p>



                    </Accordion.Body>
                  </Accordion.Item>



                  


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>What is the advantage of online doctor consultation?
</Accordion.Header>
                    <Accordion.Body>

                    <p>Online doctor consultation offers convenience, quick access to medical advice, no travel hassle, and the ability to discuss health concerns from the comfort of your own space.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What is the highest level doctor?</Accordion.Header>
                    <Accordion.Body>

                      <p>The highest level doctor is a medical specialist known as a 'Consultant' or 'Attending Physician.' They possess extensive experience, advanced training, and expertise in a specific field of medicine, often guiding complex cases and supervising medical teams.
</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index