import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Understanding Blood Pressure</h2>
  <p>Blood pressure is the force of blood against the walls of your arteries as the heart pumps it around your body. It's typically measured in two values: systolic pressure (when the heart beats) and diastolic pressure (when the heart rests between beats). A normal blood pressure reading is around 120/80 mm Hg.</p>

  <h2>The Importance of Managing Your Blood Pressure</h2>
  <p>Maintaining a healthy blood pressure level is crucial for your overall well-being. When blood pressure becomes consistently elevated, it can lead to various health problems, including:</p>

<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Heart Disease</h4>
<p>High blood pressure is a major risk factor for heart disease, which can result in heart attacks, heart failure, and other complications.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Stroke</h4>
<p>Hypertension can damage blood vessels in the brain, increasing the risk of strokes.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Kidney Problems</h4>
<p>High blood pressure can harm the kidneys over time, potentially leading to kidney disease.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Eye Issues</h4>
<p>Hypertension may damage blood vessels in the eyes and lead to vision problems.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Artery Damage</h4>
<p>Elevated blood pressure can result in hardening and narrowing of the arteries, making it difficult for blood to flow, which can affect various organs.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Other Health Issues</h4>
<p> High blood pressure has also been linked to an increased risk of vascular dementia, sexual dysfunction, and more.</p>
</div>
</div>

</div>

</div>
</div>

</section>

{/* <section className='white-bg'>
<div className='container'>
<h2>Conditions We Treat</h2>
<p>Our team is experienced in managing a wide range of pain conditions, including but not limited to:</p>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Other Health Issues</h4>
<p> High blood pressure has also been linked to an increased risk of vascular dementia, sexual dysfunction, and more.</p>

</div>
</div>
</div>
</div>
</div>

</section> */}

<section className='thrd-fold'>
<div className='container'>
  <h2>Our Approach to Blood Pressure Management</h2>
  <p>At Kyno Health clinic, we take a comprehensive and patient-focused approach to managing blood pressure. Our goal is to help you maintain a healthy blood pressure level and reduce the risk of associated health problems. Here's what you can expect when you choose our services:
</p>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Evaluation</h4>
<p>Our experienced doctors will conduct a comprehensive evaluation of your blood pressure and overall health. They will take into consideration your medical history, lifestyle factors, and potential risk factors.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Individualized Treatment</h4>
<p>We understand that every patient is unique. Your treatment plan will be tailored to your specific needs, taking into account your current blood pressure, any underlying medical conditions, and your goals for managing your blood pressure.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Medication Management</h4>
<p>In some cases, medication may be necessary to control high blood pressure. Our doctors will carefully select and monitor the use of medications to achieve the best results with minimal side effects.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Lifestyle Modifications</h4>
<p>Lifestyle changes are a cornerstone of blood pressure management. We will work with you to develop a plan that includes dietary adjustments, regular physical activity, stress management, and weight management.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Regular Monitoring</h4>
<p>Managing blood pressure is an ongoing process. Our team will ensure regular follow-up appointments to monitor your progress and adjust your treatment plan as needed.</p>
</div>
</div>



</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>

  <h3>Preventing High Blood Pressure</h3>
  <p>Prevention is a vital part of our approach. In addition to managing existing hypertension, we're committed to educating you about how to prevent high blood pressure. Our team will provide guidance on:</p>
    <ul>
      <li>A heart-healthy diet</li>
      <li>Regular exercise</li>
      <li>Stress reduction techniques</li>
      <li>Reducing sodium intake</li>
      <li>Limiting alcohol consumption</li>
      <li>Maintaining a healthy weight</li>
    </ul>
</div>
</section>

<section className='thrd-fold'>
<div className='container'>
  <h2>Why Choose Us for Your Blood Pressure Management Needs?
</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Expertise</h4>
<p>Our doctors are specialists in blood pressure management, providing you with the highest level of care.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Compassion</h4>
<p>We understand the importance of supporting you in your journey to better heart health. Our team is committed to providing compassionate care throughout your treatment.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>We have modern facilities and the latest technology to ensure accurate diagnosis and effective treatments.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Holistic Approach</h4>
<p>We focus on not only managing blood pressure but also improving your overall well-being and heart health.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Appointments</h4>
<p>We offer convenient appointment scheduling, making it easier for you to access the care you need.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Take Control of Your Blood Pressure
</h4>
<p>Your blood pressure management is essential to maintaining good heart health and overall well-being. Don't let hypertension go untreated or unmanaged. Take the first step towards better heart health by scheduling an appointment with our expert team.

Contact us today to book your consultation and embark on your journey towards achieving a healthy blood pressure and a healthier you. Your heart health is our priority.
</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index