import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'

function index() {
  return (
    <>
                    <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Accessible 24/7 Doctor Services: Expert Medical Care Anytime</title>
                    <meta name="description" content="Get immediate medical care with our 24/7 doctor services. Expert healthcare available around the clock. Contact us for prompt medical assistance." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/twenty-four-hour-visiting-doctor"/>

            </Helmet>
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}

export default index