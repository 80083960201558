import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>

    <section className='sec_fold_abt'>


      <div className='container'>
      <div className='d_qts_icon'></div>
        <div className='d_qts_icon2'></div>

        <div className='row'>

            <div className='sec_fold_abt_qts'>

            <p>At Kyno, we are driven by the zeal to put 'comfort' back into modern medical services. Our mission is to make the best medical support accessible to society at large without burning a hole in their pocket. </p>

            <p>Our team consists of qualified, trained, & sensitized doctors & medical staff. We believe that apart from the best quality of medicines & healthcare equipment, it is equally important to train our teams to be humane and kind. We offer a well-rounded medical ecosystem with a range of at-home medical services, including doctor-at-home, medicine delivery, diagnostic support, elder-care and 24X7 caretakers, nurses, & more.</p>
          </div>

            <div className='sec_fold_abt_img'>
            <img src='../images/doct_abt_secfold.svg'/>


          </div>


        </div>
      </div>
    </section>
    
    </>
  )
}

export default index