import React from 'react'
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2 className='text-center'>Services we offer</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Pediatric-consultations.jpg')}
      webp={require('../../images/Pediatric-consultations.webp')}
      style={{width: '100px'}}
      alt='PediatricConsultationsImg'
    />
{/* <img src='images/Pediatric-consultations.jpg' alt='tags' width='100px'/> */}
<h4>Pediatric Consultations</h4>
<p>Expert pediatricians offer thorough consultations in the convenience of your home. Whether it's a minor concern, ongoing condition, or seeking advice, our pediatric specialists are here to assess, diagnose, and prescribe appropriate treatments for your child.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Urgent-Care.jpg')}
      webp={require('../../images/Urgent-Care.webp')}
      style={{width: '100px'}}
      alt='UrgentCareImg'
    />
{/* <img src='images/Urgent-Care.jpg' alt='tags' width='100px'/> */}
<h4>Urgent Care </h4>
<p>Health emergencies can arise at any time. Our home pediatric service delivers immediate care for non-life-threatening situations, guaranteeing swift assistance whenever your child needs it</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Prescription-Services.png')}
      webp={require('../../images/Prescription-Services.webp')}
      style={{width: '100px'}}
      alt='PrescriptionServicesImg'
    />
{/* <img src='images/Prescription-Services.png' alt='tags' width='100px'/> */}
<h4>Prescription Services</h4>
<p>Our home pediatricians offer prescription services, delivering necessary medications directly to you. Skip the pharmacy trip and rest assured your child gets the right treatment with ease.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Diagnostic-Tests.png')}
      webp={require('../../images/Diagnostic-Tests.webp')}
      style={{width: '100px'}}
      alt='DiagnosticTestsImg'
    />
{/* <img src='images/Diagnostic-Tests.png' alt='tags' width='100px'/> */}
<h4>Diagnostic Tests</h4>
<p>Accurate evaluations often require diagnostic tests. Our home pediatric service arranges convenient tests, including blood and urine tests, at your home, ensuring thorough medical assessments without the hassle.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Vaccinations.png')}
      webp={require('../../images/Vaccinations.webp')}
      style={{width: '100px'}}
      alt='VaccinationsImg'
    />
{/* <img src='images/Vaccinations.png' alt='tags' width='100px'/> */}
<h4>Vaccinations </h4>
<p>Vaccinations Keep your child's vaccinations current without leaving home. Our home pediatric service provides a variety of vaccinations, ensuring your family stays protected from preventable diseases.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem5'>
<Image
      src={require('../../images/Follow-ups.png')}
      webp={require('../../images/Follow-ups.webp')}
      style={{width: '100px'}}
      alt='FollowUpsImg'
    />
{/* <img src='images/Follow-ups.png' alt='tags' width='100px'/> */}
<h4>Regular Follow-ups </h4>
<p>We prioritize regular and timely follow-ups to ensure your child's full recovery. Our dedicated team stays connected, monitoring progress closely and providing continuous support on the journey to optimal health.
</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2 className='text-center'>Our Experts</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-6'>
<div className='programitem6'>
<Image
      src={require('../../images/sai.jpg')}
      webp={require('../../images/sai.webp')}
      style={{width: '100px'}}
      alt='saiImg'
    />
<h4>Dr. Sai Manoj Koduri </h4>
<p><strong>Qualifications:</strong> M.B.B.S, D.N.B, IDPCCM (Paediatric)</p>
<p><strong>Overall Expérience:</strong> 6+ Years</p>
</div>
</div>

<div className='col-md-6'>
<div className='programitem6'>
<Image
      src={require('../../images/arif.jpg')}
      webp={require('../../images/arif.webp')}
      style={{width: '100px'}}
      alt='arifImg'
    />
<h4>Dr. Arif Hussain </h4>
<p><strong>Qualifications:</strong> M.B.B.S, MD (Paediatric)</p>
<p><strong>Overall Expérience:</strong> 11+ Years</p>
</div>
</div>

</div>
</div>
</div>

</section>

<section class="thrd-fold">
    <div class="container">
        <h2 className='text-center'>Benefits of Choosing Our Home Pediatrician Service</h2>
        <div class="ml-70">
            <div class="row">
                <div class="col-md-4">
                    <div class="programitem4 green">
                        <h5>Convenience</h5>
                       
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="programitem4 orange">
                        <h5>Personalized Care</h5>
                       
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="programitem4 green">
                        <h5>Reduced Risk of Infection</h5>
                       
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="programitem4 orange">
                        <h5>Comfort and Peace of Mind</h5>
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="programitem4 green">
                        <h5>Family-Focused</h5>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg">
    <div class="container">
        <h2 className='text-center'>How It Works</h2>
        <div class="ml-70">
            <div class="row">
                <div class="col-md-3">
                    <div class="programitem2">
                        <h4>Schedule an Appointment</h4>
                        <p>Call our hotline or use our online booking system to schedule an appointment at your preferred time.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="programitem2">
                        <h4>Doctor's Visit</h4>
                        <p>Our qualified doctor will arrive at your doorstep at the scheduled time. They will conduct a thorough examination, provide a diagnosis, and discuss treatment options.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="programitem2">
                        <h4>Treatment and Medication</h4>
                        <p>If necessary, the doctor will provide treatments and medications on the spot or arrange for delivery to your home.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="programitem2">
                        <h4>Follow-Up</h4>
                        <p>Our team will ensure proper follow-up, monitoring your progress and adjusting treatments if needed.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






    </>

  )
}

export default index