import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When should I consult a doctor for loose motion?
</Accordion.Header>
                    <Accordion.Body>
                      <p>You should consider seeing a doctor if your loose motions persist for more than 48 hours, are accompanied by severe abdominal pain, contain blood or mucus, or if you're showing signs of dehydration, such as extreme thirst and dark urine.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What can I expect during a doctor's appointment for loose motion?</Accordion.Header>
                    <Accordion.Body>

                      <p>During your appointment, the doctor will perform a thorough assessment of your symptoms, medical history, and recent dietary or lifestyle changes. They may recommend diagnostic tests like stool analysis or blood tests to identify the underlying cause. Based on their findings, they will create a personalized treatment plan.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>How is dehydration managed in cases of loose motion?</Accordion.Header>
                    <Accordion.Body>
                      <p>Dehydration is a common concern with loose motions. Your doctor will provide guidance on staying hydrated, which may involve oral rehydration solutions, intravenous fluids, or specific dietary recommendations to prevent further fluid loss.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What are the common causes of loose motion?
</Accordion.Header>
                    <Accordion.Body>
                    <p>Loose motions can be caused by various factors, including viral or bacterial infections, food poisoning, dietary intolerance, medications, stress, or underlying medical conditions. Your doctor will help determine the specific cause in your case.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Are there any home remedies I can try for loose motion before seeing a doctor?
</Accordion.Header>
                    <Accordion.Body>
                      <p>While staying hydrated is crucial, you can also try consuming bland, easily digestible foods and avoiding spicy or fatty foods. Over-the-counter medications like anti-diarrheals may provide temporary relief, but it's advisable to consult a doctor for a proper diagnosis and treatment plan.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>How can I prevent recurrent episodes of loose motion?</Accordion.Header>
                    <Accordion.Body>

                      <p>Your doctor will educate you on preventive measures, including good hygiene practices, proper food handling, and dietary modifications if necessary. Identifying and managing any underlying health conditions is also essential in preventing recurrence.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
           
             

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>What diagnostic tests might be recommended for loose motions?</Accordion.Header>
                    <Accordion.Body>
                      <p>Stool analysis and blood tests are commonly used to determine the cause of loose motions. Your doctor will decide which tests are necessary based on your specific symptoms and medical history.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can loose motion be a sign of a more serious condition?</Accordion.Header>
                    <Accordion.Body>
                      <p>In some cases, chronic or severe loose motions can be indicative of underlying health conditions such as irritable bowel syndrome (IBS), inflammatory bowel disease (IBD), or gastrointestinal infections. Your doctor will conduct a thorough evaluation to rule out any serious concerns.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Are there lifestyle changes that can help manage loose motions?</Accordion.Header>
                    <Accordion.Body>
                      <p>Your doctor may recommend dietary changes, stress management, and better hydration practices to help manage loose motions. Following these recommendations can significantly reduce the frequency of episodes.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>What are the advantages of seeking a doctor's help for loose motion instead of self-medicating?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Consulting a doctor ensures an accurate diagnosis and a tailored treatment plan based on the specific cause of your loose motions. Self-medication without proper evaluation can sometimes mask underlying health issues or lead to ineffective treatment.</p>

<p>Remember that it's always best to consult a healthcare professional for persistent or severe loose motions to receive the most appropriate care and guidance for your specific situation.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index