import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Css/style.css'


function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>


                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How can I get a doctor's appointment?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Getting a doctor's appointment is simple. Use our online booking system to choose your preferred date and time. Alternatively, call our dedicated hotline for assistance. We make accessing healthcare easy and convenient. Schedule your appointment today for expert medical care.


                      </p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is a doctor's appointment?
</Accordion.Header>
                    <Accordion.Body>

                      <p>A doctor appointment is a scheduled meeting with a medical professional to discuss your health concerns, receive diagnoses, treatments, or check-ups. It ensures personalized care and timely attention to your medical needs. Schedule a doctor's appointment with us for comprehensive healthcare services.
</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>What is the doctor who comes to your house?</Accordion.Header>
                    <Accordion.Body>

                      <p>The doctor who comes to your house is a qualified medical professional from our home visit service. They offer a range of medical services, including consultations, urgent care, prescriptions, and diagnostic tests, all provided at your doorstep. This convenient and personalized healthcare approach ensures you receive expert medical attention in the comfort of your own home. Schedule a visit now for comprehensive and hassle-free medical care.
</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                  


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              <Accordion.Item eventKey="3">
                    <Accordion.Header>How do I message a doctor appointment?
</Accordion.Header>
                    <Accordion.Body>

                    <p>Messaging for a doctor appointment is hassle-free. Utilize our secure online platform or app to send your preferred date and time. Alternatively, call our helpline for quick assistance. Experience convenient communication for scheduling your appointment, ensuring you receive timely medical care.</p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How can I book a doctor's appointment online?</Accordion.Header>
                    <Accordion.Body>

                      <p>Booking a doctor's appointment online is simple. Visit our website, choose your preferred healthcare provider, date, and time, then follow the prompts to confirm. Enjoy the convenience of scheduling at your fingertips, 24/7. Experience efficient and personalized healthcare – book your appointment online today.</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  {/* <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you provide online consultations?</Accordion.Header>
                    <Accordion.Body>

                      <p>We specialize in providing home-visitations. Online consultations are done mostly in two cases:</p>
                      <p>-	For proactive follow-ups after your consultation or in case you have any additional queries.<br/>
-	In case of an emergency, where the doctor may advise immediate action. </p>



                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How and when do I make the payment?</Accordion.Header>
                    <Accordion.Body>

                      <p>Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!</p>



                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Which other medical services do you provide?</Accordion.Header>
                    <Accordion.Body>

                    <p>You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services.</p>



                    </Accordion.Body>
                  </Accordion.Item> */}


                </Accordion>

              </div>

            </div></div></div>
      </section>


    </>
  );
}

export default index