import React from 'react'

function index(props) {


  
  return (
    <>
      <section className='ms'>
        <div className='container'>
          <h3>{props.content_h3}</h3>
          <p>{props.content_p}</p>
          <p>{props.content_p1}</p>
          <p>{props.content_p3}</p>
          <h3>{props.content_h3_2}</h3>
          <p>{props.content_p_2}</p>
          <p>{props.content_p_2a}</p>
          <p>{props.content_p_2b}</p>
        </div>

      </section>
    </>

  )
}

export default index