import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the root cause of dandruff and itchy scalp?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The root cause for dandruff and itchy scalp stands to be yeast overgrowth and dry or oily skin. However, if you are suffering from an itchy scalp without dandruff, it might be due to genetic factors or an immune system malfunctioning."
      }
    },{
      "@type": "Question",
      "name": "How do I stop my scalp from itching and dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can use ingredients such as lemon juice, orange peel, olive oil, etc., to combat itching and dandruff. Moreover, if you are having trouble managing it all alone, you can reach out to a certified hair clinic to get the best cure for dandruff and a dry itchy scalp."
      }
    },{
      "@type": "Question",
      "name": "How do you permanently get rid of dandruff?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can seek the medical supervision of a renowned hair clinic or specialist to get a commendable cure for itchy scalp and dandruff. Also, using medicated anti-dandruff shampoos can be beneficial."
      }
    },{
      "@type": "Question",
      "name": "Why have I started getting dandruff and an itchy scalp?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Some of the primary causes behind sudden dandruff formation and itchiness are stress, sensitive hair products, and unfavorable weather conditions. Seek medical attention to manage the situation if necessary."
      }
    }]
  }
 

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Understanding the Causes of Dandruff and Itchy Scalp: A Holistic View | Kyno Health</title>
        <meta name="description" content="Explore the causes of dandruff and itchy scalp from a holistic perspective. Learn how Kyno Health can help you achieve a healthy, dandruff-free scalp." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/blog/causes-of-dandruff-and-itchy-scalp"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index