import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'



function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are the best general physicians providing home visits in Punjabi Bagh, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You will find that all the best general physicians providing home visits in Punjabi Bagh, Delhi are from Kyno Health. Their doctors are well-trained and highly qualified to treat various conditions and diseases. Here are some names of their top doctors: Dr. Prabhu M, Dr. Gaurav Sharma, Dr Ankesh Rawat, Dr Ashish Kochekar, and Dr Happy Kumar."
      }
    },{
      "@type": "Question",
      "name": "How can I schedule an appointment with a general physician for a home visit in Punjabi Bagh?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It is quite easy to schedule an appointment with a general physician for a home visit in Punjabi Bagh. All you need to do is call Kyno Health's contact number- +91-9355618123. Once they answer the call, they will set you up for your first consultation with a home-visit general physician. Alternatively, you can mail them to support@kynohealth.com."
      }
    },{
      "@type": "Question",
      "name": "What are the qualifications and experience of the general physicians offering home services in Punjabi Bagh?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "All the general physicians offering home services at Punjabi Bagh are board-certified doctors. They will have a valid medical degree from any recognized institute. Most of the time, these degrees, like MBBS, BAMS, and BHMS, are mentioned in their user profiles. In terms of their experience, each doctor's expertise and experience vary. However, at Kyno Health, we ensure that you are provided with the best doctors in Punjabi Bagh."
      }
    },{
      "@type": "Question",
      "name": "Are the general physicians in Punjabi Bagh equipped to handle common medical conditions and emergencies during home visits?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our doctors from Kyno Health are equipped to handle all common medical conditions, such as fevers, sore throats, bed sores, hypertension, chronic illnesses, etc. We also provide emergency medical services and prioritize your health to the utmost."
      }
    },{
      "@type": "Question",
      "name": "What are the consultation fees for general physicians providing home visits in Punjabi Bagh?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At Kyno Health, we believe in making medical services affordable. Hence, our consultation fees start from Rs 999. However, the price may increase slightly depending on emergency visits or the time of the day, such as late nights."
      }
    }]
  }
  



  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Punjabi Bagh, Delhi | Kyno Health</title>
        <meta name="description" content="Receive quality healthcare at home with Kyno Health's best general physicians in Punjabi Bagh, Delhi. Enjoy convenient and professional medical care without leaving your home." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/punjabi-bagh-general-physicians-at-home" />
      </Helmet>

      <Nav />

      <Hero hero_title="Best General Physicians at Home in Punjabi Bagh, Delhi" hero_paragraph="Experience top-tier healthcare with the Best General Physicians at Home in Punjabi Bagh, Delhi. At Kyno Health, our dedicated and experienced doctors provide comprehensive medical care in the comfort of your home, ensuring convenience and personalized attention for all your health needs."/>
      
      <Content content_h3="Why General Physicians at Home Services are Better for Your Health?" content_p="Having general physicians visit you at home offers unparalleled convenience and comfort. You can avoid the hassle of traveling to a clinic, especially when you're feeling unwell. This means no waiting in queues and more time for rest and recovery. Home visits also minimize exposure to other patients, reducing the risk of infections." content_p1="Moreover, in-home consultations allow doctors to understand your living environment better. They can give more tailored advice and treatments that consider your daily routines and surroundings. This holistic approach can lead to more effective health management and faster recovery times." content_p3="Lastly, having a general physician near me at home fosters a stronger patient-doctor relationship. With more time spent together, physicians can provide more personalized care, addressing your specific health concerns in depth. This personalized attention can enhance your overall health outcomes and ensure you receive the best possible care." 

      content_h3_2="Experience Kyno Health's  Medical Services at Home in Punjabi Bagh" content_p_2="If you are a resident of Punjabi Bagh and are searching for a general physician home visit near me, your search ends here. We at Kyno Health understand the significance of making medical services accessible everywhere, and hence, we have been providing affordable health services at your home in Punjabi Bagh. Be it a simple fever or high blood sugar, do not hesitate to contact us. At Kyno Health, we will prioritize your health first and make sure help reaches you as soon as possible."/>

      <Faqs items={faqscheme} /> 
      <Footer />
    </>
  )
}



export default index