import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Third_Section from './Third_Section/index'
import Seventh_Section from './Seventh_Section/index'
import Faqs from './Faqs/index'


import { Helmet } from 'react-helmet'

export default function Home_New(){

    const faqscheme = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What medical treatments can home visit doctors in Bangalore provide?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Kyno Health's home visit doctors can provide all sorts of medical treatments in Bangalore. You can get checked for minor ailments, let us help you manage and treat your chronic ailments, and even provide post-operative care as and when required."
        }
      },{
        "@type": "Question",
        "name": "What are the fees for home visit doctors in Bangalore?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The fees for home visits to doctors in Bangalore are dependent on the treatment you want. Based on the type of consultation, the apparatus used, the use of medicines, and so on, the fees for the session are determined."
        }
      },{
        "@type": "Question",
        "name": "Can I access home visit doctor services in Bangalore during weekends and holidays?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Kyno Health is all about making healthcare accessible as much as possible. Even during public holidays and weekends, you can book an easy appointment with Kyno Health to have a skilled doctor visit you at the house."
        }
      },{
        "@type": "Question",
        "name": "What are the main benefits of using home visit doctors in Bangalore?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Calling in-home visit doctors in Bangalore is important for your convenience if you are leading a busy life or cannot visit a clinic. If you are suffering from immobility issues, are a pregnant woman, or are a senior citizen, it is not feasible to visit a hospital. This is why it is better to call home to visit doctors for maximum convenience."
        }
      },{
        "@type": "Question",
        "name": "How quickly can a home visit doctor reach my home in Bangalore?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Based on your location, a home visit to a doctor will take less than ten minutes to reach your home. If you are far away, a maximum of 30 minutes may be taken. Nevertheless, home visit doctors always arrive on time, equipped with all the important tools."
        }
      }]
    }
    
      

    return(
        <div>
                <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Emergency Home Visit Doctor Service in Bangalore | Kyno Health</title>
                    <meta name="description" content="Kyno Health emergency home visit doctor service in Bangalore provides immediate medical care at your doorstep. We provide prompt and reliable medical assistance around the clock. Get emergency medical care in Bangalore now."/>
                    <link rel="canonical" href="https://www.kynohealth.com/emergency-home-visit-doctor-bangalore"/>

            </Helmet>
            <Nav/>
            <Hero/> 
            <Third_Section/>

            <Seventh_Section/>
            <Faqs/>
     
    <Footer/>

        </div>
    )
}