import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When should I see a doctor for weakness?
</Accordion.Header>
                    <Accordion.Body>
                      <p>If you experience persistent or unexplained weakness, especially if it interferes with your daily activities, it's advisable to consult a doctor.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What could be the causes of weakness?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Weakness can be caused by various factors, including but not limited to nutritional deficiencies, infections, chronic medical conditions, neurological issues, and hormonal imbalances.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>Should I see a general practitioner or a specialist for weakness?</Accordion.Header>
                    <Accordion.Body>
                      <p>Start with a general practitioner who can perform an initial evaluation. Depending on the findings, they may refer you to a specialist such as a neurologist, endocrinologist, or rheumatologist.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What tests might be done to determine the cause of weakness?</Accordion.Header>
                    <Accordion.Body>
                    <p>Diagnostic tests may include blood tests, imaging studies (such as MRI or CT scans), electromyography (EMG), and nerve conduction studies.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Could weakness be a sign of a serious medical condition?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, weakness can be a symptom of serious conditions such as neurological disorders, autoimmune diseases, or metabolic disorders. It's important to identify and address the underlying cause.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}

              <Accordion>
              


                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Are there lifestyle changes that may help improve weakness?</Accordion.Header>
                    <Accordion.Body>

                      <p>Depending on the cause, lifestyle changes such as adopting a balanced diet, regular exercise, and adequate sleep may contribute to improved strength and overall well-being.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Can psychological factors contribute to weakness?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, stress, anxiety, and depression can manifest as physical symptoms, including weakness. It's important to discuss any emotional or mental health concerns with your healthcare provider.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Is weakness a normal part of aging?
</Accordion.Header>
                    <Accordion.Body>

                    <p>While some decline in muscle mass and strength is a natural part of aging, significant weakness should not be considered a normal part of the aging process. It should be evaluated by a healthcare professional.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What are the treatment options for weakness?
</Accordion.Header>
                    <Accordion.Body>
                    <p>Treatment depends on the underlying cause of weakness. It may include addressing nutritional deficiencies, managing chronic conditions, physical therapy, medications, or surgery, depending on the situation.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index