import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>24/7 Doctor Services: Expert Medical Care Around the Clock</h3>
    
    <p className='d-md-none d-lg-none'>Medical concerns don't always adhere to regular business hours. That's why our "24/7 Doctor" services are designed to provide you with access to qualified healthcare professionals anytime, day or night. With a focus on convenience, quality care, and timely assistance, our 24/7 doctors are here to address your medical needs whenever they arise.
</p>

<p className='mob-noee'>Medical concerns don't always adhere to regular business hours. That's why our "24/7 Doctor" services are designed to provide you with access to qualified healthcare professionals anytime, day or night. With a focus on convenience, quality care, and timely assistance, our 24/7 doctors are here to address your medical needs whenever they arise.
</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
