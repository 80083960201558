import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What are stomach issues, and when should I consider seeing a doctor for them?</Accordion.Header>
                    <Accordion.Body>
                      <p>Stomach issues encompass a wide range of digestive problems, such as abdominal pain, discomfort, irregular bowel movements, and more. You should consider seeing a doctor if your symptoms are persistent, severe, or affecting your daily life.
</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What can I expect during a visit to a doctor for stomach issues?
</Accordion.Header>
                    <Accordion.Body>

                      <p>During your appointment, the doctor will conduct a comprehensive assessment, including a review of your medical history, discussion of your symptoms, and possibly diagnostic tests. They will then create a personalized treatment plan based on the findings.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>Are there common diagnostic tests for stomach issues, and when are they recommended?</Accordion.Header>
                    <Accordion.Body>
                      <p>Common diagnostic tests include endoscopy, colonoscopy, imaging (like X-rays or CT scans), and blood work. These tests are recommended based on your specific symptoms and the suspected cause of your stomach issues.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can dietary changes help manage stomach issues?
</Accordion.Header>
                    <Accordion.Body>
                    <p>Dietary modifications are often effective in managing stomach issues. For example, avoiding trigger foods, maintaining a balanced diet, staying hydrated, and reducing the consumption of irritating substances (like caffeine and alcohol) can alleviate symptoms.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Can lifestyle changes impact stomach issues?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, lifestyle changes are significant. Stress management, regular physical activity, and maintaining a healthy lifestyle can help reduce the severity and frequency of stomach issues.
</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What conditions can doctors treat related to stomach issues?
</Accordion.Header>
                    <Accordion.Body>

                      <p>Doctors who specialize in stomach issues can address conditions such as acid reflux (GERD), irritable bowel syndrome (IBS), inflammatory bowel disease (IBD), gastritis, peptic ulcers, food intolerances and allergies, gallbladder disorders, and many more.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Can stomach issues be a sign of a more serious condition?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, in some cases, stomach issues can be indicative of underlying health conditions like inflammatory bowel disease, celiac disease, or gastrointestinal infections. A doctor's evaluation is essential to rule out serious concerns.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Are there over-the-counter remedies I can try before seeing a doctor for stomach issues?
</Accordion.Header>
                    <Accordion.Body>
                      <p>While over-the-counter medications can provide temporary relief, it's advisable to consult a doctor if your symptoms are persistent or recurring. Self-medication may not address the root cause of your issues.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>How can I prevent stomach issues from occurring or recurring?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Preventive measures include maintaining a healthy diet, managing stress, staying hydrated, avoiding trigger foods, and maintaining good hygiene practices. Following your doctor's advice is also crucial in preventing recurrence.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Is it safe to delay seeking medical attention for stomach issues?</Accordion.Header>
                    <Accordion.Body>
                      <p>Delaying medical attention for persistent stomach issues can sometimes lead to the worsening of the condition or the development of complications. It's advisable to consult a doctor for proper evaluation and timely treatment.</p>
                      <p>If you have more questions or concerns about your stomach issues, don't hesitate to reach out to a healthcare professional specializing in gastrointestinal conditions. They can provide you with the guidance and care needed to address your specific symptoms and improve your digestive health.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index