import React, { useState } from 'react'
import './Css/style.css'


function index() {

  return (
    <>

      {/*<section className='thrd-fold_abt_tab'>
        <div className='container-fluid'>
          <h2>Our Core Values</h2>
          <button type="button" className="thrd-fold_tab_btn" >All</button>
          <button type="button" className="thrd-fold_tab_btn" >Medical Supplies</button>
          <button type="button" className="thrd-fold_tab_btn" >Attendant Services</button>
          <button type="button" className="thrd-fold_tab_btn" >Ambulance</button>
          <button type="button" className="thrd-fold_tab_btn" >Physiotherapy</button>
          <button type="button" className="thrd-fold_tab_btn" >Smart Reports</button>
          <button type="button" className="thrd-fold_tab_btn" >Remote Monitoring</button>
          <button type="button" className="thrd-fold_tab_btn" >Home Sample Collection</button>
          <button type="button" className="thrd-fold_tab_btn" >Nurse Procedure</button>


        </div>
  </section>*/}

      <section className='thrd-fold_abt '>
        <div className='container'>
          <div className='ml-70'>
            <div className='row'>
            <div className='col-md-3'>
<div className='programitem mb-10'>
<img src='images/UncompromisedQuality.svg' className='' />
<h4>Uncompromised Quality</h4>
<p>We comply to the highest quality standards in every aspect</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem mb-10'>
<img src='images/Forthepeople.svg' className='' />
<h4>We Grow Together</h4>
<p>Enabling equity, sustainability, compassion, and support for all</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem mb-10'>
<img src='images/UncompromisedQuality.svg' className='' />
<h4>Dependable & Transparent</h4>
<p>Upholding strong moral & professional character in all areas</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem mb-10'>
<img src='images/UncompromisedQuality.svg' className='' />
<h4>Unbreakable Spirit</h4>
<p>Having ambition, imagination, and the conviction to follow it through</p>
</div>
</div>

            </div>
          </div></div>

      </section>
    </>

  )
}

export default index