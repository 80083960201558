import React, { useEffect, useRef, useState } from 'react'
import "../../../styles/sales_all_leads.scss"
import axios from 'axios'
import CurrentDateTime, { localBackend, slots } from '../../../utilities/constants'
import Popup from 'reactjs-popup'
import { useNavigate } from 'react-router-dom'
import filter from '../../../utilities/images/filter.png'

export default function Sales_all_leads(props) {

    const [salesEmployeeId, setSalesEmployeeId] = useState(props.salesEmployeeId)
    const [salesEmployee, setSalesEmployee] = useState(null)
    const [temporaryLeads, setTemporaryLeads] = useState(null)
    const [untouchedLeads, setUntouchedLeads] = useState(null)
    const [saveTempLeads, setSaveTempLeads] = useState(null)
    const [upcomingAppointments, setUpcomingAppointments] = useState(null)
    // const [localities, setLocalities] = useState(null)
    const [selectedLocality, setSelectedLocality] = useState("")
    const [modalmsg, setmodalmsg] = useState("")
    const [docsOfLocality, setDocsOfLocality] = useState(null)
    const [selectedDoc, setSelectedDoc] = useState("")
    const [patientAddress, setPatientAddress] = useState("")
    const [localities, setLocalities] = useState(props.localities)
    const [appointmentDate, setAppointmentDate] = useState(null)
    const [appointmentTime, setAppointmentTime] = useState(null)
    const [doctors, setDoctors] = useState(null)
    const [salesRep, setSalesRep] = useState(null)

    const [isFilterApplied, setIsFilterApplied] = useState(false)

    const [filterSelected, setFilterSelected] = useState("NONE")
    // const [selectedSalesRep, setSelectedSalesRep] = useState("")

    const [_substatus, set_substatus] = useState(false)
    const [_popupBlockId, set_popupBlockId] = useState("")
    const [__substatus, set__substatus] = useState("")
    const [substatus, setSubstatus] = useState("")
    const _substatusref = useRef(null)
    const [_selectedLeadForStatusUpdate, __setSelectedLeadForStatusUpdate] = useState(-2)

    const phoneFilter = useRef()
    const dateFilter1 = useRef()
    const dateFilter2 = useRef()
    const leadStatusFilter = useRef()
    const marketingSourceFilter = useRef()
    const nameFilter = useRef()

    const newLeadName = useRef()
    const newLeadPhone = useRef()

    const patientInfoTempLeadsFilters = [
        { "filter": "PHONE_FILTER", value: "" },
        { "filter": "DATE_FILTER", value1: "", value2: "", value: "" },
        { "filter": "LEAD_STATUS_FILTER", value: "" },
        { "filter": "MARKETING_SOURCE_FILTER", value: "" },
        { "filter": "NAME_FILTER", value: "" },
    ]


    // const [tempLeadsFiltersApplied, setTempLeadsFiltersApplied] = useState(patientInfoTempLeadsFilters)

    const navigate = useNavigate()

    const fetchAllSalesRep = async () => {
        const result = await axios.get(`${localBackend}getAllSalesEmployee?salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            // console.log(resp.data)
            if (resp.data.statusCode === 1) {
                setSalesRep(resp.data.data)
            }
        })
    }

    const fetchTemporaryLeads = async () => {
        console.log("############ fetching temp leads ######################")
        const res = await axios.get(`${localBackend}getTemporaryLeads?id=${props.salesEmployeeId}`).then((resp) => {
            if (resp.data.statusCode === 1) {
                console.log("hm", props.TempLeadFilter)
                // setTemporaryLeads(resp.data.data);
                setUntouchedLeads(resp.data.data)
                applyFilters(props.TempLeadFilter, resp.data.data)
                setSaveTempLeads(resp.data.data)
            }
        })
        return res
    }

    const fetchLocalities = async () => {
        const res = await axios.get(`${localBackend}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                // console.log("Localities: ", resp.data.data)
                return resp.data.data
            }
        })
    }

    const fetchAvailableDoctorsOfLocality = async (localityId) => {
        const res = await axios.get(`${localBackend}availableDocsOfLocality?localityId=${localityId}`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setDocsOfLocality(resp.data.data)
                return resp.data.data
            }
        })
    }

    const fetchUpcomingAppointments = async () => {
        let body = {
            "employeeType": "SALES",
            "employeeId": salesEmployeeId,
        }
        const res = await axios.post(`${localBackend}getUpcomingAppointments`, body).then((resp) => {
            // console.log(resp.data.data)
            setUpcomingAppointments(resp.data.data)
            return resp.data.data
        })
    }

    const fetchDoctors = async () => {
        const res = await axios.get(`${localBackend}allDoctors`).then((resp) => {
            setDoctors(resp.data.data)
        })
    }

    const changeLeadToActive = async (leadId, close, leadName, leadConcern) => {
        // console.log(leadId, selectedLocality, selectedDoc, patientAddress, leadName, leadConcern);
        let body = {
            leadId: leadId,
            localityId: selectedLocality,
            docId: selectedDoc,
            patientAddress: patientAddress,
            salesEmployeeId: props.salesEmployeeId,
            appointmentDate: appointmentDate,
            appointmentTime: appointmentTime,
            appointmentBookedOn: CurrentDateTime()
        }

        const result = await axios.post(`${localBackend}changeTempLeadToActiveLead`, body).then((resp) => {
            if (resp.data.statusCode === 1) {

                // /////////////////////////  In future change this to just update the state of temp leads, not query the server

                fetchTemporaryLeads()

                // //////////////////////////////////////////////////////////////////////////////////////////////////////////////

                setDocsOfLocality(null);
                setSelectedDoc("");
                setSelectedLocality("");
                close()

                props.setHomeMsg("Appointment Transferred to Active Successfully")

            } else {
                setmodalmsg("Something went wrong, Lead Transfer Failed!!")
            }
        })

    }

    const getLocalityName = (id) => {
        var obj = localities.find(l => l._id === id) || null;
        if (obj === null) {
            return "";
        } else {
            return obj.name
        }
    }

    const getDoctorNameOfLocality = () => {
        var obj = docsOfLocality.find(d => d._id === selectedDoc) || null;
        if (obj === null) {
            return "";
        } else {
            return obj.name
        }
    }
    const getDoctorName = (id) => {
        var obj = doctors.find(d => d._id === id) || null;
        if (obj === null) {
            return "";
        } else {
            return obj.name
        }
    }

    const getSalesEmployee = async () => {
        const result = await axios.get(`${localBackend}getSalesEmployee?id=${salesEmployeeId}`).then((resp) => {
            if (resp.data.statusCode == 1) {
                // console.log(resp.data)
                setSalesEmployee(resp.data.data)
            }
        })
    }

    const assignSalesRepToLead = async (seid, leadId) => {
        let body = {
            salesRepId: seid,
            leadId: leadId,
            salesHead: salesEmployeeId
        }
        // console.log(body)
        const result = await axios.post(`${localBackend}assignSalesRepToLead`, body).then((resp) => {
            setModalMessage(resp.data.data)
            return resp.data.statusCode
            // console.log(resp.data)
        })
        return result
    }

    const setModalMessage = (msg) => {
        setmodalmsg(msg);
        setInterval(() => {
            setmodalmsg("")
        }, [2000])
    }

    const updateLeadStatus = async (leadId, newStatus) => {
        let body = {
            leadId: leadId,
            newStatus: newStatus,
            salesEmployeeId: salesEmployeeId
        }

        const result = await axios.post(`${localBackend}updateLeadStatus`, body).then((resp) => {
            setModalMessage(resp.data.data)
            return resp.data
        })
        return result
    }


    const applyFilters = (_filters, _leads) => {
        // console.log(_filters, _leads)
        let l = _leads;
        // console.log(_filters, _leads)
        // setTempLeadsFiltersApplied(_filters)
        let _isFilterApplied = false
        for (let i = 0; i < _filters.length; i++) {
            let _filter = _filters[i];
            let _val = _filter.value
            if (_val !== "") {
                _isFilterApplied = true
                if (_filter.filter === "PHONE_FILTER") {
                    const filteredLeads = l.filter(lead => lead.phone.includes(_val));
                    // setTemporaryLeads(filteredLeads)
                    l = filteredLeads

                }
                else if (_filter.filter === "DATE_FILTER") {
                    const filteredLeads = l.filter(lead => ((new Date(lead.createdOn.split("T")[0])).getTime() >= (new Date(_filter.value1)).getTime() && (new Date(lead.createdOn.split("T")[0])).getTime() <= (new Date(_filter.value2)).getTime()))
                    l = filteredLeads
                } else if (_filter.filter === "LEAD_STATUS_FILTER") {
                    console.log(_val, l[0])
                    const filteredLeads = l.filter(lead => (lead.status !== undefined ? lead.status.includes(_val)  : false )   )
                    l = filteredLeads
                } else if (_filter.filter === "MARKETING_SOURCE_FILTER") {
                    const filteredLeads = l.filter(lead => lead.source === _val)
                    l = filteredLeads
                } else if (_filter.filter === "NAME_FILTER") {
                    const filteredLeads = l.filter(lead => lead.name.toLowerCase().includes(_val.toLowerCase()) || (lead.patientName !== null && lead.patientName.toLowerCase().includes(_val.toLowerCase())))
                    l = filteredLeads
                }
            }
        }
        // console.log(l)
        setIsFilterApplied(_isFilterApplied)
        setTemporaryLeads(l)
    }


    const filterLeads = async (filterType) => {
        // console.log(filterType)

        if (filterType == 1) {
            // console.log(phoneFilter.current.value)
            if (phoneFilter.current.value == "") {
                // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
                props.SetTempLeadFilter(patientInfoTempLeadsFilters)
            } else {
                const _filters = [...props.TempLeadFilter]
                const _filterUpdate = _filters.find(f => f.filter === "PHONE_FILTER")
                if (_filterUpdate) {
                    _filterUpdate.value = phoneFilter.current.value
                }
                applyFilters(_filters, saveTempLeads)
                props.SetTempLeadFilter(_filters)
                // setTempLeadsFiltersApplied(_filters)

            }

        } else if (filterType == 2) {
            // if (dateFilter.current.value == "") {
            //     setTemporaryLeads(saveTempLeads)
            //     setFilterSelected("NONE")
            //     setTempLeadsFiltersApplied([])
            // } else {
            //     if (filterSelected == "CREATED_DATE") {
            //         const filteredLeads = saveTempLeads.filter(lead => (new Date(lead.createdOn.split("T")[0])).getTime() === (new Date(dateFilter.current.value)).getTime())
            //         setTemporaryLeads(filteredLeads)
            //     } else {
            //         const filteredLeads = temporaryLeads.filter(lead => (new Date(lead.createdOn.split("T")[0])).getTime() === (new Date(dateFilter.current.value)).getTime())
            //         setTemporaryLeads(filteredLeads)
            //     }
            //     setFilterSelected("CREATED_DATE")
            // }
            if (dateFilter1.current.value == "" || dateFilter2.current.value == "") {
                props.SetTempLeadFilter(patientInfoTempLeadsFilters)
                // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
            } else {
                const _filters = [...props.TempLeadFilter]
                const _filterUpdate = _filters.find(f => f.filter === "DATE_FILTER")
                if (_filterUpdate) {
                    _filterUpdate.value1 = dateFilter1.current.value
                    _filterUpdate.value2 = dateFilter2.current.value
                    _filterUpdate.value = "APPLIED"
                }
                applyFilters(_filters, saveTempLeads)
                props.SetTempLeadFilter(_filters)
                // setTempLeadsFiltersApplied(_filters)
            }
        } else if (filterType == 3) {
            if (leadStatusFilter.current.value == "") {
                props.SetTempLeadFilter(patientInfoTempLeadsFilters)
                // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
            } else {
                const _filters = [...props.TempLeadFilter]
                const _filterUpdate = _filters.find(f => f.filter === "LEAD_STATUS_FILTER")
                if (_filterUpdate) {
                    _filterUpdate.value = leadStatusFilter.current.value
                }
                applyFilters(_filters, saveTempLeads)
                props.SetTempLeadFilter(_filters)
                // setTempLeadsFiltersApplied(_filters)
            }
        } else if (filterType == 4) {
            if (marketingSourceFilter.current.value == "") {
                props.SetTempLeadFilter(patientInfoTempLeadsFilters)
                // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
            } else {
                const _filters = [...props.TempLeadFilter]
                const _filterUpdate = _filters.find(f => f.filter === "MARKETING_SOURCE_FILTER")
                if (_filterUpdate) {
                    _filterUpdate.value = marketingSourceFilter.current.value
                }
                applyFilters(_filters, saveTempLeads)
                props.SetTempLeadFilter(_filters)
                // setTempLeadsFiltersApplied(_filters)
            }
        }
        else if (filterType == 5) {
            if (nameFilter.current.value == "") {
                props.SetTempLeadFilter(patientInfoTempLeadsFilters)
                // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
            } else {
                const _filters = [...props.TempLeadFilter]
                const _filterUpdate = _filters.find(f => f.filter === "NAME_FILTER")
                if (_filterUpdate) {
                    _filterUpdate.value = nameFilter.current.value
                }
                applyFilters(_filters, saveTempLeads)
                props.SetTempLeadFilter(_filters)
                // setTempLeadsFiltersApplied(_filters)
            }
        }
    }

    const handleNewLead =  async (close) => {
        let _name = newLeadName.current.value;
        let _phone = newLeadPhone.current.value;

        let _body = {
            name: _name,
            phone: _phone,
            concern: "",
            source: "Sales Dashboard"
        }

        const result = await axios.post(`${localBackend}createLead`, _body).then((resp) =>  {
            setModalMessage(resp.data.data)
            if(resp.data.statusCode ===  1){
                close()
            }
        })
    }



    useEffect(() => {
        // console.log(salesEmployeeId, props.localities)
        fetchDoctors()
        fetchAllSalesRep()
        if (localities == null) {
            fetchLocalities()
        }
        if (salesEmployeeId !== "") {
            fetchTemporaryLeads();
            fetchUpcomingAppointments()
            // applyFilters(tempLeadsFiltersApplied)
            getSalesEmployee()
            const intervalId = setInterval(fetchTemporaryLeads, 30000)
            return () => clearInterval(intervalId)
        }
        // fetchLocalities()
    }, [salesEmployeeId, localities, props.TempLeadFilter, __substatus])

    return (
        <>
            <div className='sales-all-leads'>
                <div className='filters-bar'>
                    <div className='headers'>
                        <div className='clear' onClick={(e) => {
                            props.SetTempLeadFilter(patientInfoTempLeadsFilters)
                            // setTempLeadsFiltersApplied(patientInfoTempLeadsFilters)
                            setTemporaryLeads(saveTempLeads)
                            isFilterApplied(false)
                            props.clearFilter()
                            console.log(props.TempLeadFilter, patientInfoTempLeadsFilters)
                            // props
                        }}>Clear Filters</div>
                        <div className={props.TempLeadFilter.find(f => f.filter === "NAME_FILTER").value !== "" ? "filter-applied" : ""}><span>Patient Name </span><div className='btn' onClick={(e) => { filterLeads(5) }}></div></div>
                        <div className={props.TempLeadFilter.find(f => f.filter === "PHONE_FILTER").value !== "" ? "filter-applied" : ""}><span>Phone Number </span><div className='btn' onClick={(e) => { filterLeads(1) }}></div></div>
                        <div className={props.TempLeadFilter.find(f => f.filter === "DATE_FILTER").value !== "" ? "filter-applied" : ""}><span>Enquiry Date </span><div className='btn' onClick={(e) => { filterLeads(2) }}></div></div>
                        <div className={props.TempLeadFilter.find(f => f.filter === "LEAD_STATUS_FILTER").value !== "" ? "filter-applied" : ""}><span>Lead Status </span><div className='btn' onClick={(e) => { filterLeads(3) }}></div></div>
                        <div className={props.TempLeadFilter.find(f => f.filter === "MARKETING_SOURCE_FILTER").value !== "" ? "filter-applied" : ""}><span>Marketing Source </span><div className='btn' onClick={(e) => { filterLeads(4) }}></div></div>
                    </div>
                    <div className='filters'>
                        <div>Filters: </div>
                        <div className='patient-name'>
                            <input ref={nameFilter} type='text' placeholder='Patient Name' />
                        </div>
                        <div className='phone-number'>
                            <span>+91</span>
                            <input ref={phoneFilter} type='number' placeholder='Phone Number' />
                        </div>
                        <div className='enquiry-date'>
                            Start Date <input ref={dateFilter1} type='date' />
                            <br />
                            End Date <input ref={dateFilter2} type='date' />
                        </div>
                        <div className='lead-status'>
                            <select ref={leadStatusFilter}>
                                <option value="">Select Status</option>
                                <option value="FOLLOW_UP__">Follow Up</option>
                                <option value="APPOINTMENT_BOOKED__">Appointment Booked</option>
                                <option value="APPOINTMENT_COMPLETED__">Appointment Completed</option>
                                <option value="IRRELEVENT_LEAD__">Irrelevent Lead</option>
                                <option value="CONTACT_IN_FUTURE__">Contact In Future</option>
                                <option value="DNP__">Did Not Pick</option>
                                <option value="DOCTOR_ISSUE__">Doctor Issue</option>
                                <option value="TIMING_ISSUE__">Timing Issue</option>
                                <option value="ALTERNATE__">Alternate</option>
                                <option value="LOCATION_ISSUE__">Location Issue</option>
                                <option value="COST_ISSUE__">Cost Issue</option>
                            </select>
                        </div>
                        <div className='marketing-source'>
                            <select ref={marketingSourceFilter}>
                                <option value={""}>Select Marketing Source</option>
                                <option value="Google Ads">Google Ads</option>
                                <option value="Google Ad Page">Google Ad Page</option>
                                <option value="Website">Website</option>
                                <option value="Website Book Appointment">Website Book Appointment</option>
                                <option value="Sales Dashboard">Sales Dashboard</option>
                            </select>
                        </div>
                    </div>


                </div>

                <Popup className='create-new-lead' modal nested trigger={
                    <div className='create-new-lead-trigger'>+ Create New Lead</div>
                }>{(close) => {
                    return (
                        <div  className='new-lead'>
                            <div className='title'>Create New Lead</div>
                            <div className='name'>
                                <div>Enter Lead Name</div>
                                <input type='text' ref={newLeadName} />
                            </div>
                            <div className='phone'>
                                <div>Enter Lead Phone (without +91)</div>
                                <input type='number' ref={newLeadPhone} />
                            </div>
                            
                            <div className='submit'   onClick={(e) => {
                                handleNewLead(close)
                            }}>Submit</div>
                        </div>
                    )
                }}</Popup>
                {/* <div className='title'>Here is the list of all Temporary Leads</div> */}

                <div className='leads'>
                    <div className='left-section'>
                        <div className='header'>
                            <div className='sno'>S.No</div>
                            <div className='patient-name'>Patient Name</div>
                            <div className='lead-name'>Lead Name</div>
                            <div className='patient-id'>Patient ID</div>
                            <div className='patient-number'>Phone Number</div>
                            <div className='enquiry-date'>Enquiry Date</div>
                            <div className='category'>Category</div>
                            <div className='city'>City</div>
                            <div className='marketing-source'>Marketing Source</div>
                            <div className='lead-status'>Lead Status</div>
                            <div className='lead-status'>Lead Sub Status</div>
                            <div className='patient-type'>Patient Type</div>
                            <div className='remark'>Remark</div>
                            {salesEmployee !== null && (salesEmployee.position === "SALES_HEAD" || salesEmployee.position === "SALES_MANAGER") &&
                                <div className='bd-name'>BD Name</div>
                            }
                        </div>

                        <div className='all-leads'>
                            {temporaryLeads !== null && salesEmployee &&
                                temporaryLeads.map((block, index) => {
                                    // let block = _lead.lead[0]
                                    // let comment = _lead.comment[0]
                                    // console.log(block.createdOn)
                                    const currentTime = new Date(CurrentDateTime());
                                    const leadCreatedTime = new Date(block.createdOn);
                                    const diff = Math.floor(Math.abs(currentTime - leadCreatedTime) / (1000 * 60 * 60));
                                    var leadName = ""
                                    var leadConcern = ""
                                    var time = block.createdOn.split("T")[1].split(".")[0]
                                    var date = block.createdOn.split("T")[0]
                                    if (salesEmployee.position === "SALES_REPRESENTATIVE") {
                                        if (block.currentSalesEmployee === salesEmployeeId) {
                                            return (
                                                <div className={block.currentSalesEmployee == salesEmployeeId ? "lead assigned" : "lead"} key={index}>
                                                    <div className='sno'>{index + 1}</div>
                                                    <div className='patient-name' onClick={() => {
                                                        navigate(`/dashboard/patient-information-page?pid=${block.phone}`)
                                                    }}>{(block.patientName == "" || block.patientName == null) ? "[no-name]" : block.patientName}</div>
                                                    <div className='lead-name'>
                                                        {(block.name == "" || block.name == null) ? "[no-name]" : block.name}
                                                    </div>
                                                    <div className='patient-id'>{`Patients/${block.phone}`} </div>
                                                    <div className='patient-number'>{block.phone}</div>
                                                    <div className='enquiry-date'>{date}</div>
                                                    <div className='category'>General Category</div>
                                                    <div className='city'>{(block.zone === null || block.zone === "") ? "[no-city]" : getLocalityName(block.zone)}</div>
                                                    <div className='marketing-source'>{block.source}</div>
                                                    <div className='lead-status'>
                                                        <select value={block.status} onChange={async (e) => {
                                                            updateLeadStatus(block._id, e.target.value)
                                                        }}>
                                                            <option value={""}>Select Lead Status</option>
                                                            <option value="NEW">New</option>
                                                            <option value="OPD_DONE">OPD Done</option>
                                                            <option value="RELEVENT">Relevent</option>
                                                            <option value="IRRELEVENT">Irrelevent</option>
                                                            <option value="LOCATION_ISSUE">Location Issue</option>
                                                            <option value="INVALID_NUMBER">Invalid Number</option>
                                                            <option value="FOLLOW_UP">Follow Up</option>
                                                            <option value="DID_NOT_PICK">Did Not Pick</option>
                                                        </select>
                                                    </div>
                                                    <div className='patient-type'>New</div>
                                                    {block.comment !== null ?
                                                        <div className='remark'>{block.comment.comment}</div> :
                                                        <div className='remark'></div>
                                                    }
                                                    {salesEmployee !== null && (salesEmployee.position === "SALES_HEAD" && salesEmployee.position === "SALES_MANAGER") &&
                                                        <div className='bd-name'>
                                                            <select value={block.currentSalesEmployee} onChange={async (e) => {
                                                                const res = await assignSalesRepToLead(e.target.value, block._id)
                                                                if (res == 1) {
                                                                    block.currentSalesEmployee = e.target.value
                                                                }
                                                            }} >
                                                                <option value={""} key={-1} >Select Sales Rep</option>
                                                                {salesRep !== null &&
                                                                    salesRep.map((rep, index) => {
                                                                        return (
                                                                            <option key={index} value={rep._key}>{rep.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    }
                                    else {
                                        return (
                                            <div className={block.currentSalesEmployee == salesEmployeeId ? "lead assigned" : "lead"} key={index}>
                                                <div className='sno'>{index + 1}</div>
                                                <div className='patient-name' onClick={() => {
                                                    navigate(`/dashboard/patient-information-page?pid=${block.phone}`)
                                                }}>{(block.patientName == "" || block.patientName == null) ? "[no-name]" : block.patientName}</div>
                                                <div className='lead-name'>
                                                    {(block.name == "" || block.name == null) ? "[no-name]" : block.name}
                                                </div>
                                                <div className='patient-id'>{`Patients/${block.phone}`} </div>
                                                <div className='patient-number'>{block.phone}</div>
                                                <div className='enquiry-date'>{date}</div>
                                                <div className='category'>General Category</div>
                                                <div className='city'>{(block.zone === null || block.zone === "") ? "[no-city]" : getLocalityName(block.zone)}</div>
                                                <div className='marketing-source'>{block.source}</div>
                                                <div className='lead-status'>
                                                    <select value={_substatus === false ? (block.status !== undefined ? block.status.split("__")[0] + "__" : "") : (__setSelectedLeadForStatusUpdate == index ? __substatus : (block.status !== undefined ? block.status.split("__")[0] + "__" : ""))} onChange={async (e) => {
                                                        // updateLeadStatus(block._id, e.target.value)
                                                        let _leadStatus = e.target.value;

                                                        if (_leadStatus === 'FOLLOW_UP__' || _leadStatus === "LOCATION_ISSUE__" || _leadStatus === "COST_ISSUE__") {
                                                            set_substatus(false)
                                                            set_popupBlockId("")
                                                            updateLeadStatus(block._id, e.target.value)
                                                            block.status = _leadStatus
                                                        } else {
                                                            set__substatus(e.target.value)
                                                            set_popupBlockId(block._id)
                                                            __setSelectedLeadForStatusUpdate(index)
                                                            console.log(__substatus, e.target.value)
                                                            set_substatus(true)
                                                        }
                                                    }}>

                                                        <option value={""}>Select Lead Status</option>
                                                        <option value="FOLLOW_UP__">Follow Up</option>
                                                        <option value="APPOINTMENT_BOOKED__">Appointment Booked</option>
                                                        <option value="APPOINTMENT_COMPLETED__">Appointment Completed</option>
                                                        <option value="IRRELEVENT_LEAD__">Irrelevent Lead</option>
                                                        <option value="CONTACT_IN_FUTURE__">Contact In Future</option>
                                                        <option value="DNP__">Did Not Pick</option>
                                                        <option value="DOCTOR_ISSUE__">Doctor Issue</option>
                                                        <option value="TIMING_ISSUE__">Timing Issue</option>
                                                        <option value="ALTERNATE__">Alternate</option>
                                                        <option value="LOCATION_ISSUE__">Location Issue</option>
                                                        <option value="COST_ISSUE__">Cost Issue</option>
                                                    </select>


                                                    <Popup modal nested open={_substatus && _popupBlockId ===block._id} onClose={() => {
                                                        set_substatus(false)
                                                        set_popupBlockId("")
                                                        set__substatus("")
                                                    }} className='lead-substatus-popup'>{(closeSubLeadPopup) => {

                                                        return (
                                                            <div>
                                                                <div className='lead-substatus-title'>Update Lead Status</div>
                                                                <div className='lead-substatus'>{__substatus}</div>
                                                                {__substatus === "APPOINTMENT_BOOKED__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="HOME_CONSULTATION">Home Consultation</option>
                                                                        <option value="ONLINE_CONSULTATION">Online Consultation</option>
                                                                        <option value="DOCTOR_PROCEDURE">Doctor Procedure</option>
                                                                        <option value="NURSING_PROCEDURE">Nursing Procedure</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "APPOINTMENT_COMPLETED__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="FEEDBACK_DONE">Feedback Done</option>
                                                                        <option value="FEEDBACK_NOT_DONE">Feedback Not Done</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "IRRELEVENT_LEAD__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="DUPLICATE">Duplicate</option>
                                                                        <option value="SPAM">Spam</option>
                                                                        <option value="TEST">Test</option>
                                                                        <option value="MISTAKENLY_ENQUIRED">Mistakenly Enquired</option>
                                                                        <option value="JUNK">Junk</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "CONTACT_IN_FUTURE__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="INFORMATION">Information</option>
                                                                        <option value="MEDICINE">Medicine</option>
                                                                        <option value="DIAGNOSTICS">Diagnostics</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "DNP__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="ONCE">Once</option>
                                                                        <option value="TWICE">Twice</option>
                                                                        <option value="THREE_OR_MORE">Three Or More</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "DOCTOR_ISSUE__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="FEMALE_DOCTOR_REQUIRED">Female Doctor Required</option>
                                                                        <option value="EXPERIENCED_DOCTOR_REQUIRED">Expecienced doctor required</option>
                                                                        <option value="GYNE">Gyne</option>
                                                                        <option value="ENT">ENT</option>
                                                                        <option value="ORTHO">Ortho</option>
                                                                        <option value="NEURO">Neuro</option>
                                                                        <option value="PSYCHIATRIST">Psychiatrist</option>
                                                                        <option value="OPTHAL">Opthal - Eye Specialist</option>
                                                                        <option value="DERMATOLOGIST">Dermatologist</option>
                                                                        <option value="UROLOGIST">Urologist</option>
                                                                        <option value="SEXOLOGIST">Sexologist</option>
                                                                        <option value="PEDIATRICIAN">Pediatrician</option>
                                                                        <option value="CARDIOLOGIST">Cardiologist</option>
                                                                        <option value="PULMUNOLOGIST">Pulmunologist</option>
                                                                        <option value="NEPHROLOGIST">Nephrologist</option>
                                                                        <option value="GASTRO">Gastro</option>
                                                                        <option value="OTHERS">Others</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "TIMING_ISSUE__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="MISSED_1_HR_TAT">Missed 1 Hr TAT</option>
                                                                        <option value="DOCTOR_UNAVAILABLE">Doctor Unavailable</option>
                                                                    </select>
                                                                }
                                                                {__substatus === "ALTERNATE__" &&
                                                                    <select ref={_substatusref} onChange={async (e) => {
                                                                        if (e.target.value !== "") {
                                                                            let _totalLeadStatus = __substatus + e.target.value
                                                                            updateLeadStatus(block._id, _totalLeadStatus)
                                                                            setAppointmentDate(_substatus(false))
                                                                            closeSubLeadPopup()
                                                                        }
                                                                    }}>
                                                                        <option value="">Select Sub Status</option>
                                                                        <option value="EMERGENCY">Emergency</option>
                                                                        <option value="CLINIC">Clinic</option>
                                                                        <option value="OTHER">Other</option>
                                                                    </select>
                                                                }
                                                            </div>
                                                        )
                                                    }}
                                                    </Popup>



                                                </div>
                                                {/* <div className='lead-status'>
                                                    {__substatus === "APPOINTMENT_BOOKED__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="HOME_CONSULTATION">Home Consultation</option>
                                                            <option value="ONLINE_CONSULTATION">Online Consultation</option>
                                                            <option value="DOCTOR_PROCEDURE">Doctor Procedure</option>
                                                            <option value="NURSING_PROCEDURE">Nursing Procedure</option>
                                                        </select>
                                                    }
                                                    {__substatus === "APPOINTMENT_COMPLETED__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="FEEDBACK_DONE">Feedback Done</option>
                                                            <option value="FEEDBACK_NOT_DONE">Feedback Not Done</option>
                                                        </select>
                                                    }
                                                    {__substatus === "IRRELEVENT_LEAD__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="DUPLICATE">Duplicate</option>
                                                            <option value="SPAM">Spam</option>
                                                            <option value="TEST">Test</option>
                                                            <option value="MISTAKENLY_ENQUIRED">Mistakenly Enquired</option>
                                                            <option value="JUNK">Junk</option>
                                                        </select>
                                                    }
                                                    {__substatus === "CONTACT_IN_FUTURE__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="INFORMATION">Information</option>
                                                            <option value="MEDICINE">Medicine</option>
                                                            <option value="DIAGNOSTICS">Diagnostics</option>
                                                        </select>
                                                    }
                                                    {__substatus === "DNP__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="ONCE">Once</option>
                                                            <option value="TWICE">Twice</option>
                                                            <option value="THREE_OR_MORE">Three Or More</option>
                                                        </select>
                                                    }
                                                    {__substatus === "DOCTOR_ISSUE__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="FEMALE_DOCTOR_REQUIRED">Female Doctor Required</option>
                                                            <option value="EXPERIENCED_DOCTOR_REQUIRED">Expecienced doctor required</option>
                                                            <option value="GYNE">Gyne</option>
                                                            <option value="ENT">ENT</option>
                                                            <option value="ORTHO">Ortho</option>
                                                            <option value="NEURO">Neuro</option>
                                                            <option value="PSYCHIATRIST">Psychiatrist</option>
                                                            <option value="OPTHAL">Opthal - Eye Specialist</option>
                                                            <option value="DERMATOLOGIST">Dermatologist</option>
                                                            <option value="UROLOGIST">Urologist</option>
                                                            <option value="SEXOLOGIST">Sexologist</option>
                                                            <option value="PEDIATRICIAN">Pediatrician</option>
                                                            <option value="CARDIOLOGIST">Cardiologist</option>
                                                            <option value="PULMUNOLOGIST">Pulmunologist</option>
                                                            <option value="NEPHROLOGIST">Nephrologist</option>
                                                            <option value="GASTRO">Gastro</option>
                                                            <option value="OTHERS">Others</option>
                                                        </select>
                                                    }
                                                    {__substatus === "TIMING_ISSUE__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="MISSED_1_HR_TAT">Missed 1 Hr TAT</option>
                                                            <option value="DOCTOR_UNAVAILABLE">Doctor Unavailable</option>
                                                        </select>
                                                    }
                                                    {__substatus === "ALTERNATE__" &&
                                                        <select ref={_substatusref} onChange={async (e) => {
                                                            if (e.target.value !== "") {
                                                                let _totalLeadStatus = __substatus + e.target.value
                                                                updateLeadStatus(block._id, _totalLeadStatus)
                                                            }
                                                        }}>
                                                            <option value="">Select Sub Status</option>
                                                            <option value="EMERGENCY">Emergency</option>
                                                            <option value="CLINIC">Clinic</option>
                                                            <option value="OTHER">Other</option>
                                                        </select>
                                                    }
                                                </div> */}


                                                {
                                                    _substatus === false &&
                                                    <div className='lead-status'>
                                                        {block.status !== undefined ? block.status.split("__")[1] : ""}
                                                    </div>
                                                }
                                                <div className='patient-type'>New</div>
                                                {block.comment !== null ?
                                                    <div className='remark'>{block.comment.comment}</div> :
                                                    <div className='remark'></div>
                                                }
                                                {salesEmployee !== null && (salesEmployee.position === "SALES_HEAD" || salesEmployee.position === "SALES_MANAGER") &&
                                                    <div className='bd-name'>
                                                        <select value={block.currentSalesEmployee} onChange={async (e) => {
                                                            const res = await assignSalesRepToLead(e.target.value, block._id)
                                                            if (res == 1) {
                                                                block.currentSalesEmployee = e.target.value
                                                            }
                                                        }} >
                                                            <option value={""} key={-1} >Select Sales Rep</option>
                                                            {salesRep !== null &&
                                                                salesRep.map((rep, index) => {
                                                                    return (
                                                                        <option key={index} value={rep._key}>{rep.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                    </div>
                    <div className='right-section'>
                        {/* <div className='untouched-enquiry'>
                            {untouchedLeads !== null &&
                                <div className='title'>Untouched Enquiry ( {(untouchedLeads.filter(lead => (lead.status === undefined || lead.status === null || lead.status === ""))).length} )</div>
                            }
                            <div className='leads'>
                                {untouchedLeads !== null && untouchedLeads.map((block, index) => {
                                    // console.log(block.status )
                                    if (block && (block.status === undefined || block.status === null || block.status === "")) {
                                        return (
                                            <div className='lead' key={index}>
                                                <div className='name'>{(block.patientName == "" || block.patientName == null) ? "[no-name]" : block.patientName}</div>
                                                <div className='city'>{block.city === undefined ? "[no-city]" : block.city}</div>
                                                <div className='phone'>{block.phone}</div>
                                                <div className='category'>General</div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div> */}

                        {upcomingAppointments !== null && localities !== null && doctors !== null &&
                            <div className='upcoming-appointments'>
                                <div className='title'>Upcoming Appointments ({(upcomingAppointments.filter(a => a !== null)).length})</div>
                                <div className='appointments'>
                                    {
                                        upcomingAppointments.map((block, index) => {
                                            if (block && block.status !== "CANCELLED") {
                                                // console.log(block)

                                                return (
                                                    <div className='appointment' key={index} onClick={(e) => {
                                                        navigate(`/dashboard/patient-information-page?pid=${block.patientPhone}`)
                                                    }}>
                                                        <div className='left'>
                                                            <div className='name'>{block.name === "" ? "[no-name]" : block.name}</div>
                                                            <div className='city'>{getLocalityName(block.city)}</div>
                                                            <div className='address'>{block.patientAddress}</div>
                                                        </div>
                                                        <div className='right'>
                                                            <div className='date'>Appt Date: {block.appointmentDate}</div>
                                                            <div className='time'>Appt Time: {slots[block.appointmentSlot - 1]}</div>
                                                            <div className='doctor'>Doc Name: {getDoctorName(block.doctorId)}</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>




                </div>
                <div className='modal-msg'>{modalmsg}</div>
                <div className='filter-applied-msg'>{isFilterApplied && "Filter Applied"}</div>
            </div>
        </>
    )
}


// {/* <div className='temp-leads'>
//     <div className='temp-lead headers'>
//         <div className='name'>Name</div>
//         <div className='phone'>Phone</div>
//         <div className='source'>Source</div>
//         <div className='concern'>Concern</div>
//         <div className='createdOn'>Time</div>
//         {/* <div className='action-btn'>Action btn</div> */}
//     </div>
//     {temporaryLeads !== null &&
//         temporaryLeads.map((block, index) => {

//             const currentTime = new Date(CurrentDateTime());
//             const leadCreatedTime = new Date(block.createdOn);
//             const diff = Math.floor(Math.abs(currentTime - leadCreatedTime) / (1000 * 60 * 60));

//             var leadName = ""
//             var leadConcern = ""
//             var time = block.createdOn.split("T")[1].split(".")[0]
//             return (
//                 // <div className='temp-lead' key={index}>

//                 <Popup trigger={
//                     <div className={diff < 24 ? "temp-lead recent" : "temp-lead"}>
//                         <div className='name'>{block.name == "" ? "[no-name]" : block.name}</div>
//                         <div className='phone'>{block.phone}</div>
//                         <div className='source'>{block.source}</div>
//                         <div className='concern'>{block.concern == "" ? "[no-concern]" : block.concern}</div>
//                         {/* <div className={block.isNewLead == true ? "new new-lead" : "new-lead"}>{block.isNewLead ? "Yes" : "No"}</div> */}
//                         <div className='createdOn'>{time}</div>
//                     </div>
//                 } modal nested key={index}>{
//                         (close) => (
//                             <div className='temp-lead-modal'>
//                                 <div className='lead-details'>
//                                     <div className='title'>Lead Details</div>
//                                     <div><div>Name </div>
//                                         {block.name == "" ?
//                                             <input type="text" placeholder='no-name' onChange={(e) => {
//                                                 leadName = e.target.value;
//                                                 // console.log(leadName)
//                                             }} />
//                                             : block.name}
//                                     </div>
//                                     <div><div>Phone </div>{block.phone}</div>
//                                     <div><div>Source </div> {block.source}</div>
//                                     <div><div>Concern </div> {
//                                         block.concern == "" ? <input type='text' placeholder='no-concern' onChange={(e) => {
//                                             leadConcern = e.target.value
//                                         }} /> : block.concern
//                                     }</div>
//                                     <div><div>New Lead </div> {block.isNewLead === true ? "Yes" : "No"}</div>

//                                     {/* <div className='discard-lead'>Discard Lead</div> */}
//                                     {/* <div className='change-lead-type'>
//                                                         <select>
//                                                             <option value="">Select Lead type</option>
//                                                             <option>Appointment Booked somewhere else</option>
//                                                             <option></option>
//                                                         </select>
//                                                     </div> */}

//                                 </div>
//                                 <div className='lead-form'>
//                                     {localities !== null &&
//                                         <div>
//                                             <div className='select-locality'>Select Locality : {getLocalityName()}</div>
//                                             <select value={selectedLocality} onChange={(e) => {
//                                                 setSelectedLocality(e.target.value);
//                                                 fetchAvailableDoctorsOfLocality(e.target.value)
//                                                 setmodalmsg("")
//                                             }} >
//                                                 <option key={-1} value="">Select Locality</option>
//                                                 {localities.map((options, index) => (
//                                                     <option key={index} value={options._id}>{options.name}</option>
//                                                 ))}</select>

//                                             {docsOfLocality !== null &&
//                                                 <div className='docs-of-locality'>
//                                                     <div className='title'>Assign Doctor to the lead : {getDoctorName()}</div>
//                                                     <select value={selectedDoc} onChange={(e) => {
//                                                         setSelectedDoc(e.target.value)
//                                                     }}>
//                                                         <option key={-1} value="">Select Doctor</option>
//                                                         {docsOfLocality.map((doc, ind) => (
//                                                             <option key={ind} value={doc._id}>{doc.name}</option>
//                                                         ))}
//                                                     </select>
//                                                 </div>
//                                             }
//                                         </div>
//                                     }
//                                     <div className='address-input-label'>Enter Address of the Patient</div>
//                                     <textarea className='address-input' value={patientAddress} onChange={(e) => {
//                                         setPatientAddress(e.target.value)
//                                     }} > </textarea>


//                                     <div className='date-input'>
//                                         <div className='title'>Enter Appointment Date</div>
//                                         <input type='date' onChange={(e) => {
//                                             setAppointmentDate(e.target.value)
//                                         }} />
//                                     </div>

//                                     <div className='time-input'>
//                                         <div className='title'>Enter Appointment Time</div>
//                                         <input type='time' onChange={(e) => {
//                                             console.log(e.target.value)
//                                             setAppointmentTime(e.target.value)
//                                         }} />
//                                     </div>


//                                     <div>Change the lead to Active Lead</div>
//                                     <div className='submit-btns'>
//                                         <div className='yes' onClick={(e) => {
//                                             if (selectedLocality === "") {
//                                                 setmodalmsg("Select a Locality")
//                                             }
//                                             else if (selectedDoc === "") {
//                                                 setmodalmsg("Select a Doctor")
//                                             } else {
//                                                 changeLeadToActive(block._id, close, leadName, leadConcern)
//                                             }
//                                         }}>Yes</div>
//                                         <div className='no' onClick={(e) => {
//                                             setDocsOfLocality(null)
//                                             setSelectedLocality("")
//                                             setSelectedDoc("")
//                                             setPatientAddress("")
//                                             close()
//                                         }}>No</div>
//                                     </div>

//                                     <div className='modal-msg'>{modalmsg}</div>
//                                 </div>
//                             </div>
//                         )
//                     }
//                 </Popup>
//                 // </div>
//             )
//         })
//     }
// </div> */}