import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>
      <section className='fourth-fold'>

        <div className='container'>

          <div className='row d-flex align-items-center'>

            <div className='col-md-9 col-6'>
              <div className='schedule'>
                <h5>Conclusion</h5>

                <p>With Kyno Health's at-home doctor services, it will feel like having a doctor at home at all times! Our medical home care services are meant to be convenient and personalized for you. We bring to the table a comprehensive approach towards good healthcare delivery in Bangalore. Routine medical care, emergency assistance, diagnostic testing, specialized treatment- you just name it, and we will be right here to assist you. One can always rely on Kyno Health to deliver the highest quality medical care right in the comfort of one's home.</p>
                {/* <div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div> */}
                {/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

              </div>
            </div>



            <div className='col-md-3 col-6'>
              <div className=''>
                <img className='img-fluid d-none' src='/images/image-43500.svg' />
                <img className='img-fluid' src='/images/image-43500-l.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>
      <section className="nine-fold">
        <div className="container">
          <h5>Questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What medical treatments can home visit doctors in Bangalore provide?</Accordion.Header>
                    <Accordion.Body>
                      <p>Kyno Health's home visit doctors can provide all sorts of medical treatments in Bangalore. You can get checked for minor ailments, let us help you manage and treat your chronic ailments, and even provide post-operative care as and when required. </p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the fees for home visit doctors in Bangalore?</Accordion.Header>
                    <Accordion.Body>

                      <p>The fees for home visits to doctors in Bangalore are dependent on the treatment you want. Based on the type of consultation, the apparatus used, the use of medicines, and so on, the fees for the session are determined. </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Can I access home visit doctor services in Bangalore during weekends and holidays? </Accordion.Header>
                    <Accordion.Body>

                      <p>Yes, Kyno Health is all about making healthcare accessible as much as possible. Even during public holidays and weekends, you can book an easy appointment with Kyno Health to have a skilled doctor visit you at the house.</p>
                    </Accordion.Body>
                  </Accordion.Item>



                </Accordion>

              </div>

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
                <Accordion>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>What are the main benefits of using home visit doctors in Bangalore?</Accordion.Header>
                    <Accordion.Body>

                      <p>Calling in-home visit doctors in Bangalore is important for your convenience if you are leading a busy life or cannot visit a clinic. If you are suffering from immobility issues, are a pregnant woman, or are a senior citizen, it is not feasible to visit a hospital. This is why it is better to call home to visit doctors for maximum convenience.</p>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>How quickly can a home visit doctor reach my home in Bangalore?</Accordion.Header>
                    <Accordion.Body>

                      <p>Based on your location, a home visit to a doctor will take less than ten minutes to reach your home. If you are far away, a maximum of 30 minutes may be taken. Nevertheless, home visit doctors always arrive on time, equipped with all the important tools.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default index