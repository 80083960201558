import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'


function index() {


  return (
    <>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>General Health Check-Up Services - Kyno Health</title>
                    <meta name="description" content="At Kyno Health Center, we understand that your health is your most valuable asset. To empower you to take control of your well-being, we offer a state-of-the-art Comprehensive General Health Check-Up service." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/"/>

            </Helmet>
        
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}





export default index