import React, { useState } from "react";
import '../../styles/ourServices.css'
// import rightarrow from "../../utilities/images/rightArrow.png"
import service1 from "../../utilities/images/Our_services/service1.png"
import service2 from "../../utilities/images/Our_services/service2.png"
import service3 from "../../utilities/images/Our_services/service3.png"
import service4 from "../../utilities/images/Our_services/service4.png"
import service5 from "../../utilities/images/Our_services/service5.png"
import service6 from "../../utilities/images/Our_services/service6.png"

export default function OurServices() {

    // const [s1, setS1] = useState("service active")
    // const [s2, setS2] = useState("service")
    // const [s3, setS3] = useState("service")
    // const [s4, setS4] = useState("service")

    // const openService = (i) => {
    //     console.log("Service hit: " + i);
    //     setS1("service")
    //     setS2("service")
    //     setS3("service")
    //     setS4("service")
    //     if(i == 1){
    //         setS1("service active")
    //     }
    //     else if(i == 2){
    //         setS2("service active")
    //     }
    //     else if(i == 3){
    //         setS3("service active")
    //     }
    //     else{
    //         setS4("service active")
    //     }
    // }

    return (
        <>
            {/* <div className="our-services-section">
                <div className="leftbar">
                    <div className="leftbar-head" style={{ fontSize: "35px", fontWeight: 750 }}>Our Services</div>
                    <div className="leftbar-content" style={{ opacity: 0.75 }}>Our focus is to constantly improve the patient experience through innovation and technology.</div>
                </div>
                <div className="rightbar">

                    <div className={s1} onClick={(e) => {openService(1)}}>
                        <div className="service-title">
                            <img src={rightarrow} alt=""/>
                            At-Home Consultations
                        </div> 
                        <div className="service-content">
                            We bring the diagnostic equipment at home for basic tests to make the initial screening process easier for the patients. There is no need to see a specialist or visit the ER to get diagnosed. The test reports are also made available within 6-8 hours.
                        </div>

                        <hr />
                    </div>

                    <div className={s2} onClick={(e) => {openService(2)}}>
                        <div className="service-title">
                        <img src={rightarrow} alt=""/>
                            Diagnostic Services
                        </div>
                        <div className="service-content">
                            We provide prescribed and over-the-counter medications to patients at a 15% discount and deliver them withan an hour without any delay. Find the medicine you need, place the order and get doorstep delivery.
                        </div>

                        <hr />
                    </div>

                    <div className={s3} onClick={(e) => {openService(3)}}>
                        <div className="service-title">
                        <img src={rightarrow} alt=""/>
                            Medicine Delivery
                        </div>
                        <div className="service-content">
                            We ensure that our patients get proper medical attention on priority at best-in-class medical centers at a reasonable price. We are partnered with top hospitals and clinics to make superlative healthcare affordable and easily accessible.
                        </div>

                        <hr />
                    </div>

                    <div className={s4} onClick={(e) => {openService(4)}}>
                        <div className="service-title">
                        <img src={rightarrow} alt=""/>
                            Hospital Support
                        </div>
                        <div className="service-content">
                            KYNO's experienced doctors are well-trained and certified. Our doctors thoroughly evaluate the patients without making the patients feel rushed. Whether you need a routine medical checkup or treatment for a chronioic disorder, our specialists will take care of everything.
                        </div>
                    </div>

                </div>
            </div> */}



            <div className="our-services" id="ourServices">
                <div className="title">Our Services</div>
                <div className="desc">Our medical team in prepared to handle a wide range of medical needs, such as primary care, and specialist consultations. We have the knowledge and experience to meet all of your healthcare needs.</div>
                <div className="content">
                    <div className="row1">
                        <div>
                            <div>Schedule your appointment via call/chat</div>
                            <img src={service1} />
                        </div>
                        <div>
                            <div>Doctor at your home within 60 min</div>
                            <img src={service2} />
                        </div>
                        <div>
                            <div>Prolonged, detailed and personalized health check ups</div>
                            <img src={service3} />
                        </div>
                    </div>
                    <div className="row2">
                        <div>
                            <div>Prescribed diagnostic tests taken from home</div>
                            <img src={service4} />
                        </div>
                        <div>
                            <div>Medicines delivered at home</div>
                            <img src={service5} />
                        </div>
                        <div>
                            <div>Regular and timely follow-ups are done to ensure complete recovery</div>
                            <img src={service6} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}