import React, { useEffect, useState } from 'react'
import "../../../styles/Sales_All_appointments.scss"
import axios from 'axios'
import { localBackend } from '../../../utilities/constants'
import { useNavigate } from 'react-router-dom'

export default function Sales_All_Appointments(props) {

    const navigate = useNavigate()

    const [salesEmployeeId, setSalesEmployeeId] = useState(props.salesEmployeeId)
    const [salesEmployees, setSalesEmployees] = useState(null)

    const [saveAllAppointments, setSaveAllAppointments] = useState(null)
    const [filteredAllAppointments, setFilteredAllAppointments] = useState(null)
    const [localities, setLocalities] = useState(null)
    const [doctors, setdoctors] = useState(null)


    const fetchAllAppointments = async () => {
        const result = await axios.get(`${localBackend}getAllAppointments?salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            console.log(resp.data.data)
            setSaveAllAppointments(resp.data.data)
            setFilteredAllAppointments(resp.data.data)
        })
    }


    const fetchAllSalesEmployee = async () => {
        const result = await axios.get(`${localBackend}getAllSalesEmployee?salesEmployeeId=${salesEmployeeId}`).then((resp) => {
            setSalesEmployees(resp.data.data)
        })
    }

    const getSalesEmployeeName = (id) => {
        // console.log(salesEmployees)
        var obj = salesEmployees.find(d => d._key == id) || null;
        var name = obj === null ? "" : obj.name;
        return name
    }

    const fetchLocalities = async () => {
        const res = await axios.get(`${localBackend}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                // console.log("Localities: ", resp.data.data)
                return resp.data.data
            }
        })
    }

    const getLocalityName = (id) => {
        var obj = localities.find(l => l._id === id) || null;
        if (obj === null) {
            return "";
        } else {
            return obj.name
        }
    }

    const fetchdoctors = async () => {
        const result = await axios.get(`${localBackend}allDoctors`).then((resp) => {
            // console.log(resp.data.data)
            setdoctors(resp.data.data)
        })
    }

    const getdoctornamebyid = (id) => {
        var obj = doctors.find(d => d._id == id) || null;
        var name = obj === null ? "" : obj.name;
        return name
    }

    useEffect(() => {
        fetchLocalities()
        fetchdoctors()
        if (salesEmployeeId !== "" && props.tabSelected == "APPOINTMENTS") {
            fetchAllAppointments();
            fetchAllSalesEmployee()
            const intervalId = setInterval(() => {
                fetchAllAppointments()
            }, 30000)
            return () => clearInterval(intervalId)
        }
    }, [salesEmployeeId])

    return (
        <>
            <div className='sales-all-appointments'>

                <div className='filter-bar'></div>

                <div className='content'>

                    <div className='headers'>
                        <div className='sno'>S.No.</div>
                        <div className='patient-name'>Patient Name</div>
                        <div className='city'>City</div>
                        <div className='appointment-date'>Appointment Date</div>
                        <div className='appointment-status'>Appointment Status</div>
                        <div className='fees-charged'>Fees Charged</div>
                        <div className='appointment-type'>Appointment Type</div>
                        <div className='doctor'>Doctor</div>
                        <div className='bd-name'>BD name</div>
                        <div className='payment-status'>Payment Status</div>
                        <div className='open-appointment'>Open Appointment</div>
                    </div>

                    {filteredAllAppointments !== null && salesEmployees !== null && doctors !== null && localities !== null && filteredAllAppointments.map((_appointment, _index) => {

                        return (

                            <div className='appointment'>
                                <div className='sno'>{_index+1}</div>
                                <div className='patient-name'>{_appointment.patientFamily !== null ? _appointment.patientFamily.name : ""}</div>
                                <div className='city'>{_appointment.patient !== null ? getLocalityName(_appointment.patient.city) : "[no-city]"}</div>
                                <div className='appointment-date'>{_appointment.appointmentdate || _appointment.appointmentDate}</div>
                                <div className='appointment-status'>{_appointment.status}</div>
                                <div className='fees-charged'>{_appointment.amountToBeCollected} Rs.</div>
                                <div className='appointment-type'>{_appointment.serviceType}</div>
                                <div className='doctor'>{getdoctornamebyid(_appointment.doctorId)}</div>
                                <div className='bd-name'>{getSalesEmployeeName(_appointment.appointmentBookedBy)}</div>
                                <div className='payment-status'>{_appointment.transaction !== null  ?  _appointment.transaction.status : "[no-transaction]"}</div>
                                <div className='open-appointment' onClick={(e) => {
                                    navigate(`/dashboard/patient-information-page?pid=${_appointment.patientPhone}&back=sales-dashboard-appointment`)
                                }}>Open Appointment</div>
                            </div>

                        )
                    })}


                </div>

            </div>
            {/* </div> */}
        </>
    )
}
