import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Services We Offer</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem2'>
<h4>Medical Consultations</h4>
<p>Our qualified and skilled doctors are available for comprehensive medical consultations in the comfort of your home. Whether you're dealing with a minor ailment, a chronic condition, or simply need medical advice, our home doctors are here to listen, diagnose, and recommend appropriate treatments.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Urgent Care</h4>
<p>Life's unexpected health issues don't always happen during regular office hours. Our home doctor service provides urgent medical care for non-life-threatening conditions, ensuring you receive prompt attention when you need it the most.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Prescription Services</h4>
<p>When a prescription is necessary, our home doctors can provide you with the required medications, saving you a trip to the pharmacy. We ensure you have access to the right treatments without any unnecessary hassle.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Diagnostic Tests</h4>
<p>In many cases, diagnostic tests are essential for accurate medical evaluation. Our home doctor service can arrange for various diagnostic tests, such as blood tests, urine tests, and more, right in the comfort of your home.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Vaccinations</h4>
<p>Stay up-to-date with your vaccinations without having to leave your house. Our home doctor service offers a range of vaccinations to help you and your family stay protected against preventable diseases.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Benefits of Choosing Our Home Doctor Service</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-4'>
<div className='programitem3'>
<h4>Convenience</h4>
<p>No more waiting rooms or travel time. Our home doctor service brings medical care to your doorstep, saving you time and energy.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Personalized Care</h4>
<p>We focus on providing individualized attention to each patient. Our doctors take the time to understand your specific needs and concerns.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Reduced Risk of Infection</h4>
<p>Especially during times of epidemics or when you're feeling unwell, avoiding crowded medical facilities can lower the risk of exposure to infections.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Comfort and Peace of Mind</h4>
<p>Receiving medical care at home allows you to recover in a familiar and comfortable environment, reducing stress and promoting a quicker healing process.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Family-Focused</h4>
<p>Our home doctor service caters to the entire family, from young children to the elderly. We understand the importance of holistic healthcare for all age groups.

</p>
</div>
</div>

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>How It Works</h2>
<div className='ml-70'>
<div className='row'>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Schedule an Appointment</h4>
<p>Call our hotline or use our online booking system to schedule an appointment at your preferred time.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Doctor's Visit</h4>
<p>Our qualified doctor will arrive at your doorstep at the scheduled time. They will conduct a thorough examination, provide a diagnosis, and discuss treatment options.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Treatment and Medication</h4>
<p>If necessary, the doctor will provide treatments and medications on the spot or arrange for delivery to your home.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will ensure proper follow-up, monitoring your progress and adjusting treatments if needed.</p>
</div>
</div>

{/* <div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will schedule follow-up visits as needed to monitor your progress and make adjustments to your treatment plan if required.</p>
</div>
</div> */}


</div>
</div></div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index