import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When should I seek medical attention for an animal bite?</Accordion.Header>
                    <Accordion.Body>
                      <p>It is essential to seek medical attention for an animal bite as soon as possible, regardless of the severity. Even minor bites can lead to infections, and prompt evaluation by a doctor helps assess the risk and initiate appropriate treatment.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How do I know if an animal bite is infected?</Accordion.Header>
                    <Accordion.Body>
                      <p>Signs of infection include redness, swelling, warmth around the wound, and the presence of pus or increasing pain. If you notice any of these symptoms, it's crucial to consult a doctor promptly for evaluation and treatment.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header> What types of doctors specialize in treating animal bites?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Doctors who specialize in emergency medicine, trauma, or infectious diseases are typically well-equipped to handle the evaluation and treatment of animal bites. At Kyno Health, our team includes doctors with expertise in these areas.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Are all animal bites at risk of rabies transmission?
</Accordion.Header>
                    <Accordion.Body>
                    <p>While not all animal bites carry the risk of rabies, it's essential to assess the situation carefully. Bites from unknown animals or wildlife may pose a higher risk. Our doctors are trained to evaluate the risk of rabies transmission and administer appropriate vaccinations if necessary.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What should I do before coming to the doctor for an animal bite?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Clean the wound gently with mild soap and water, apply an antiseptic if available, and cover the wound with a clean bandage. However, these steps do not replace the need for professional medical evaluation, so seeking prompt medical attention is crucial.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                  

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>Do I need a tetanus shot for every animal bite?</Accordion.Header>
                    <Accordion.Body>
                      <p>Tetanus shots are typically recommended for puncture wounds or bites that break the skin. Our doctors will assess your immunization status and provide a tetanus shot if necessary to prevent tetanus infection.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How long does it take for an animal bite to heal?</Accordion.Header>
                    <Accordion.Body>
                      <p>The healing time for an animal bite varies depending on the severity of the injury and the type of treatment provided. Our doctors will provide guidance on wound care and schedule follow-up appointments to monitor the healing process.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can I receive treatment for an animal bite without insurance?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, our Kyno Health Hospital offers options for patients without insurance. We are committed to providing accessible care, and our staff can discuss available payment plans or alternative arrangements.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>What precautions should I take after receiving treatment for an animal bite?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Follow the care instructions provided by our doctors, including proper wound care, taking prescribed medications, and attending scheduled follow-up appointments. Report any unusual symptoms or concerns promptly.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Are there specific complications associated with animal bites?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, complications can include infections, tissue damage, and in some cases, the transmission of diseases. Our doctors are trained to identify and address potential complications, ensuring comprehensive care for your well-being.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index