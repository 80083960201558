import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Schedule Doctor Appointment: Your Path to Timely and Hassle-Free Healthcare</h3>
    
    <p className='d-md-none d-lg-none'>At Kyno Health, we understand that your time is valuable, and your health is paramount. That's why we've made scheduling a doctor's appointment a seamless and convenient process. With just a few simple steps, you can access the medical care you need, when you need it. Say goodbye to long waiting times and hello to efficient and patient-centered healthcare.
</p>

<p className='mob-noee'>At Kyno Health, we understand that your time is valuable, and your health is paramount. That's why we've made scheduling a doctor's appointment a seamless and convenient process. With just a few simple steps, you can access the medical care you need, when you need it. Say goodbye to long waiting times and hello to efficient and patient-centered healthcare.
</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
