import React, { useEffect, useState } from "react";
import bg from "../../utilities/images/moedbg.png"

import drAnish from "../../utilities/images/Our_doctors/drAnish.jpg"
import drKuldeep from "../../utilities/images/Our_doctors/drKuldeep.jpg"
import drPulkit from "../../utilities/images/Our_doctors/drPulkit.jpg"
import drUmar from "../../utilities/images/Our_doctors/drUmar.jpg"
import drGaurav from "../../utilities/images/Our_doctors/drGaurav.jpg"
import "../../styles/moed.css"
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import axios from "axios";
import { backendURL } from "../../utilities/constants";


export default function Meetoutdoctors() {


    const [docObj, setDocObj] = useState(null)
    const [docLoading, setDocLoading] = useState(false)

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            //   slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 600 },
            items: 2,
            //   slidesToSlide: 2 // optional, default to 1.
        },
        // mobile: {
        //   breakpoint: { max: 464, min: 0 },
        //   items: 1,
        // //   slidesToSlide: 1 // optional, default to 1.
        // }
    };


    const fetchDoctors = () => {
        axios.get(`${backendURL}allDoctors`).then((resp) => {
            let r = resp.data.data;
            console.log(resp.data.data)
            setDocObj(r);
            setDocLoading(true)
        }).catch(err => {
            console.log(err)
            setDocLoading(false)
        })
    }

    useEffect(() => {
        fetchDoctors()
    }, [])


    return (
        <>
            <div className="moed">
                <div className="moed-bg">
                    <img src={bg} />
                </div>
                <div className="heading">Meet Our Expert Doctors</div>
                {docLoading !== false && docObj !== null &&
                    <Carousel responsive={responsive} showDots={false} swipeable={true} infinite={true} autoPlay={true} autoPlaySpeed={2000} className="moed-content-carousel">
                        {docObj.map((block, index) => {
                            if (block.currentStatus == "AVAILABLE") {
                                return (
                                    <div className="doc" key={index}>
                                        <img src={`https://drive.google.com/uc?export=view&id=${block.img_link}`} alt={block.name} />
                                        <div>
                                            <div className="doc-name">{block.name}</div>
                                            <div className="doc-type">{block.speciality}</div>
                                            <div className="doc-exp">{block.experience}</div>
                                        </div>
                                    </div>
                                )
                            }
                        })}



                    </Carousel>

                }

                {docLoading !== null && docObj !== null &&
                    <div className="moed-content">


                        {docObj.map((block, index) => {
                            if (block.currentStatus === "AVAILABLE") {
                                return (
                                    <div className="doc" key={index}>
                                        <img src={`https://drive.google.com/uc?export=view&id=${block.img_link}`} alt={block.name} />
                                        <div>
                                            <div className="doc-name">{block.name}</div>
                                            <div className="doc-type">{block.speciality}</div>
                                            <div className="doc-exp">{block.experience}</div>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                    </div>
                }
            </div>
        </>
    )
}