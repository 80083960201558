import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
       <section className='thrd-fold'>
<div className='container'>
<h2 className='mb-2 text-center'>Call In Special Medical Attention At Home</h2>
<p className='text-center'>You may ask, "What exactly are they offering?". We are here to tell you everything we mean to give you. Kyno Health is a convenient and comfortable way to access medical care at home. You do not have to face the hassle of commuting to the hospital when you have us, for we understand the importance of time when it comes to your health. We are here to give you the peace of mind you deserve through timely assistance.</p>
<div className=''>
<div className='row'>


<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/excellence.svg' alt='excellence' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Precise Treatment</h4>
<p>We hold the highest ideals of medical service. We are all about ensuring that every home visit is spent in giving you the treatment you deserve. Our approach is precise and calculated to give out the correct diagnosis. Our general physicians have undergone complex training and stick to protocols. There is no compromise for us when it comes to you. This team of physicians goes out of its way to ensure you feel satisfied after each treatment. A total of 5000+ patients have given us a thumbs up! But we do not stop here. Gurgaon is a city of busybodies and driven people who deserve home care to help them remain healthy. You hustle, and we will take care of your health. After all, having us over is like having a doctor at home.
 </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/Equipped.svg' alt='equipped' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Top-notch Equipment</h4>
<p>How do we ensure perfect treatment? Easy, we always have top-notch equipment with us. Our medical and emergency medical kits are always on the move as we navigate the busy Gurgaon landscape to reach you. The amount of lives lost and suffering caused just because of the lack of necessary equipment is heartbreaking. We truly add "care" back to healthcare by always being prepared with every medical tool. This is an important attribute because each patient needs to have the chance to be treated carefully and holistically. It does not matter if you need a basic consultation or thorough treatment; you just know we will come with your medical supplies kit. These kits help us check your vitals and perform quick on-site tests. Basic medication, diagnostic instruments, sanitation tools, you name it, we carry it all with us </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/consultation.svg' alt='consultation' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Thorough Consultation</h4>
<p>Consultations at physical clinics and hospitals are not timely and affordable for everyone. Senior citizens, pregnant women, and those with a busy life overall may be unable to visit a clinic and wait for hours. You may even know when you can be consulted due to long queues. This is never the case with Kyno Health. We eliminate the need to leave the house and stick to a time slot someone else decided for you. You can choose any time you need to meet us; our doctors will take it from then on. In the comfort of your house, you can have a thorough consultation where you can communicate about your health and symptoms. Our doctors only believe in detailed consultations that help them prescribe tailored treatments instead of generic solutions.</p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/follow.svg' alt='follow' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Follow-up Sessions</h4>
<p>Once you have had our thorough consultation, you can go beyond that to schedule follow-up sessions with us. We have dedicated follow-up assistance sessions to discuss your health and conditions further. We care about knowing your progress and how you can improve in the long run. Medical care is meant to bring you closer to well-being with every consultation. It is a pleasure to serve you beyond one consultation and see you do better health-wise.</p>
</div>
</div>


</div>
</div></div>

</section>
    </>

  )
}

export default index