import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'

function index() {
  return (
    <>
                <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Doctor Home Visit in Noida | Kyno Health</title>
                    <meta name="description" content="Experience convenient and personalized medical care with Kyno Health's doctor home visit service in Noida. Experienced doctors, prompt service, and quality care at your doorstep." data-react-helmet="true"/>
                    <link rel="canonical" href="https://www.kynohealth.com/doctor-home-visit-noida"/>

            </Helmet>
    <Nav/>
    <Hero/>
    <Content/>
    <Faqs/>
    <Footer/>
    </>
  )
}

export default index