import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Why is a general health check-up important, even if I feel healthy?</Accordion.Header>
                    <Accordion.Body>
                      <p>Regular health check-ups are crucial for early detection of potential health issues that may not exhibit noticeable symptoms. Detecting problems early allows for prompt intervention and improved treatment outcomes.
</p>
                    </Accordion.Body>

                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header> How often should I undergo a general health check-up?
</Accordion.Header>
                    <Accordion.Body>

                      <p>The frequency of health check-ups depends on factors such as age, gender, family history, and existing health conditions. In general, an annual check-up is recommended for adults. However, your healthcare provider will tailor a schedule based on your individual needs.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What tests are typically included in a general health check-up?
</Accordion.Header>
                    <Accordion.Body>
                      <p>A comprehensive health check-up may include physical examinations, blood tests (cholesterol, blood sugar, etc.), imaging studies (X-rays, ultrasound), cardiac evaluations, cancer screenings, vision and hearing tests, nutritional assessments, and lifestyle counseling.
</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Can a general health check-up detect all health issues?
</Accordion.Header>
                    <Accordion.Body>
                    <p>While a general health check-up is comprehensive, it may not cover every possible health concern. Your healthcare provider will tailor the tests based on your age, gender, and risk factors. Some conditions may require specialized screenings.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Do I need to fast before a general health check-up?</Accordion.Header>
                    <Accordion.Body>
                      <p>Certain blood tests, such as cholesterol and blood sugar, may require fasting for accurate results. Your healthcare provider will provide specific instructions on fasting or any other preparations before the check-up.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              
              <Accordion.Item eventKey="5">
                    <Accordion.Header>How long does a general health check-up take?
</Accordion.Header>
                    <Accordion.Body>
                      <p>The duration varies based on the tests included in your check-up. On average, a comprehensive health check-up may take a few hours. It's advisable to allocate sufficient time to ensure all necessary assessments are completed thoroughly.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Are general health check-ups covered by insurance?</Accordion.Header>
                    <Accordion.Body>
                      <p>Many insurance plans cover general health check-ups, but coverage varies. It's essential to check with your insurance provider to understand the specific tests covered and any out-of-pocket expenses.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>What should I bring to my general health check-up?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Bring a list of current medications, any relevant medical history, and details of any symptoms or concerns you may have. This information will help your healthcare provider tailor the check-up to address your specific needs.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  
                  <Accordion.Item eventKey="8">
                    <Accordion.Header> Can I discuss specific health concerns during my check-up?</Accordion.Header>
                    <Accordion.Body>
                      <p>Absolutely. A general health check-up is an excellent opportunity to discuss any health concerns or questions you may have. Be open and honest with your healthcare provider to ensure a comprehensive assessment.


</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>How long does it take to receive the results of my health check-up?</Accordion.Header>
                    <Accordion.Body>
                      <p>The turnaround time for results varies depending on the tests performed. Some results may be available on the same day, while others may take a few days. Your healthcare provider will inform you of the expected timeline for receiving results and schedule a follow-up appointment to discuss them.
If you have additional questions or concerns, feel free to reach out to our healthcare team at Kyno Health Center. We are here to support you on your journey to better health.

</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>
    </>
  );
}

export default index