import React from 'react'
import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index(props) {
  return (

    <>
      <section className="baner-region">

        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side ml-70'>
                <h3>{props.hero_title}</h3>
                <p>{props.hero_paragraph}</p>
                <p>{props.hero_paragraph_1}</p>


              </div>
            </div>


            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../../images/tags-1.png')}
                    webp={require('../../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../../images/bnr-img.png')}
                  webp={require('../../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default index
