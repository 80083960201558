import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>Frequently Asked Questions (FAQs) on How to Control Blood Pressure</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Why is controlling blood pressure important?</Accordion.Header>
                    <Accordion.Body>
                      <p>Controlling blood pressure is crucial because high blood pressure, or hypertension, can lead to serious health issues, including heart disease, stroke, and kidney problems.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is considered normal blood pressure?</Accordion.Header>
                    <Accordion.Body>

                      <p>Normal blood pressure is typically around 120/80 mmHg. Blood pressure is measured in millimeters of mercury (mmHg) and consists of two numbers: systolic pressure (the force when the heart beats) and diastolic pressure (the force when the heart is at rest between beats).</p>
                    </Accordion.Body>
                  </Accordion.Item>
               
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>How can diet help control blood pressure?</Accordion.Header>
                    <Accordion.Body>

                      <p>A heart-healthy diet, such as the DASH (Dietary Approaches to Stop Hypertension) diet, emphasizes fruits, vegetables, whole grains, lean proteins, and low-fat dairy. Additionally, reducing sodium intake and increasing potassium-rich foods contribute to blood pressure control.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Why is maintaining a healthy weight important for blood pressure control?</Accordion.Header>
                    <Accordion.Body>

                      <p>Being overweight or obese is a significant risk factor for hypertension. Losing even a small amount of weight can have a positive impact on blood pressure.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What types of exercise help with blood pressure control?</Accordion.Header>
                    <Accordion.Body>
                      <p>Regular physical activity, such as brisk walking, swimming, cycling, or jogging, can improve cardiovascular health and contribute to lower blood pressure. Aim for at least 150 minutes of moderate-intensity exercise per week.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>How does alcohol consumption affect blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>Excessive alcohol intake can contribute to high blood pressure. Moderation is key, with up to two drinks per day for men and up to one drink per day for women considered acceptable.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Does smoking impact blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, smoking damages blood vessels and contributes to the hardening of arteries, raising blood pressure. Quitting smoking is a crucial step towards better cardiovascular health and blood pressure control.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>


                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Can stress affect blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>Chronic stress can contribute to elevated blood pressure. Stress-management techniques, such as deep breathing, meditation, and engaging in relaxing activities, can help with blood pressure control.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>How does sleep affect blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>Poor sleep quality and duration can impact blood pressure. Aim for 7-9 hours of quality sleep per night, establish a regular sleep routine, and create a comfortable sleep environment.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Is home blood pressure monitoring important?</Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, home blood pressure monitoring allows for proactive management. Regularly tracking your blood pressure at home with a reliable monitor can provide valuable information for you and your healthcare provider.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* ---- */}

                  <Accordion.Item eventKey="10">
                    <Accordion.Header>Are there specific foods to avoid for blood pressure control?</Accordion.Header>
                    <Accordion.Body>
                      <p>Foods high in sodium, saturated fats, and cholesterol should be limited for blood pressure control. Processed foods, fast foods, and excessive consumption of red meat are examples of items that may contribute to hypertension.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="11">
                    <Accordion.Header>Can caffeine intake affect blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>While the relationship between caffeine and blood pressure is complex, excessive intake can lead to a temporary spike. Individuals sensitive to caffeine may consider limiting their intake, especially in the afternoon and evening.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="12">
                    <Accordion.Header>Do I need medication to control blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>In some cases, lifestyle changes alone may not be sufficient, and medication prescribed by your healthcare provider may be necessary. It's crucial to take prescribed medications consistently and attend follow-up appointments.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index