import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What is blood pressure, and why is it important to monitor?
</Accordion.Header>
                    <Accordion.Body>
                      <p>Blood pressure is the force of blood against the walls of your arteries. Monitoring blood pressure is crucial because high blood pressure, or hypertension, can lead to serious health problems like heart disease, stroke, and kidney disease.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>When should I see a doctor for blood pressure concerns?
</Accordion.Header>
                    <Accordion.Body>

                      <p>You should consult a doctor if you have high blood pressure, a family history of hypertension, or if you experience symptoms like frequent headaches, dizziness, or chest pain. Regular check-ups are also advisable to monitor your blood pressure.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>What can I expect during a visit to a doctor for blood pressure management?</Accordion.Header>
                    <Accordion.Body>
                      <p>During your visit, the doctor will measure your blood pressure, review your medical history, and discuss your lifestyle and any symptoms. They will create a treatment plan tailored to your specific needs, which may include medication, lifestyle changes, or both.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I manage blood pressure on my own without medication?</Accordion.Header>
                    <Accordion.Body>
                    <p>Lifestyle modifications, such as a heart-healthy diet, regular exercise, stress management, and limiting sodium and alcohol intake, can often help manage blood pressure. However, some individuals may require medication to control hypertension effectively.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>What lifestyle changes are most effective in managing blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>The most effective lifestyle changes include maintaining a healthy diet, engaging in regular physical activity, reducing stress, maintaining a healthy weight, and limiting sodium and alcohol consumption. Your doctor can provide guidance on these changes.</p>                  
                    </Accordion.Body>
                  </Accordion.Item>


                 

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>Are there any risk factors for high blood pressure that I should be aware of?</Accordion.Header>
                    <Accordion.Body>

                      <p>Risk factors for high blood pressure include a family history of hypertension, obesity, a sedentary lifestyle, a diet high in sodium, smoking, excessive alcohol consumption, and chronic stress.
</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Can children and young adults develop high blood pressure? </Accordion.Header>
                    <Accordion.Body>
                      <p>Yes, although less common, children and young adults can develop high blood pressure. It may be related to underlying medical conditions or lifestyle factors. Regular check-ups are essential for early detection.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>What are the potential complications of unmanaged high blood pressure?</Accordion.Header>
                    <Accordion.Body>
                      <p>Unmanaged high blood pressure can lead to heart disease, stroke, kidney disease, vision problems, sexual dysfunction, and other serious health issues. It's essential to manage blood pressure to reduce these risks.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="8">
                    <Accordion.Header>How often should I have my blood pressure checked by a doctor?</Accordion.Header>
                    <Accordion.Body>
                      <p>The frequency of blood pressure checks may vary depending on your age, risk factors, and your current blood pressure status. Your doctor will provide guidance on how often you should monitor your blood pressure and schedule follow-up appointments.</p>
<p>Regular blood pressure monitoring, combined with a healthy lifestyle and medical treatment when necessary, is essential for maintaining good heart health. If you have concerns or questions about your blood pressure, consult a doctor for guidance and personalized management.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>Can I stop taking blood pressure medication once my blood pressure is under control?</Accordion.Header>
                    <Accordion.Body>
                      <p>Discontinuing blood pressure medication should only be done under the supervision of your doctor. It's crucial to maintain blood pressure control to prevent complications. Changes to your treatment plan should be made in consultation with your healthcare provider.
</p>
                    </Accordion.Body>
                  </Accordion.Item>
                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index