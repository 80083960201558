import React from 'react'
import { Link } from "react-router-dom";
 import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Mastering Monsoon Health- Expert Tips to Avoid Getting Sick</h3>
   
    <p>The tormenting heatwave in India is all set to depart, leaving everyone super excited about monsoons! Most parts of the country are already experiencing refreshing monsoon showers, bringing much needed relief from the heat, and the related health issues that recently affected the entire nation. While the monsoons have many of us excited, anticipating chai, pakoras, and more, they also bring their own set of concerns. The increased humidity and water stagnation during this season heighten the risk of several diseases, including dengue, malaria, and chikungunya. Recently, Kerala, with its first monsoon showers, saw surges in dengue, leptospirosis, and hepatitis, prompting the health minister to urge precautions to prevent these outbreaks. This necessitates taking preventive measures to keep these illnesses at bay and enjoy the monsoon season to the fullest. In this blog, we&rsquo;ll discuss some of the common monsoon ailments, their symptoms, and preventive measures to stay protected against them.</p>
 <Link className="btn btn-warning " to="tel:01206056623">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
