import React from 'react'
import {Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';
// import './js/faqscheme.js'

function index() {

  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are the doctors providing the medical services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our team consists of highly-qualified General Physicians with a wide range of experience in top medical institutions. Meet Our Team"
      }
    },{
      "@type": "Question",
      "name": "How long will the home consultation last?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our doctors dedicate time to provide great treatment and answer all your questions. Our average consultations last 25-30 minutes."
      }
    },{
      "@type": "Question",
      "name": "What are the consultation charges for a doctor at home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Scheduled at-home consultation charges are ₹999 per visit. The charges may be slightly higher during the night or for immediate emergency home visits."
      }
    },{
      "@type": "Question",
      "name": "Can I request a visit by the same doctor I consulted before?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Certainly! Our aim is to provide you with the comfort & reliability of a 'family doctor'."
      }
    },{
      "@type": "Question",
      "name": "Can I contact or follow up with the doctor after consultation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Sure. Our team will proactively follow up with you. You may also reach out to your doctor for any queries after your consultation, free of charge."
      }
    },{
      "@type": "Question",
      "name": "Do you provide online consultations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We specialize in providing home-visitations. Online consultations are done mostly in two cases:- For proactive follow-ups after your consultation or in case you have any additional queries.- In case of an emergency, where the doctor may advise immediate action."
      }
    },{
      "@type": "Question",
      "name": "How and when do I make the payment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pay after the consultation with no upfront payments. When it comes to yours or your loved one’s health, time is of the essence!"
      }
    },{
      "@type": "Question",
      "name": "Which other medical services do you provide?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You may also request for trained and experienced nurses, 24X7 caretakers, advanced at-home diagnostic tests, sample collections, physiotherapy at home, medicines at home, or ambulance services. Explore All Our Services."
      }
    }]
  }
  return (
    <>
    <script type="application/ld+json">
      {JSON.stringify(faqscheme )}
    </script>

    <div className='main-menu'>
    <div className='container'>
    <nav className="navbar navbar-expand-lg navbar-light">
  <div className="container-fluid">
  <Link className="navbar-brand" to="#">
  <Image
      src={require('../../images/head-logo.png')}
      webp={require('../../images/head-logo.webp')}
      title='Kyno Health Logo'
      alt='headLogoImg'
    />
    {/* <img src="../images/head-logo.png" tittle="Kyno Health Logo" alt='Logo'/> */}
    </Link>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
     <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
    <div id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item">
        <Link className="nav-link" to="/">Home</Link>
        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/about-us">About Us</Link>        
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>        
        </li>


        <li className="nav-item dropdown">
        <a href='/' className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown'>Services <span className="caret"></span></a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-home-visit-noida">Doctors at Home in 60 mins</Link>
                </li>
                {/* <li>
                  <Link className="nav-link dropdown-item" to="/physician-near-me">Physician near me</Link>
                </li> */}
                <li>
                  <Link className="nav-link dropdown-item" to="/doctors-near-me">Best Doctors in Delhi - NCR</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/schedule-doctor-appointment">Appointment Scheduling</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/iv-therapy">IV Drip at Home</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/treatment-for-bed-sore">Bed Sore Treatment</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-on-call-home-visit">On-Call Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/twenty-four-hour-visiting-doctor">24*7 Doctors</Link>
                </li>
                <li>
                  <Link className="nav-link dropdown-item" to="/doctor-for-fever-treatment">Doctors for Fever at Home</Link>
                </li>
              </ul>
            </li>

        {/* <li className="nav-item">
        <Link className="nav-link" to="/services">Services</Link> */}
        
        {/* </li> */}
        {/* <li className="nav-item">
        <Link className="nav-link" to="/meet_our_doctors">Services</Link>
        </li> */}
        <li className="nav-item butn">
        {/*<Link className="nav-link" to="/booking_form">Book a visit</Link> */} {/*Book Visit*/}
        <Link className="nav-link" to="tel:+917996643888">Call: 79966 43888</Link>
        </li>

        <li className="nav-item butn seo">
        <Link className="nav-link" to="tel:+917996643888">Call: 7996643888</Link>      
        </li>

      </ul>
    </div>
    </div>
  </div>
</nav>
</div>
</div>
    </>
  )
}

export default index