import React, { useState } from 'react'
import "../../../styles/sales_doctor_management.scss"
import axios from 'axios'
import ReactSwitch from 'react-switch'
import { localBackend } from '../../../utilities/constants'
import Select from 'react-select'
export default function Sales_doctor_management(props) {



    const [doctors, setDoctors] = useState(null)
    const [localities, setLocalities] = useState(null)
    const  [salesEmployeeId, setSalesEmployeeId] = useState(props.salesEmployeeId)

    const fetchLocalities = async () => {
        const res = await axios.get(`${localBackend}getAllLocalities`).then((resp) => {
            if (resp.data.statusCode === 1) {
                setLocalities(resp.data.data)
                // console.log(resp.data.data)
                return resp.data.data
            }
        })
    }


    const getdoctornamebyid = (id) => {
        var obj = doctors.find(d => d._id == id) || null;
        var name = obj === null ? "" : obj.name;
        return name
    }
    const fetchdoctors = async () => {
        const result = await axios.get(`${localBackend}allDoctors`).then((resp) => {
            // console.log(resp.data.data)
            setDoctors(resp.data.data)
        })
    }

    const handleStatusToggle = async (id, status) => {
        
        let s = status == true ? "AVAILABLE": "UNAVAILABLE"
        let body = {
            doctorId:  id,
            salesEmployeeId: salesEmployeeId,
            status: s
        }
        const result = await axios.post(`${localBackend}toggleDoctorStatus`, body).then((resp) => {
            // console.log(resp.data)
            if(resp.data.statusCode === 1){

                const updates = [...doctors]

                const toUpdate = updates.find(item => item._id == id)
                if(toUpdate){
                    if(status == false){
                        toUpdate.currentStatus = "UNAVAILABLE"
                    }else{
                        toUpdate.currentStatus = "AVAILABLE"
                    }
                    setDoctors(updates)
                    // console.log("Updated", updates)
                }
            }
        })

    }

    const  handleLocalityChange = async  (doc, event) =>  {
        // console.log(doc, event)
        let _l = ""
        for(let i = 0 ; i <  event.length; i++){
            let _t = event[i].name;
            _l = _l + "," + _t
        }
        console.log(_l)

        let body = {
            salesEmployeeId: salesEmployeeId,
            doctorId:  doc._id,
            doctorLocalities: _l.substring(1)
        }

        const result = await axios.post(`${localBackend}handleDoctorLocalityChange`, body).then((resp) => {
            console.log(resp.data)
            if(resp.data.statusCode === 1 ){
                const updates  = [...doctors]

                const toUpdate =  updates.find(item => item._id  == doc._id)
                if(toUpdate){
                    toUpdate.locality = _l.substring(1)
                }
                setDoctors(updates)
            }
        })

    }


    useState(() => {
        fetchLocalities()
        fetchdoctors()
    }, [doctors])

    return (
        <>
            <div className='doctor-management-panel'>
                <div className='headers'>
                    <div className='name'>Name</div>
                    <div className='speciality'>Speciality</div>
                    <div className='experience'>Experience</div>
                    <div className='status'>Status</div>
                    <div className='toogle-status'>Toogle Status</div>
                    <div className='locality'>Locality</div>
                </div>

                <div className='doctors'>
                    {doctors !== null && doctors.map((doc, dIndex) => {

                        let _localities = doc.locality.split(",")
                        let __locality = []
                        for(let i = 0; i < _localities.length; i++){
                            let _found = localities.find(obj => obj.name == _localities[i])
                            __locality.push(_found)
                        }
                        // console.log(_localities)
                        return (
                            <div className='doctor' key={dIndex}>
                                <div className='name'>{doc.name}</div>
                                <div className='speciality'>{doc.speciality}</div>
                                <div className='experience'>{doc.experience}</div>
                                <div className="status"><span className={`${doc.currentStatus == "AVAILABLE" ? "active" : ""}`}>{doc.currentStatus}</span></div>
                                <div className='toogle-status'>
                                    <ReactSwitch checked={doc.currentStatus == "AVAILABLE"} onChange={(e) => {
                                        // console.log("changeing state: ", e)
                                        handleStatusToggle(doc._id, e)
                                        // employeeStatus = e
                                    }} />
                                </div>
                                <div className='locality'>
                                    <Select 
                                        options={localities}
                                        onChange={(e) => {
                                            handleLocalityChange(doc, e)
                                        }}
                                        isMulti={true}
                                        value={__locality}
                                        className='select-disease-container'
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option._id}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
