import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Experienced Paediatricians <span className='yellow'>(Child Specialists)</span> at Your Home!</h3>
    
    <p className='d-md-none d-lg-none'>Whether it's a sudden fever, a concerning rash, or any other paediatric health issue, you can trust our professionals to provide expert care at the comfort of your home.
</p>

<p className='mob-noee'>Whether it's a sudden fever, a concerning rash, or any other paediatric health issue, you can trust our professionals to provide expert care at the comfort of your home.
</p>
 <Link className="btn btn-warning " to="tel:+919818676103">Call Now</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagImg'
    />
    </div>
      <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
