import React from 'react'

function index() {
  return (
    <>
<section className=''>
<div className='container'>
<h2>Types of Diabetes</h2>

<h4>Type 1 Diabetes:</h4>
<ul>
<li>Type 1 diabetes is an autoimmune disorder where the body's immune system mistakenly attacks and destroys the insulin-producing beta cells in the pancreas. This results in little to no insulin production. Typically diagnosed in childhood or adolescence, individuals with Type 1 diabetes require daily insulin injections to survive. The cause of this type is still not fully understood, and it is not preventable.</li>
</ul>
<h4>Type 2 Diabetes:</h4>
<ul>
<li>Type 2 diabetes is characterized by insulin resistance, where the body's cells do not respond effectively to insulin, or insufficient insulin is produced. This type is often associated with lifestyle factors such as poor diet, sedentary behavior, and obesity. While it primarily affects adults, it can also occur in children. Type 2 diabetes can often be managed with lifestyle changes, including a healthy diet, regular exercise, and, in some cases, medication.</li>
</ul>

<h2>Common Symptoms</h2>
<p>Recognizing the symptoms of diabetes is crucial for early diagnosis and effective management. Common symptoms include:</p>
<ul>
<li><strong>Increased Thirst:</strong> Individuals with diabetes may experience excessive thirst, leading to increased water consumption.</li>
<li><strong>Frequent Urination:</strong> The inability of the kidneys to reabsorb glucose can result in increased urine production, leading to frequent trips to the bathroom.</li>
<li><strong>Unexplained Weight Loss:</strong> Despite increased hunger and food intake, weight loss may occur due to the body's inability to use glucose for energy.</li>
<li><strong>Fatigue:</strong> Insufficient glucose utilization can lead to fatigue and a general sense of low energy.</li>
<li><strong>Blurred Vision:</strong> High blood sugar levels can affect the shape of the eye's lens, leading to blurred vision.</li>
<li><strong>Slow Wound Healing:</strong> Diabetes can impair the body's ability to heal wounds and injuries, increasing the risk of infections.</li>
</ul>

<h2>What Causes Diabetes?</h2>
<p>Diabetes is a complex medical condition with various contributing factors. The causes of diabetes differ between the two main types: Type 1 and Type 2.</p>

<h4>Type 1 Diabetes:</h4>
<ul>
<li><strong>Autoimmune Response:</strong> The primary cause of Type 1 diabetes is an autoimmune reaction in which the body's immune system mistakenly attacks and destroys the insulin-producing beta cells in the pancreas. The exact trigger for this autoimmune response is not fully understood, but both genetic and environmental factors likely play a role.</li>
<li><strong>Genetic Predisposition: </strong>There is a genetic component to Type 1 diabetes, and individuals with a family history of the condition may have a higher risk of developing it. However, not everyone with a genetic predisposition will develop Type 1 diabetes, indicating the involvement of environmental triggers.</li>
<li><strong>Environmental Factors: </strong>Certain environmental factors, such as viral infections or exposure to specific toxins, may contribute to the development of Type 1 diabetes in individuals with a genetic predisposition. However, the precise environmental triggers are still under investigation.</li>
</ul>

<h4>Type 2 Diabetes:</h4>
<ul>
<li><strong>Insulin Resistance:</strong> In Type 2 diabetes, the primary cause is insulin resistance, where the body's cells do not respond effectively to insulin. This resistance forces the pancreas to produce more insulin to maintain normal blood sugar levels.</li>
<li><strong>Genetics:</strong> There is a strong genetic component in Type 2 diabetes, and individuals with a family history of the condition are at a higher risk. Genetic factors can influence insulin resistance and the functioning of insulin-producing cells.</li>
<li><strong>Lifestyle Factors:</strong> Unhealthy lifestyle choices significantly contribute to the development of Type 2 diabetes. These factors include:</li>
<li><strong>Poor Diet:</strong> Diets high in refined carbohydrates, sugars, and unhealthy fats can contribute to obesity and insulin resistance.</li>
<li><strong>Lack of Physical Activity:</strong> Sedentary lifestyles reduce the body's ability to utilize insulin effectively, increasing the risk of Type 2 diabetes.</li>
<li><strong>Obesity:</strong> Excess body weight, especially around the abdomen, is a major risk factor for Type 2 diabetes.</li>
<li><strong>Age and Ethnicity:</strong> The risk of Type 2 diabetes increases with age, and certain ethnic groups, such as African Americans, Hispanic/Latino Americans, Native Americans, and Asian Americans, are at higher risk.</li>
<li><strong>Gestational Diabetes:</strong> Women who experienced gestational diabetes during pregnancy are at an increased risk of developing Type 2 diabetes later in life.</li>
</ul>

<p>Understanding the causes of diabetes is essential for prevention, early detection, and effective management. While genetic factors may contribute, lifestyle choices play a significant role, highlighting the importance of adopting a healthy lifestyle to reduce the risk of developing Type 2 diabetes. <br/> Additionally, ongoing research continues to deepen our understanding of the complex interplay between genetics and environmental factors in the development of both Type 1 and Type 2 diabetes.</p>

<h2>What is the Treatment of Diabetes ?</h2>
<p>The treatment of diabetes aims to manage blood sugar levels and prevent complications associated with the condition. The specific approach to treatment depends on the type of diabetes (Type 1 or Type 2) and individual factors. Here's an overview of the common treatment options for diabetes:</p>
<h5>Type 1 Diabetes Treatment:</h5>

<p><strong>Insulin Therapy:</strong>Individuals with Type 1 diabetes require insulin therapy because their bodies do not produce insulin. Insulin can be administered through injections or an insulin pump.</p>

<p><strong>Multiple daily injections:</strong> Regular insulin injections, including long-acting and short-acting insulins, are commonly used to mimic the body's natural insulin production.</p>
<p><strong>Insulin pump:</strong> An insulin pump delivers a continuous supply of insulin throughout the day, and individuals can adjust the insulin dose based on their needs.</p>


<h5>Type 2 Diabetes Treatment:</h5>
<ul>
<li><strong>Lifestyle Modifications:</strong>

<p><strong>Dietary Changes:</strong> Adopting a balanced and healthy diet is crucial. Emphasize whole foods, limit refined carbohydrates and sugars, and control portion sizes.</p>
<p><strong>Regular Exercise:</strong> Physical activity improves insulin sensitivity and helps control blood sugar levels. Aim for at least 150 minutes of moderate-intensity exercise per week.</p>

</li>
<li><strong>Oral Medications:</strong>

<p>Various oral medications are available to help manage blood sugar levels in Type 2 diabetes.</p>
<p>Examples include metformin, sulfonylureas, meglitinides, DPP-4 inhibitors, SGLT2 inhibitors, and others.</p>
<p>Medication choices depend on factors such as the individual's health, response to medications, and potential side effects.</p>

</li>
<li><strong>Injectable Medications:</strong>

<p>For some individuals with Type 2 diabetes, injectable medications may be recommended, especially if oral medications are insufficient.<br/> Examples include GLP-1 receptor agonists and insulin.</p>

</li>
</ul>

<h4>Common Strategies for Both Types:</h4>
<ul>
<li><strong>Blood Sugar Monitoring:</strong>

<p>Regular monitoring of blood sugar levels helps individuals understand how their lifestyle choices and medications impact their diabetes management.<br/> Continuous glucose monitoring (CGM) systems provide real-time data on blood sugar levels.</p>

</li>
<li><strong>Education and Support:</strong>

<p>Education about diabetes management is crucial. Individuals need to understand their condition, medication regimens, and how lifestyle choices impact their health.<br/>Support groups and healthcare professionals can provide valuable guidance and emotional support.</p>

</li>
<li><strong>Weight Management:</strong>

<p>For individuals with Type 2 diabetes who are overweight, weight loss can significantly improve insulin sensitivity and blood sugar control.</p>

</li>
<li><strong>Regular Medical Check-ups:</strong>

<p>Routine check-ups with healthcare providers are essential for monitoring overall health, assessing diabetes management, and adjusting treatment plans as needed.</p>

</li>
<li><strong>Glycemic Control:</strong>

<p>The goal is to maintain blood sugar levels within a target range to prevent short-term and long-term complications.</p>

</li>
</ul>
<p>It's important to note that diabetes management is highly individualized, and treatment plans may need adjustments over time. Regular communication with healthcare professionals, adherence to treatment plans, and a commitment to a healthy lifestyle are key components of successful diabetes management. Additionally, ongoing research and advancements in diabetes care contribute to the development of new treatment options and strategies.</p>

</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Conclusion</h5>
<p>Diabetes is a complex and lifelong condition that requires careful management to prevent complications and maintain a good quality of life. By understanding the types, symptoms, and effective strategies for managing diabetes, individuals can take control of their health and work towards a brighter and healthier future. Regular collaboration with healthcare professionals and a commitment to a healthy lifestyle are crucial elements in the journey to successfully managing</p>

{/* <div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div> */}
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='/images/image-43500.svg'/>
<img className='img-fluid' src='/images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index