import React from "react";
import Accordion from 'react-bootstrap/Accordion';



function index() {

  return (
    <>

      <section className="nine-fold">
        <div className="container">
          <h5>questions for kyno</h5>
          <div className="ml-70">
            <h2>FAQs</h2>
          </div>

          <div className="mlt fqs">
            <div className="row">

              <div className="col-md-6">
                {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}

                <Accordion>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What are the benefits of home visit doctor services?</Accordion.Header>
                    <Accordion.Body>
                      <p>There are multiple benefits associated with getting your treatment or check-up done by Home Visit Doctors, such as increased convenience, personalized medical attention, expert support for chronic conditions, etc.</p>
                    </Accordion.Body>
                  </Accordion.Item>


                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What is the purpose of a home visit?</Accordion.Header>
                    <Accordion.Body>

                      <p>A home visit allows the Home Visit Doctors to create a better bond with the patient in a familiar environment by acquiring their medical history. It readily reduces the defaulter rate and increases medical compliance.</p>
                    </Accordion.Body>
                  </Accordion.Item>
               

                </Accordion>

              </div>

              <div className="col-md-6">
              {/* <Accordion defaultActiveKey={['4']} alwaysOpen> */}
              <Accordion>
              <Accordion.Item eventKey="5">
                    <Accordion.Header>What is the use of health care services?</Accordion.Header>
                    <Accordion.Body>

                      <p>Healthcare services are associated with improving a patient's health by diagnosing, treating, and preventing all sorts of physical complications such as diseases, injuries, etc.</p>

                    </Accordion.Body>
                  </Accordion.Item>
           
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>What is the main function of the healthcare system?</Accordion.Header>
                    <Accordion.Body>
                      <p>The primary aim of the healthcare system is to promote activities associated with maintaining and improving health. It also refers to the promotion of health-determinant effects.</p>
                    </Accordion.Body>
                  </Accordion.Item>

                
                </Accordion>
              </div>
            </div>
            </div>
            </div>
      </section>


    </>
  );
}

export default index