import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Understanding Bed Sores: A Complex Challenge</h2>
<p>Bed sores, also known as pressure ulcers or decubitus ulcers, develop when there's prolonged pressure on the skin and underlying tissues. This often occurs in individuals who are bedridden, use wheelchairs, or have limited mobility. The lack of movement restricts blood flow to specific areas, leading to tissue damage and open wounds.</p>
<h3>The Importance of Proper Dressing and Care</h3>
<p>Managing bed sores requires meticulous care to prevent infection, aid healing, and minimize discomfort. Proper dressing is a crucial aspect of this process. Dressings not only protect the wound from bacteria and contaminants but also promote an optimal healing environment. Our "Bed Sore Dressing at Home" service ensures that this essential aspect of care is delivered effectively.</p>
<div className='ml-70'>
<div className='row'>
<h3>Benefits of Bed Sore Dressing at Home</h3>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Comfort and Convenience</h4>
<p>Dealing with bed sores can already be uncomfortable. Our at-home service eliminates the need for additional travel, making the healing process more convenient and less stressful.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Personalized Attention</h4>
<p>Our trained healthcare professionals provide one-on-one care tailored to the specific needs of the patient. Each wound is unique, and our team understands the importance of individualized treatment.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Reduced Risk of Infection</h4>
<p>Home environments are generally more familiar and controlled than clinical settings, minimizing the risk of exposure to potentially harmful pathogens.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Faster Healing</h4>
<p>Being in a comfortable and familiar environment can contribute positively to the healing process. It can reduce stress, which is crucial for faster recovery.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Emotional Support</h4>
<p>Dealing with bed sores can take an emotional toll. Our team not only addresses the physical aspect of wound care but also provides emotional support to patients and their families.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
<h2>Our Approach to Bed Sore Dressing at Home</h2>
<p>When you choose our service, you're choosing compassionate and professional care for your or your loved one's bed sores. Here's what you can expect:</p>
<div className='ml-70'>
<div className='row'>




<div className='col-md-4'>
<div className='programitem3'>
<h4>Initial Assessment</h4>
<p>Our skilled healthcare professional will conduct a thorough assessment of the wound's condition, size, depth, and any signs of infection. This assessment guides the choice of dressings and treatment plan.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Dressing Selection</h4>
<p>Based on the assessment, our team will select the appropriate dressings for the wound. We use a variety of advanced dressings designed to accelerate healing and prevent infection.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Wound Cleaning</h4>
<p>Before applying new dressings, our team will gently clean the wound using sterile techniques to ensure a germ-free environment.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>

<h4>Application of Dressings</h4>
<p>Our skilled professionals will carefully apply the selected dressings, taking into consideration the wound's size, location, and level of exudate.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem3'>
<h4>Monitoring and Follow-Up</h4>
<p>Regular monitoring is essential for tracking the wound's progress. Our team will schedule follow-up visits to assess healing and make any necessary adjustments to the treatment plan.</p>
</div>
</div>

{/* <div className='col-md-3'>
<div className='programitem3'>

<h4>Vaccinations</h4>
<p>Stay up-to-date on essential vaccinations for disease prevention.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem3'>

<h4>Wound Care</h4>
<p>Skilled wound assessment, dressing changes, and infection prevention for faster healing.
</p>
</div>
</div> */}


</div>
</div></div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Empowering Patients and Caregivers</h2>
<p>Our goal is not only to provide effective wound care but also to empower patients and caregivers with the knowledge needed to maintain proper wound hygiene between visits. Our healthcare professionals educate caregivers on how to identify signs of infection, change dressings if needed, and provide emotional support to the patient.</p>

<h2>Choosing Comfort, Choosing Healing</h2>
<p>At "Bed Sore Dressing at Home," we understand the challenges that come with managing bed sores. Our services are designed to alleviate these challenges and provide holistic care in the comfort of your own home. Through personalized attention, professional wound care, and emotional support, we're here to help you or your loved one on the journey to healing. Contact us today to take the first step towards compassionate and effective bed sore treatment at home.</p>

{/* <div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Schedule an Appointment</h4>
<p>Call our hotline or use our online booking system to schedule an appointment at your preferred time.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Doctor's Visit</h4>
<p>Our qualified doctor will arrive at your doorstep at the scheduled time. They will conduct a thorough examination, provide a diagnosis, and discuss treatment options.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Treatment and Medication</h4>
<p>If necessary, the doctor will provide treatments and medications on the spot or arrange for delivery to your home.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Follow-Up</h4>
<p>Our team will ensure proper follow-up, monitoring your progress and adjusting treatments if needed.</p>
</div>
</div>

</div>
</div> */}

</div>
</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index