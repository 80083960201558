import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Why Seek Immediate Medical Attention for Animal Bites?</h2>
<p>Animal bites, whether from domestic pets or wildlife, can pose serious health risks. Immediate medical attention is crucial to prevent complications such as infection, tissue damage, and the potential transmission of diseases. Our specialized doctors are well-equipped to assess and treat a variety of animal bites, tailoring their approach based on the nature of the injury and the species involved.
</p>
 
<div className='ml-70'>
<div className='row'>
<h3>Our Expertise in Animal Bite Care</h3>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Prompt Evaluation</h4>
<p>Our doctors prioritize timely evaluation of animal bites to determine the severity of the injury and the risk of infection. This initial assessment guides the course of treatment.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Wound Cleaning and Dressing</h4>
<p>Thorough cleaning of the wound is essential to remove debris and reduce the risk of infection. Our doctors use advanced techniques and sterile materials for wound dressing to promote optimal healing.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Rabies Evaluation and Vaccination</h4>
<p>Animal bites, especially from unknown or wild animals, may carry the risk of rabies transmission. Our doctors are trained to assess this risk and provide appropriate vaccinations and post-exposure prophylaxis if necessary.

</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Antibiotic Therapy</h4>
<p>To prevent and treat infections associated with animal bites, our doctors may prescribe targeted antibiotic therapy. This ensures comprehensive care and minimizes the risk of complications.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Tetanus Immunization</h4>
<p>Tetanus is a potential concern with puncture wounds or deep bites. Our doctors will assess your immunization status and administer a tetanus vaccine if needed to safeguard your health.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Follow-Up Care</h4>
<p>We prioritize ongoing care and monitoring to track the progress of the healing process. Follow-up appointments are scheduled to address any concerns, ensure proper recovery, and make adjustments to the treatment plan if necessary.

</p>
</div>
</div>
</div>
</div>
</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Why Choose Kyno Health for Animal Bite Care?</h2>
<div className='ml-70'>
<div className='row'>
<div className='col-md-3'>
<div className='programitem2'>
<h4>Specialized Team</h4>
<p>Our doctors have specialized training in emergency medicine and are well-versed in the unique challenges presented by animal bites. They bring a wealth of experience and expertise to ensure optimal care.
</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>Kyno Health is equipped with cutting-edge facilities to support the diagnosis and treatment of animal bites. From diagnostic tools to treatment rooms, we provide a seamless and efficient care experience.</p>
</div>
</div>

<div className='col-md-3'>
<div className='programitem2'>
<h4>Compassionate Approach</h4>
<p>We understand the emotional stress associated with animal bites, and our doctors approach each case with empathy and compassion. Our goal is not only to treat the physical wounds but also to support you through the emotional aspects of the healing process.

</p>
</div>
</div>


<div className='col-md-3'>
<div className='programitem2'>
<h4>Collaborative Care</h4>
<p>Our doctors work closely with other specialists, including infectious disease experts and surgeons, to ensure a comprehensive approach to your care. This collaborative effort enhances the quality of treatment and promotes better outcomes.

</p>
</div>
</div>
</div>
</div>
</div>

</section>



<section className='thrd-fold'>
<div className='container'>
  <h2>Conclusion</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-12'>
<p>At Kyno Health, we prioritize your health and well-being in the face of animal bites. Our experienced doctors are dedicated to providing prompt, effective, and compassionate care. Trust us to be your partner in recovery, offering the expertise and support you need during this challenging time.</p>
<p>If you or a loved one has experienced an animal bite, don't hesitate to reach out to Kyno Health. We're here to provide the expert care you deserve. Contact us today to schedule a consultation and take the first step toward healing.</p>

</div>


</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index