import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Why are regular doctor appointments important?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Regular doctor appointments are essential for preventive care, early detection of health issues, and building a strong relationship with your healthcare provider. They help monitor your health, address concerns, and promote overall well-being."
      }
    },{
      "@type": "Question",
      "name": "How often should I schedule a doctor appointment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The frequency of doctor appointments may vary based on age, health status, and individual needs. As a general guideline, adults should aim for an annual check-up, while those with chronic conditions or specific health concerns may require more frequent visits."
      }
    },{
      "@type": "Question",
      "name": "What should I bring to a doctor appointment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Bring a list of current medications, any relevant medical records, information about your family's medical history, and a list of questions or concerns you want to discuss. This helps your healthcare provider better understand your health and address your specific needs."
      }
    },{
      "@type": "Question",
      "name": "What can I expect during a routine doctor appointment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A routine doctor appointment typically involves checking vital signs, discussing your health history, addressing any concerns you may have, and performing necessary screenings or tests. It's also an opportunity to discuss lifestyle factors and set health goals."
      }
    },{
      "@type": "Question",
      "name": "How can I manage the cost of doctor appointments?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Check with your healthcare provider about insurance coverage and inquire about any available discounts or payment plans. Some clinics also offer sliding scales or reduced fees for those without insurance. Investing in preventive care can often save on potential future healthcare costs."
      }
    },{
      "@type": "Question",
      "name": "What if I don't have any specific health concerns? Should I still go for a check-up?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, even if you feel healthy, regular check-ups are important for preventive care. They help detect potential health issues before symptoms arise and establish a baseline for your health. Preventive care is about maintaining and optimizing your well-being."
      }
    },{
      "@type": "Question",
      "name": "How do I choose the right healthcare provider for my needs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Consider factors such as location, office hours, communication style, and any specific medical needs you may have. Ask for recommendations from friends, family, or colleagues, and schedule a meet-and-greet appointment to assess compatibility."
      }
    },{
      "@type": "Question",
      "name": "Can I discuss mental health concerns during a doctor appointment?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely. Doctor appointments are not limited to physical health; they encompass mental health as well. Feel free to discuss stressors, emotional well-being, or any mental health concerns with your healthcare provider. They can offer support, resources, or referrals to mental health professionals."
      }
    },{
      "@type": "Question",
      "name": "What should I do if I'm nervous or anxious about doctor appointments?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It's common to feel nervous about medical visits. Communicate your concerns with your healthcare provider, and they can offer reassurance, explain procedures, and work with you to make the experience more comfortable. Open communication is key to a positive healthcare experience."
      }
    },{
      "@type": "Question",
      "name": "How can I make the most out of my doctor appointments?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Prepare for your appointments by bringing relevant information, asking questions about your health, and actively participating in discussions about preventive measures and lifestyle choices. Building a collaborative relationship with your healthcare provider ensures you receive comprehensive care tailored to your needs."
      }
    }]
  }



  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Importance of Scheduling and Attending Doctor Appointments</title>
        <meta name="description" content="In our busy lives, filled with work commitments, social obligations, and personal responsibilities, taking the time for a doctor appointment might seem like a minor inconvenience." data-react-helmet="true" />

        <meta name="keywords" content="doctor appointment,doctor appointment booking,doctor schedule appointment,doctors schedule" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/importance-of-doctor-appointments/" />
      </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index