import React from 'react'
import './Css/style.css'

function index() {
  return (
    <>
       <section className='thrd-fold'>
<div className='container'>
<h2 className='mb-2 text-center'>Get All The Medical Attention You Need, Right At Home</h2>
<p className='text-center'>Kyno Health firmly believes that each patient is different. Their needs vary from time to time, and so should the medical attention they are getting. Our wide range of medical services is meant to bring the finest care to your doorstep. You may want routine check-ups, medication management, wound care, or specialized treatment; whatever it is, Kyno Health ensures you are getting it at the earliest. Let us now see how Kyno Health brings all the medical attention you need right at your home:</p>
<div className=''>
<div className='row'>


<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/excellence.svg' alt='excellence' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Personalized Care For Each Patient</h4>
<p>Personalized care for each patient is the key fundamental of Kyno Health's home doctor in Bangalore services. Each patient has their own issues and concerns that they wish to address. We have on board a group of dedicated and empathetic doctors who will help you get back on the path of well-being. They will develop personalized care plans and tailor specific health goals that will help in bettering your condition. Each patient is struggling with something different, like post-surgery issues, chronic conditions, or minor ailments. Kyno Health brings home compassionate care so you get the attention you deserve. </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/Equipped.svg' alt='equipped' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Shortest Emergency Response Times</h4>
<p>Medical emergencies are moments of quick thought. One wrong move, and a person's condition can worsen, and they may lose their life. Kyno Health is an establishment of quick response to cater to such emergency situations where every second counts. Where ambulances prove late and unpunctual, we reach you in no time. Our 24/7 response channels help us prompt the movement of skilled doctors toward your home. When you rely on us, your troubles like chest pain, trouble breathing, or any other medical emergency will be catered to in no time. </p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/consultation.svg' alt='consultation' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Chronic Disease Consultations And Management</h4>
<p>Chronic diseases can significantly impact the quality of life you lead. They can impact you both physically and mentally. Managing them can be quite challenging on a daily basis, especially when you do not have access to continuous care. Kyno Health is the bridge towards better management of your chronic conditions. We are not a one-time guest at your house but your best companions in helping you manage your chronic condition and get meaningful consultations regarding it. We will monitor your progress, help you access the right care, and accompany you in every stage of your betterment.</p>
</div>
</div>

<div className='col-lg-6'>
<div className='programitem pt-2'>
<img src='images/follow.svg' alt='follow' className='mb-1 mt-1' />
<h4 className="mb-1 mt-1">Holistic Care Guaranteed</h4>
<p>From an emergency doctor home visit to simple consultations, Kyno Health ensures that the job is done. Our approach to everything is purely holistic, which determines that we wish to get to not just the physical aspects of the ailment but even the emotional and social aspects of your well-being. Our healthcare professionals are adept at understanding your situation and providing the much-needed support. Trusting Kyno Health's comprehensive care is the best thing you will do for your health.</p>
</div>
</div>


</div>
</div></div>

</section>
    </>

  )
}

export default index