import React from 'react'

function index() {
  return (
    <>
      <section className='ms'>
        <div className='container'>

          <h2>What are the Common Monsoon Ailments to Watch Out For?</h2>

          <p>Aside from the pleasant weather, the monsoon brings several health concerns. In July 2023, monsoon rainfall in India was 13 percent above the long-term average, intensifying the dengue crisis. As flooding affected many regions, certain districts experienced an unexpected surge in mosquito-borne diseases such as dengue fever. Since these risks prevail each year, it&rsquo;s crucial to be prepared. Before discussing preventive measures, let&rsquo;s first explore the various monsoon illnesses and their symptoms.</p>

          <ul>

            <li> <strong> Dengue Fever: </strong> <p>  Dengue fever is spread by Aedes mosquitoes. It&rsquo;s quite common during monsoons, and requires prompt attention. Symptoms of Dengue fever include high fever, headache, joint and muscle pain, pain behind the eyes, and rashes on the body.
            </p> </li>
            <li> <strong> Malaria: </strong> <p> Malaria spreads through the bites of infected female Anopheles mosquitoes. Common symptoms of Malaria include fever, chills, nausea, vomiting, severe headache, and muscle pain.
            </p> </li>
            <li> <strong> Chikungunya: </strong> <p> Chikungunya virus is transmitted by mosquitoes, most commonly Aedes (Stegomyia), aegypti and Aedes (Stegomyia) albopictus. Symptoms to watch out for include sudden high fever, muscle pain, joint pain, rash on the body, and severe fatigue.
            </p> </li>
            <li> <strong> Leptospirosis: </strong> <p> This condition, often common in Monsoons, is caused by exposure to water contaminated with animal urine. High fever, chills, headache, muscle ache, vomiting, and jaundice are among the common symptoms of Leptospirosis.
            </p> </li>
            <li> <strong> Hepatitis A and E: </strong> <p> Hepatitis A and E is transmitted through contaminated food and water. The various symptoms it may cause include fever, fatigue, loss of appetite, nausea, vomiting, abdominal pain, and yellowing of skin and eyes (jaundice).
            </p> </li>
            <li> <strong> Typhoid Fever: </strong> <p> Typhoid is also common during monsoon season and spreads through contact with contaminated food and water. To remain protected, watch out for symptoms such as high, prolonged fever, stomach ache, weakness, headache, and loss of appetite.
            </p> </li>
            <li> <strong> Respiratory Infections: </strong> <p> Monsoon accompanies humidity and dampness, which increases the risk of respiratory infections. Cough, cold, sore throat, and bronchitis are the common symptoms.
            </p> </li>
            <li> <strong> Fungal Infections: </strong> <p> Humidity during monsoons contributes to fungal infections. These infections may cause symptoms such as skin rashes, redness, and itchiness, primarily in the groin area and feet.</p> </li>

          </ul>


          <h2>Kyno's Expert Tips to Prevent Monsoon Health Issues</h2>
          <p>To help you navigate through this monsoon season healthily&mdash;enjoying your favourite pakoras rather than medicines&mdash;Kyno Health experts have curated some essential tips. Follow these tips to a T to stay healthy and disease-free.</p>

          <li><strong>Stay Dry:</strong>
            <br />  Keep yourself dry to avoid infections. To do so, always carry an umbrella or raincoat and wear waterproof shoes.</li>
          <li><strong>Drink Clean Water:</strong> <br />
            The water you consume should be boiled or purified. This is important to ensure you remain safe against waterborne diseases, which are quite common during monsoons. Also, always carry your own water bottle when you step out.</li>
          <li><strong>Boost Your Immunity:</strong> <br />
            Eating a balanced diet is essential for boosting immunity and remaining disease-free. Make sure your diet is rich in vitamin C and E. Eat ample lemons, oranges, and green leafy vegetables to stay healthy.</li>
          <li><strong>Prevent Mosquito Breeding:</strong> <br />
            Mosquitoes breed by laying eggs in stagnant water. Therefore, ensure there&rsquo;s no stagnant water around your house. Use mosquito repellents and nets to prevent mosquito bites and remain safeguarded against illnesses like dengue and malaria.</li>
          <li><strong>Use Neem or Tulsi Leaves:</strong> <br />
            Sip on boiled neem or tulsi water multiple times daily. Tulsi contain natural antibacterial and antiviral properties that can help prevent infections.</li>
          <li><strong>Sanitise Personal Items:</strong> <br />
            Ensure all the articles you&rsquo;re using are sanitised properly. These include mobile phones, keys, and wallets as they can carry bacteria and viruses.</li>
          <li><strong>Maintain Hygiene:</strong> <br />
            The importance of washing hands can&rsquo;t be stressed enough. Wash your hands regularly, especially before eating something. In addition, keep your surroundings clean, and take a shower immediately if you get wet in the rain to prevent infections.</li>
          <li><strong>Bonus Tip!!! :</strong> <br />
            If you or a loved one falls sick during the monsoon season, you can always rely on Kyno Health. Our health experts will come to your doorstep, saving you the hassle of stepping out for checkups and tests in pouring rain, thereby reducing the risk of infections and illnesses.</li>
          <p>If you want to book an at-home health checkup, get in touch today!</p>
          <p>Stay Safe!!</p>


        </div>
      </section>

    </>

  )
}

export default index