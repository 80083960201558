import React from 'react'

function index() {
  return (
    <>
<section className='thrd-fold'>
<div className='container'>
<h2>Understanding Stomach Issues</h2>
  <p>Stomach issues, also known as gastrointestinal or digestive problems, encompass a variety of conditions that affect the organs of the digestive system, including the stomach, intestines, and more. These issues can manifest as discomfort, pain, irregular bowel movements, and other symptoms that impact your overall well-being.
</p>

</div>

</section>


<section className='white-bg'>
<div className='container'>
<h2>Our Approach to Hypertension Management
</h2>
  <p>At Kyno Health clinic, we take a comprehensive and patient-focused approach to address stomach issues. Our goal is to diagnose the root cause of your symptoms and provide you with personalized treatment options. Here's what you can expect when you choose our services:
</p>
<div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Thorough Evaluation</h4>
<p>Our experienced doctors will conduct a detailed assessment of your symptoms, medical history, and any recent dietary or lifestyle changes. Understanding the source of your stomach issues is essential for creating an effective treatment plan.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Diagnostic Tests</h4>
<p>In some cases, we may recommend diagnostic tests, such as endoscopy, colonoscopy, imaging, or blood work, to identify the underlying cause of your stomach problems
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Individualized Treatment</h4>
<p>Once we've identified the cause, we will create a personalized treatment plan tailored to your specific needs. This may include medications, dietary recommendations, lifestyle changes, or procedures to alleviate your symptoms.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Dietary Guidance</h4>
<p>Nutrition plays a significant role in digestive health. Our team can provide guidance on dietary modifications to alleviate your symptoms and improve your overall well-being.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Lifestyle Modifications</h4>
<p>Lifestyle factors, including stress and physical activity, can impact stomach issues. We will work with you to develop strategies for stress management and maintaining a healthy lifestyle.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Regular Monitoring</h4>
<p>Stomach issue management is an ongoing process. We will ensure regular follow-up appointments to monitor your progress and make adjustments to your treatment plan as necessary.</p>
</div>
</div>
</div>
</div>
</div>

</section>

<section className='thrd-fold'>
<div className='container'>
<h2>Conditions We Treat</h2>
<p>Our team is experienced in managing a wide range of stomach issues, including but not limited to:
</p>
<div className=''>
<div className='row'>
<div className='col-md-12'>
<div className=''>
<ul>
  <li>Acid Reflux (GERD)</li>
  <li>Irritable Bowel Syndrome (IBS)</li>
  <li>Inflammatory Bowel Disease (IBD)</li>
  <li>Gastritis</li>
  <li>Peptic Ulcers</li>
  <li>Food Intolerances and Allergies</li>
  <li>Gallbladder Disorders</li>
  <li>Gastrointestinal Infections</li>
  <li>Constipation and Diarrhea</li>
  <li>Diverticulitis</li>
  <li>Celiac Disease</li>
</ul>

</div>
</div>
</div>
</div>
</div>

</section>

<section className='white-bg'>
<div className='container'>
  <h2>Why Choose Us for Your Stomach Issue Management Needs?</h2>

  <div className='ml-70'>
<div className='row'>
<div className='col-md-4'>
<div className='programitem2'>
<h4>Expertise</h4>
<p>Our doctors are specialists in gastrointestinal conditions, providing you with the highest level of care.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Compassion</h4>
<p>We understand the distress and inconvenience that stomach issues can bring to your life. Our team is committed to providing compassionate support throughout your journey to digestive health.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>State-of-the-Art Facilities</h4>
<p>We have modern facilities and the latest technology to ensure accurate diagnosis and effective treatments.</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Holistic Approach</h4>
<p>We focus not only on relieving your immediate symptoms but also on addressing the underlying causes and improving your overall well-being.
</p>
</div>
</div>

<div className='col-md-4'>
<div className='programitem2'>
<h4>Flexible Appointments</h4>
<p>We offer convenient appointment scheduling, making it easier for you to access the care you need.</p>
</div>
</div>

<div className='col-md-12'>
  <h4>Take Control of Your Stomach Issue.</h4>
  <p>Don't let stomach issues control your life any longer. Whether you're dealing with chronic discomfort, pain, or irregular bowel movements, we're here to help. Take the first step toward better digestive health by scheduling an appointment with our expert doctors.</p>

<p>Contact us today to book your consultation and begin your journey toward a life free from the discomfort of stomach issues. Your digestive health is our priority.</p>
</div>

</div>
</div>
</div>

</section>


<section className='fourth-fold'>
  
<div className='container'>

<div className='row d-flex align-items-center'>


<div className='col-md-9 col-6'>
<div className='schedule'>
<h5>Your Health, Your Home
</h5>
<p>At Kyno Health, we believe that quality healthcare should be easily accessible and comfortable. Our home doctor service is designed to provide you with the medical attention you need, right where you feel most at ease – your home. Trust our experienced medical professionals to deliver expert care and prioritize your well-being every step of the way. Contact us today to schedule your home doctor visit and experience healthcare in a whole new way.
</p>
<div><a href='' className="btn btn-warning">Schedule a Home Visit</a></div>
{/* <Link className="btn btn-warning">Schedule a Home Visit</Link> */}

</div>
</div>



<div className='col-md-3 col-6'>
<div className=''>
<img className='img-fluid d-none' src='images/image-43500.svg'/>
<img className='img-fluid' src='images/image-43500-l.svg'/>
</div>
</div>

</div>
</div>

</section>


    </>

  )
}

export default index