import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'

import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

import { Link } from "react-router-dom";
import Image from 'react-image-webp';

function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How can I find the best general physicians for home visits in Mehrauli, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can take the help of your social circle which includes family members and friends as they provide the true information of the general physician. You can also take the help of online sites to check the reviews and comments posted by other people."
      }
    },{
      "@type": "Question",
      "name": "How do I schedule an appointment with a general physician for a home visit in Mehrauli, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can drop the mail or visit the website and have a chat with the concerned person to schedule the appointment. You can also take the help of phone numbers as mentioned on the website for scheduling the appointment."
      }
    },{
      "@type": "Question",
      "name": "What are the typical services offered by general physicians for home visits in Mehrauli, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": " Routine Check-ups and Physical Examinations \n Diagnosis and Treatment of Acute Illnesses \n hronic Disease Management \n edication Management \n iagnostic Services \n accinations and Immunizations \n ealth Education and Counseling",
      }
    },{
      "@type": "Question",
      "name": "Can general physicians offer emergency home visits in Mehrauli, Delhi?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, emergency service is also provided by general physicians but it will cost more as compared to a normal visit."
      }
    },{
      "@type": "Question",
      "name": "How do the fees for general physician home visits in Mehrauli, Delhi compare to traditional clinic appointments?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There is a reasonable fee for general physicians in the Mehrauli location. It varies from 1000 to 5000 INR depending on the services provided by them."
      }
    }]
  }
  
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Best General Physicians at Home in Mehrauli | Kyno Health</title>
        <meta name="description" content="Kyno Health provides top-notch general physician services at home in Mehrauli. Our experienced doctors offer comprehensive medical care and personalized consultations, ensuring quality healthcare in the comfort of your home. Trust Kyno Health for reliable and convenient home healthcare services in Mehrauli." data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true" />

        <link rel="canonical" href="https://www.kynohealth.com/general-physicians-home-in-mehrauli" />
      </Helmet>

      <Nav />

      <section className="baner-region">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <div className='left-side'>

              <h3>Best General Physicians at Home in Mehrauli</h3>
<p>Finding the best general physicians at home in Mehrauli has become increasingly important, especially for those with limited mobility or chronic health conditions. These physicians offer personalized, comprehensive care tailored to your specific needs, right in the comfort of your own home.</p>
<p>When searching for &lsquo;the best general physicians near me&rsquo; to provide in-home care, look for those with extensive experience, a strong track record of positive patient outcomes, and a compassionate, patient-centered approach. The top general physicians at Kyno Health in Mehrauli will work closely with you to develop a customized treatment plan, monitor your condition, and make adjustments as needed.</p>

              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <div className='right-side'>
                <div className='aft'>
                  <img src='/images/Topology-1.svg' alt='topology' />
                </div>
                <div className='tag'>
                  <Image
                    src={require('../images/tags-1.png')}
                    webp={require('../images/tags-1.webp')}
                    alt='tags1Img'
                  />
                </div>
                <Image
                  src={require('../images/bnr-img.png')}
                  webp={require('../images/bnr-img.webp')}
                  className='bnrimg img-fluid'
                  alt='bnrImg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ms'>
        <div className='container'>

        <h3>Reasons to choose the general physicians at home visit</h3>
<ul>
<li>Accessing the Best General Physicians at Home in Mehrauli is a game-changer for your healthcare needs. With the convenience of having a qualified medical professional coming directly to your doorstep, you can receive top-notch care without the hassle of traveling to a clinic or hospital.</li>
<li>These experienced physicians are equipped to handle a wide range of medical concerns, from routine check-ups to managing chronic conditions. By bringing their expertise to your home, you can avoid the long wait times and crowded waiting rooms often associated with traditional healthcare settings.</li>
<li>Moreover, the personalized attention you receive from the Best General Physicians at Home ensures that your specific health needs are addressed with the utmost care and consideration. These professionals take the time to listen to your concerns, understand your medical history, and develop a tailored treatment plan that caters to your unique requirements.</li>
</ul>
<p>Investing in the services of the Best General Physicians at Home is a wise choice that can significantly improve your overall well-being and quality of life. Embrace the convenience and quality of care that these exceptional medical professionals provide, and experience the difference it can make in your healthcare journey.</p>
<h3>Expectations During a Home Visit from a General Physician in Mehrauli</h3>
<p>When a general physician arrives for a home visit, anticipate a detailed evaluation tailored to your unique health situation. The physician will meticulously gather your health history and conduct a physical examination to understand your current health status. Depending on what they find, they may recommend further testing or procedures to ensure a comprehensive assessment.</p>
<p>A significant focus during the visit is on open communication. The physician will listen intently to your concerns, ensuring that no question goes unanswered. Together, you will work on crafting a care plan that addresses your health objectives, taking into account your environment and lifestyle.</p>
<p>The visit is an opportunity to receive focused and personalized medical advice without the rush often felt in clinical settings. It's designed to be a collaborative process, with the physician offering insights and guidance based on their observations and your input. This approach not only addresses immediate health concerns but also lays the groundwork for ongoing health management strategies.</p>


        </div>
      </section>


      <Faqs items={faqscheme} />
      <Footer />
    </>
  )
}



export default index