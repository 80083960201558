import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'
import Faqs from './Faqs/index'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {
  const faqscheme = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is a general physician?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A general physician, also known as a family doctor or primary care physician, is a medical professional who provides comprehensive healthcare for individuals and families. They are trained to diagnose and treat a wide range of medical conditions and play a crucial role in preventive care."
      }
    },{
      "@type": "Question",
      "name": "What services do general physicians offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians offer a broad spectrum of services, including routine check-ups, preventive care, vaccinations, management of chronic conditions, early disease detection, and coordination with specialists when necessary."
      }
    },{
      "@type": "Question",
      "name": "Why is it important to have a general physician?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Having a general physician is important for several reasons. They provide continuity of care, offer preventive services to maintain health, manage chronic conditions, and serve as a central point of contact for coordinating specialized care when needed."
      }
    },{
      "@type": "Question",
      "name": "How often should I see my general physician?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The frequency of visits may vary based on age, health status, and individual needs. However, a general guideline is to have an annual check-up for adults and more frequent visits for those with chronic conditions or specific health concerns."
      }
    },{
      "@type": "Question",
      "name": "What is the difference between a general physician and a specialist?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians are trained to provide comprehensive care for a wide range of health issues, while specialists focus on specific medical fields or conditions. General physicians often act as coordinators, referring patients to specialists when necessary."
      }
    },{
      "@type": "Question",
      "name": "Can a general physician treat chronic conditions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, general physicians are trained to manage and treat a variety of chronic conditions such as diabetes, hypertension, and asthma. They work in collaboration with specialists to ensure comprehensive and coordinated care."
      }
    },{
      "@type": "Question",
      "name": "How do I choose the right general physician for me?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Consider factors such as location, office hours, communication style, and any specific medical needs you may have. Ask for recommendations from friends, family, or colleagues, and schedule a meet-and-greet appointment to assess compatibility."
      }
    },{
      "@type": "Question",
      "name": "What should I bring to my first appointment with a general physician?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Bring a list of current medications, past medical records, information about your family's medical history, and any specific concerns or symptoms you may be experiencing. This helps your general physician understand your health better."
      }
    },{
      "@type": "Question",
      "name": "Do general physicians provide vaccinations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, general physicians offer vaccinations as part of preventive care. They can provide routine vaccinations for children and adults, as well as recommend specific vaccinations based on individual health needs."
      }
    },{
      "@type": "Question",
      "name": "How do general physicians contribute to preventive care?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "General physicians play a key role in preventive care by conducting regular check-ups, screenings, and providing guidance on healthy lifestyle choices. They focus on identifying and addressing health issues before they become more serious."
      }
    }]
  }
  

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(faqscheme)}
      </script>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>The Role of General Physicians in Your Healthcare Journey</title>
        <meta name="description" content="General Physicians Also known as family doctors, these medical professionals play a pivotal role in preventive care, early diagnosis, and overall well-being." data-react-helmet="true" />

        <meta name="keywords" content="general physician,general physician meaning,general practitioner,general physician specialist,general physician vs general practitioner" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/roles-of-a-general-physician/" />             </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Faqs />
      <Footer />
    </>
  )
}





export default index