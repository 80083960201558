import React from 'react'
import Nav from '../Nav/index'
import Footer from '../Footer/index'
import Hero from './Hero/index'
import Content from './Content/index'

import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'


function index() {

  return (
    <>

      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Kyno - Plum Partnership</title>
        <meta name="description" content="Kyno - Plum Partnership" data-react-helmet="true" />

        <meta name="keywords" content="" data-react-helmet="true"/>

        <link rel="canonical" href="https://www.kynohealth.com/partnership/plum"/>
                     </Helmet>

      <Nav />
      <Hero />
      <Content />
      <Footer />
    </>
  )
}





export default index