import React from 'react'
import Nav from './Google/Nav/index'
import Hero from './Google/Hero/index'
import Footer from './Google/Footer/index'
import Second_Section from './Google/Second_Section/index'
import Third_Section from './Google/Third_Section/index'
import Fourth_Section from './Google/Fourth_Section/index'
import Fifth_Section from './Google/Fifth_Section/index'
import Sixth_Section from './Google/Sixth_Section/index'
import Book_Visit from  './Google/Book_Visit/index'
import Seventh_Section from './Google/Seventh_Section/index'
import Eight_Section from './Google/Eight_Section/index'
import NInth_Section from './Google/Ninth_Section/index'
import { Helmet } from 'react-helmet'
import logo from "./images/logo.png"
import {useNavigate} from 'react-router-dom'



export default function Home_New(){

    const navigate = useNavigate()
    const servicesClicked =()=>{
        const elem = document.getElementById("services");
        if(elem){
            elem.scrollIntoView({'behavior':"smooth"})
        }
    }

    return(
        <div>
            <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>
                    
                    <title>Medical Consultation | Best Physician Doctor in Noida</title>
                    <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment."/>
                    <link rel="canonical" href="https://www.kynohealth.com/"/>

                    <meta property='og:image'  content={logo} />
                    <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
            </Helmet>
            <Nav/>
            <Hero/> 
            <Second_Section/>
            <Third_Section/>
            <Fourth_Section/>
            <Fifth_Section/>
            <Book_Visit />
            <Sixth_Section/>
            <Seventh_Section/>
            <Eight_Section/>
            <NInth_Section/>
    

    <Footer/>

        </div>
    )
}